import { Link } from "react-router-dom";
import { useUserContext } from "../Context/UserContext";
import { useEffect } from "react";

const LoginNavComp = () => {
  const { userInfo } = useUserContext();

  return (
    <div className="row col-10 col-lg-8 justify-content-between  align-items-center p-3 mb-5">
      <h4 className="col">
        <Link to="/employee-dashboard">
          <button className="btn btn-light">Leave Application</button>
        </Link>
      </h4>
      <h4 className="col">
        <Link to="/employee-dashboard/salary-certificate/">
          <button className="btn btn-success">Salary Certificate</button>
        </Link>
      </h4>
      {userInfo.role === "admin" || userInfo.role === "sales" ? (
        <h4 className="col">
          <Link to="/sales-dashboard/">
            <button className="btn btn-warning">Sales Dashboard</button>
          </Link>
        </h4>
      ) : (
        ""
      )}

      {userInfo.role === "admin" ? (
        <h4 className="col">
          <Link to="/admin-dashboard/">
            <button className="btn btn-danger">Admin Dashboard</button>
          </Link>
        </h4>
      ) : (
        ""
      )}

      {/* <h4 className="col">
              <LogoutButton></LogoutButton>
            </h4>
            <h4 className="col">
              <LogoutButton></LogoutButton>
            </h4> */}
    </div>
  );
};
export default LoginNavComp;
