import { } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const PressRelease = () => {
  const { i18n } = useTranslation();
  return (
    <div className="cont-insights-stories  cont-services-digital-cx">
      <div class="container-fluid  mt-5 mb-5">
        <div class="row align-items-center mb-4 mb-md-0">
          <div class="col-12 col-lg-6 p-2 p-md-5  pb-0 pb-lg-5">
            <div className="press-img11 align-items-center justify-content-end "></div>
          </div>
          <div class="col-12 col-lg-6 p-2 p-md-5" >
            <a class="btn btn-outline-danger mb-3"><Trans i18nKey="pressRelease"><Trans i18nKey="pressRelease">Press Release</Trans></Trans></a>
            <a
              href="https://wam.ae/ar/details/1395303185849"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <h3 data-aos="fade-up">
                <num1>
                  <Trans i18nKey="arabYouthCenter">
                    The Arab Youth Center announces the members of the second edition of the "Arab Youth Pioneers"
                  </Trans>
                </num1>
              </h3>
              <p data-aos="fade-up">
                <Trans i18nKey="arabYouthCenterDescription">
                  ABU DHABI, 11th August, 2020 (WAM) -- The Arab
                  Youth Center, chaired by His Highness Sheikh Theyab bin Mohammed
                  bin Zayed Al Nahyan , announced, in conjunction with
                  International Youth Day
                </Trans>
                <i className="blog-read-more"><Trans i18nKey="readMore">...Read More</Trans></i>
              </p>
            </a>
          </div>
        </div>
        <div class="row align-items-center mb-4 mb-md-0">
          <div class="col-12 col-lg-6 p-2 p-md-5  pb-0 pb-lg-5">
            <div className="press-img12 align-items-center justify-content-end"></div>
          </div>
          <div class="col-12 col-lg-6 p-2 p-md-5 text-start ">
            <a class="btn btn-outline-danger mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
            <a
              href="https://www.emaratalyoum.com/life/four-sides/2023-08-12-1.1774116"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <h3 data-aos="fade-up">
                <num1>
                  <Trans i18nKey="arabYouthPioneersTitle">
                    The members of the second edition of the 'Arab Youth Pioneers'
                    announced by the Arab Youth Center.
                  </Trans>
                </num1>
              </h3>
              <p data-aos="fade-up">
                <Trans i18nKey="arabYouthPioneersDescription">
                  The Arab Youth Center, headed by His Highness Sheikh Diab bin
                  Mohammed bin Zayed Al Nahyan, revealed, in conjunction with
                  International Youth Day, the members of the second edition of
                  the "Arab Youth Pioneers" initiative
                  <i className="blog-read-more"> <Trans i18nKey="readMore">...Read More</Trans></i>
                </Trans>
              </p>
            </a>
          </div>
        </div>
        {/* 
        <div class="row align-items-center">
          <div class="col-12 col-lg-6 p-2 p-md-5  pb-0 pb-lg-5">
            <div className="press-img10 align-items-center justify-content-end "></div>
          </div>
          <div class="col-12 col-lg-6 p-2 p-md-5 text-start ">
            <a class="btn btn-outline-danger mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
            <a
              href="https://news.outsourceaccelerator.com/tafaseel-bpo-expands/"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <h3 data-aos="fade-up">
                <num1>Tafaseel BPO expands, eyes 2025 IPO launch</num1>
              </h3>
              <p data-aos="fade-up">
                AJMAN, UNITED ARAB EMIRATES — Business Process Outsourcing (BPO)
                provider Tafaseel BPO recently launched its modernized website
                and inaugurated a fresh office in Egypt as the company
                solidifies its growth trajectory in anticipation of its{" "}
                <num1> 2025 </num1>
                Initial Public Offering (IPO).
                <i className="blog-read-more"><Trans i18nKey="readMore">...Read More</Trans></i>
              </p>
            </a>
          </div>
        </div> */}
      </div>

      <div class="container-fluid">
        <div class="row pt-5 pb-5 careers-cards justify-content-center text-start">
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img9 align-items-center justify-content-end "></div>
            <a
              href="https://www.arabianbusiness.com/resources/tafaseel-bpo-unveils-revamped-website-and-egypt-expansion-as-part-of-its-ipo-vision-for-2025"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <num1>
                    <Trans i18nKey="websiteExpansionTitle">
                      Tafaseel BPO unveils revamped website and Egypt expansion as
                      part of its IPO vision for 2025
                    </Trans>
                  </num1>
                </h5>

                <p className="">
                  <Trans i18nKey="websiteExpansionDescription">
                    <num1>
                      Tafaseel BPO, the largest homegrown Business Process
                      Outsourcing (BPO) company in the UAE, takes a giant stride
                      towards its
                      2025
                      vision with the launch of its revamped website and the opening
                      of a new office in Egypt.
                    </num1>
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img10 align-items-center justify-content-end "></div>
            <a
              href="https://news.outsourceaccelerator.com/tafaseel-bpo-expands/"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 className="card-title">
                  <Trans i18nKey="expansionTitle">
                    <num1>Tafaseel BPO expands, eyes 2025 IPO launch</num1>
                  </Trans>
                </h5>

                <p>
                  <Trans i18nKey="expansionDescription">
                    AJMAN, UNITED ARAB EMIRATES — Business Process Outsourcing
                    (BPO) provider Tafaseel BPO recently launched its modernized
                    website and inaugurated a fresh office in Egypt as the company
                    solidifies its growth trajectory in anticipation of its{" "}
                    <num1> 2025 </num1>
                    Initial Public Offering (IPO).
                  </Trans>
                </p>
              </div>
            </a>
          </div>

          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img5 align-items-center justify-content-end "></div>
            <a
              href="https://www.alittihad.ae/news/%D8%A7%D9%84%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF%D9%8A/4346302/%D8%BA%D8%B1%D9%81%D8%A9-%D8%B9%D8%AC%D9%85%D8%A7%D9%86-%D8%AA%D8%B4%D9%8A%D8%AF-%D8%A8%D9%86%D9%85%D9%88-%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9-%D8%B1%D9%88%D8%A7%D8%AF-%D8%A7%D9%84%D8%A3%D8%B9%D9%85%D8%A7%D9%84-%D8%A7%D9%84%D9%85%D9%88%D8%A7%D8%B7%D9%86%D9%8A%D9%86"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="chamberPraisesTitle">
                    <num1>
                      {" "}
                      Ajman Chamber praises the growth of Emirati entrepreneurs' projects
                    </num1>{" "}
                  </Trans>
                </h5>

                <p className="">
                  <Trans i18nKey="chamberPraisesDescription">
                    Abdullah Al Muwaji, Chairman of the Board of Directors of the Ajman Chamber, speaks about the progress of UAE entrepreneurs
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img3 align-items-center justify-content-end "></div>

            <a
              href="https://www.arabianbusiness.com/culture-society/466894-emirati-entrepreneur-maryam-al-nuaimi-is-first-woman-in-uaes-bpo-industry"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="firstWomanTitle">
                    <num1>First woman in UAE's BPO industry</num1>
                  </Trans>
                </h5>

                <p data-aos="fade-up">
                  <Trans i18nKey="firstWomanDescription">
                    Emirati entrepreneur Maryam Al Nuaimi is the first woman in UAE’s BPO industry.
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img4 align-items-center justify-content-end "></div>
            <a
              href="https://www.arabianbusiness.com/powerlists/arabian-business-30-under-40-list-celebrating-the-future-leaders-of-the-middle-east#Arabian-Business-30-Under-40-List-Table"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i8nKey="arabianBusiness"><num1>Arabian Business 30 Under 40 List</num1></Trans>{" "}
                </h5>

                <p className="">
                  <Trans i18nKey="celebratingFutureLeaders">
                    Celebrating the future leaders of the Middle East
                  </Trans>
                </p>
              </div>
            </a>
          </div>

          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img6 align-items-center justify-content-end "></div>
            <a
              href="https://www.alkhaleej.ae/2023-01-19/%D8%BA%D8%B1%D9%81%D8%A9-%D8%B9%D8%AC%D9%85%D8%A7%D9%86-%D8%AA%D8%B9%D8%B2%D8%B2-%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84-%D8%A7%D9%84%D9%85%D8%A8%D8%A7%D8%B4%D8%B1-%D9%85%D8%B9-%D8%A7%D9%84%D9%82%D8%B7%D8%A7%D8%B9-%D8%A7%D9%84%D8%AE%D8%A7%D8%B5/%D8%A3%D8%B3%D9%88%D8%A7%D9%82-%D8%A7%D9%84%D8%A5%D9%85%D8%A7%D8%B1%D8%A7%D8%AA/%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="chamberEnhancesCommunicationTitle">
                    <num1>
                      {" "}
                      Ajman Chamber enhances direct communication with the private sector
                    </num1>{" "}
                  </Trans>
                </h5>

                <p className="">
                  <Trans i18nKey="chamberEnhancesCommunicationDescription">
                    A delegation from the Ajman Chamber of Commerce and Industry, led by Abdullah Al Muwaiji, Chairman of the Board of Directors, visited the headquarters of Tafaseel.
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img7 align-items-center justify-content-end "></div>
            <a
              href="https://www.wam.ae/ar/details/1395303120770"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="chamberPraisesGrowthTitle">
                    <num1>
                      Ajman Chamber praises the remarkable growth of the projects of Emirati business owners
                    </num1>{" "}
                  </Trans>
                </h5>

                <p className="">
                  <Trans i18nKey="chamberPraisesDescription">
                    Abdullah Al Muwaji, Chairman of the Board of Directors of the Ajman Chamber, speaks about the progress of UAE entrepreneurs
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img8 align-items-center justify-content-end "></div>
            <a
              href="https://www.wam.ae/ar/details/1395303120770"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="ajmanResourcesEventTitle">
                    <num1>
                      "Ajman Resources" organizes the "open day" for immediate job interviews
                    </num1>{" "}
                  </Trans>
                </h5>

                <p className="">
                  <Trans i18nKey="ajmanResourcesEventDescription">
                    The Human Resources Department of the Government of Ajman organized an open day for immediate job interviews for the employment of nationals
                  </Trans>
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PressRelease;
