import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';

const Blog = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);
  return (
    <>
      <div className="container-fluid cont-blog overflow-hidden">
        <div class="row align-items-center justify-content-center">
          <div class="blog-col">
            <div className="blog-mobile mt-4">
              <h1 className="card-title text-center achieve">
                PRESS RELEASE & NEWS
              </h1>
              <div
                className="blog-col-mobile card mb-4 mt-3 p-3 "
                data-aos="fade-up"
              >
                <div className="col">
                  <h3 className="card-title mb-2 blog-issue-h5">
                    <Trans i18nKey="websiteExpansionTitle">
                      Tafaseel BPO unveils revamped website and Egypt expansion as
                      part of its IPO vision for 2025
                    </Trans>
                  </h3>
                  <p data-aos="fade-up">

                    <Trans i18nKey="websiteExpansionDescription">
                      <num1>
                        Tafaseel BPO, the largest homegrown Business Process
                        Outsourcing (BPO) company in the UAE, takes a giant stride
                        towards its
                        2025
                        vision with the launch of its revamped website and the opening
                        of a new office in Egypt.
                      </num1>
                    </Trans>
                    <a
                      href="https://www.arabianbusiness.com/resources/tafaseel-bpo-unveils-revamped-website-and-egypt-expansion-as-part-of-its-ipo-vision-for-2025"
                      className="ps-2 blog-read-more"
                      target="_blank"
                    >
                      <i>...Read More</i>
                    </a>
                  </p>
                </div>
              </div>
              <div
                className="blog-col-mobile card mb-4 mt-3 p-3 "
                data-aos="fade-up"
              >
                <div className="col">
                  <h3 className="card-title mb-2 blog-issue-h5">
                    <Trans i18nKey="callCentersTitle">
                      <nums1>Call centers for free from Tafaseel</nums1>
                    </Trans>
                  </h3>
                  <p data-aos="fade-up">
                    <Trans i18nKey="callCentersDescription">
                      Eng. Ahmed Khabeer, The CEO of the Tafaseel group of companies, specializing in call center services and IT, announced the provision of their services free of charge to the government. This initiative aims to support the state's efforts in combating the spread of the 'Corona' virus.
                    </Trans>
                    <a
                      href="https://www.albayan.ae/economy/local-market/2020-03-29-1.3815157"
                      className="ps-2 blog-read-more"
                      target="_blank"
                    >
                      <i>...Read More</i>
                    </a>
                  </p>
                </div>
              </div>
              <div
                className="blog-col-mobile card mb-4  p-3 "
                data-aos="fade-up"
              >
                <div className="col">
                  <h3 className="card-title mb-2 blog-issue-h5">
                    <Trans i18nKey="firstWomanTitle">
                      <num1>First woman in UAE's BPO industry</num1>
                    </Trans>
                  </h3>
                  <p data-aos="fade-up">
                    Emirati entrepreneur Maryam Al Nuaimi is first woman in
                    UAE’s BPO industry. In an exclusive interview with Arabian
                    Business, Nuaimi opened up about her journey to gain the
                    trust of society and her clients in the business process
                    outsourcing industry
                    <a
                      href="https://www.arabianbusiness.com/culture-society/466894-emirati-entrepreneur-maryam-al-nuaimi-is-first-woman-in-uaes-bpo-industry"
                      className="ps-2 blog-read-more"
                      target="_blank"
                    >
                      <i>...Read More</i>
                    </a>
                  </p>
                </div>
              </div>

              <div
                className="blog-col-mobile card mb-4 mt-3 p-3 "
                data-aos="fade-up"
              >
                <div className="col">
                  <h5 className="card-title mb-2 blog-issue-h5">
                    <Trans i8nKey="arabianBusiness">Arabian Business 30 Under 40 List</Trans>
                  </h5>
                  <p className="" data-aos="fade-up">
                    <Trans i18nKey="celebratingFutureLeaders">
                      Celebrating the future leaders of the Middle East
                    </Trans>
                    <a
                      href="https://www.arabianbusiness.com/powerlists/arabian-business-30-under-40-list-celebrating-the-future-leaders-of-the-middle-east#Arabian-Business-30-Under-40-List-Table"
                      className="ps-2 blog-read-more"
                      target="_blank"
                    >
                      <i>...Read More</i>
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="blog-desk">
              <h3 className="card-title mb-4" data-aos="fade-up">
                <b> <num1>Tafaseel BPO unveils revamped website and Egypt expansion as
                  part of its IPO vision for 2025</num1></b>
              </h3>
              <a
                href="https://www.arabianbusiness.com/resources/tafaseel-bpo-unveils-revamped-website-and-egypt-expansion-as-part-of-its-ipo-vision-for-2025"
                className=""
                target="_blank"
              >
                <p data-aos="fade-up">
                  Tafaseel BPO, the largest homegrown Business Process
                  Outsourcing (BPO) company in the UAE, takes a giant stride
                  towards its <num1>2025</num1> vision with the launch of its revamped
                  website and the opening of a new office in Egypt. These
                  strategic moves underscore Tafaseel BPO’s commitment to
                  excellence and its ambitious vision for expanding its global
                  reach and services in the coming years.
                  <a className="ps-2 blog-read-more">
                    <i>...Read More</i>
                  </a>
                </p>
              </a>
            </div>

            <div class="Path_140" data-aos="fade-up">
              <svg class="Path_140" viewBox="2.064 0.69 626.237 244.153">
                <path
                  id="Path_140"
                  d="M 156.186767578125 244.8430023193359 L 628.3006591796875 244.8430023193359 C 603.8256225585938 153.4316558837891 562.6256103515625 70.48690032958984 508.8406066894531 0.6899999380111694 L 2.064000129699707 0.6899999380111694 C 71.42018890380859 60.78668594360352 125.6047897338867 145.6458892822266 156.186767578125 244.8430023193359"
                ></path>
              </svg>
              <div className="row row-text Path_140-t" data-aos="fade-up">
                <div className="col">
                  <a
                    href="https://www.arabianbusiness.com/powerlists/arabian-business-30-under-40-list-celebrating-the-future-leaders-of-the-middle-east#Arabian-Business-30-Under-40-List-Table"
                    className="ps-2"
                    style={{ color: "white" }}
                    target="_blank"
                  >
                    <h5 className="card-title mb-2 blog-issue-h5">
                      Arabian Business 30 Under 40 List
                    </h5>
                    <p className="text-center">
                      Celebrating the future leaders of the Middle East
                      <a
                        href="https://www.arabianbusiness.com/powerlists/arabian-business-30-under-40-list-celebrating-the-future-leaders-of-the-middle-east#Arabian-Business-30-Under-40-List-Table"
                        className="ps-2 blog-read-more"
                        target="_blank"
                      >
                        <i>...Read More</i>
                      </a>
                      {/* <a className="ps-2 blog-read-more">Read More</a> */}
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="Path_142" data-aos="fade-up">
              <svg class="Path_142" viewBox="72.519 107.587 200 244.153">
                <path
                  id="Path_142"
                  d="M 87.82536315917969 247.6519317626953 C 87.82536315917969 283.2884216308594 84.93622589111328 318.0888061523438 79.46290588378906 351.7400207519531 L 551.676025390625 351.7400207519531 C 555.5889282226562 320.5315551757812 557.6529541015625 288.6369018554688 557.6529541015625 256.18115234375 C 557.6529541015625 205.0929870605469 552.6240844726562 155.3555297851562 543.1220703125 107.5869827270508 L 72.51899719238281 107.5869827270508 C 82.47737121582031 152.1883697509766 87.82536315917969 199.1481018066406 87.82536315917969 247.6519317626953"
                ></path>
              </svg>
              <div className="row row-text Path_142-t">
                <div className="col">
                  <a
                    href="https://www.albayan.ae/economy/local-market/2020-03-29-1.3815157"
                    style={{ color: "white" }}
                    target="_blank"
                  >
                    <h5 className="card-title mb-2">PRESS RELEASE & NEWS</h5>
                    <p data-aos="fade-up">
                      Eng. Ahmed Khabeer, Tafaseel group's CEO offers free call
                      center services to support government's fight against
                      "Corona" virus.
                      <a
                        href="https://www.albayan.ae/economy/local-market/2020-03-29-1.3815157"
                        className="ps-2 blog-read-more"
                      >
                        <i>...Read More</i>
                      </a>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="Path_144" data-aos="fade-up">
              <svg class="Path_144" viewBox="17.717 214.483 601.322 244.153">
                <path
                  id="Path_144"
                  d="M 17.71700096130371 458.635986328125 L 524.349365234375 458.635986328125 C 569.2919921875 386.706787109375 602.0088500976562 303.9826354980469 619.038818359375 214.4830017089844 L 144.7261657714844 214.4830017089844 C 121.539680480957 310.1017456054688 76.91624450683594 394.3047180175781 17.71700096130371 458.635986328125"
                ></path>
              </svg>
              <div id="text_ba">
                <div className="row row-text Path_144-t">
                  <div className="col">
                    {/* <Link to="/group-management" style={{ color: "white" }}> */}
                    <a
                      href="https://www.alkhaleej.ae/2023-01-19/%D8%BA%D8%B1%D9%81%D8%A9-%D8%B9%D8%AC%D9%85%D8%A7%D9%86-%D8%AA%D8%B9%D8%B2%D8%B2-%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84-%D8%A7%D9%84%D9%85%D8%A8%D8%A7%D8%B4%D8%B1-%D9%85%D8%B9-%D8%A7%D9%84%D9%82%D8%B7%D8%A7%D8%B9-%D8%A7%D9%84%D8%AE%D8%A7%D8%B5/%D8%A3%D8%B3%D9%88%D8%A7%D9%82-%D8%A7%D9%84%D8%A5%D9%85%D8%A7%D8%B1%D8%A7%D8%AA/%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF"
                      style={{ color: "white" }}
                      target="_blank"
                    >
                      <h5 className="card-title mb-2">
                        Ajman Chamber enhances direct communication
                      </h5>
                      <p data-aos="fade-up">
                        Ajman Chamber enhances direct communication with private
                        sector
                        <a
                          href="https://www.alkhaleej.ae/2023-01-19/%D8%BA%D8%B1%D9%81%D8%A9-%D8%B9%D8%AC%D9%85%D8%A7%D9%86-%D8%AA%D8%B9%D8%B2%D8%B2-%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84-%D8%A7%D9%84%D9%85%D8%A8%D8%A7%D8%B4%D8%B1-%D9%85%D8%B9-%D8%A7%D9%84%D9%82%D8%B7%D8%A7%D8%B9-%D8%A7%D9%84%D8%AE%D8%A7%D8%B5/%D8%A3%D8%B3%D9%88%D8%A7%D9%82-%D8%A7%D9%84%D8%A5%D9%85%D8%A7%D8%B1%D8%A7%D8%AA/%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF"
                          className="ps-2 blog-read-more"
                        >
                          <i>...Read More</i>
                        </a>
                      </p>
                    </a>

                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid ">
        <div className="row">
          
        </div>
      </div> */}
    </>
  );
};
export default Blog;
