import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import { useState } from "react";

const ScrollTrigger1 = () =>{
  const [counterOn, setCounterOn] = useState(false);

    return(
        <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <div class="ps-md-5 pe-md-5 mt-5 mb-5 cont-point">
          <h1 className="pb-md-4 pe-2 ps-2 achieve" data-aos="fade-up">
            Live Stats
          </h1>
          <div class="row justify-content-evenly">
          <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={1000} duration={4} />}
                +
              </h1>
              <h2>Employees</h2>
            </div>
            
            <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={30} duration={6} />}
                
              </h1>
              <h2>Nationalities</h2>
            </div>
            <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={13} duration={5} />}
                
              </h1>
              <h2>Languages</h2>
            </div>
         
          </div>
          <div className="row justify-content-evenly">
            <div class="col-12 col-md-4 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={2.5} duration={6}  decimals={1} />}
                M+
              </h1>
              <h2>Lives Touched</h2>
            </div>
            <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={10} duration={5} />}
                M+
              </h1>
              <h2>Successful Rings</h2>
            </div>
            <div class="col-12 col-md-4 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={2.4} duration={5} decimals={1} />}
                B+
              </h1>
              <h2>Payment Processed</h2>
            </div>
          
          </div>
          <div class="row justify-content-evenly">
            
            <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={6.2} duration={6} decimals={1} />}
                M+
              </h1>
              <h2>Live Chats</h2>
            </div>
            <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={2.8} duration={5} decimals={1} />}
                M+
              </h1>
              <h2>Emails Handled</h2>
            </div>
            <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>
                {counterOn && <CountUp end={7} duration={5}  />}
              </h1>
              <h2>Sites</h2>
            </div>
            {/* <div class="col-12 col-md-3 p-3" data-aos="fade-up">
              <h1>{counterOn && <CountUp end={2500} duration={5} />}+</h1>
              <h2>Social Swings</h2>
            </div> */}
          </div>
         
        </div>
      </ScrollTrigger>
    )
}

export default ScrollTrigger1