import { Trans, useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const {i18n} = useTranslation();
  return (
    <div className="cont-privacy-policy pt-lg-5">
      <div className="privacy-img">
        <h1 className="mb-5"><Trans i18nKey="privacyPolicy">Privacy Policy</Trans></h1>
      </div>

      {/* <h1>Hello</h1> */}
      <div className="footer-notice-text" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'start' }}>
        <div className="container ps-5 pe-5 ">
          <div className="row">
            <h5><Trans i18nKey="privacyPolicy">Privacy Policy</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="privacyPoint1">
              This privacy notice discloses the privacy practices for Tafaseel
              BPO. This privacy notice applies solely to information collected
              by this website. It will notify you of the following:
              </Trans>
            </p>
            <p>
              <Trans i18nKey="privacyPoint2">
              What personally identifiable information is collected from you
              through the website, how it is used, and with whom it may be
              shared.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="privacyPoint3">
              What choices are available to you regarding the use of your data.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="privacyPoint4">
              The security procedures in place to protect the misuse of your information.
              </Trans>
            </p>

            <p>
              <Trans i18nKey="privacyPoint5">
              How you can correct any inaccuracies in the information.
              </Trans>
            </p>
          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5><Trans i18nKey="registration">Registration</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="registrationDescription">
              In order to use this website, a user must first complete the registration form. During registration, a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest.
              </Trans>
            </p>
          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5><Trans i18nKey="electronicPayments">Electronic Payments</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="electronicPaymentsDescription">
              Tafaseel BPO uses CCAvenue online payment gateway for online payment transactions.
              </Trans>
            </p>
          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5><Trans i18nKey="informationCollection">Information Collection, Use, and Sharing</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="informationCollectionDescription">
              We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone. We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request. Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products, or services, or changes to this privacy policy.
              </Trans>
            </p>
          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5><Trans i18nKey="informationCollection">Information Collection, Use, and Sharing</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="informationCollectionDescription">
              We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone. We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request. Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products, or services, or changes to this privacy policy.
              </Trans>
            </p>
          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5><Trans i18nKey="sharing">Sharing</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="sharingDescription">
              We share aggregated demographic information with our advertisers. This is not linked to any personal information that can identify any individual person.
              </Trans>
            </p>
          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5><Trans i18nKey="yourAccessToControlOverInformation">Your Access to and Control Over Information</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="yourAccessToControlOverInformationDescription">
              You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:
              </Trans>
            </p>
            <p><Trans i18nKey="sharingPoint1">See what data we have about you, if any.</Trans></p>
            <p><Trans i18nKey="sharingPoint2">Change/correct any data we have about you.</Trans></p>
            <p><Trans i18nKey="sharingPoint3">Have us delete any data we have about you.</Trans></p>
            <p>
              <Trans i18nKey="sharingPoint4">Express any concern you have about our use of your data. Security</Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5><Trans i18nKey="security">Security</Trans></h5>
            <p className="p-blue">
              <Trans i18nKey="securityDescription">
              We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the Web page.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="securityDescription2">
              While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="securityDescription3">
              If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 600599996 or via email at info@tafaseel.ae.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
