import { Trans } from "react-i18next"

const VideosComp = () => {

    return (
        <>
            <h1><Trans i18nKey="comingSoon">Coming Soon..</Trans></h1>
        </>
    )
}
export default VideosComp