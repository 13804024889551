import axios from "axios";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import jsonp from "jsonp";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { db } from "../firebaseConfig";
import { Trans, useTranslation } from "react-i18next";

const EnquiryFormSmall = (prop) => {
  const { t } = useTranslation();
  let subProp = prop.subProp !== undefined ? prop.subProp : "Contact Us";
  console.log(subProp);
  const [formNameVal, setFormNameVal] = useState("");
  const [formEmailVal, setFormEmailVal] = useState("");
  const [phone, setPhone] = useState("");
  const [formMsgVal, setFormMsgVal] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formspreeUrl = process.env.REACT_APP_FORMSPREE_URL;
    // Get the form data
    const formData = new FormData(event.target);
    // const formDataObject = {};

    // for (let [name, value] of formData.entries()) {
    //   formDataObject[name] = value;
    // }
    // console.log(formDataObject);

    // console.log(formNameVal);
    // console.log(formEmailVal);
    // console.log(phone);
    // console.log(formMsgVal);

    try {
      await addDoc(collection(db, "sales-leads"), {
        NAME: formNameVal,
        EMAIL: formEmailVal,
        PHONE: phone,
        MESSAGE: formMsgVal,
        Timestamp: serverTimestamp(),
        LeadStatus: "Not Contacted",
        Source: "Paid",
        Subject: subProp,
      });
      alert("Form submitted successfully!");
    } catch (err) {
      console.log(err);
    }

    // console.log(phone);

    // submitNotifyForm(formData);
    try {
      // Send a POST request to Formspree endpoint
      await axios.post(formspreeUrl, formData);

      // Perform any desired actions after successful form submission
      // For example, display a success message
      alert("Form submitted successfully!");
      event.target.reset();
      setPhone("");
      submitNotifyForm(formData);
    } catch (error) {
      // Handle any errors that occur during form submission
      // For example, display an error message
      alert("Form submission failed!");
    }

    setFormNameVal("");
    setFormEmailVal("");
    setPhone("");
    setFormMsgVal("");
  };

  const submitNotifyForm = (formData) => {
    const url = process.env.REACT_APP_API_URL;

    let queryString = "";
    for (const [name, value] of formData.entries()) {
      queryString += `&${encodeURIComponent(name)}=${encodeURIComponent(
        value
      )}`;
    }
    // console.log(`${url}${queryString}`);

    jsonp(`${url}${queryString}`, { param: "c" }, (_, data) => {
      const { msg, result } = data;
      // do something with the response
      // alert(msg);
    });
  };
  return (
    <div className="container-fluid cont-form">
      <div className="row justify-content-center align-items-center">
        <form
          className="ps-4 pe-4 ps-md-5 pe-md-5 col-12  pt-4 pb-5"
          onSubmit={handleSubmit}
        >
          <h1 className="pb-3 form-text-h1"><Trans i18nKey="letsTalk">Let's Talk</Trans></h1>

          <div className="row">
            <div class="mb-4 col-12 col-lg-4">
              <input name="SUBJECT" type="hidden" value="Contact Us" />
              <input
                name="NAME"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder={t('fullName')}
                value={formNameVal}
                onChange={(e) => setFormNameVal(e.target.value)}
                required
              ></input>
            </div>

            <div class="mb-4 col-12 col-lg-4">
              <input
                name="EMAIL"
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder={t('yourEmail')}
                value={formEmailVal}
                onChange={(e) => setFormEmailVal(e.target.value)}
                style={{ fontFamily: "sans-serif" }}
                required
              ></input>
            </div>
            <div class="mb-4 col-12 col-lg-4">
              <num1>
                <PhoneInput
                  country={"ae"}
                  value={phone}
                  onChange={(value, country, e, formattedValue) => {
                    setPhone(formattedValue); // Update the raw phone value in the state
                  }}
                  class="form-control"
                  placeholder={t('phoneNo')}
                  required
                />
              </num1>
              <input name="PHONE" type="hidden" value={phone} />

              {/* <input
                name="PHONE"
                type="number"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Phone No. (*)"
                required
                style={{ fontFamily: "sans-serif" }}
              ></input> */}
            </div>
          </div>

          <div class="mb-4">
            <textarea
              name="MESSAGE"
              class="form-control"
              placeholder={t('yourMessage')}
              id="floatingTextarea2"
              value={formMsgVal}
              onChange={(e) => setFormMsgVal(e.target.value)}
              style={{ height: "100px", fontFamily: "sans-serif" }}
              required
            ></textarea>
          </div>
          {/* <div class="mb-4">
            <label
              class="form-check-label text-start text-black"
              for="exampleCheck1"
            >
              <input
                type="checkbox"
                name="Consent Checkbox"
                className=""
                required
              ></input>{" "}
              I consent to receive relevant industry news, analyst reports,
              white papers, case studies, and product information from Tafaseel.
              Tafaseel will never share or sell your information to third
              parties.{" "}
              <a
                href="https://www.tafaseel.ae/#/privacy-policy/"
                target="_blank"
                className="privacy-anchor"
              >
                See Tafaseel's Privacy Policy
              </a>
            </label>
          </div> */}
          <button type="submit" class="btn col-12 col-md-4 pt-2 pb-2">
            <Trans i18nKey="getAQuote">Get A Quote</Trans>
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnquiryFormSmall;
