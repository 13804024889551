// import aboutSvg from '../Assets/images/about.svg'
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";

const About = () => {
 
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);
  return (
    <div className="container about-cont mt-4 ">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-5 col-lg-5 pt-2 pb-2" data-aos="fade-up">
          <img
            src={"https://github.com/Cypher-inc/syni-p/blob/master/home/about.775f4ea1c3a53a2fd4fc.png?raw=true"}
            className="img-fluid"
          ></img>
        </div>
        <div className="col-12 col-sm-12 col-md-7 col-lg-7  text-start">
          <p className="about-content" data-aos="fade-up">
            <Trans i18nKey={'aboutUsDescription'}>Tafaseel is UAE’s home grown largest business process outsourcing
            company. Delivering best outsourcing services, skilled people,
            premium places, stringent processes and cutting edge technology</Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
