import Accordion from "react-bootstrap/Accordion";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import EnquiryFormSmall from "../EnquirFormSmall";
import { Trans } from "react-i18next";

const ContactCenterLanding = (props) => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const [counterOn, setCounterOn] = useState(false);

  return (
    <div className="cont-services-digital-cx">
      <Helmet>
        <title>
          Elevate Customer Interactions: Contact Center Outsourcing with
          Tafaseel BPO
        </title>
        <meta
          name="description"
          content="Discover the power of contact center outsourcing with Tafaseel BPO. Let our expert team manage your customer interactions across channels, providing exceptional support and enhancing customer satisfaction. Benefit from cost-efficient solutions, omnichannel support, and 24/7 availability, while focusing on your core business activities."
        />
      </Helmet>
      <div className="contact-img0 align-items-center mt-md-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6 mt-5">
            <h1 className="mb-5"><Trans i18nKey="contactCenter">Contact Center Outsourcing</Trans></h1>
          </div>
          <div className="col-12 col-lg-6">
            <EnquiryFormSmall subProp={props.subProp}></EnquiryFormSmall>
          </div>
        </div>

        {/* <div class="navbar-buttons mbr-section-btn">
        <Link to="/contact">
            <a class="btn btn-mobile p-2 ps-5 pe-5" href="./contact">
              Contact Us
            </a>
          </Link>
        </div> */}
      </div>

      {/* <div class="container-fluid  mt-5 mb-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 p-5">
            <div className="digital-img2 align-items-center justify-content-end"></div>
          </div>
          <div class="col-12 col-md-6 p-5 text-start">
            <h3 data-aos="fade-up">Lorem ipsum dolor sit amet</h3>
            <p className="pe-4 " data-aos="fade-up">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>
      </div> */}

      {/* ////////////////Intelligent solutions/////////////////// */}
      <div className="container-fluid intelligent-text">
        <div className="row align-items-center">
          <div class="col-12 col-md-6 p-5 text-start">
            <h3 data-aos="fade-up">
              <Trans i18nKey="contactCenterOutsourcingTitle">What is Contact Center Outsourcing?</Trans>
            </h3>
            <p className="pe-4" data-aos="fade-up">
              <Trans i18nKey="contactCenterOutsourcingDescription">
                Contact center outsourcing involves partnering with a specialized firm like Tafaseel BPO to handle your customer interactions across various channels. By outsourcing your contact center operations, you can tap into a dedicated team of contact center experts who excel in providing prompt, knowledgeable, and personalized support to your customers. This allows you to focus on core business activities while ensuring exceptional customer experiences.
              </Trans>
            </p>
          </div>
          <div class="col-12 col-md-6 p-5 text-start" data-aos="fade-up">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header><Trans i18nKey="costEfficiencyTitle">Cost Efficiency</Trans></Accordion.Header>
                <Accordion.Body>
                  By outsourcing your contact center function to Tafaseel BPO,
                  you can significantly reduce costs associated with setting up
                  and managing an in-house contact center. Our cost-effective
                  solutions provide access to skilled professionals and advanced
                  technologies without the burden of additional expenses.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Omnichannel Support</Accordion.Header>
                <Accordion.Body>
                  Tafaseel BPO offers support across multiple communication
                  channels, including phone, email, chat, and social media. Our
                  contact center experts are trained to handle customer
                  inquiries, resolve issues, provide technical support, and
                  offer assistance across various platforms.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <num1>24/7 </num1> Customer Support
                </Accordion.Header>
                <Accordion.Body>
                  With Tafaseel BPO, you can provide round-the-clock customer
                  support. Our contact center operates <num1>24/7 </num1>,
                  ensuring that your customers receive assistance whenever they
                  need it, regardless of time zones or geographic locations.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Enhanced Customer Satisfaction
                </Accordion.Header>
                <Accordion.Body>
                  Exceptional customer support leads to higher customer
                  satisfaction and loyalty. Tafaseel BPO's dedicated contact
                  center professionals are trained to deliver personalized,
                  empathetic, and efficient support, ensuring that your
                  customers have a positive experience with your brand.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <div className=" cont-stats  pt-5 mb-5 cont-process">
          <h1 style={{ fontSize: "3em" }}>Process</h1>
          <h1 style={{ fontSize: "3em" }}>Highlights</h1>
          <div className="container pt-2 pt-md-4">
            <div className="row text-center justify-content-evenly">
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Icon3.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "100px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5 mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12 p-3" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={2000000} duration={6} />}+
                      </h2>
                      <p><Trans i18nKey="chatsHandled">Chats Handled</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Icon2.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "100px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5  mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12 p-3" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={6000000} duration={6} />}+
                      </h2>
                      <p>Emails Handled</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Icon4.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "100px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5 mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12 p-3" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={10000000} duration={6} />}+
                      </h2>
                      <p><Trans i18nKey="paymentsProcessed">Payments Processed</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Icon.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "100px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5 mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12 p-3" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={4000000} duration={6} />}+
                      </h2>
                      <p><Trans i18nKey="chatsHandled">Chats Handled</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-2 pt-md-4 cont-stats2 d-none d-md-block">
            <div className="row text-center justify-content-evenly flex-flow-wrap">
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class=" mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={95} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="fcrScore">FCR Score%</Trans></p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class=" mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={93} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="cSatScore">C-SAT SCore%</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class="mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={94} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="mktScore">MKT Score%</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class=" mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={92} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="qualityScore">Quality Score%</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class="mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={87} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="serviceLevel">Service Level%</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class=" mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={97} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="answeredRate">Answered Rate%</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class=" mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={3} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="abandonedRate">Abandoned Rate%</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-1 p-0">
                <div class=" mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2>
                        {counterOn && <CountUp end={85} duration={6} />}%
                      </h2>
                      <p><Trans i18nKey="utilizationRate">Utilization Rate%</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container pt-2 pt-md-4">
            <div className="row text-center justify-content-evenly">
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Vector%20Smart%20Object.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "200px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5 mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12 p-3" data-aos="fade-up">
                      <h2>{counterOn && <CountUp end={12} duration={6} />}+</h2>
                      <p><Trans i18nKey="privateSector">Private Sector</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Process.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "200px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5  mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12 p-3" data-aos="fade-up">
                      <h2>{counterOn && <CountUp end={13} duration={6} />}+</h2>
                      <p><Trans i18nKey="governmentEntities">Government Entities</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Vector%20Smart%20Object-1.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "200px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5 mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12 p-3" data-aos="fade-up">
                      <h2>{counterOn && <CountUp end={54} duration={6} />}+</h2>
                      <p><Trans i18nKey="managedServiceProjects">Managed Service Projects</Trans></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <img
                  src="https://github.com/Cypher-inc/syni-p/blob/master/about-us/Process/Vector%20Smart%20Object-2.png?raw=true"
                  class="card-img-top mb-2"
                  alt="..."
                  style={{ height: "200px", width: "auto" }}
                ></img>

                <div class="ps-md-5 pe-md-5 mb-md-5 cont-point">
                  <div class="row justify-content-between">
                    <div class="col-12" data-aos="fade-up">
                      <h2><Trans i18nKey="fromUAEToTheWorld">From UAE to the world</Trans></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollTrigger>
      {/* ////////////////////////////////////////// */}

      <div
        id="carouselExampleFade"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card col-lg-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <Trans i18nKey={"skilledContactCenterTitle"}>Skilled Contact Center Professionals</Trans>
                  </h5>
                  <p class="card-text p-3">
                    <Trans i18nKey={"skilledContactCenterDescription"}>
                      Tafaseel BPO brings a team of highly skilled contact center
                      professionals with extensive experience in delivering
                      outstanding customer support. Our experts are trained to
                      handle various customer scenarios, ensuring prompt and
                      effective resolutions.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-4 col-lg-3">
                <div class="card-body">
                  <h5 className="card-title">
                    <Trans i18nKey="cuttingEdgeTechnologiesTitle">Cutting-edge Technologies</Trans>
                  </h5>
                  <p className="card-text p-3">
                    <Trans i18nKey="cuttingEdgeTechnologiesDescriptionContactCenter">
                      We leverage advanced contact center technologies and tools to optimize efficiency and deliver seamless customer experiences. Our omni-channel support systems enable consistent and cohesive customer interactions across multiple channels.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-4 col-lg-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <Trans i18nKey="flexibleSolutionsTitle">Flexible Solutions</Trans>
                  </h5>
                  <p class="card-text p-3">
                    <Trans i18nKey="flexibleSolutionsDescription">
                      Tafaseel BPO provides flexible contact center solutions tailored to your
                      specific needs. Whether you require full-time support, overflow assistance
                      during peak periods, or after-hours support, we can customize our services
                      to meet your requirements.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card  col-lg-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <Trans i18nKey="dataDrivenInsightsTitle">Data-driven Insights</Trans>
                  </h5>
                  <p class="card-text p-3">
                    <Trans i18nKey="dataDrivenInsightsDescription">
                      We believe in continuous improvement and optimization. Tafaseel BPO
                      utilizes data analytics and customer feedback to gain valuable insights
                      into customer preferences, pain points, and trends. This enables us to
                      continuously refine our contact center strategies and deliver even better
                      results.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-4 col-lg-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <Trans i18nKey={"skilledContactCenterTitle"}>Skilled Contact Center Professionals</Trans>
                  </h5>
                  <p class="card-text p-3">
                    <Trans i18nKey={"skilledContactCenterDescription"}>
                      Tafaseel BPO brings a team of highly skilled contact center
                      professionals with extensive experience in delivering
                      outstanding customer support. Our experts are trained to
                      handle various customer scenarios, ensuring prompt and
                      effective resolutions.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-4 col-lg-3">
                <div class="card-body">
                  <h5 className="card-title">
                    <Trans i18nKey="cuttingEdgeTechnologiesTitle">Cutting-edge Technologies</Trans>
                  </h5>
                  <p className="card-text p-3">
                    <Trans i18nKey="cuttingEdgeTechnologiesDescriptionContactCenter">
                      We leverage advanced contact center technologies and tools to optimize efficiency and deliver seamless customer experiences. Our omni-channel support systems enable consistent and cohesive customer interactions across multiple channels.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default ContactCenterLanding;
