import { Button, Modal } from "react-bootstrap";
import LoginPage from "./LoginPage";
import { Trans, useTranslation } from "react-i18next";

const LoginModalComp = (props) => {
  const { i18n } = useTranslation();
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.handleClose}
        centered
        dialogClassName="modal-center"
        size="md"
        style={{direction: `${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}}
      >
        <Modal.Header closeButton={true} className="justify-content-center" style={{gap: i18n.language === 'ar' ?  '16rem' : '21rem' }}>
          <Modal.Title className="form-text-h1"><h2><Trans i18nKey="login">Login</Trans></h2></Modal.Title>
        </Modal.Header>

        <Modal.Body className="mb-5">
          <LoginPage handleClose={props.handleClose}></LoginPage>{" "}
          {/* Pass handleClose to the Login component */}
        </Modal.Body>
        
      </Modal>
    </>
  );
};
export default LoginModalComp;
