import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
// import "../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css"
import "./App.css";
import TagManager from 'react-gtm-module';
// import Nav from "./comps/Nav";
import CarouselHome from "./comps/CarouselHome";
import About from "./comps/About";
// import Nums from "./comps/Nums";
// import Services from "./comps/Services";
// import Testimony from "./comps/Testimony";
// import Blog from "./comps/Blog";
import Footer from "./comps/Footer";
// import New_nav from "./comps/New_nav";
import { Routes, Route, Navigate } from "react-router-dom";
// import Media from "./comps/Media/Media";
// import Industries from "./comps/Industries/Industries";
// import Consulting from "./comps/Consulting/Consulting";
// import Values from "./comps/Why_Ts/Values";
// import GroupManagement from "./comps/Why_Ts/GroupManagement";
// import GroupOfCompanies from "./comps/Why_Ts/GroupOfCompanies";
// import PeopleTrust from "./comps/Why_Ts/PeopleTrust";
// import DigitalCx from "./comps/Services/DigitalCx";
// import TrustSafety from "./comps/Services/TrustSafety";
// import ArtificialIntelligence from "./comps/Services/ArtificialInteligence";
// import VideoCx from "./comps/Services/VideoCx";
// import Metaverse from "./comps/Services/Metaverse";
// import BackOffice from "./comps/Services/BackOffice";
// import IntegratedSales from "./comps/Services/IntegratedSales";
// import Accounting from "./comps/Services/Accouting";
// import Extra from "./comps/Extra";

// import Procurrement from "./comps/Services/Procurement";
// import Warehousing from "./comps/Services/Warehousing";
// import Transportation from "./comps/Services/Transportation";
// import Accomodation from "./comps/Services/Accommodation";
// import DigitalTransformation from "./comps/Services/DigitalTransformation";
// import Workspace from "./comps/Services/Workspace";
// import KnowledgeProcess from "./comps/Services/KnowledgeProcess";
// import FinanceOperations from "./comps/Services/FinanceOperations";
// import SalesOutsourcing from "./comps/Services/SalesOutsourcing";
// import MarketingOutsourcing from "./comps/Services/MarketingOutsourcing";
// import CustomerServices from "./comps/Services/CustomerServices";
// import ContactCenter from "./comps/Services/ContactCenter";
// import Outsourcing from "./comps/Services/Outsourcing";
// import HumanResources from "./comps/Services/HumanResources";
// import ITOutsourcing from "./comps/Services/ITOutsourcing";
// import MediaCommunication from "./comps/Industries/MediaCommunication";
// import Automotive from "./comps/Industries/Automotive";
// import Retail from "./comps/Industries/Retail";
// import HealthCare from "./comps/Industries/HealthCare";
// import FoodBeverages from "./comps/Industries/FoodBeverages";
// import TravelTours from "./comps/Industries/TravelTours";
// import E_Commerce from "./comps/Industries/E_Commerce";
// import GovernmentPublicService from "./comps/Industries/GovernmentPublicService";
// import RealEstateFacilityManagement from "./comps/Industries/RealEstateFacilityManagement";
// import ConsumerGoods from "./comps/Industries/ConsumerGoods";
// import LogisticsTransportationIndustry from "./comps/Industries/LogisticsTransportationIndustry";
// import OilGasCompanies from "./comps/Industries/OilGasCompanies";
// import EducationCallCenterServices from "./comps/Industries/EducationCallCenterServices";
// import CountdownTimer from "./comps/CountdownTimer";
// import SignupForm from "./comps/SignupForm";
import EnquiryForm from "./comps/EnquiryForm";

import React, { lazy, Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ScrollTrigger1 from "./comps/ScrollTrigger1";
import NewNav2 from "./comps/NewNav2";
import PrivacyPolicy from "./comps/FooterComps/PrivacyPolicy";
import FairUsage from "./comps/FooterComps/FairUsage";
import TermsOfService from "./comps/FooterComps/TermsOfService";
import { Helmet } from "react-helmet";
import ErrorComp from "./comps/ErrorComp";
import ContactCenterLanding from "./comps/Services/ContactCenterLanding";
import MarketingLanding from "./comps/Services/MarketingLanding";
import HumanResourcesLanding from "./comps/Services/HumanResourceLanding";
import DigitalCxLanding from "./comps/Services/DigitalCxLanding";
import NewBlog1 from "./comps/Insights/NewBlog1";
import LoginPage from "./comps/Login/LoginPage";
import SalesPage from "./comps/Login/SalesPage";
import { useUserContext } from "./comps/Context/UserContext";
import SignupPage from "./comps/Login/SignupPage";
import EmployeePage from "./comps/Login/EmployeePage";
import SalaryCertPage from "./comps/Login/SalaryCertPage";
import AdminPage from "./comps/Login/AdminPage";
import { useTranslation } from "react-i18next";
// import MarqeeImages from "./comps/MarqueeImages";

// import AboutTs from "./comps/Why_Ts/AboutTs";
// import Careers from "./comps/Careers/Careers";
// import Esr from "./comps/Esr";

//Home
const Services = lazy(() => import("./comps/Services"));
const MarqeeImages = lazy(() => import("./comps/MarqueeImages"));
const Testimony = lazy(() => import("./comps/Testimony"));
const Blog = lazy(() => import("./comps/Blog"));

// Lazy loading imports for all the components
const ITOutsourcing = lazy(() => import("./comps/Services/ITOutsourcing"));
const FinanceOperations = lazy(() =>
  import("./comps/Services/FinanceOperations")
);
const SalesOutsourcing = lazy(() =>
  import("./comps/Services/SalesOutsourcing")
);
const MarketingOutsourcing = lazy(() =>
  import("./comps/Services/MarketingOutsourcing")
);
const CustomerServices = lazy(() =>
  import("./comps/Services/CustomerServices")
);
const ContactCenter = lazy(() => import("./comps/Services/ContactCenter"));
const HumanResources = lazy(() => import("./comps/Services/HumanResources"));
const Procurrement = lazy(() => import("./comps/Services/Procurement"));
const Warehousing = lazy(() => import("./comps/Services/Warehousing"));
const Transportation = lazy(() => import("./comps/Services/Transportation"));
const Accomodation = lazy(() => import("./comps/Services/Accommodation"));
const KnowledgeProcess = lazy(() =>
  import("./comps/Services/KnowledgeProcess")
);
const DigitalTransformation = lazy(() =>
  import("./comps/Services/DigitalTransformation")
);
const Workspace = lazy(() => import("./comps/Services/Workspace"));

// Lazy loading imports for all the components -
//Industries
const MediaCommunication = lazy(() =>
  import("./comps/Industries/MediaCommunication")
);
const Automotive = lazy(() => import("./comps/Industries/Automotive"));
const Retail = lazy(() => import("./comps/Industries/Retail"));
const HealthCare = lazy(() => import("./comps/Industries/HealthCare"));
const FoodBeverages = lazy(() => import("./comps/Industries/FoodBeverages"));
const TravelTours = lazy(() => import("./comps/Industries/TravelTours"));
const E_Commerce = lazy(() => import("./comps/Industries/E_Commerce"));
const GovernmentPublicService = lazy(() =>
  import("./comps/Industries/GovernmentPublicService")
);
const RealEstateFacilityManagement = lazy(() =>
  import("./comps/Industries/RealEstateFacilityManagement")
);
const ConsumerGoods = lazy(() => import("./comps/Industries/ConsumerGoods"));
const LogisticsTransportationIndustry = lazy(() =>
  import("./comps/Industries/LogisticsTransportationIndustry")
);
const OilGasCompanies = lazy(() =>
  import("./comps/Industries/OilGasCompanies")
);
const EducationCallCenterServices = lazy(() =>
  import("./comps/Industries/EducationCallCenterServices")
);

// Lazy loading imports for all the components -
//Why_ts
const GroupManagement = lazy(() => import("./comps/Why_Ts/GroupManagement"));
// Add more lazy loading imports for other components here...
const AboutTs = lazy(() => import("./comps/Why_Ts/AboutTs"));
const GroupOfCompanies = lazy(() => import("./comps/Why_Ts/GroupOfCompanies"));

//Careers
const Careers = lazy(() => import("./comps/Careers/Careers"));

//GTM
const tagManagerArgs = {
  gtmId: 'GTM-KS358TRG'
}

TagManager.initialize(tagManagerArgs);

//Esr
const Esr = lazy(() => import("./comps/Esr"));

//insights
const ClientStories = lazy(() => import("./comps/Insights/ClientStories"));

// import ClientStories from "./comps/Insights/ClientStories";

// import SupplierRegisteration from "./comps/SupplierRegisteration";
const SupplierRegisteration = lazy(() =>
  import("./comps/SupplierRegisteration")
);

// import Contact from "./comps/Contact-page/Contact";
const Contact = lazy(() => import("./comps/Contact-page/Contact"));

function App() {
  const location = useLocation();
  const {i18n, t} = useTranslation();
  // const { userInfo } = useUserContext();
  // console.log(userInfo);

  const RequireAuth = ({ children }) => {
    const { userInfo } = useUserContext();
    console.log(userInfo);
    
    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
      if (userInfo !== null) {
        setIsLoading(false);
      }
    }, [userInfo]);

    if (isLoading) {
      // You might choose to render a loading spinner or any other loading indicator
      return <div>Loading...</div>;
    }

    return userInfo ? children : <Navigate to="/" />;
  };

  useEffect(() => {
    if(i18n.language === 'ar') {
      document.documentElement.setAttribute('lang', 'ar');
    } else {
      document.documentElement.setAttribute('lang', 'en');
    }
  }, [i18n.language])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Tafaseel",
    url: "https://www.tafaseel.ae/",
    logo: "https://github.com/Cypher-inc/syni-p/blob/master/home/Tafaseel%20Logo%20all1.ebeffaea2fa23785e408.png?raw=true",
    sameAs: [
      "https://www.linkedin.com/company/tafaseel-bpo/?originalSubdomain=ae/",
      "https://twitter.com/TafaseelBPO1/",
      "https://www.facebook.com/tafaseelbpo?mibextid=LQQJ4d/",
      "https://www.instagram.com/tafaseel_bpo/?igshid=YmMyMTA2M2Y%3D/",
      "https://www.tafaseel.ae/",
    ],
  };

  return (
    <div className="App" dir={i18n.language === 'ar' ? 'rtl': 'ltr'}>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>

      <Helmet>
        <title>
          Leading Business Process Outsourcing Services in UAE | Tafaseel
        </title>
        <meta
          name="description"
          content="Discover Tafaseel, UAE's premier business process outsourcing company, offering top-notch outsourcing services, skilled workforce, premium facilities, rigorous processes, and advanced technology solutions. Transform your business with our expertise."
        />
      </Helmet>
      <header>
        {/* <New_nav></New_nav> */}
        <NewNav2></NewNav2>
      </header>

      <Routes>
        <Route
          path="/"
          element={
            <>
              <CarouselHome></CarouselHome>
              <About></About>
              <ScrollTrigger1></ScrollTrigger1>
              {/* <Nums></Nums> */}
              <Suspense fallback={<div>Loading...</div>}>
                <Services />
              </Suspense>

              <Suspense fallback={<div>Loading...</div>}>
                <MarqeeImages />
              </Suspense>

              <Suspense fallback={<div>Loading...</div>}>
                <Testimony />
              </Suspense>

              <Suspense fallback={<div>Loading...</div>}>
                <Blog />
              </Suspense>
              <Footer></Footer>
              {/* <Extra></Extra> */}
            </>
          }
        ></Route>

        <Route
          path="*"
          element={
            <>
              <ErrorComp></ErrorComp>
            </>
          }
        ></Route>

        {/* <Route
          path="/signup"
          element={
            <>
              <SignupPage></SignupPage>
            </>
          }
        ></Route> */}
        <Route
          path="/admin-dashboard/"
          element={
            <>
              <RequireAuth>
                <AdminPage></AdminPage>
              </RequireAuth>
            </>
          }
        ></Route>
        <Route
          path="/sales-dashboard"
          element={
            <>
              <RequireAuth>
                <SalesPage></SalesPage>
              </RequireAuth>
            </>
          }
        ></Route>
        <Route
          path="/employee-dashboard"
          element={
            <>
              <RequireAuth>
                <EmployeePage></EmployeePage>
              </RequireAuth>
            </>
          }
        ></Route>
        <Route
          path="/employee-dashboard/salary-certificate/"
          element={
            <>
              <RequireAuth>
                <SalaryCertPage></SalaryCertPage>
              </RequireAuth>
            </>
          }
        ></Route>
        {/* <div className="container-fluid"> */}
        {/* <div className="row"> */}

        {/* <Routes>
        <Route
          path="/group-management"
          element={
            <>
              <GroupManagement></GroupManagement>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}

        <Route
          path="/group-management"
          element={
            <>
              {/* GroupManagement */}
              <Suspense fallback={<div>Loading...</div>}>
                <GroupManagement />
                <Footer valProp={"contact-foot"} />
              </Suspense>
            </>
          }
        ></Route>
        {/* Add more routes here */}

        <Route
          path="/about-us"
          element={
            <>
              {/* AboutTs */}
              <Suspense fallback={<div>Loading...</div>}>
                <AboutTs />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/group-of-companies"
          element={
            <>
              {/* GroupOfCompanies */}
              <Suspense fallback={<div>Loading...</div>}>
                <GroupOfCompanies />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        {/* <Routes>
        <Route
          path="/people-trust"
          element={
            <>
              <PeopleTrust></PeopleTrust>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}
        {/* 
      <Routes>
        <Route
          path="/industries"
          element={
            <>
              <Industries></Industries>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}

        {/* <Routes>
        <Route
          path="/Media"
          element={
            <>
              <Media></Media>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}

        <Route
          path="/contact"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Contact></Contact>
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        {/* //////////////////services///////////////////// */}
        {/* <Routes>
          <Route
            path="/consulting"
            element={
              <>
                <Consulting></Consulting>
                <Footer valProp={"contact-foot"}></Footer>
              </>
            }
          ></Route>
        </Routes> */}
        {/* <Routes>
        <Route
          path="/services/digital-cx"
          element={
            <>
              <DigitalCx></DigitalCx>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}
        {/* <Routes>
        <Route
          path="/services/art"
          element={
            <>
              <VideoCx></VideoCx>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}

        {/* Landing Pages */}
        <Route
          path="/sales/contact-center-outsourcing/"
          element={
            <>
              {/* ITOutsourcing */}
              <ContactCenterLanding subProp={t('contactCenter')} />
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>

        <Route
          path="/sales/marketing-outsourcing/"
          element={
            <>
              {/* ITOutsourcing */}
              <MarketingLanding subProp={"Marketing Outsourcing"} />
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>

        <Route
          path="/sales/human-resources-outsourcing/"
          element={
            <>
              {/* ITOutsourcing */}
              <HumanResourcesLanding subProp={"Human Resources Outsourcing"} />
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
        <Route
          path="/sales/digital-cx-outsourcing/"
          element={
            <>
              {/* ITOutsourcing */}
              <DigitalCxLanding subProp={"Digital Cx Outsourcing"} />
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>

        <Route
          path="/services/it-outsourcing"
          element={
            <>
              {/* ITOutsourcing */}
              <Suspense fallback={<div>Loading...</div>}>
                <ITOutsourcing />
                <EnquiryForm subProp={t('itOutSourcing')}></EnquiryForm>
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/finance-operations-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <FinanceOperations></FinanceOperations>
                <EnquiryForm
                  subProp={t('financeOperationOutSourcing')}
                ></EnquiryForm>
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/sales-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <SalesOutsourcing></SalesOutsourcing>
                <EnquiryForm
                  subProp={t('salesOperationOutsourcing')}
                ></EnquiryForm>
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/marketing-outsourcing-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <MarketingOutsourcing></MarketingOutsourcing>
                <EnquiryForm
                  subProp={t('marketingOperationsOutSourcing')}
                ></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/customer-services-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <CustomerServices></CustomerServices>
                <EnquiryForm
                  subProp={t('customerServiceOutsourcing')}
                ></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/contact-center-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <ContactCenter></ContactCenter>
                <EnquiryForm
                  subProp={t('contactCenter')}
                ></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        {/* <Route
          path="/services/outsourcing"
          element={
            <>
              <Outsourcing></Outsourcing>
              <EnquiryForm></EnquiryForm>

              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route> */}

        <Route
          path="/services/human-resources-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <HumanResources></HumanResources>
                <EnquiryForm
                  subProp={t('humanResourceOutSourcing')}
                ></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/procurrement-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Procurrement></Procurrement>
                <EnquiryForm subProp={t('procurementOutSourcing')}></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/warehousing-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Warehousing></Warehousing>
                <EnquiryForm subProp={t('warehousingOutsourcing')}></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/transportation-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Transportation></Transportation>
                <EnquiryForm
                  subProp={t('transportationOutsourcing')}
                ></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/accomodation-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Accomodation></Accomodation>
                <EnquiryForm subProp={t('accommodationOutsourcing')}></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/knowledge-process-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <KnowledgeProcess></KnowledgeProcess>
                <EnquiryForm
                  subProp={t('knowledgeProcessOutsourcing')}
                ></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/services/cx-digital-transformation-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <DigitalTransformation></DigitalTransformation>
                <EnquiryForm
                  subProp={t('cxAndDigitalTransformationOutsourcing')}
                ></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>
        <Route
          path="/services/Workspace-outsourcing"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Workspace></Workspace>
                <EnquiryForm subProp={t('workSpace')}></EnquiryForm>

                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        {/* ///////////////////industries/////////////////////// */}
        <Route
          path="/industries/media-communication"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <MediaCommunication />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/automotive"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Automotive />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/retail"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Retail />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/healthcare"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <HealthCare />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/food-and-beverages"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <FoodBeverages />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/travel-and-tours"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <TravelTours />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/e-commerce"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <E_Commerce />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/government-and-public-service"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <GovernmentPublicService />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/real-estate-and-facility-management"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <RealEstateFacilityManagement />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/consumer-goods"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <ConsumerGoods />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/logistics-and-transportation-industry"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <LogisticsTransportationIndustry />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/oil-gas-companies"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <OilGasCompanies />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/industries/education-call-center-services"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <EducationCallCenterServices />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>
        {/* <Routes>
        <Route
          path="/services/trust-safety"
          element={
            <>
              <TrustSafety />
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}

        {/* //////////////insights////////////////// */}

        <Route
          path="/insights/client-stories"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <ClientStories></ClientStories>
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>
        <Route
          path="/insights/maryam-arab-youth"
          element={
            <>
              <NewBlog1></NewBlog1>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>

        {/* <Routes>
        <Route
          path="/insights/press-releases"
          element={
            <>
              <PressRelease></PressRelease>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes>
      <Routes>
        <Route
          path="/insights/blogs-page"
          element={
            <>
              <BlogsPage></BlogsPage>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          }
        ></Route>
      </Routes> */}

        {/* ////////////////////Careers////////////////// */}
        <Route
          path="/careers"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Careers></Careers>
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        ></Route>

        <Route
          path="/esg"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Esr />
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          } // Make sure the component is rendered like this
        />
        <Route
          path="/supplier-registration"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <SupplierRegisteration></SupplierRegisteration>
                <Footer valProp={"contact-foot"}></Footer>
              </Suspense>
            </>
          }
        />

        {/* /////////////footer//////////////// */}
        <Route
          path="/privacy-policy/"
          element={
            <>
              <PrivacyPolicy />
              <Footer valProp={"contact-foot"}></Footer>
            </>
          } // Make sure the component is rendered like this
        />

        <Route
          path="/fair-usage/"
          element={
            <>
              <FairUsage />
              <Footer valProp={"contact-foot"}></Footer>
            </>
          } // Make sure the component is rendered like this
        />
        <Route
          path="/terms-and-conditions/"
          element={
            <>
              <TermsOfService></TermsOfService>
              <Footer valProp={"contact-foot"}></Footer>
            </>
          } // Make sure the component is rendered like this
        />
      </Routes>
    </div>
  );
}

export default App;
