import { Button } from "react-bootstrap";
import LogoutButton from "./LogoutButton";
import { useUserContext } from "../Context/UserContext";
import { Link } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useEffect, useState } from "react";
import LoginNavComp from "./LoginNav";

const AdminPage = () => {
  const { userInfo } = useUserContext();

  const [userList, setUserList] = useState();
  const [allUsersLeaveData, setAllUsersLeaveData] = useState({});

  // const getUserList = async () => {
  //   try {
  //     const querySnapshot = await getDocs(collection(db, "users"));

  //     const allLeaveData = {}; // Create an object to store leave data for all users

  //     for (const userDoc of querySnapshot.docs) {
  //       const leaveApplicationRef = await getDocs(
  //         query(
  //           collection(db, "users", userDoc.id, "leave-applications"),
  //           orderBy("Timestamp", "desc")
  //         )
  //       );

  //       if (leaveApplicationRef.docs.length > 0) {
  //         const leaveApplicationData = leaveApplicationRef.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));

  //         allLeaveData[userDoc.id] = leaveApplicationData; // Store leave data for the user
  //       }
  //     }

  //     console.log(allLeaveData);

  //     setAllUsersLeaveData(allLeaveData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const getUserList = async () => {
    try {
      let leaveApplicationData = [];
      const querySnapshot = await getDocs(
        collection(db, "users"),
        orderBy("TimeStamp", "desc")
      );
      // console.log(querySnapshot.docs[0]);

      //my loop
      // const filterData = [];
      // for (const val in querySnapshot.docs) {
      //   const doc = querySnapshot.docs[val];
      //   filterData.push({
      //     id: doc.id,
      //     ...doc.data(),
      //   });
      // }
      // console.log(filterData);

      //   console.log(querySnapshot.docs[8].id);
      querySnapshot.docs.map(async (doc) => {
        // console.log(doc.id);
        const userIdVal = doc.id;
        const leaveApplicationRef = await getDocs(
          query(
            collection(db, "users", doc.id, "leave-applications"),
            orderBy("Timestamp", "desc")
          )
        );
        if (leaveApplicationRef.docs.length > 0) {
          // const leaveApplicationData = leaveApplicationRef.docs.map((doc) => {
          //   // console.log(doc.data());
          //   return {
          //     id: doc.id,
          //     ...doc.data(),
          //   };
          // });

          leaveApplicationRef.docs.map((doc) => {
            // console.log(doc.data());
            leaveApplicationData.push({
              id: doc.id,
              userId: userIdVal,
              ...doc.data(),
            });
          });

          leaveApplicationData.sort(function (a, b) {
            return b.Timestamp - a.Timestamp;
          });
          // console.log(leaveApplicationData);

          setAllUsersLeaveData(() => ({
            // ...prevState,
            leaveApplicationData,
          }));
        }
      });

      //getting sub collection data
      //   // Get the reference to the subcollection "leave-application"
      //   const subCollectionRef = collection(doc.ref, "leave-application");

      //   // Fetch documents from the subcollection
      //   const subCollectionSnapshot = await getDocs(subCollectionRef);

      //docs loop
      // const filterData = querySnapshot.docs.map((doc) => {
      //   // doc.data() is never undefined for query doc snapshots
      //   // console.log(doc.id, " => ", doc.data());
      //   return {
      //     id: doc.id,
      //     ...doc.data(),
      //   };
      // });

      // console.log(filterData);
      // setUserList(filterData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handleLeaveStatusChange = async (userId, selVal, docId) => {
    // console.log(userId, selVal, docId);

    try {
      const docRef = doc(db, "users", userId, "leave-applications", docId); // Update 'sales-leads' with your collection name
      await updateDoc(docRef, {
        LeaveStatus: selVal,
      });
      console.log("Document updated successfully");
      getUserList();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  console.log(allUsersLeaveData);
  let count = 0;
  return (
    <div className="container-fluid mt-lg-5 pt-md-5 pb-5 sales-dash-cont">
      <div className="row justify-content-center">
        {/* <div className="row col-10 col-lg-8 justify-content-between  align-items-center p-3 mb-5">
          <h4 className="col">
            <Link to="/employee-dashboard">
              <button className="btn btn-light">Leave Application</button>
            </Link>
          </h4>
          <h4 className="col">
            <Link to="/employee-dashboard/salary-certificate/">
              <button className="btn btn-success">Salary Certificate</button>
            </Link>
          </h4>
          <h4 className="col">
            <Link to="/sales-dashboard/">
              <button className="btn btn-warning">Sales Dashboard</button>
            </Link>
          </h4>
          <h4 className="col">
            <Link to="/admin-dashboard/">
              <button className="btn btn-danger">Admin Dashboard</button>
            </Link>
          </h4>
        </div> */}
        <LoginNavComp></LoginNavComp>
        <h2>Admin Dashboard</h2>

        <div className="container-fluid mt-2 col-12 col-md-10 col-lg-6">
          <div className="row justify-content-between  p-3 align-items-center mb-5">
            <div className="logout-cont d-flex p-3 justify-content-center justify-content-lg-between align-items-center">
              <div className="col-8 text-start">
                <h4 className="mb-0">
                  <num1>{userInfo.user.displayName}</num1>
                </h4>
              </div>

              <div className="col-4 text-end">
                <LogoutButton></LogoutButton>
              </div>
            </div>
          </div>
        </div>

        <div className="container number-text">
          <h1 className="mb-3">Leave Applications:</h1>

          <div className="row">
            {allUsersLeaveData !== undefined &&
            allUsersLeaveData.length !== 0 ? (
              <div className="col-12 table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Leave Date</th>
                      <th scope="col">Emp Id</th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Leave Reason</th>
                      <th scope="col">Leave Type</th>
                      <th scope="col">Leave Certificate</th>
                      <th scope="col">Leave Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(allUsersLeaveData).map(
                      ([userId, userLeaveData]) => {
                        // console.log(userLeaveData);

                        // return <p>{userId}</p>;
                        return userLeaveData.map((userLeaveDataText, index) => {
                          // console.log(
                          //   userId,
                          //   userLeaveDataText.id,
                          //   userLeaveDataText.LeaveReason
                          // );
                          const LeaveDates = Object.values(
                            userLeaveDataText.LeaveDate
                          );
                          count += 1;
                          return (
                            <tr key={index}>
                              <th scope="row" className="col">
                                {count}
                              </th>
                              <td>
                                {LeaveDates.length <= 1 ? (
                                  <div>{LeaveDates}</div>
                                ) : (
                                  LeaveDates.map((data1, index) => (
                                    <div key={index}>
                                      {data1}
                                      <br />
                                    </div>
                                  ))
                                )}
                              </td>

                              <td className="col">{userLeaveDataText.EmpId}</td>
                              <td className="col">
                                {userLeaveDataText.EmployeeName}
                              </td>
                              <td className="col">
                                {userLeaveDataText.LeaveReason}
                              </td>
                              <td className="col">
                                {userLeaveDataText.LeaveType}
                              </td>
                              <td className="col">
                                {userLeaveDataText.LeaveCertificate !== null &&
                                userLeaveDataText.LeaveCertificate !==
                                  undefined ? (
                                  <a
                                    href={userLeaveDataText.LeaveCertificate}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link-primary"
                                  >
                                    Certificate Link
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="col-2">
                                {/* {userLeaveDataText.LeaveStatus} */}
                                <select
                                  class={`form-select ${
                                    userLeaveDataText.LeaveStatus === "Approved"
                                      ? "sales-status-dropdown-green"
                                      : userLeaveDataText.LeaveStatus ===
                                        "Rejected"
                                      ? "sales-status-dropdown-hot"
                                      : ""
                                  }`}
                                  aria-label="Default select example"
                                  value={userLeaveDataText.LeaveStatus}
                                  onChange={(e) => {
                                    const selVal = e.target.value;
                                    const docId = userLeaveDataText.id;
                                    const userId = userLeaveDataText.userId;
                                    handleLeaveStatusChange(
                                      userId,
                                      selVal,
                                      docId
                                    );
                                  }}
                                >
                                  <option value="Awaiting">Awaiting</option>
                                  <option value="Approved">Approved</option>
                                  <option value="Rejected">Rejected</option>
                                </select>
                              </td>
                            </tr>

                            // <div key={index} className="d-flex">
                            //   <p className="col">{userLeaveDataText.EmpId}</p>
                            //   <p className="col">
                            //     {userLeaveDataText.EmployeeName}
                            //   </p>
                            //   <p className="col">{userLeaveDataText.LeaveReason}</p>

                            //   <p className="col">{userLeaveDataText.LeaveType}</p>
                            //   <p className="col">{userLeaveDataText.LeaveStatus}</p>
                            // </div>
                          );
                        });
                      }
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <p>No Data Available</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminPage;
