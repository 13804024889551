import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import { useUserContext } from "../Context/UserContext";
import { useEffect, useState } from "react";
import axios from "axios";
import LoginNavComp from "./LoginNav";

const SalaryCertPage = () => {
  const { userInfo } = useUserContext();

  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState();

  useEffect(() => {
    // console.log(userInfo);
    setEmployeeId(userInfo.user.email.slice(0, -12));
    setEmployeeName(userInfo.user.displayName);
  }, [userInfo]);

  const handleSalaryFormSubmit = async (e) => {
    e.preventDefault();
    // console.log(employeeId, employeeName);
    // const targetEmail = "krisd2468@gmail.com";
    const formSpreeSalaryUrl = process.env.REACT_APP_FORMSPREE_CONTACT_URL;

    const Message1 = `Dear Manager,

    I hope this email finds you well. I am writing to kindly request a salary certificate. 
    The certificate is required for of applying for a loan / visa application, etc.
    
    If possible, could you please provide the salary certificate in a format that includes details such as my basic salary, allowances, deductions, and any other relevant information? 
    Your prompt assistance in this matter would be greatly appreciated, as I am in the process of completing the necessary documentation.
    
    Thank you for your attention to this request. I look forward to your positive response.
    
    Best regards,
    ${employeeName}
    ${employeeId}`;

    console.log(Message1);

    const formData = {
      EmpId: employeeId,
      EmpName: employeeName,
      Message: Message1,
    };

    try {
      await axios.post(formSpreeSalaryUrl, formData);
      alert("Request for salary certificate sent successfully!");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container-fluid mt-lg-5 pt-md-5 pb-5 sales-dash-cont">
      <div className="row justify-content-center">
        {/* <div className="row col-10 col-lg-8 justify-content-between  align-items-center p-3 mb-5">
          <h4 className="col">
            <Link to="/employee-dashboard">
              <button className="btn btn-light">Leave Application</button>
            </Link>
          </h4>
          <h4 className="col">
            <Link to="/employee-dashboard/salary-certificate/">
              <button className="btn btn-success">Salary Certificate</button>
            </Link>
          </h4>
        </div> */}
        <LoginNavComp></LoginNavComp>
        <h2 className="">Salary Certificate Dashboard</h2>

        <div className="container-fluid mt-2 col-12 col-lg-6">
          <div className="row justify-content-between p-3 align-items-center mb-5">
            <div className="logout-cont d-flex p-3 justify-content-center  align-items-center">
              <div className="col-8 text-start">
                <h4 className="mb-0">
                  <num1>{userInfo.user.displayName}</num1>
                </h4>
              </div>

              <div className="col-4 text-end">
                <LogoutButton></LogoutButton>
              </div>
            </div>
          </div>
        </div>

        <h2 className="testing-font"> Request Salary Certificate</h2>
        <div className="container-fluid mt-2 col-10 col-lg-6">
          <div className="row text-start justify-content-center">
            <form className="row" onSubmit={handleSalaryFormSubmit}>
              <input
                name="SUBJECT"
                type="hidden"
                value="Request for Salary Certificate"
              />
              <div class="mb-3 col-12">
                <label for="exampleInputEmail1" class="form-label">
                  Employee ID
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  required
                  disabled
                ></input>
              </div>
              <div class="mb-5 col-12">
                <label for="exampleInputPassword1" class="form-label">
                  Employee Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  required
                  disabled
                ></input>
              </div>

              <div class="mb-5 col-12 text-center">
                <button className="btn btn-danger p-3 col-10 col-md-7 rounded-pill">
                  Request Salary Certificate
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalaryCertPage;
