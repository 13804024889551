import { Link } from "react-router-dom";
import WistiaVideoComp from "./WistiaVideoComp";
import { Trans } from "react-i18next";
// import mySg from "../Assets/images/banner-1.svg";

const CarouselHome = () => {
  return (
    <div
      id="carouselExampleControls"
      class="carousel slide home-carousel"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item  active" data-bs-interval="15000">
          {/* <iframe
            width="100%"
            height="860vw"
            src="https://player.vimeo.com/video/845649654?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          ></iframe> */}

          {/* <div className="wistia-video d-lg-none">
            <div className="wistia_responsive_padding">
              <div className="wistia_responsive_wrapper">
                <iframe
                  src="https://fast.wistia.net/embed/iframe/tf61ep3kts?seo=false&videoFoam=true"
                  title="Comp 1 Video"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameborder="0"
                  class="wistia_embed"
                  name="wistia_embed"
                  msallowfullscreen
                  width="100%"
                  height="100%"
                  controls="0"
                  className="wistia-video"
                ></iframe>
              </div>
            </div>
          </div> */}
          {/* <WistiaVideoComp linkProp="https://fast.wistia.net/embed/iframe/tf61ep3kts?seo=false&videoFoam=true"></WistiaVideoComp> */}

          <WistiaVideoComp linkProp="https://fast.wistia.net/embed/iframe/tf61ep3kts?seo=false&videoFoam=true"></WistiaVideoComp>

          <div className="wistia-video d-none d-lg-block">
            <div className="wistia_responsive_padding">
              <div className="wistia_responsive_wrapper">
                <iframe
                  src="https://fast.wistia.net/embed/iframe/5ptn4cs5d0?seo=false&videoFoam=true"
                  title="Comp 1 Video"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameborder="0"
                  scrolling="no"
                  class="wistia_embed"
                  name="wistia_embed"
                  msallowfullscreen
                  width="100%"
                  height="100%"
                  controls="0"
                  className="wistia-video"
                ></iframe>
              </div>
            </div>
          </div>
          {/* <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/OpeATk_9nXA?autoplay=1&mute=1&controls=0&loop=1&modestbranding=1&playlist=OpeATk_9nXA"
            className="videoBg"
          ></iframe> */}

          {/* <video
            src={require("../Assets/videos/Comp 1-1.m4v")}
            type="video/mp4"
            loop
            class=" w-100 "
            autoPlay
            muted
          ></video> */}
          {/* <video
            src={require("../Assets/videos/Comp 1 1-1.m4v")}
            type="video/mp4"
            loop
            class=" w-100"
            autoPlay
            muted
          ></video> */}
        </div>

        <div class="carousel-item " data-bs-interval="50000">
          <img
            // src={require("../Assets/images/BANNER AB mob.jpg")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/BANNER%20AB%20mob.jpg?raw=true"
            }
            // height={810}
            class="d-block d-lg-none w-100 h-80 custom-height"
            alt="..."
            // loading="lazy"
            style={{ filter: "brightness(0.6)" }}
          ></img>
          <img
            // src={require("../Assets/images/BANNER AB.jpg")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/BANNER%20AB.jpg?raw=true"
            }
            // height={810}
            class="d-none d-lg-block w-100 h-80 custom-height article-btn-relative"
            alt="..."
            // loading="lazy"
            style={{ filter: "brightness(0.6)" }}

          ></img>
          <a
            href="https://www.arabianbusiness.com/resources/tafaseel-bpo-unveils-revamped-website-and-egypt-expansion-as-part-of-its-ipo-vision-for-2025"
            target="_blank"
            className="article-btn btn"
          >
           <Trans i18nKey="readArticle">Read Article</Trans>
          </a>
        </div>

        <div class="carousel-item " data-bs-interval="5000">
          <img
            // src={require("../Assets/images/banner-1.png")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/Banners%204%20Mobail%20DIV%20_%20(2).jpg?raw=true"
            }
            // height={810}
            class="d-block d-lg-none w-100 h-80 custom-height article-btn-relative"
            alt="..."
            loading="lazy"
          ></img>
          <img
            // src={require("../Assets/images/banner-1.png")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/BANNER%20ID%20(1).jpg?raw=true"
            }
            // height={810}
            class="d-none d-lg-block w-100 h-80 custom-height"
            alt="..."
            loading="lazy"
          ></img>
        </div>
        {/* <div class="carousel-item">
          <img
            // src={require("../Assets/images/banner-2.png")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/banner-2%20(1).png?raw=true"
            }
            // height={810}
            class="d-block w-100 h-80"
            alt="..."
          ></img>
        </div> */}
        <div class="carousel-item" data-bs-interval="15000">
          {/* <iframe
            width="100%"
            height="860vw"
            src="https://www.youtube.com/embed/bMyeRjAOEYI?autoplay=1&mute=1&controls=0&loop=1&playlist=bMyeRjAOEYI"
          ></iframe> */}
          {/* <div className="wistia-video d-lg-none">
            <div className="wistia_responsive_padding">
              <div className="wistia_responsive_wrapper">
                <iframe
                  src="https://fast.wistia.net/embed/iframe/xhox56zs2d?seo=false&videoFoam=true"
                  title="Comp 1 Video"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameborder="0"
                  scrolling="no"
                  class="wistia_embed"
                  name="wistia_embed"
                  msallowfullscreen
                  width="100%"
                  height="100%"
                  controls="0"
                  className="wistia-video"
                ></iframe>
              </div>
            </div>
          </div> */}

          <div className="wistia-video d-lg-none">
            <div className="wistia_responsive_padding">
              <div className="wistia_responsive_wrapper">
                <iframe
                  src="https://fast.wistia.net/embed/iframe/xhox56zs2d?seo=false&videoFoam=true"
                  title="Comp 1 Video"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameBorder="0"
                  scrolling="no"
                  class="wistia_embed"
                  name="wistia_embed"
                  msallowfullscreen
                  width="100%"
                  height="100%"
                  controls="0"
                  className="wistia-video"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="wistia-video d-none d-lg-block">
            <div className="wistia_responsive_padding">
              <div className="wistia_responsive_wrapper">
                <iframe
                  src="https://fast.wistia.net/embed/iframe/c33i99baiz?seo=false&videoFoam=true"
                  title="Comp 1 Video"
                  allow="autoplay; fullscreen"
                  allowtransparency="true"
                  frameBorder="0"
                  class="wistia_embed"
                  name="wistia_embed"
                  msallowfullscreen
                  width="100%"
                  height="100%"
                  controls="0"
                  className="wistia-video"
                ></iframe>
              </div>
            </div>
          </div>

          {/* <Link to="/esr">
            <button class="btn btn-primary iframe-button align-items-center">
              Visit Esr
            </button>
          </Link> */}
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <img
            // src={require("../Assets/images/banner.png")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/Banners%204%20Mobail_.jpg?raw=true"
            }
            // height={810}
            class="d-block d-lg-none w-100 h-80 custom-height"
            alt="..."
            loading="lazy"
          ></img>
          <img
            // src={require("../Assets/images/banner.png")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/BANNER%20IT_.jpg?raw=true"
            }
            // height={810}
            class="h-80 custom-height d-none d-lg-block"
            alt="..."
            loading="lazy"
          ></img>
        </div>
        {/* <div class="carousel-item" data-bs-interval="50000">
          <img
            // src={require("../Assets/images/banner.png")}
            src={
              "https://github.com/Cypher-inc/syni-p/blob/master/home/BANNER%20IT_.jpg?raw=true"
            }
            // height={810}
            class=" d-block w-100 h-80 custom-height"
            alt="..."
          ></img>
        </div> */}
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default CarouselHome;
