import React, { useState } from "react";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollTrigger from "react-scroll-trigger";
import { Link } from "react-router-dom";
import ClientStoriesContent from "./CleintStoriesContent";
import Blog from "../Blog";
import pressRelease from "./PressRelease";
import BlogsPage from "./BlogsPage";
import PressRelease from "./PressRelease";
import VideosComp from "./VideosComp";
import { Helmet } from "react-helmet";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Trans } from "react-i18next";

const NewBlog1 = () => {
  const [selectedItem, setSelectedItem] = useState("Insights");

  // Function to handle the click event on the dropdown items
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const Text1 = `%0A   الاحتفال برواد الشباب العرب: مريم الجرمن النعيمي - بصمة التغيير،
  إلهام المستقبل 
  \n %0A`;

  return (
    <div className="cont-insights-stories cont-services-digital-cx cont-new-blog">
      <Helmet>
        <title>Insights and Inspiration: Blogs by Tafaseel BPO</title>
        <meta
          name="description"
          content="Explore a collection of insightful and inspirational blogs by Tafaseel BPO. Dive into topics spanning various industries, business trends, and solutions. Gain valuable knowledge and stay informed."
        />
      </Helmet>
      <div className="insights-img">
        <h1 className="mb-5">Blogs</h1>
        {/* <h1 className="mb-5">
          Create digital, dynamic led , customer connections
        </h1> */}
        <div class="navbar-buttons mbr-section-btn"></div>
      </div>

      <div class="container-fluid">
        <div class="row pt-5 pb-5 careers-cards justify-content-center text-start align-items-center">
          <div className="col-10  col-lg-6 mb-3 ms-4 me-4 p-0">
            <Link to="/insights/client-stories">
              <p class="btn btn-outline-danger  mb-3">Go Back</p>
            </Link>{" "}
            <div className="press-img14 align-items-center justify-content-end mb-3 d-none d-lg-block"></div>
            <div className="press-img14-mob align-items-center justify-content-end mb-3 d-block d-lg-none"></div>

            <h3 style={{ fontSize: "2.5em" }}>
              <num1>
                {" "}
                الاحتفال برواد الشباب العرب: مريم الجرمن النعيمي - بصمة
                التغيير، إلهام المستقبل
              </num1>{" "}
            </h3>
            <p className="">
              <num1>
                في عالم مليء بالإمكانيات اللامتناهية والإنجازات الرائعة، كشف
                مركز الشباب العربي الذي يترأسه سمو الشيخ ذياب بن محمد بن زايد آل
                نهيان، وبالتزامن مع اليوم العالمي للشباب، عن أعضاء النسخة
                الثانية من مبادرة «روّاد الشباب العربي»، ممن حققوا إنجازات تعكس
                صورة مشرفة لشباب العالم العربي، وتركوا بصمة إيجابية في
                مجتمعاتهم. أعلن عن النسخة الثانية من "رواد الشباب العرب" مجموعة
                من العقول الشابة الاستثنائية الذين تركوا أيضًا بصمة لا تنسى من
                الإلهام عبر العالم العربي. جاء الإعلان عن الروّاد خلال حفل تكريم
                نظمه مركز الشباب العربي في أبوظبي، بحضور وزيرة تنمية المجتمع
                نائب رئيس مركز الشباب العربي، شما المزروعي، والمكلف بقيادة ملف
                الشباب في وزارة الثقافة والشباب ماجد النعيمي. مريم النعيمي:
                رائدة ذات رؤية من بين هؤلاء الرواد هي مريم الجرمن النعيمي، رائدة
                الأعمال الإماراتية التي قامت بإعادة رسم مجال مراكز الاتصال
                وتعهيد الأعمال في الشرق الأوسط. بصفتها الرئيسة والمؤسسة لمجموعة
                الشركات "تفاصيل بي بي أو" رحلة 13 عام في تعهيد الأعمال من خلال
                "تفاصيل بي بي أو" خلقت قصة من الريادة والإنجاز في سوق تعهيد
                الأعمال بقطاعاته المختلفة لتخدم أكثر من خمسين مركز اتصال حكومي و
                تدير أكثر من 1000 موظفي داخل مواقع عملياتها في الإمارات من 33
                جنسية مقدمة تجربة وطنية تنافسية و خيارات مرنة لدمج كافة أطياف
                المجتمع من خلال فرصة العمل المختلف و العمل عن بُعد ومن المنزل
                والعمل الهجين والتنوع في المبادرات المجتمعية التي تعكس التزامها
                نحو الوطن والمنطقة العربية والذي كان جليا خلال جائحة كوفيد-19،
                حيث قدمت "تفاصيل بي بي أو" خدمات مراكز اتصال مجانية للمؤسسات
                الحكومية، تجسيدًا للمسؤولية الاجتماعية للشركات وروح التضامن
                والتكاتف الوطني. مسيرة مريم تعكس صورة لرائدة الأعمال العربية
                الناجحة في ظل دورها كأم تسعى لتحقيق إنجازات ريادية في ظل تحقيق
                التوازن بين الأعمال والالتزامات الأسرية، مع رحلة أكاديمية تشمل
                حصولها على درجة الماجستير في الأمن الدولي والمدني من جامعة
                خليفة، وشهادة في ريادة الأعمال الاجتماعية جامعة إنسياد للأعمال
                في سنغافورة وهي خريجة برنامج قيادات حكومة الإمارات ضمن برنامج
                الثورة الصناعية الرابعة وهي عضو مجلس إدارة غرفة عجمان
              </num1>
            </p>
          </div>

          <div className="col-12 col-lg-4 text-center">
            <div className="col-10 card p-3">
              <h3>Sharing is Caring</h3>
              <div class="social-media align-center d-flex mb-4 justify-content-center">
                <a
                  class="icon-transition mx-4"
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://tafaseel.ae/insights/maryam-arab-youth&quote=${Text1}`}
                  target="_blank"
                >
                  <FaFacebook style={{ color: "black", fontSize: "3em" }} />
                </a>
                <a
                  class="icon-transition mx-4"
                  href={`https://twitter.com/intent/tweet?url=https://tafaseel.ae/insights/maryam-arab-youth&text=${Text1}`}
                  target="_blank"
                >
                  <FaTwitter style={{ color: "black", fontSize: "3em" }} />
                </a>
                <a
                  class="icon-transition mx-4"
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=https://tafaseel.ae/insights/maryam-arab-youth&title=${Text1}`}
                  //   href="http://www.linkedin.com/shareArticle?mini=true&url=https://stackoverflow.com/questions/10713542/how-to-make-custom-linkedin-share-button/10737122&title=How%20to%20make%20custom%20linkedin%20share%20button&summary=some%20summary%20if%20you%20want&source=stackoverflow.com"
                  target="_blank"
                >
                  <FaLinkedin style={{ color: "black", fontSize: "3em" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row pt-5 pb-5 careers-cards justify-content-center text-start">
          <h3 className="text-center">Other Impactful Stories</h3>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img9 align-items-center justify-content-end "></div>
            <a
              href="https://www.arabianbusiness.com/resources/tafaseel-bpo-unveils-revamped-website-and-egypt-expansion-as-part-of-its-ipo-vision-for-2025"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <num1>
                    <Trans i18nKey="websiteExpansionTitle">
                      Tafaseel BPO unveils revamped website and Egypt expansion as
                      part of its IPO vision for 2025
                    </Trans>
                  </num1>
                </h5>

                <p className="">
                  <Trans i18nKey="websiteExpansionDescription">
                    <num1>
                      Tafaseel BPO, the largest homegrown Business Process
                      Outsourcing (BPO) company in the UAE, takes a giant stride
                      towards its
                      2025
                      vision with the launch of its revamped website and the opening
                      of a new office in Egypt.
                    </num1>
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img10 align-items-center justify-content-end "></div>
            <a
              href="https://news.outsourceaccelerator.com/tafaseel-bpo-expands/"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 className="card-title">
                  <Trans i18nKey="expansionTitle">
                    <num1>Tafaseel BPO expands, eyes 2025 IPO launch</num1>
                  </Trans>
                </h5>

                <p>
                  <Trans i18nKey="expansionDescription">
                    AJMAN, UNITED ARAB EMIRATES — Business Process Outsourcing
                    (BPO) provider Tafaseel BPO recently launched its modernized
                    website and inaugurated a fresh office in Egypt as the company
                    solidifies its growth trajectory in anticipation of its{" "}
                    <num1> 2025 </num1>
                    Initial Public Offering (IPO).
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img5 align-items-center justify-content-end "></div>
            <a
              href="https://www.alittihad.ae/news/%D8%A7%D9%84%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF%D9%8A/4346302/%D8%BA%D8%B1%D9%81%D8%A9-%D8%B9%D8%AC%D9%85%D8%A7%D9%86-%D8%AA%D8%B4%D9%8A%D8%AF-%D8%A8%D9%86%D9%85%D9%88-%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9-%D8%B1%D9%88%D8%A7%D8%AF-%D8%A7%D9%84%D8%A3%D8%B9%D9%85%D8%A7%D9%84-%D8%A7%D9%84%D9%85%D9%88%D8%A7%D8%B7%D9%86%D9%8A%D9%86"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="chamberPraisesTitle">
                    <num1>
                      {" "}
                      Ajman Chamber praises the growth of Emirati entrepreneurs' projects
                    </num1>{" "}
                  </Trans>
                </h5>

                <p className="">
                  <Trans i18nKey="chamberPraisesDescription">
                    Abdullah Al Muwaji, Chairman of the Board of Directors of the Ajman Chamber, speaks about the progress of UAE entrepreneurs
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img3 align-items-center justify-content-end "></div>

            <a
              href="https://www.arabianbusiness.com/culture-society/466894-emirati-entrepreneur-maryam-al-nuaimi-is-first-woman-in-uaes-bpo-industry"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="firstWomanTitle">
                    <num1>First woman in UAE's BPO industry</num1>
                  </Trans>
                </h5>

                <p data-aos="fade-up">
                  <Trans i18nKey="firstWomanDescription">
                    Emirati entrepreneur Maryam Al Nuaimi is the first woman in UAE’s BPO industry.
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img4 align-items-center justify-content-end "></div>
            <a
              href="https://www.arabianbusiness.com/powerlists/arabian-business-30-under-40-list-celebrating-the-future-leaders-of-the-middle-east#Arabian-Business-30-Under-40-List-Table"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <num1><Trans i8nKey="arabianBusiness">Arabian Business 30 Under 40 List</Trans></num1>{" "}
                </h5>

                <p className="">
                  <Trans i18nKey="celebratingFutureLeaders">
                    Celebrating the future leaders of the Middle East
                  </Trans>
                </p>
              </div>
            </a>
          </div>
          <div class="card col-12 col-md-5 col-lg-3 mb-3 ms-4 me-4 p-0">
            <div className="press-img6 align-items-center justify-content-end "></div>
            <a
              href="https://www.alkhaleej.ae/2023-01-19/%D8%BA%D8%B1%D9%81%D8%A9-%D8%B9%D8%AC%D9%85%D8%A7%D9%86-%D8%AA%D8%B9%D8%B2%D8%B2-%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84-%D8%A7%D9%84%D9%85%D8%A8%D8%A7%D8%B4%D8%B1-%D9%85%D8%B9-%D8%A7%D9%84%D9%82%D8%B7%D8%A7%D8%B9-%D8%A7%D9%84%D8%AE%D8%A7%D8%B5/%D8%A3%D8%B3%D9%88%D8%A7%D9%82-%D8%A7%D9%84%D8%A5%D9%85%D8%A7%D8%B1%D8%A7%D8%AA/%D8%A7%D9%82%D8%AA%D8%B5%D8%A7%D8%AF"
              className="ps-2 blog-read-more"
              target="_blank"
            >
              <div class="card-body">
                <a class="btn btn-outline-danger  mb-3"><Trans i18nKey="pressRelease">Press Release</Trans></a>
                <h5 class="card-title">
                  <Trans i18nKey="chamberEnhancesCommunicationTitle">
                    <num1>
                      {" "}
                      Ajman Chamber enhances direct communication with the private sector
                    </num1>{" "}
                  </Trans>
                </h5>

                <p className="">
                <Trans i18nKey="chamberEnhancesCommunicationDescription">
                    A delegation from the Ajman Chamber of Commerce and Industry, led by Abdullah Al Muwaiji, Chairman of the Board of Directors, visited the headquarters of Tafaseel.
                  </Trans>
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBlog1;
