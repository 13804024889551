import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faMobileScreenButton,
  faHashtag,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import EnquiryFormSmall from "../EnquirFormSmall";
import { Trans } from "react-i18next";

const DigitalCxLanding = (props) => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const [counterOn, setCounterOn] = useState(false);
  return (
    <div className="cont-services-digital-cx">
      <Helmet>
        <title>
          Enhanced Customer Experience: CX and Digital Outsourcing with Tafaseel
          BPO
        </title>
        <meta
          name="description"
          content="Elevate customer experience with Tafaseel BPO's CX and digital outsourcing solutions. Collaborate with our experts to create impactful digital strategies, improve customer engagement, and drive brand loyalty. Leverage our innovative approaches, data-driven insights, and commitment to enhancing customer interactions."
        />
      </Helmet>

      <div className="digital-img01 align-items-center mt-md-5">
        {/* <h1 className="mb-5">Elevate Customer Experiences with Tafaseel BPO</h1> */}
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6 mt-5">
            <h1 className="mb-5"><Trans i18nKey="cxAndDigitalOutsourcing">CX and Digital Outsourcing</Trans></h1>
          </div>
          <div className="col-12 col-lg-6">
            <EnquiryFormSmall subProp={props.subProp}></EnquiryFormSmall>
          </div>
        </div>
      </div>

      {/* ////////////////Intelligent solutions/////////////////// */}
      <div className="container-fluid intelligent-text">
        <div className="row align-items-center">
          <div class="col-12 col-md-6 p-5 text-start">
            <h3 data-aos="fade-up">
              Why Choose CX and Digital Outsourcing with Tafaseel BPO?
            </h3>
            <p className="pe-4" data-aos="fade-up">
              When you choose workspace outsourcing with Tafaseel BPO, you can
              unlock numerous benefits for your organization
            </p>
          </div>
          <div class="col-12 col-md-6 p-5 text-start" data-aos="fade-up">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Flexible Work Environment</Accordion.Header>
                <Accordion.Body>
                  We provide flexible workspace solutions that cater to your
                  specific requirements. Whether you need dedicated office
                  spaces, coworking areas, meeting rooms, or virtual office
                  services, we can tailor our offerings to meet your needs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header><Trans i18nKey="costEfficiencyTitle">Cost Efficiency</Trans></Accordion.Header>
                <Accordion.Body>
                  By outsourcing your workspace needs to Tafaseel BPO, you can
                  reduce costs associated with leasing, maintenance, utilities,
                  and other administrative tasks. Our cost-effective solutions
                  allow you to optimize your workspace expenses and allocate
                  resources more efficiently.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Access to Amenities and Services
                </Accordion.Header>
                <Accordion.Body>
                  Tafaseel BPO offers a range of amenities and services to
                  enhance your workspace experience. From high-speed internet
                  and state-of-the-art technology infrastructure to reception
                  services, mail handling, and administrative support, we ensure
                  that your workspace is equipped with everything you need to
                  operate smoothly.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Scalability and Expansion</Accordion.Header>
                <Accordion.Body>
                  Our workspace solutions are designed to accommodate your
                  business growth and changing needs. Whether you need to scale
                  up your workspace or expand into new locations, we can provide
                  flexible options that align with your expansion plans.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////////// */}

      <div
        id="carouselExampleFade"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card col-lg-3">
                <div class="card-body">
                  <h5 class="card-title"><Trans i18nKey="customerServiceOutsourcing">Customer Service Outsourcing</Trans></h5>
                  <p class="card-text p-3">
                    We provide end-to-end customer service solutions, including
                    phone, email, chat, and social media support. Our customer
                    service representatives are trained to deliver personalized
                    and empathetic interactions, ensuring exceptional customer
                    satisfaction.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title">Digital Marketing Outsourcing</h5>
                  <p class="card-text p-3">
                    Tafaseel BPO assists in digital marketing activities,
                    including social media management, content creation, SEO
                    optimization, paid advertising, and analytics. We help you
                    increase brand visibility, drive traffic, and generate leads
                    through targeted digital marketing strategies.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title">Customer Analytics and Insights</h5>
                  <p class="card-text p-3">
                    Our data analysts extract valuable customer insights from
                    various data sources, enabling data-driven decision-making,
                    personalized marketing campaigns, and proactive customer
                    engagement.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card col-lg-3">
                <div class="card-body">
                  <h5 class="card-title">User Experience (UX) Design</h5>
                  <p class="card-text p-3">
                    Tafaseel BPO provides flexible and scalable marketing
                    solutions that can adapt to your changing business needs.
                    Whether you need ongoing marketing support or specific
                    campaign assistance, we can tailor our services to meet your
                    requirements.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title">Digital Transformation Consulting</h5>
                  <p class="card-text p-3">
                    Tafaseel BPO offers strategic consulting services to guide
                    your digital transformation journey. We assess your current
                    digital capabilities, identify opportunities for
                    improvement, and develop a roadmap for successful digital
                    adoption.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block">
                <div class="card-body">
                  <h5 class="card-title">AI and Automation Solutions</h5>
                  <p class="card-text p-3">
                    Our experts leverage artificial intelligence (AI) and
                    automation technologies to optimize processes, automate
                    repetitive tasks, and enhance efficiency across customer
                    interactions, data analysis, and operational workflows.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default DigitalCxLanding;
