import { Trans } from "react-i18next";

const FairUsage = () => {
  return (
    <div className="cont-privacy-policy pt-lg-5">
      <div className="privacy-img">
        <h1 className="mb-5">Tafaseel Fair Usage</h1>
      </div>

      {/* <h1>Hello</h1> */}
      <div className="footer-notice-text text-start">
        <div className="container ps-5 pe-5 ">
          <div className="row">
            <h5>
              <Trans i18nKey="fairUsagePolicy">Fair Usage Policy</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="fairUsagePoint1">
                Our Fair Use Policy applies to Services which are stated to be subject to
                the Fair Use Policy ("Fair Use Services").
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="fairUsagePoint2">
                Our Fair Use Policy is intended to ensure:
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="fairUsagePoint3">
                The availability of Our Services to all eligible Tafaseel BPO Customers; and
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="fairUsagePoint4">
                that the Fair Use Services are not used in an unreasonable manner.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="fairUsagePoint5">
                We reserve the right to vary the terms of this Fair Use Policy from time to
                time with no prior notice and immediate effect.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="fairUsagePoint6">
                We may rely on the Fair Use Policy where Your usage of the Fair Use Services
                is unreasonable, as defined below.
              </Trans>
            </p>

          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5>
              <Trans i18nKey="unreasonableUse">Unreasonable Use</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseDescription">
                It is unreasonable use of a Fair Use Service where Your use of the service
                is reasonably considered by Tafaseel BPO to:
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUsePointA">a. be fraudulent;</Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUsePointB">b. involve a non-ordinary use;</Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUsePointC">
                c. cause significant network congestion, disruption, or otherwise adversely
                affect the Tafaseel BPO network, a supplier’s network; or
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUsePointD">
                d. adversely affect another person’s use of or access to the Fair Use
                Services, the Tafaseel BPO network, or a supplier’s network.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseWithoutLimitation">Without limitation:</Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUsePointAFraudulent">
                a. Fraudulent use includes resupplying or reselling a Service without
                Tafaseel BPO's written consent so that someone else may access, use, or
                commercially exploit a Fair Use Service;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUsePointBNonOrdinary">
                b. Non-ordinary use includes circumstances where You make or receive calls
                in any non-ordinary manner without obtaining Our written consent first,
                which consent We may give or withhold, or make subject to conditions, in
                Our discretion. Use in a non-ordinary manner includes:
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseForwardingExcessiveCalls">
                forwarding excessive calls (i.e., where the number of calls of the relevant
                type in a given period is at least three times the average number of calls
                of that type in that period for all customers on the same plan)
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseExtensiveCallTimeSpan">
                extensive call time span (i.e., where the call duration of the relevant type
                in a given period is at least three times the average call duration of that
                type in that period for all customers on the same plan)
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseTransferringCalls">
                transferring calls from more than one (1) branch without consent
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseUsageToMenace">
                usage to menace, harass, or injure any person or damage anything;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseUsageInConnectionWithBreach">
                usage in connection with a breach of or committing an offense against any
                law, regulation, standard, or code;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="unreasonableUseOtherActivity">
                any other activity that would not be reasonably regarded as ordinary use in
                relation to the Fair Use Service.
              </Trans>
            </p>

          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5>
              <Trans i18nKey="ourRights">Our Rights</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="ourRightsDescription">
                If We reasonably consider your use of the Fair Use Services is unreasonable, we may, at our sole discretion, without telling you before we do so:
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="ourRightsPointA">a. block inbound ports so you cannot host services</Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="ourRightsPointB">
                b. remove any unlimited calls feature and charge you for your calls at Tafaseel BPO's standard rates;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="ourRightsPointC">
                c. suspend or limit the Service (or any feature of it) in accordance with Our Agreement with You; and/or
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="ourRightsPointD">
                d. terminate the Service in accordance with Our Agreement with You.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="ourRightsPolicySupplementary">
                This policy is supplementary to and does not limit any of Tafaseel BPO's rights.
              </Trans>
            </p>

          </div>
        </div>

      </div>
    </div>
  );
};
export default FairUsage;
