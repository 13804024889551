import callSvg from "../Assets/images/call-1.svg";
import mailSvg from "../Assets/images/mail-1.svg";
import locSvg from "../Assets/images/location.svg";
import footSvg from "../Assets/images/footer-logo.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";

const Footer = (props) => {
  // console.log(props.valProp);
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);
  return (
    <>
      <div
        class={`container-fluid cont-footer ${
          props.valProp !== undefined ? "black-foot" : ""
        }`}
      >
        <div class="row justify-content-evenly">
          {props.valProp !== undefined ? (
            <div class="extra-footer">
              {/* <div class="col-12 col-md-3 ms-md-5">
                <div class="footer1">
                  <span>
                    <img class="img-fluid me-3" src={callSvg}></img>
                    <a
                      class="brand_phone mbr-fonts-style display-2"
                      href="tel:600599996"
                    >
                      <h4> 600 599 996</h4>
                    </a>
                  </span>

                  <span>
                    <img class="img-fluid me-3" src={mailSvg}></img>{" "}
                    <h4>
                      <a
                        href="mailto:info@tafaseel.ae"
                        class="text-danger text-primary"
                      >
                        info@tafaseel.ae
                      </a>
                    </h4>
                  </span>
                  <span>
                    <img class="img-fluid me-3" src={locSvg}></img>{" "}
                    <p>
                      Dubai Commercity Building (B2) – Dubai
                      <br />
                      Ground floor Conqueror Tower – Ajman
                      <br />
                      Ajman Boulevard Building (B) – Ajman
                    </p>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-2 ms-md-5" data-aos="fade-up">
                <ul>
                  <h5>Industries</h5>

                  <Link to="/services/digital-cx">
                    <li>Media & Communication</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Automotive</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Retail</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Health Care</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Food & Beverages</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Travel & Tours</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>E-Commerce</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Government & Public Service</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Real Estate & Facility Management</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Consumer Goods</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Logistics And Transportation Industry</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Oil And Gas Companies</li>
                  </Link>
                  <Link to="/services/digital-cx">
                    <li>Education Call Center Services</li>
                  </Link>
                </ul>
              </div>
              <div class="col-12 col-md-2 ms-md-5" data-aos="fade-up">
                <ul>
                  <h5>Services</h5>

                  <Link to="/services/it-outsourcing">
                    <li>IT Outsourcing</li>
                  </Link>
                  <Link to="/services/finance-operations-outsourcing">
                    <li>Finance Operations Outsourcing</li>
                  </Link>
                  <Link to="/services/sales-outsourcing">
                    <li>Sales Outsourcing</li>
                  </Link>
                  <Link to="/services/marketing-outsourcing-outsourcing">
                    <li>Marketing Outsourcing</li>
                  </Link>
                  <Link to="/services/customer-services-outsourcing">
                    <li>Customer Services Outsourcing</li>
                  </Link>
                  <Link to="/services/contact-center-outsourcing">
                    <li>Contact Center Outsourcing</li>
                  </Link>

                  <Link to="/services/human-resources-outsourcing">
                    <li>Human Resources Outsourcing</li>
                  </Link>
                  <Link to="/services/procurrement-outsourcing">
                    <li>Procurement Outsourcing</li>
                  </Link>
                  <Link to="/services/warehousing-outsourcing">
                    <li>Warehousing Outsourcing</li>
                  </Link>
                  <Link to="/services/transportation-outsourcing">
                    <li>Transportation Outsourcing</li>
                  </Link>
                  <Link to="/services/accomodation-outsourcing">
                    <li>Accomodation Outsourcing</li>
                  </Link>
                  <Link to="/services/knowledge-process-outsourcing">
                    <li>Knowledge Process Outsourcing</li>
                  </Link>
                  <Link to="/services/cx-digital-transformation-outsourcing">
                    <li>CX and Digital Outsourcing</li>
                  </Link>
                  <Link to="/services/workspace-outsourcing">
                    <li>Workspace Outsourcing</li>
                  </Link>
                </ul>
              </div>
              <div class="col-12 col-md-2 ms-md-5" data-aos="fade-up">
                <ul>
                  <h5>Affliate Companies</h5>
                  <a href="https://rannah.ae/">
                    <li>Rannah</li>
                  </a>
                </ul>
              </div> */}
            </div>
          ) : (
            <>
              {/* <div class="col-12 col-md-3 ms-md-5 mb-5">
                <div class="footer1 mt-4 mt-md-0">
                  <span>
                    <img class="img-fluid me-3" src={callSvg}></img>
                    <a
                      class="brand_phone mbr-fonts-style display-2"
                      href="tel:600599996"
                    >
                      <h4> 600 599 996</h4>
                    </a>
                  </span>

                  <span>
                    <img class="img-fluid me-3" src={mailSvg}></img>{" "}
                    <h4>
                      <a
                        href="mailto:info@tafaseel.ae"
                        class="text-danger text-primary"
                      >
                        info@tafaseel.ae
                      </a>
                    </h4>
                  </span>
                  <span>
                    <img class="img-fluid me-3" src={locSvg}></img>{" "}
                    <p>
                      <a
                        href="https://g.page/DubaiCommercity?share"
                        className="mb-2"
                        target="_blank"
                      >
                        Dubai Commercity Building (B2) – Dubai Ground floor
                      </a>
                      <br></br>
                      <a
                        class="pb-3"
                        href="https://goo.gl/maps/pq7E63oQK7HvRiKx6"
                        target="_blank"
                      >
                        Conqueror Tower – Ajman
                      </a>
                      <br></br>
                      <a
                        class="pb-3"
                        href="https://goo.gl/maps/cYaQrmLTp4rvCd168"
                        target="_blank"
                      >
                        Ajman Boulevard Building (B) – Ajman
                      </a>
                    </p>
                  </span>
                </div>
              </div> */}
              {/* <div class="col-12 col-md-2 ms-md-5" data-aos="fade-up">
                <ul>
                  <h5>Industries</h5>

                  <Link to="/industries/media-communication">
                    <li>Media & Communication</li>
                  </Link>
                  <Link to="/industries/automotive">
                    <li>Automotive</li>
                  </Link>
                  <Link to="/industries/retail">
                    <li>Retail</li>
                  </Link>
                  <Link to="/industries/healthcare">
                    <li>Health Care</li>
                  </Link>
                  <Link to="/industries/food-and-beverages">
                    <li>Food & Beverages</li>
                  </Link>
                  <Link to="/industries/travel-and-tours">
                    <li>Travel & Tours</li>
                  </Link>
                  <Link to="/industries/e-commerce">
                    <li>E-Commerce</li>
                  </Link>
                  <Link to="/industries/government-and-public-service">
                    <li>Government & Public Service</li>
                  </Link>
                  <Link to="/industries/real-estate-and-facility-management">
                    <li>Real Estate & Facility Management</li>
                  </Link>
                  <Link to="/industries/consumer-goods">
                    <li>Consumer Goods</li>
                  </Link>
                  <Link to="/industries/logistics-and-transportation-industry">
                    <li>Logistics And Transportation Industry</li>
                  </Link>
                  <Link to="/industries/oil-gas-companies">
                    <li>Oil And Gas Companies</li>
                  </Link>
                  <Link to="/industries/education-call-center-services">
                    <li>Education Call Center Services</li>
                  </Link>
                </ul>
              </div> */}

              <div class="col-12 col-md-2 ms-md-5 cont-services-lg">
                <ul>
                  <h5 className="mb-3 mb-md-4"><Trans i18nKey="industryFocus">Industry Focus</Trans></h5>

                  <Link to="/services/it-outsourcing">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="itOutSourcing">IT Outsourcing</Trans></li>
                  </Link>
                  <Link to="/services/finance-operations-outsourcing">
                    <li className="mb-1 mb-md-2">
                      <Trans i18nKey="financeOperationOutSourcing">Finance Operations Outsourcing</Trans>
                    </li>
                  </Link>
                  <Link to="/services/sales-outsourcing">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="salesOutSourcing">Sales Outsourcing</Trans></li>
                  </Link>
                  <Link to="/services/marketing-outsourcing-outsourcing">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="marketingOutSourcing">Marketing Outsourcing</Trans></li>
                  </Link>
                  <Link to="/services/customer-services-outsourcing">
                    <li className="mb-1 mb-md-2">
                     <Trans i18nKey="customerOutSourcing">Customer Services Outsourcing</Trans>
                    </li>
                  </Link>
                  <Link to="/services/contact-center-outsourcing">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="contactCenter">Contact Center Outsourcing</Trans></li>
                  </Link>
                </ul>
              </div>
              <div class="col-12 col-md-2 ms-md-5 cont-services-lg">
                <ul>
                  <h5 className="mb-3 mb-md-4"><Trans i18nKey="operationalFunctions">Operational Functions</Trans></h5>

                  <Link to="/services/human-resources-outsourcing">
                    <li className="mb-1 mb-md-2">
                     <Trans i18nKey="humanResourceOutSourcing">Human Resources Outsourcing</Trans>
                    </li>
                  </Link>
                  <Link to="/services/procurrement-outsourcing">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="procurementOutSourcing">Procurement Outsourcing</Trans></li>
                  </Link>
                  <Link to="/services/warehousing-outsourcing">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="warehousingOutsourcing">Warehousing Outsourcing</Trans></li>
                  </Link>
                  <Link to="/services/transportation-outsourcing">
                    <li className="mb-1 mb-md-2">
                      {" "}
                      <Trans i18nKey="transportationOutsourcing">Transportation Outsourcing</Trans>
                    </li>
                  </Link>
                  <Link to="/services/accomodation-outsourcing">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="accommodationOutsourcing">Accommodation Outsourcing</Trans></li>
                  </Link>
                </ul>
              </div>
              <div class="col-12 col-md-2 ms-md-5 cont-services-lg">
                <ul>
                  <h5 className="mb-2 mb-md-4"><Trans i18nKey="businessSolution">Business Solution</Trans></h5>

                  <Link to="/services/knowledge-process-outsourcing">
                    <li className="mb-1 mb-md-2">
                      <Trans i18nKey="knowledgeProcessOutsourcing">Knowledge Process Outsourcing</Trans>
                    </li>
                  </Link>
                  <Link to="/services/cx-digital-transformation-outsourcing">
                    <li className="mb-1 mb-md-2">
                    <Trans i18nKey="cxAndDigitalOutsourcing">CX and Digital Outsourcing</Trans>
                    </li>
                  </Link>
                  <Link to="/services/workspace-outsourcing">
                    <li className="mb-1 mb-md-2">
                    <Trans i18nKey="workspaceOutsourcing">Workspace Outsourcing</Trans>
                    </li>
                  </Link>
                </ul>
              </div>
              <div class="col-12 col-md-2 ms-md-5">
                <ul>
                  <h5 className="mb-2 mb-md-4">
                    <Trans i18nKey="affliateCompanies">
                    Affliate <br></br> Companies
                    </Trans>
                  </h5>
                  <a href="https://rannah.ae/" target="_blank">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="rannah">Rannah</Trans></li>
                  </a>
                  <a href="http://www.tafaseelit.ae/index.php" target="_blank">
                    <li className="mb-1 mb-md-2">
                      <Trans i18nKey="tafaseelIT">Tafaseel IT</Trans>
                    </li>
                  </a>
                  {/* <a href="http://6.ws15.ecompany.ae/403.html" target="_blank">
                    <li className="mb-1 mb-md-2">Blooming Beauty</li>
                  </a> */}
                  <a href="https://salamahealthcare.ae/" target="_blank">
                    <li className="mb-1 mb-md-2"><Trans i18nKey="salamaHealthCareService">Salama Healthcare services</Trans></li>
                  </a>
                </ul>
              </div>
              <div className="navbar-dummy" style={{ flex: "0 0 5%" }}></div>
            </>
          )}

          {/* <div class="col-12 col-md-2" data-aos="fade-up">
            <ul>
              <h5>Products and Platforms</h5>

              <li>
                <a href="#">Banking</a>{" "}
              </li>
              <li>
                <a href="#">Capital Markets</a>{" "}
              </li>
              <li>
                <a href="#">Consumer Goods and Distribution</a>{" "}
              </li>
              <li>
                <a href="#">Education</a>{" "}
              </li>
              <li>
                <a href="#">Energy, Resources, and Utilities</a>{" "}
              </li>
              <li>
                <a href="#">Healthcare</a>{" "}
              </li>
              <li>
                <a href="#">High Tech</a>{" "}
              </li>
              <li>
                <a href="#">Insurance</a>{" "}
              </li>
              <li>
                <a href="#">Life Sciences</a>{" "}
              </li>
              <li>
                <a href="#">Manufacturing</a>{" "}
              </li>
              <li>
                <a href="#">Public Sector</a>{" "}
              </li>
              <li>
                <a href="#">Retail</a>{" "}
              </li>
              <li>
                <a href="#">Travel and Logistics</a>{" "}
              </li>
            </ul>
          </div> */}

          <div class="container cont-foot2  pt-4 pt-md-5 mb-lg-4 mt-md-1  mt-5">
            <div class="row justify-content-center align-items-center row-foot2">
              <div class="col-12 ">
                <div class="footer-logo">
                  <img class="img-fluid" src={footSvg}></img>
                </div>
              </div>

              <div class="col-12 col-md-6 foot-items ms-2 ms-md-0 mt-3 mt-md-4  align-items-center">
                <a className="me-3 me-lg-0 footer-copy d-none" disabled>
                  <p>©2023 TAFASEEL </p>
                </a>

                <Link
                  to="/privacy-policy/"
                  className="me-3 me-lg-0 footer-copy "
                >
                  <p><Trans i18nKey="privacyPolicy">Privacy Policy</Trans></p>
                </Link>
                <Link to="/fair-usage/" className="me-3 me-lg-0 footer-copy ">
                  <p><Trans i18nKey="fairUsage">Fair Usage</Trans></p>
                </Link>
                <Link
                  to="/terms-and-conditions/"
                  className="me-3 me-lg-0 footer-copy "
                >
                  <p><Trans i18nKey="termsAndConditions">Terms & Conditions</Trans></p>
                </Link>
              </div>

              <div class="foot-socials d-flex align-items-center">
                <a
                  href="https://www.linkedin.com/company/tafaseel-bpo/"
                  target="_blank"
                >
                  <img
                    src={require("../Assets/images/linkedin.png")}
                    class="img-fluid"
                  ></img>
                </a>
                {/* <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  class="r-k200y r-13v1u17 r-4qtqp9 r-yyyyoo r-5sfk15 r-dnmrzs r-kzbkwu r-bnwqim r-1plcrui r-lrvibr"
                >
                  <g>
                    <path d="M14.258 10.152L23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293l-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0"></path>
                  </g>
                </svg> */}
                <a href="https://twitter.com/TafaseelBPO1" target="_blank">
                  <img
                    src={require("../Assets/images/twitter.png")}
                    class="img-fluid"
                  ></img>
                  {/* <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: "38px"}}/> */}
                </a>
                <a
                  href="https://instagram.com/tafaseel_bpo?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <img
                    src={require("../Assets/images/instagram.png")}
                    class="img-fluid"
                  ></img>
                </a>
                <a href="#" target="_blank">
                  <img
                    src={require("../Assets/images/youtube.png")}
                    class="img-fluid"
                  ></img>
                </a>
                <a
                  href="https://www.facebook.com/tafaseelbpo?mibextid=LQQJ4d"
                  target="_blank"
                >
                  <img
                    src={require("../Assets/images/facebook.png")}
                    class="img-fluid"
                  ></img>
                </a>

                <a
                  href="https://www.tiktok.com/@tafaseeluae?_t=8dlCfWYeDB7&_r=1"
                  target="_blank"
                >
                  <img
                    src={require("../Assets/images/tiktok.png")}
                    class="img-fluid"
                  ></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
