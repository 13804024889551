import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../Assets/images/tafaseel-logo-225x80.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faAngleLeft,
  faChevronRight,
  faLanguage,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import React, { useEffect, useState } from "react";
import LoginModalComp from "./Login/LoginModalComp";
import { Trans, useTranslation } from "react-i18next";

const NewNav2 = () => {
  const { i18n, t } = useTranslation();
  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };
  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsDropdownVisible(screenWidth < 750);
    };

    // Attach the event listener to the window resize event
    window.addEventListener("resize", handleResize);

    // Call the handler initially to set the initial visibility state
    handleResize();

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [expanded, setExpanded] = useState(false);

  const handleDropdownSelect = () => {
    setExpanded(false);
  };

  const changeLang = (lang) => {
    i18n.changeLanguage(lang)
  }

  // const imageRef = useRef(null);

  // useEffect(() => {
  //   const image = new Image();
  //   image.src = "https://github.com/Cypher-inc/syni-p/blob/master/home/Tafaseel%20Logo%20all1.ebeffaea2fa23785e408.png?raw=true";
  //   image.onload = () => {
  //     // The image is preloaded, now add it to the DOM or update the dynamic content.
  //     if (imageRef.current) {
  //       imageRef.current.src = image.src;
  //     }
  //   };

  //   // Optionally, you can handle the case where the image fails to load.
  //   image.onerror = () => {
  //     console.log('Failed to preload the image.');
  //   };
  // }, []);

  //modal
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    setShowModal(false)
  }, [t])

  return (
    <div className="NewNav2">
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-light ${expanded ? "fixed-top" : ""
          }`}
      >
        <div class="container-fluid  cont-new-nav justify-content-between">
          <button
            class="navbar-toggler me-2 ms-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          {/* <div class="col-2"> */}
          <Link to="/">
            <a class="navbar-brand  ms-0 ms-md-3" href="#">
              <img
                class="img-fluid tafaseel-logo me-lg-1 px-4"
                src={
                  "https://github.com/Cypher-inc/syni-p/blob/master/home/Tafaseel%20Logo%20all1.ebeffaea2fa23785e408.png?raw=true"
                }
                style={{ height: "60px", width: "auto" }}
                alt="Tafaseel Logo"
              ></img>
            </a>
          </Link>
          {/* <div className="navbar-dummy" style={{ flex: "0 0 10%" }}></div> */}
          {/* </div> */}

          <div class="col-12 col-lg-10 col-md-6 ml-auto">
            <div
              className={`collapse navbar-collapse ${expanded ? "show" : ""}`}
              id="navbarNav"
            >
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-start justify-content-between nav-mega pe-4 ps-4">
                <ul className="navbar-nav">
                  <li class="nav-item dropdown px-3 has-megamenu">
                    <a
                      class="nav-link dropdown-toggle d-flex align-items-center justify-content-between"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span><Trans i18nKey="whyTafaseel">Why Tafaseel</Trans></span>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="d-lg-none"
                      />
                    </a>

                    <ul
                      class="dropdown-menu megamenu dropdown-menu-services fullscreen-dropdown"
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="d-flex d-flex-nav justify-content-center flex-wrap">
                        <div class=" col-12 mt-4">
                          <div class="container cont-nav-items cont-nav-indust ">
                            <div class=" row justify-content-evenly h-100 pb-5 pe-4 ps-4 pe-md-5 ps-md-5">
                              {/* <div class="col-12 mb-3 ">
                                <h6>
                                At Tafaseel, we believe thadt good is never good
                                enough.
                                  </h6>
                              </div> */}

                              <div class="col-12  col-lg-6 h-120 nav-img-items ">
                                <div class="ms-md-5 row justify-content-between  ">
                                  <div class="col-12 mb-3 ">
                                    <h6 className="mb-5 d-lg-none">
                                      {" "}
                                      <FontAwesomeIcon icon={faAngleLeft} /> Why
                                      Tafaseel{" "}
                                    </h6>

                                    <h6 style={{ textAlign: i18n.language === 'ar' ? 'right' : 'start' }}>
                                      <Trans i18nKey="whyTafaseelTitle">
                                        At Tafaseel, we believe that good is never good enough.
                                      </Trans>
                                    </h6>
                                  </div>

                                  <div class="col-12  col-lg-6 h-120 nav-img-items">
                                    <div
                                      class=" "
                                      aria-labelledby="dropdownMenuButton"
                                      data-bs-popper="static"
                                    >
                                      <Link
                                        to="/about-us"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="aboutUs">About us</Trans></li>
                                      </Link>
                                      <Link
                                        to="/group-management"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="leadership">Leadership</Trans></li>
                                      </Link>
                                    </div>
                                    {/* <div
                                      class="collapse show "
                                      id="layout-collapse"
                                    >
                                      <ul class="list-unstyled fw-normal pb-1 small">
                                        <Link
                                          to="/about-us"
                                          onClick={handleDropdownSelect}
                                        >
                                          <li>About us</li>
                                        </Link>

                                        <Link
                                          to="/group-management"
                                          onClick={handleDropdownSelect}
                                        >
                                          <li>Leadership</li>
                                        </Link>
                                      </ul>
                                    </div> */}
                                  </div>
                                  <div class="col-12  col-lg-6 h-120 nav-img-items">
                                    <div
                                      class=" "
                                      aria-labelledby="dropdownMenuButton"
                                      data-bs-popper="static"
                                    >
                                      <Link
                                        to="/group-of-companies"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="groupOfCompanies">Group Of Companies</Trans></li>
                                      </Link>

                                      <a
                                        href="https://academy.tafaseel.ae/"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="tafaseelLearning">Tafaseel learning</Trans></li>
                                      </a>
                                    </div>

                                    {/* <div
                                      class=""
                                    >
                                      <ul class="list-unstyled fw-normal pb-1 small">
                                        <Link
                                          to="/group-of-companies"
                                          onClick={handleDropdownSelect}
                                        >
                                          <li>Group Of Companies</li>
                                        </Link>

                                        <li>News</li>
                                        <li>Events</li>
                                        <a
                                          href="https://academy.tafaseel.ae/"
                                          onClick={handleDropdownSelect}
                                        >
                                          <li>Tafaseel learning</li>
                                        </a>
                                      </ul>
                                    </div> */}
                                  </div>
                                </div>
                              </div>

                              <div class="col-12  col-md-6 h-120 nav-img-items">
                                <div class="nav-img why_ts"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </li>
                </ul>

                <li class="nav-item dropdown px-3 has-megamenu">
                  <a
                    class="nav-link dropdown-toggle d-flex align-items-center justify-content-between "
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><Trans i18nKey="services">Services</Trans></span>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="d-lg-none"
                    />
                  </a>
                  <ul
                    class="dropdown-menu megamenu dropdown-menu-services fullscreen-dropdown"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="d-flex d-flex-nav justify-content-center flex-wrap">
                      <div class=" col-12">
                        <div class="container cont-nav-items">
                          <div class="ms-md-5 row justify-content-evenly h-100 pb-5 pe-4 ps-4 pe-md-5 ps-md-5">
                            {/* <div class="col-12 col-md-6 mb-3 nav-heading">
                            Digital CX and AI
                          </div> */}
                            {/* <div class="col-12 col-md-6 mb-3 nav-heading">
                          </div> */}
                            <div class="col-12 col-lg-4 h-120 nav-img-items pt-4 nav-sub-dropdown">
                              <h6 className="mb-5 d-lg-none p-0">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faAngleLeft}
                                /> <Trans i18nKey="services">Services&nbsp;</Trans>
                              </h6>

                              <h5
                                className="dropdown-toggle nav-heading"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={handleDropdownClick}
                              >
                                <Trans i18nKey="industryFocus">Industry Focus</Trans>
                              </h5>
                              <div
                                className={` ${isDropdownVisible ? "dropdown-menu" : ""
                                  }`}
                                aria-labelledby="dropdownMenuButton"
                              >
                                <Link
                                  to="/services/contact-center-outsourcing"
                                  className="dropdown-item"
                                  onClick={handleDropdownSelect}
                                >
                                  <li><Trans i18nKey="contactCenter">Contact Center Outsourcing</Trans></li>
                                </Link>

                                <Link
                                  to="/services/it-outsourcing"
                                  className="dropdown-item"
                                  onClick={handleDropdownSelect}
                                >
                                  <li><Trans i18nKey="itOutSourcing">IT Outsourcing</Trans></li>
                                </Link>

                                <Link
                                  to="/services/sales-outsourcing"
                                  className="dropdown-item"
                                  onClick={handleDropdownSelect}
                                >
                                  <li><Trans i18nKey="salesOutSourcing">Sales Outsourcing</Trans></li>
                                </Link>
                                <Link
                                  to="/services/marketing-outsourcing-outsourcing"
                                  className="dropdown-item"
                                  onClick={handleDropdownSelect}
                                >
                                  <li><Trans i18nKey="marketingOutSourcing">Marketing Outsourcing</Trans></li>
                                </Link>

                                <Link
                                  to="/services/customer-services-outsourcing"
                                  className="dropdown-item"
                                  onClick={handleDropdownSelect}
                                >
                                  <li><Trans i18nKey="customerOutSourcing">Customer Services Outsourcing</Trans></li>
                                </Link>

                                <Link
                                  to="/services/finance-operations-outsourcing"
                                  className="dropdown-item"
                                  onClick={handleDropdownSelect}
                                >
                                  <li><Trans i18nKey="financeOperationOutSourcing">Finance Operations Outsourcing</Trans></li>
                                </Link>
                              </div>
                            </div>
                            <div class="col-12 col-lg-4 h-120 nav-img-items pt-4 nav-sub-dropdown">
                              <div className="dropdown">
                                <h5
                                  className="dropdown-toggle nav-heading"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={handleDropdownClick}
                                >
                                  <Trans i18nKey="operationalFunctions">Operational Functions</Trans>
                                </h5>

                                {/* <Link to="/services/outsourcing">
                                <li>Outsourcing</li>
                              </Link> */}
                                <div
                                  className={`dropdown-menu ${isDropdownVisible
                                    ? ""
                                    : "dropdown-menu show "
                                    }`}
                                  data-bs-popper="static"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <Link
                                    to="/services/human-resources-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li><Trans i18nKey="humanResourceOutSourcing">Human Resources Outsourcing</Trans></li>
                                  </Link>
                                  <Link
                                    to="/services/procurrement-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li><Trans i18nKey="procurementOutSourcing">Procurement Outsourcing</Trans></li>
                                  </Link>
                                  <Link
                                    to="/services/warehousing-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li><Trans i18nKey="warehousingOutsourcing">Warehousing Outsourcing</Trans></li>
                                  </Link>
                                  <Link
                                    to="/services/transportation-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li><Trans i18nKey="transportationOutsourcing">Transportation Outsourcing</Trans></li>
                                  </Link>
                                  <Link
                                    to="/services/accomodation-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li><Trans i18nKey="accommodationOutsourcing">Accommodation Outsourcing</Trans></li>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-4 h-120 nav-img-items pt-4 nav-sub-dropdown">
                              <div className="dropdown">
                                <h5
                                  className="dropdown-toggle nav-heading"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={handleDropdownClick}
                                >
                                  <Trans i18nKey="businessSolution">Business Solution</Trans>
                                </h5>
                                <div
                                  className={`dropdown-menu ${isDropdownVisible
                                    ? ""
                                    : "dropdown-menu show "
                                    }`}
                                  data-bs-popper="static"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <Link
                                    to="/services/knowledge-process-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li><Trans i18nKey="knowledgeProcessOutsourcing">Knowledge Process Outsourcing</Trans></li>
                                  </Link>
                                  <Link
                                    to="/services/cx-digital-transformation-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li><Trans i18nKey="cxAndDigitalOutsourcing">CX and Digital Outsourcing</Trans></li>
                                  </Link>
                                  <Link
                                    to="/services/workspace-outsourcing"
                                    className="dropdown-item"
                                    onClick={handleDropdownSelect}
                                  >
                                    <li> <Trans i18nKey="workspaceOutsourcing">Workspace Outsourcing</Trans></li>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>

                <li class="nav-item dropdown px-3 has-megamenu">
                  <a
                    class="nav-link dropdown-toggle d-flex align-items-center justify-content-between"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><Trans i18nKey="industries">Industries</Trans></span>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="d-lg-none"
                    />
                  </a>
                  <ul
                    class="dropdown-menu megamenu dropdown-menu-services fullscreen-dropdown"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="d-flex d-flex-nav justify-content-center flex-wrap">
                      <div class=" col-12 mt-4 nav-sub-dropdown">
                        <div class="container cont-nav-items cont-nav-indust">
                          <div class=" row justify-content-evenly h-100 pb-5 pe-4 ps-4 pe-md-5 ps-md-5">
                            {/* <div class="col-12 mb-3 ">
                                <h6>
                                At Tafaseel, we believe thadt good is never good
                                enough.
                                  </h6>
                              </div> */}
                            <div class="col-12  col-lg-6">
                              <div class="ms-md-5 row justify-content-start">
                                <div class="col-12 mb-3 ">
                                  <h6 className="mb-5 d-lg-none">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faAngleLeft}
                                    />{" "}
                                    <Trans i18nKey="industries">Industries</Trans>{" "}
                                  </h6>

                                  <h6 className="" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'start' }}>
                                    <Trans i18nKey="industriesTitle">Advanced, industry-specific solutions</Trans>
                                  </h6>
                                </div>

                                <div class="col-12  col-lg-6 h-120">
                                  <div
                                    class=" "
                                    aria-labelledby="dropdownMenuButton"
                                    data-bs-popper="static"
                                  >
                                    <ul class="list-unstyled fw-normal pb-1 small">
                                      <Link
                                        to="/industries/media-communication"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="mediaAndCommunication">Media & Communication</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/automotive"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="automotive">Automotive</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/retail"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="retail">Retail</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/healthcare"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="healthCare">Health Care</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/food-and-beverages"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="foodAndBeverages">Food & Beverages</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/travel-and-tours"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="travelAndTours">Travel & Tours</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/e-commerce"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="ecommerce">E-Commerce</Trans></li>
                                      </Link>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col-12  col-lg-6 h-120">
                                  <div
                                    class=" "
                                    aria-labelledby="dropdownMenuButton"
                                    data-bs-popper="static"
                                  >
                                    <ul class="list-unstyled fw-normal pb-1 small">
                                      <Link
                                        to="/industries/government-and-public-service"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="governmentAndPublicService">Government & Public Service</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/real-estate-and-facility-management"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li>
                                          <Trans i18nKey="realEstateAndFacilityManagement">Real Estate & Facility Management</Trans>
                                        </li>
                                      </Link>
                                      <Link
                                        to="/industries/consumer-goods"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="consumerGoods">Consumer Goods</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/logistics-and-transportation-industry"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li>
                                          <Trans i18nKey="logisticsAndTransportationIndustry">
                                            Logistics & Transportation
                                            Industry
                                            </Trans>
                                        </li>
                                      </Link>
                                      <Link
                                        to="/industries/oil-gas-companies"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="oilAndGasCompanies">Oil And Gas Companies</Trans></li>
                                      </Link>
                                      <Link
                                        to="/industries/education-call-center-services"
                                        onClick={handleDropdownSelect}
                                      >
                                        <li><Trans i18nKey="educationSolutions">Education Solutions</Trans></li>
                                      </Link>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12  col-lg-6 h-120">
                              <div class="nav-img indust"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>
                <Link
                  to="/insights/client-stories"
                  onClick={handleDropdownSelect}
                >
                  <li class="nav-item px-3">
                    <a class="nav-link" aria-current="page" href="#">
                      <Trans i18nKey="insights">Insights</Trans>
                    </a>
                  </li>
                </Link>

                <Link to="/careers" onClick={handleDropdownSelect}>
                  <li class="nav-item px-3">
                    <a class="nav-link" aria-current="page" href="#">
                      <Trans i18nKey="careers">Careers</Trans>
                    </a>
                  </li>
                </Link>
                <Link to="/esg" onClick={handleDropdownSelect}>
                  <li class="nav-item px-3 nav-esr">
                    <a class="nav-link " aria-current="page" href="#">
                      <img
                        src={require("../Assets/images/ICONS/leaf.png")}
                        alt="Icon"
                        className="col-12 me-1"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <Trans i18nKey="esg">ESG</Trans>
                    </a>
                  </li>
                </Link>
                {/* <Link to="/contact" onClick={handleDropdownSelect}> */}
                <li class="nav-item px-3 nav-login-btn">
                  <a
                    class="nav-link"
                    aria-current="page"
                    onClick={handleShow}
                  >
                    <Trans i18nKey="login">Login</Trans>
                  </a>
                  <LoginModalComp showModal={showModal} handleClose={handleClose} />
                </li>
                {/* </Link> */}
                <Link to="/contact" onClick={handleDropdownSelect}>
                  <li class="nav-item px-3">
                    <a
                      class="nav-link contact-back"
                      aria-current="page"
                      href="#"
                    >
                      <Trans i18nKey="contactUs">Contact Us</Trans>
                    </a>
                  </li>
                </Link>
                <div class="btn-group">
                  <button type="button" class="btn btn-transparent dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                    <FontAwesomeIcon
                      icon={faGlobe}
                    />
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                    <li><a class="dropdown-item" href="#" onClick={() => changeLang('en')}>English</a></li>
                    <li><a class="dropdown-item" href="#" onClick={() => changeLang('ar')}>عربي</a></li>
                  </ul>
                </div>
              </ul>
              {/* <li class="px-3">
                <div class="dropdown">
                  <a class="btn btn-transparent btn-lg p-2 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon
                      icon={faGlobe}
                    />
                  </a>

                  <ul class="dropdown-menu me-2">
                    <li><a class="dropdown-item" href="#" onClick={() => changeLang('en')}>English</a></li>
                    <li><a class="dropdown-item" href="#" onClick={() => changeLang('ar')}>عربي</a></li>
                  </ul>
                </div>
              </li> */}


              {/* <div class="navbar-buttons mbr-section-btn me-3">
                <a class="btn btn-mobile p-2" href="tel:600599996">
                  <FontAwesomeIcon icon={faPhone} className="pe-2" />
                  600 599996
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default NewNav2;
