import { useEffect, useState } from "react";
import { useUserContext } from "../Context/UserContext";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebaseConfig";
import LogoutButton from "./LogoutButton";
import { CircularProgressbar } from "react-circular-progressbar";
import DatePicker from "react-multi-date-picker";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Buttons from "react-multi-date-picker/components/button";
import { Link } from "react-router-dom";
import LoginNavComp from "./LoginNav";

const EmployeePage = () => {
  const { userInfo } = useUserContext();
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [textareaField, setTextareaField] = useState();
  //   const [leaveDate, setLeaveDate] = useState();
  const [userLeaveData, setUserLeaveData] = useState();
  const [optionVal, setOptionVal] = useState(new Date());

  const [leaveDate, setLeaveDate] = useState();
  const [fileInput, setFileInput] = useState();
  const [uploadProgress, setUploadProgress] = useState();

  const [certificateUrl, setCertificateUrl] = useState(null);
  // console.log(userInfo.user);

  const employeeFormSubmitFun = async (e) => {
    e.preventDefault();

    // console.log(employeeId);
    // console.log(employeeName);
    // console.log(textareaField);

    // console.log(leaveDate);

    const formattedDates = leaveDate.map(
      (date) => `${date.year}/${date.month}/${date.day}`
    );

    const leaveFormData = {
      EmpId: employeeId,
      EmployeeName: employeeName,
      LeaveDate: formattedDates,
      LeaveReason: textareaField,
      LeaveType: optionVal,
      LeaveStatus: "Awaiting",
      LeaveCertificate: certificateUrl,
    };

    console.log(leaveFormData);

    try {
      const userLeaveCollectionRef = collection(
        db,
        "users",
        userInfo.user.uid,
        "leave-applications"
      );
      await addDoc(userLeaveCollectionRef, {
        ...leaveFormData,
        Timestamp: serverTimestamp(),
      });

      alert("Leave Application Submitted Successfully");
      displayLeaveInfo();
    } catch (err) {
      console.error(err);
    }

    // setEmployeeId("");
    // setEmployeeName("");
    setTextareaField("");
    setFileInput("");
    setLeaveDate("");
    setCertificateUrl("")
  };

  const displayLeaveInfo = async () => {
    const userLeaveCollectionRef = query(
      collection(db, "users", userInfo.user.uid, "leave-applications"),
      orderBy("Timestamp", "desc")
    );
    // const q = query(userLeaveCollectionRef);

    const querySnapshot = await getDocs(userLeaveCollectionRef);
    const filterData = querySnapshot.docs.map((items) => {
      return {
        ...items.data(),
        id: items.id,
      };
    });
    console.log(filterData);
    setUserLeaveData(filterData);
  };

  useEffect(() => {
    displayLeaveInfo();
    setEmployeeId(userInfo.user.email.slice(0, -12));
    setEmployeeName(userInfo.user.displayName);
  }, []);

  //   console.log(userLeaveData.length);
  useEffect(() => {
    
    if (fileInput !== undefined && fileInput.length !== 0) {
      const storageRef = ref(storage, `medical-cert/${fileInput.name}`);
      const uploadTask = uploadBytesResumable(storageRef, fileInput);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          if (progress === 100) {
            alert("Mediacal Certificate Uploaded  sucessfully");
          }
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.log(error);

          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            // setImageUrl(downloadURL);
            setCertificateUrl(downloadURL);
          });
        }
      );
    }
  }, [fileInput]);

  return (
    <div className="container-fluid mt-lg-5 pt-md-5 pb-5 sales-dash-cont">
      <div className="row justify-content-center">
        {/* <div className="row col-10 col-lg-8 justify-content-between  align-items-center p-3 mb-5">
          <h4 className="col">
            <Link to="/employee-dashboard">
              <button className="btn btn-light">Leave Application</button>
            </Link>
          </h4>
          <h4 className="col">
            <Link to="/employee-dashboard/salary-certificate/">
              <button className="btn btn-success">Salary Certificate</button>
            </Link>
          </h4>
          <h4 className="col">
            <Link to="/sales-dashboard/">
              <button className="btn btn-warning">Sales Dashboard</button>
            </Link>
          </h4>
          <h4 className="col">
            <Link to="/admin-dashboard/">
              <button className="btn btn-danger">Admin Dashboard</button>
            </Link>
          </h4>
        </div> */}
        <LoginNavComp></LoginNavComp>

        <h2>Employee Dashboard</h2>

        <div className="container-fluid mt-2 col-12 col-md-10 col-lg-6">
          <div className="row justify-content-between  p-3 align-items-center mb-5">
            <div className="logout-cont d-flex p-3 justify-content-center justify-content-lg-between align-items-center">
              <div className="col-8 text-start">
                <h4 className="mb-0">
                  <num1>{userInfo.user.displayName}</num1>
                </h4>
              </div>

              <div className="col-4 text-end">
                <LogoutButton></LogoutButton>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 mb-5">
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 col-lg-4 row justify-content-center ">
              <h1>Leaves Left</h1>
              <div style={{ height: 180 }} className="mb-5 col-8 col-lg-6">
                <CircularProgressbar value={0} text={`0`} />
              </div>
            </div>
            <div className="col-10 col-md-6 col-lg-4 row justify-content-center">
              <h1>Leaves Taken</h1>
              <div style={{ height: 180 }} className="mb-5 col-8 col-lg-6">
                <CircularProgressbar value={0} text={`0`} />
              </div>
            </div>
          </div>
        </div>

        <h2>Leave Application</h2>
        <div className="container-fluid col-10 col-lg-6 mt-2">
          <div className="row text-start">
            <form onSubmit={employeeFormSubmitFun}>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Employee ID
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={employeeId}
                  //   onChange={(e) => setEmployeeId(e.target.value)}
                  required
                  disabled
                ></input>
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Employee Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  value={employeeName}
                  //   onChange={(e) => setEmployeeName(e.target.value)}
                  required
                  disabled
                ></input>
              </div>

              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Select type of leave
                </label>
                <select
                  class="form-select"
                  id="referalDropdown"
                  required
                  onChange={(e) => setOptionVal(e.target.value)}
                >
                  <option value="" selected>
                    None
                  </option>
                  <option value="Annual">Annual Leave</option>
                  <option value="Sick">Sick Leave</option>
                </select>
              </div>

              {optionVal === "Annual" ? (
                <div class="mb-3 number-text date-emp-cont">
                  <label for="exampleInputPassword1" class="form-label">
                    Select Date
                  </label>
                  <br></br>
                  {/* <input
                    type="date"
                    id="calendarInput"
                    name="calendarInput"
                    className="form-control w-100"
                    value={leaveDate}
                    onChange={(e) => setLeaveDate(e.target.value)}
                    required
                  ></input> */}

                  <DatePicker
                    multiple
                    value={leaveDate}
                    onChange={setLeaveDate}
                    render={<InputIcon style={{ color: "black" }} />}
                    className="form-control"
                  />
                </div>
              ) : optionVal === "Sick" ? (
                <div class="mb-3 number-text d-flex date-emp-cont">
                  <div className="col-6">
                    <label for="exampleInputPassword1" class="form-label">
                      Select Date
                    </label>
                    <br></br>
                    <DatePicker
                      multiple
                      value={leaveDate}
                      onChange={setLeaveDate}
                      render={<InputIcon style={{ color: "black" }} />}
                      className="form-control"
                    />
                  </div>
                  <div className="col-6">
                    <label for="exampleInputPassword1" class="form-label">
                      Upload Medical Certificate
                      {leaveDate !== undefined && leaveDate.length > 1
                        ? " (*)"
                        : ""}
                    </label>
                    <input
                      type="file"
                      onChange={(e) => setFileInput(e.target.files[0])}
                      required={leaveDate !== undefined && leaveDate.length > 1}
                    ></input>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Reason for Leave
                </label>
                <textarea
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  rows="4"
                  value={textareaField}
                  onChange={(e) => setTextareaField(e.target.value)}
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                class="btn btn-danger"
                disabled={uploadProgress < 100}
                // onClick={employeeFormSubmitFun}
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        <div className="container number-text mt-5 pt-md-4">
          <h1 className="mb-3">Leave Status:</h1>
          <div className="row justify-content-center">
            {userLeaveData !== undefined && userLeaveData.length !== 0 ? (
              <div className="col-12 col-md-10 table-responsive">
                <table class="table ">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Leave Date</th>
                      <th scope="col">Emp Id</th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Leave Type</th>
                      <th scope="col">Leave Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userLeaveData.map((leaveData, index) => {
                      const LeaveDates = Object.values(leaveData.LeaveDate);
                      // console.log(LeaveDates.length);

                      return (
                        <>
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>
                              {LeaveDates.length <= 1 ? (
                                <div>{LeaveDates}</div>
                              ) : (
                                LeaveDates.map((data1, index) => (
                                  <div key={index}>
                                    {data1}
                                    <br />
                                  </div>
                                ))
                              )}
                            </td>
                            <td>{leaveData.EmpId}</td>
                            <td>{leaveData.EmployeeName}</td>
                            <td>{leaveData.LeaveType}</td>

                            <td
                              class={`${
                                leaveData.LeaveStatus === "Approved"
                                  ? "sales-status-dropdown-green"
                                  : leaveData.LeaveStatus === "Rejected"
                                  ? "sales-status-dropdown-hot"
                                  : ""
                              }`}
                            >
                              {leaveData.LeaveStatus}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeePage;
