import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    // Listen for changes in the authentication state
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        // console.log({...userDocSnapshot.data()});
        const userData = userDocSnapshot.data();
        setUserInfo({ user, role: userData.UserRole });
        // setUserInfo(user);
        // console.log("User is signed in:", user.uid);
      } else {
        // User is signed out
        setUserInfo(null); // Clear the user info when signed out
        console.log("User is signed out");
      }
      setAuthChecked(true);
    });

    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      {authChecked ? children : null}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}
