import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en-US",
    debug: true,
    resources: {
      en: {
        translation: {
          aboutUsDescription:
            "Tafaseel is UAE’s home grown largest business process outsourcing company. Delivering best outsourcing services, skilled people, premium places, stringent processes and cutting edge technology",
          liveStats: "Live Stats",
          employees: "Employees",
          nationalities: "Nationalities",
          languages: "Languages",
          millionPlus: "M+",
          billionPlus: "B+",
          liveTouched: "Lives Touched",
          successfulRings: "Successful Rings",
          paymentProcessed: "Payment Processed",
          liveChats: "Live Chats",
          emailsHandled: "Emails Handled",
          sites: "Sites",
          whatWeDo: "What We Do?",
          serviceDescription:
            "We take pride in offering exceptional outsourcing solutions tailored to your specific business requirements. With our client-centric approach, we are dedicated to optimizing your operations, driving growth, and exceeding your expectations. Our team of professionals is committed to providing reliable, high-quality services that help enhance your business performance. Partner with us to gain a competitive edge and achieve success in today's dynamic marketplace.",
          industryFocus: "Industry Focus",
          contactCenter: "Contact Center Outsourcing",
          itOutSourcing: "IT Outsourcing",
          financeOperationOutSourcing: "Finance Operations Outsourcing",
          salesOperationOutsourcing: "Sales Operations Outsourcing",
          marketingOperationsOutSourcing: "Marketing Operations Outsourcing",
          salesOutSourcing: "Sales Outsourcing",
          marketingOutSourcing: "Marketing Outsourcing",
          customerOutSourcing: "Customer Services Outsourcing",
          operationalFunctions: "Operational Functions",
          humanResourceOutSourcing: "Human Resources Outsourcing",
          procurementOutSourcing: "Procurement Outsourcing",
          warehousingOutsourcing: "Warehousing Outsourcing",
          transportationOutsourcing: "Transportation Outsourcing",
          cxAndDigitalTransformationOutsourcing:
            "CX & Digital Transformation Outsourcing",
          workSpace: "Workspace",
          accommodationOutsourcing: "Accommodation Outsourcing",
          businessSolution: "Business Solution",
          knowledgeProcessOutsourcing: "Knowledge Process Outsourcing",
          cxAndDigitalOutsourcing: "CX and Digital Outsourcing",
          workspaceOutsourcing: "Workspace Outsourcing",
          affliateCompanies: "Affliate <br></br> Companies",
          ourClients: "Our Clients",
          testimonials: "Testimonials",
          arjunSingha: "Arjun Singh",
          arjunSinghFeedback: `"Tafaseel BPO's authentic and specific approach made all the difference. Their relevance to our unique needs was impressive, resulting in significant positive changes. With a positive tone and emotional impact, they truly understand their clients. Their quantifiable results showcase their expertise and commitment to success."`,
          malikAbubakar: "Malik Abubakar",
          malikFeedback: `"We're grateful for Tafaseel BPO's authentic and timely support. Their specific strategies and relevant solutions enhanced our productivity significantly. The positive tone and emotional impact were evident throughout our journey together. With quantifiable results, their excellence is undeniable. Tafaseel BPO truly stands out."`,
          benjamin: "Benjamin Davies",
          benjaminFeedback: `"Choosing Tafaseel BPO was transformative. Their authentic, specific, and relevant solutions worked wonders. With our client's photo, shared with permission, the emotional impact is evident. Quantifiable results prove their excellence. Timely, concise, and impressive—Tafaseel BPO is unparalleled."`,
          fromVisionToVictory: "From<br></br>Vision To Victory",
          ourInspirationalJourney: "Our Inspirational journey",
          maryamAlNauimi: "H.E Maryam Al Nuaimi",
          presidentAndFounder: "President & Founder",
          maryamAlNauimiDescription: `"From a call center supervisor to the founder of Tafaseel BPO, my journey is fueled by passion and perseverance starting with two agents, we aimed to redefine service delivery. Today, our legacy thrives on the commitment of our people and the pursuit of excellence"`,
          ahmedKhbeer: "Ahmed Khbeer",
          partnerAndGroupCEO: "Partner & Group CEO",
          ahmedKhbeerDescription: `"Beginning as a call center agent. I partnered with Maryam to
                    drive Tafaseel BPO's expansion. our shared vision and embrace
                    of technology empowered us. With a foundation in <span className='num1'>3P's</span>, we continue delivering exceptional
                    service and embracing innovation"`,
          mission: "Mission",
          missionDescription:
            "To embark on an extraordinary journey of transforming the BPO landscape, bridging continents, and becoming the epitome of excellence in global business services, enriching organizations with unparalleled success and inspiring limitless possibilities",
          aboutUsDescription1:
            "At Tafaseel BPo, we embrace the power of the 3P's - People, Place.and Process, backed by the transformative force of Technology.This winning combination propels us to deliver unparalleled service, where our dedicated team, carefully crafted environment, streamlined processes, and cutting-edge technology converge to create a symphony of excellence for our valued clients.",

          technologyDescription:
            "We embrace cuting-edge technology to elevate our service standards.From automation to Al, we harness innovation to redefine service delivery and ensure unparalleled efficiency at every level. Technology empowers us to consistently deliver exceptional experiences to our valued clients",
          groupOfCompanies: "Group of Companies",
          fromUAEToTheWorld: "From UAE to the world",
          rannah: "Rannah",
          rannahDescription:
            "Rannah.ae is world's first fully automated on demand call center subscription platform made for every business from anywhere in the world",
          tafaseelBPO: "Tafaseel BPO",
          tafaseelBPODescription:
            "UAE home grown leading business process Outsourcing services. Founded in 2011. specialize in Call center outsourcing, build, management, operate, transfer. IT integrated solutions, infrastructure and Business communication. Customer service training and consultancy.",
          salamaHealthCare: "Salama Health Care",
          salamaHealthCareService: "Salama Healthcare services",
          salamaHealthCareDescription:
            "Salama Healthcare specialized in Home Healthcare to promote the safe and healthy living of the community around us by ensuring excellent quality care right at your home.",
          bloomingBeautyLounge: "Blooming Beauty Lounge",
          bloomingBeautyLoungeDescription:
            "A promising one-stop beauty and social lounge for women letting them revive, connect and bloom.",
          tafaseelIT: "Tafaseel IT",
          tafaseelITDescription:
            "Tafaseel IT provides cutting-edge technology solutions that can help propel your organization while preparing you for the future. Transform your organization with an experienced IT solution provider that has been focused on technology and networking.",
          whyTafaseel: "Why Tafaseel?",
          services: "Services",
          industries: "Industries",
          insights: "Insights",
          careers: "Careers",
          esg: "ESG",
          login: "Login",
          industriesTitle: "Advanced, industry-specific solutions",
          mediaAndCommunication: "Media & Communication",
          automotive: "Automotive",
          retail: "Retail",
          healthCare: "Health Care",
          foodAndBeverages: "Food & Beverages",
          travelAndTours: "Travel & Tours",
          ecommerce: "E-Commerce",
          governmentAndPublicService: "Government & Public Service",
          realEstateAndFacilityManagement: "Real Estate & Facility Management",
          consumerGoods: "Consumer Goods",
          logisticsAndTransportationIndustry:
            "Logistics & Transportation Industry",
          oilAndGasCompanies: "Oil & Gas Companies",
          educationSolutions: "Education Solutions",
          aboutUs: "About Us",
          tafaseelLearning: "Tafaseel Learning",
          leadership: "Leadership",
          contactUs: "Contact Us",
          readArticle: "Read Article",
          callUs: "Call us",
          mondayToFriday: "Monday - Friday",
          call: "Call",
          email: "Email",
          weWillReplyASAP: "We will reply as soon as possible",
          sendUsAnEmail: "Send us an email",
          dubaiOffice: "Dubai Office",
          dubaiAddress: "Dubai CommerCity, 11 17th St - Umm Ramool",
          ajmanOfficeHQ: "Ajman Office (HQ)",
          ajmanHQAddress:
            "Conqueror Tower, Sheikh Maktoum Bin Rashid St - Ajman Industrial 1",
          ajmanOfficeBO: "Ajman Office (BO)",
          ajmanBOAddress: "Boulevard Building B, Al Jurf 2",
          cairoOffice: "Cairo Office",
          cairoAddress:
            "Building (O118), 3rd floor, Office (302), Ring Road, One Kattameya, Cairo, Egypt",
          viewMap: "View Map",
          letsTalk: "Let's talk",
          reasonToContact: "Select the reason for your contact",
          contactSale: "Contact a Sales Representative",
          wantToWorkForTafaseel: "I want to work for Tafaseel",
          howCanWeHelp: "Just Let Us Know How We Can Help",
          termsAndConditions: "Terms & Conditions",
          fairUsage: "Fair Usage",
          privacyPolicy: "Privacy Policy",
          username: "Username",
          password: "Password",
          environmentSocialGovernance: "Environment Social Governance",
          environmentSocialGovernanceDescription1:
            "Welcome to the Office of Sustainability, the central hub dedicated to the coordination and guidance of Tafaseel's sustainability roadmap. As stewards of Tafaseel's Sustainability Vision",
          environmentSocialGovernanceDescription2:
            "we are committed to fostering a greener and more sustainable future.",
          sustainablePractices: "Sustainable Practices",
          sustainablePracticesDescription:
            "We align projects and programs to promote sustainable practices, monitor and report on our sustainability performance, and coordinate the implementation of Tafaseel's Climate Action Plan. Our commitment to transparency extends to communicating our sustainability actions and initiatives to the public.",
          engagingWithTheCommunity: "Engaging With the Community",
          engagingWithTheCommunityDescription:
            "we actively engage with the community, fostering sustainability literacy and raising awareness about our collective responsibility to protect the environment. Together, we can make a positive impact.",
          environmentSocialGovernanceFooterText1:
            "Join us in our journey towards a more sustainable future. Explore our website to learn more about our initiatives and how you can get involved.",
          environmentSocialGovernanceFooterText2:
            "Together, we can create lasting change",
          getInTouch: "Get in touch",
          haveSuggestionsMakingTafaseelBetter:
            "Have suggestions on making Tafaseel more sustainable?",
          wantToBeInvolved: "Want to be involved?",
          emailUsAt: "Email us at",
          whyWorkForTafaseel: "Why Work For Tafaseel?",
          careersDescription1:
            "Tafaseel BPO offers a dynamic environment with cutting-edge technology, fostering career growth, and collaboration. Employees receive recognition and appreciation for impactful work, while enjoying a supportive culture and work-life balance.",
          careersDescription2:
            "Strong leadership, competitive compensation, and benefits make Tafaseel an attractive choice for professionals",
          homegrownHeroes: "Homegrown Heroes",
          lanaHashim: "Lana Hashim",
          lanaHashimDescription:
            "My humble journey at Tafaseel BPO began as a Contact Center Agent in June 2013. Today, standing as the Contact Center Operations Manager, I am deeply proud of my remarkable growth. Leading our dedicated team is a true calling, creating an inspiring work environment where everyone thrives. Tafaseel BPO's commitment to personal growth and the unwavering support from colleagues have been the driving forces behind my success. Upholding our values, I aim to push the boundaries of achievement, making every customer interaction memorable. To all aspiring colleagues, with passion and dedication, your journey at Tafaseel BPO can be extraordinary.",
          noorulAmeen: "Noorul Ameen",
          aboutNoorulAmeen:
            "Skilled ICT professional with 7  years' UAE experience. Expert in strategic IT planning, IT infrastructure management, cybersecurity, and team leadership, driving operational efficiency and innovation.",
          noorulAmeenDescription:
            "Starting as an ICT Engineer, my fulfilling journey at Tafaseel BPO led me to the prestigious role of ICT Director. Over 7 years, I honed strategic planning and cybersecurity skills, driving operational efficiency and fostering innovation in this dynamic environment. Tafaseel's commitment to employee growth empowered me to flourish personally and professionally. Collaborating with an exceptional team, I experienced a workplace culture that values ideas and contributions. Grateful for the trust and opportunities bestowed upon me, I look forward to an inspiring future as Tafaseel BPO continues to achieve new industry heights.",
          jobOpportunities: "Job opportunities",
          workForceManagementOfficer: "Work Force Management Officer (WFM)",
          workForceManagementOfficerDescription:
            "Meet service and productivity goals. Maintain workforce management platform, forecast workloads, analyze performance for improvement. Coordinate reporting, develop agent scheduling processes, and aid strategic planning. Present recommendations to senior management.",
          ceoTitle: "CEO - Saudi Arabia",
          ceoDescription:
            "Tafaseel BPO International, a subsidiary of Tafaseel BPO Group, seeks a visionary CEO to lead Saudi Arabia operations, drive growth, innovate, foster excellence, build partnerships, monitor trends, ensure compliance, and optimize finances.",
          applyNow: "Apply Now",
          contactCentreManagerTitle: "Contact Centre Manager",
          contactCentreManagerDescription:
            "Lead managers, ensure targets met, coach teams, maintain compliance, suggest process enhancements, collaborate across departments, analyze reports, communicate with clients, achieve project goals. Requirements: Bachelor's, <num1>7-10 years (5 in BPO)</num1>, leadership, bilingual, industry knowledge, tech skills, potential certifications.",
          seniorAccountantTitle: "Senior Accountant",
          seniorAccountantDescription:
            "Ensure timely month-end closing, prepare financial statements, review activity, reconcile accounts, record transactions, and assist the accounts team. Requires CMA/CPA certification, <num1> 3 </num1> years of accounting experience, excellent Arabic and English communication, and computer skills.",
          ictSupportEngineerTitle: "ICT Support Engineer",
          ictSupportEngineerDescription:
            "Install and allocate PC units, resolve technical issues for internal clients, maintain datacenter infrastructure, manage software licenses, and suggest procedure improvements. Requires degree in computer studies, English proficiency, multitasking skills, and knowledge of MS Office, Windows <num1>10</num1>, IP Telephony, networking, and hardware/ software.",
          networkSecurityEngineerTitle: "Network Security Engineer",
          networkSecurityEngineerDescription:
            "Maintain LAN and WAN architecture, implement security protocols, resolve security issues, track vulnerable scripts, and ensure compliance with ISMS policy. Requires Bachelor's Degree in Computer Science or related field, experience as Network Security Engineer, coding knowledge, and strong analytical and troubleshooting skills",
          customerServiceRepresentativeTitle:
            "Customer Service Representative (People With Determination)",
          customerServiceRepresentativeDescription:
            "Handle incoming/outgoing calls, respond to emails/chats, resolve customer complaints/queries, escalate issues, provide service info, document calls as per SOPs, follow up, maintain call logs, experience in Financial Industry, bilingual (Arabic/English <num1>B2</num1>).",
          customerServiceRepresentativeRussianTitle:
            "Customer Service Representative (Russian Speaker)",
          customerServiceRepresentativeRussianDescription:
            "Handle calls, emails, and chats. Resolve complaints/queries, escalate issues, provide service info, and document calls per SOPs. Follow up, complete call logs, and reports. Experience as a Contact Centre Agent. Bilingual: Arabic and Russian.",
          talentedWorkForce: "Talented <br></br>Workforce",
          convenientLocation: "Convenient<br>Location",
          highestQualityService: "Highest<br>Quality<br>Service",
          stringentProcess: "Stringent<br>Process",
          theBestPrice: "The<br>Best<br>Price",
          advanceTech: "Advance<br>Tech",
          excellence: "Excellence",
          integrity: "Integrity",
          clientCentricApproach: "Client-Centric Approach",
          peopleOriented: "People Oriented",
          socialResponsibility: "Social<br/>Responsibility",
          accountability: "Accountability",
          continuousLearning: "Continuous Learning",
          transparency: "Transparency",
          dataPrivacy: "Data Privacy",
          processDescription:
            "Operations department plays a critical role in ensuring that all targets are met or exceeded: Operations departments helps our clients to deliver high quality customer service ondiexceed the customers expectations.",
          processTagLine: `"Do What You Do Best And Outsource The Rest"`,
          peterDrucker: "-Peter Drucker",
          performanceManagementTitle: "Performance Management",
          performanceManagementDescription:
            "Monitoring employee performance through metrics such as call handle time, average speed of answer, and first-call resolution rates. Identifying areas for improvement and coaching employees to improve their performance.",
          workforceManagementTitle: "Workforce Management",
          workforceManagementDescription:
            "Ensuring that there are enough employees available to handle call volumes, and that employees are appropriately trained and scheduled to meet customer demand.",
          technologyManagementTitle: "Technology Management",
          technologyManagementDescription:
            "Managing the call center's technology infrastructure, including phone systems, customer relationship management (CRM) software, and other tools to improve efficiency and customer satisfaction.",
          processImprovementTitle: "Process Improvement",
          processImprovementDescription:
            "Ensuring that interactions are handled in a professional and courteous manner, and that employees are following established processes and protocols. This may involve monitoring calls, providing feedback to employees, and conducting regular training sessions to improve performance.",
          qualityAssuranceTitle: "Quality Assurance",
          qualityAssuranceDescription:
            "Ensuring that interactions are handled in a professional and courteous manner, and that employees are following established processes and protocols. This may involve monitoring calls, providing feedback to employees, and conducting regular training sessions to improve performance.",
          process: "Process",
          highlights: "Highlights",
          chatsHandled: "Chats Handled",
          paymentsProcessed: "Payments Processed",
          fcrScore: "FCR Score%",
          cSatScore: "C-SAT Score%",
          mktScore: "MKT Score%",
          qualityScore: "Quality Score%",
          serviceLevel: "Service Level%",
          answeredRate: "Answered Rate%",
          abandonedRate: "Abandoned Rate%",
          utilizationRate: "Utilization Rate%",
          privateSector: "Private Sector",
          governmentEntities: "Government Entities",
          managedServiceProjects: "Managed Service Projects",
          aboutMaryamAlNuaimi:
            "Emirati trailblazer, serial entrepreneur, and founder of Tafaseel BPO group. Pioneered call centers in the Middle East. Honored with awards and recognition. Committed to social impact, female empowerment, and national solidarity. Visionary leader driving Tafaseel's growth and success in diverse sectors.",
          aboutAhmedKhbeer:
            "In the realm of dynamic leadership, Ahmed Khbeer stands as a living testament to boundless possibilities. His awe-inspiring journey has taken UAE's largest BPO to unprecedented heights, from 14 to 1,000 employees, Co-founding thriving ventures like Rannah.ae، Tafaseel IT and namaa  agricultural investment, and consulting for governments in UAE and KSA, he fosters a culture of value, making every team member an integral part of our success. Ahmed's audacious aim is a 2025 publicly listing Tafaseel BPO on Dubai Stock Exchange. His unwavering determination and inspirational leadership make him the driving force behind our journey to greatness.",
          ictDirector: "ICT Director",
          mohammedJamil: "Mohammed Jamil",
          financeDirector: "Finance Director",
          aboutMohammedJamil:
            "With 9 plus years of extensive audit and accounting experience in manufacturing, import-export, and service industries, Jamil excels at revamping controls, simplifying procedures, and maximizing accounting accuracy. Proficient in Oracle Financial, ERP ODOO, QuickBooks, Tally ERP 9, Zoho Books, and MS Office.",
          aymanFadl: "Ayman Fadl",
          operationsAndSupportFunctionDirector:
            "Operations & Support Function Director",
          aboutAyman:
            "Contact Center Operations Director with 10 years' UAE experience. Proficient in BPO, Contact Center Operations, Quality Assurance & Training. Focus on growth, customer satisfaction, KPIs.",
          muhanadElRashied: "Muhanad El Rashied",
          humanCapitalDirector: "Human Capital Director",
          aboutMuhanadElRashied:
            "As a Strategic Human Resources Manager, Muhanad brings 8 years of valuable experience in HR across various government and private BPO domains (Contact Center). With a proven track record in human capital management, he upholds the best industry standards and trends.",
          contactCenterOperationsManager: "Contact Center Operations Manager",
          aboutLanaHashim:
            "An experienced Operations Manager with strong leadership, customer relationship management, and staff coaching skills. With over a decade in Tafaseel starting off as a contact center agent.",
          purnimaKrishnan: "Purnima Krishnan",
          riskAndComplianceSpecialist: "Risk & Compliance Specialist",
          aboutPurnimaKrishnan:
            "GRC professional with 8+ years' overall HR and governance experience. Worked in Dubai, Al Ain, Ajman (UAE), and Mumbai/Delhi (India). Expert in Strategic Progression Management, HR, recruitment, and administrative skills, driving growth and fostering positive work environments.",
          mahaMahram: "Maha Mahram",
          projectManagementOfficer: "Project Management Officer",
          aboutMahaMahram:
            "Collaborative team player with 6+ years UAE experience. Effective under pressure, committed to teamwork, helpfulness, and determination. Contributes effectively to any project, creating a positive atmosphere, and supporting colleagues. Starting a glorious career as a fresh graduate in Tafaseel as a contact center agent to now being a PMO with multiple certifications.",
          emadMohammed: "Emad Mohammed",
          businessDevelopmentManager: "Business Development Manager",
          aboutEmadMohammed:
            "Results-driven professional with 15 years' expertise in Business Development, Client Relationship Management, Customer Experience, Operations, Retail, Account Management, Customer Success. UAE and Egypt experience.",
          sage: "Sage",
          marketingManager: "Marketing Manager",
          aboutSage:
            "12+ years experienced Marketing Manager, 8 years in Dubai. Proficient in SEO, Social Media Management, PR, PPC, Web development, team player,  leadership. Worked in UAE, India, UK. Results-oriented, trend-aware, and adaptable for diverse campaigns.",
          abdelRahmanAlSage: "Abdelrahman Al-Saga",
          operationsSupervisor: "Operations Supervisor",
          aboutAbdelRahmanAlSage:
            "Seasoned professional with 8+ years' experience in Egypt. Expert in Training, Project Management, Leadership, Communication, Critical thinking, Problem Solving, and Business planning. Brings strong business awareness and valuable skills to the team.",
          adelAlaydi: "Adel Alaydi",
          ictAssistantManager: "ICT Assistant Manager",
          aboutAdelAlaydi:
            "Technology and Support expert with a passion since 2005. UAE-born and experienced in Dubai, Sharjah, Ajman. Believes in uplifting others and leading by example.",
          alyMahmoud: "Aly Mahmoud",
          hrAssistantManager: "HR Assistant Manager",
          aboutAlyMahmoud:
            "HR Assistant Manager with 5 years' UAE experience. Skilled in recruitment, onboarding, training, employee relations. Excellent communication, building strong staff relationships.",
          ahmedBassam: "Ahmed Bassam",
          assistantManagerOps: "Assistant Manager Ops",
          aboutAhmedBassam:
            "Customer Service Excellence, Contact Center Management, Leadership, and Team Management expert with +7 years' experience in the UAE. Skilled in Quality Management, Training, Process Improvement, and Data Analysis.",
          mahmoudBadar: "Mahmoud Bader",
          projectManager: "Project Manager",
          aboutMahmoudBadar:
            "Determined and responsible Contact Center Manager with over 10 years of success in various International & Local groups. Skilled in boosting efficiency, streamlining procedures, and optimizing long-term success. Currently leading Al-Futtaim Engineering and Technologies' contact center operations.",
          muradAlBloushi: "Murad Al Bloushi",
          humanCapitalGeneralist: "Human Capital Generalist",
          aboutMuradAlBloushi:
            "HR Assistant Manager with 3 years in HRM, 5 years in UAE's BPO industry. Expert in PR and ER, contributes to policy development, data analysis, and HR activities. Team player with strong communication.",
          yaghoobAlJanahi: "Yaghoob Al-Janahi",
          pro: "PRO",
          aboutYaghoobAlJanahi:
            "PRO and ER specialist with 5 years' UAE experience. Efficiently handles communication, scheduling, client relationships. Committed to delivering excellent services and ensuring compliance.",
          mohsenEshag: "Mohsen Eshag",
          operationsCoordinator: "Operations Coordinator",
          aboutMohsenEshag:
            "Operations Coordinator with 12 years' experience in Sudan and UAE. Expertise in team coordination, administrative tasks. Committed to efficient workflow, contributing to success.",
          ahmedAlmansy: "Ahmed Almansy",
          assistantManager: "Assistant Manager - Quality and Training",
          aboutAhmedAlmansy:
            "Customer Service Excellence, Contact Center Management, Leadership, and Team Management expert with +7 years' experience in the UAE. Skilled in Quality Management, Training, Process Improvement, and Data Analysis.",
          pressRelease: "Press Release",
          blogs: "Blogs",
          videos: "Videos",
          tafaseelTalks: "Tafaseel Talks",
          mediaAndCommunicationSolutions: "Media & Communication Solutions",
          industrySolutions: "Industry Solutions",
          industrySolutionsDescription:
            "We understand the challenges and opportunities in the Media &amp; Communication industry. Our comprehensive solutions are designed to support your success in this fast-paced digital landscape. We offer a range of services that empower your business to stay competitive, including",
          ourExpertise: "Our Expertise",
          ourExpertiseDescription:
            "Our team of professionals brings extensive expertise in the Media &amp; Communication industry. We excel in delivering the following services",
          contentCreationAndManagement:
            "Content creation and management for diverse media platforms",
          socialMediaManagement:
            "Social media management and community engagement",
          dataAnalyticsAndInsights:
            "Data analytics and insights for effective audience targeting",
          customerSupportAndRelationshipManagement:
            "Customer support and relationship management",
          exploreOurRangeOfServices:
            "Explore our range of services specifically tailored for the Media &amp; Communication industry.",
          weOffer: "We offer:",
          moderationAndQualityControl: "Moderation & Quality Control",
          digitalMarketingServices: "Digital Marketing Services",
          customerSupportAndRelationManagement:
            "Customer Support & Relationship Management",
          contentCreationAndManagement1: "Content Creation & Management",
          dataAnalyticsInsights: "Data Analytics & Insights",
          automotiveOutsourcingSolutions: "Automotive Outsourcing Solutions",
          industrySpecificSolutions: "Industry-specific Solutions",
          industrySpecificSolutionsDescription:
            "We understand the fast-paced and competitive nature of the automotive industry. Our comprehensive range of solutions is designed to support your success. Partner with us for efficient and cost-effective outsourcing services, specifically designed for automotive businesses.",
          ourAutomotiveExpertise: "Our Automotive Expertise",
          ourAutomotiveExpertiseDescription:
            "With years of experience in the automotive industry, our team is well-equipped to handle a variety of critical tasks and processes. We offer a suite of services tailored to meet the specific needs of automotive businesses, including:",
          customerSupportAndSalesAssistance:
            "Customer support and sales assistance for automotive products and services",
          dataManagementAndAnalytics:
            "Data management and analytics for inventory, sales, and customer insights",
          backOfficeOperations:
            "Back-office operations, including finance, accounting, and administrative tasks",
          leadGenerationAndAppointmentSetting:
            "Lead generation and appointment setting for dealerships and service centers",
          comprehensiveAutomotiveSolutions:
            "Comprehensive Automotive Solutions",
          retailSolutionsForSuccess: "Retail Solutions for Success",
          retailIndustrySolutions: "Retail Industry Solutions",
          retailIndustrySolutionsDescription:
            "In the ever-evolving and competitive retail industry, businesses face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of solutions to address them. We collaborate with retail stores, e-commerce businesses, and omni-channel retailers to provide efficient and cost-effective outsourcing services.",
          ourExpertiseInRetailIndustry: "Our Expertise in the Retail Industry",
          ourExpertiseInRetailIndustryDescription:
            "With years of experience in the retail industry, our team of professionals is well-equipped to handle a variety of tasks and processes. From customer support and order management to inventory management and data analytics, we offer a suite of services designed to enhance operational efficiency, improve customer satisfaction, and drive growth.",
          customerSupportAndInquiryManagement:
            "Customer support and inquiry management for retail businesses",
          orderProcessingAndManagement:
            "Order processing and management for online and offline sales channels",
          inventoryManagementAndOptimization:
            "Inventory management and optimization for efficient supply chain operations",
          dataAnalyticsAndInsightsForSales:
            "Data analytics and insights for sales forecasting and performance analysis",
          customerSupportAndInquiryManagementTitle:
            "Customer Support & Inquiry Management",
          orderProcessingAndManagementTitle: "Order Processing & Management",
          streamlinedHealthcareSolutions: "Streamlined Healthcare Solutions",
          healthcareIndustrySolutions: "Healthcare Industry Solutions",
          healthcareIndustrySolutionsDescription:
            "In the rapidly evolving healthcare industry, organizations face diverse challenges. At Tafaseel BPO, we offer a range of solutions to address these challenges. We collaborate with hospitals, clinics, medical practices, and healthcare providers to deliver efficient and cost-effective outsourcing services.",
          ourExpertiseInHealthcare: "Our Expertise in the Healthcare Industry",
          ourExpertiseInHealthcareDescription:
            "With years of experience in healthcare, our team of professionals excels in handling critical tasks and processes. We provide a comprehensive suite of services designed to enhance operational efficiency and patient care:",
          patientSupportAndAppointment:
            "Patient support and appointment scheduling",
          medicalBillingAndClaimsManagement:
            "Medical billing and claims management",
          patientSupportAndAppointmentTitle:
            "Patient Support & Appointment Scheduling",
          medicalBillingAndClaimsManagementTitle:
            "Medical Billing & Claims Management",
          medicalTranscriptionAndDocumentation:
            "Medical Transcription & Documentation Services",
          dataManagementAndAnalyticsTitle: "Data Management & Analytics",
          revenueCycleManagement: "Revenue Cycle Management",
          medicalCodingAndCompliance: "Medical Coding & Compliance",
          foodAndBeveragesOutsourcingSolutions:
            "Food & Beverages Outsourcing Solutions",
          industriesWeCaterTo: "Industries We Cater To",
          foodAndBeveragesOutsourcingSolutionsDescription:
            "In the ever-evolving and competitive food and beverages industry, businesses face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of outsourcing solutions to address them. We collaborate with restaurants, cafes, food delivery services, catering companies, and more to provide efficient and cost-effective services.",
          ourExpertiseInFoodAndBeverages: "Our Expertise in Food & Beverages",
          ourExpertiseInFoodAndBeveragesDescription:
            "With years of experience in the food and beverages industry, our team of professionals is well-equipped to handle a variety of tasks and processes. We offer a comprehensive suite of services designed to enhance operational efficiency, improve customer satisfaction, and drive growth in the food and beverages sector.",
          menuPlanningAndDevelopment:
            "Menu planning and development for diverse culinary offerings",
          orderManagementAndFulfillment:
            "Order management and fulfillment to ensure streamlined operations",
          qualityControlAndCompliance:
            "Quality control and compliance for maintaining high food safety standards",
          customerSupportAndEngagement:
            "Customer support and engagement for exceptional dining experiences",
          industriesWeServe: "Industries We Serve",
          exploreOurRangeOfServicesFoodAndBeverages:
            "Explore our range of services specifically tailored for the food and beverages industry.",
          weProvideSolutionsTo: "We provide solutions to:",
          menuPlanningAndDevelopmentTitle: "Menu Planning & Development",
          orderManagementAndFulfillmentTitle: "Order Management & Fulfillment",
          customerSupportAndEngagementTitle: "Customer Support & Engagement",
          qualityControlAndComplianceTitle: "Quality Control & Compliance",
          travelAndToursOutsourcingSolutions:
            "Travel & Tours Outsourcing Solutions",
          industrySpecificSolutionsTitle: "Industry-Specific Solutions",
          travelAndToursOutsourcingSolutionsDescription:
            "In the dynamic and competitive travel and tours industry, businesses face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of outsourcing solutions to address them. We collaborate with travel agencies, tour operators, destination management companies, and transportation providers to deliver efficient and cost-effective services.",
          ourExpertiseInTravelTours: "Our Expertise in Travel & Tours",
          ourExpertiseInTravelToursDescription:
            "With years of experience in the travel and tours industry, our team of professionals excels in handling critical tasks and processes. We offer a comprehensive suite of services designed to enhance operational efficiency, improve customer satisfaction, and drive growth:",
          itineraryPlanningAndCustomization:
            "Itinerary planning and customization for unforgettable travel experiences",
          reservationManagementAndCoordinationForFlights:
            "Reservation management and coordination for flights, accommodations, and activities",
          customerSupportAndAssistance:
            "Customer support and assistance throughout the travel journey",
          destinationResearchAndRecommendations:
            "Destination research and recommendations for personalized travel experiences",
          itineraryPlanningAndCustomizationTitle:
            "Itinerary Planning & Customization",
          reservationManagementAndCoordinationTitle:
            "Reservation Management & Coordination",
          customerSupportAndAssistanceTitle: "Customer Support & Assistance",
          destinationResearchAndRecommendationsTitle:
            "Destination Research & Recommendations",
          tourGuideServices: "Tour Guide Services",
          transportationLogistics: "Transportation Logistics",
          productListingAndCatalog:
            "Product listing and catalog management for accurate and appealing online displays",
          inventoryManagement:
            "Inventory management and optimization for streamlined supply chain operations",
          orderProcessingAndFulfillment:
            "Order processing and fulfillment to ensure efficient and timely deliveries",
          customerSupportAndInquiry:
            "Customer support and inquiry management for exceptional online shopping experiences",
          ourExpertiseInECommerce: "Our Expertise in E-commerce",
          ourExpertiseInECommerceDescription:
            " With years of experience in the e-commerce industry, our team of professionals is well-equipped to handle a variety of tasks and processes. From product listing and inventory management to order processing and customer support, we offer a suite of services designed to enhance operational efficiency, improve customer satisfaction, and drive growth in the e-commerce sector.",
          eCommerceIndustrySolution: "E-commerce Industry Solutions",
          eCommerceIndustrySolutionDescription:
            "In the fast-paced and competitive world of e-commerce, businesses face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of solutions to address them. We collaborate with online retailers, marketplaces, and e-commerce startups to provide efficient and cost-effective outsourcing services.",
          eCommerceSolutionsForOnlineSuccess:
            "E-commerce Solutions for Online Success",
          productListingAndCatalogManagementTitle:
            "Product Listing & Catalog Management",
          orderProcessingAndFulfillmentTitle: "Order Processing & Fulfillment",
          eCommerceWebsiteDevelopment: "E-commerce Website Development",
          inventoryManagementAndOptimizationTitle:
            "Inventory Management & Optimization",
          digitalMarketingAndAdvertising: "Digital Marketing & Advertising",
          governmentAndPublicServiceSolutions:
            "Government & Public Service Industry Solutions",
          governmentAndPublicServiceSolutionsDescription:
            "In the complex and ever-evolving landscape of government and public service, organizations face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of solutions to address them. We collaborate with government agencies, public service departments, and civic organizations to provide efficient and reliable outsourcing services.",
          ourExpertiseInGovernmentAndPublicService:
            "Our Expertise in Government & Public Service",
          ourExpertiseInGovernmentAndPublicServiceDescription:
            " With years of experience in working with government and public service entities, our team of professionals is well- equipped to handle a variety of tasks and processes. From data management and citizen support to administrative services and document processing, we offer a suite of services designed to enhance operational efficiency, improve citizen experiences, and drive effective governance.",
          dataManagementAndAnalyticsForInformedDecisionMaking:
            "Data management and analytics for informed decision-making and policy formulation",
          citizenSupportAndEngagement:
            "Citizen support and engagement through helplines, online portals, and email services",
          administrativeServices:
            "Administrative services, including document processing, record-keeping, and information dissemination",
          researchAndReportingToSupport:
            "Research and reporting to support evidence-based decision-making and performance evaluation",
          citizenSupportAndEngagementTitle: "Citizen Support & Engagement",
          administrativeServicesTitle: "Administrative Services",
          researchAndReporting: "Research & Reporting",
          digitalTransformationSolutions: "Digital Transformation Solutions",
          projectManagementConsultancy: "Project Management & Consultancy",
          realEstateTitle: "Real Estate & Facility Management Solutions",
          realEstateDescription:
            "In the competitive and dynamic real estate and facility management industry, organizations face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of solutions to address them. We collaborate with real estate developers, property management companies, facility management providers, and building owners to provide efficient and reliable outsourcing services.",
          realEstateExpertiseTitle:
            "Our Expertise in Real Estate & Facility Management",
          realEstateExpertiseDescription:
            "With years of experience in working with real estate and facility management entities, our team of professionals is well-equipped to handle a variety of tasks and processes. From property marketing and tenant management to maintenance planning and sustainability services, we offer a suite of services designed to enhance operational efficiency, improve tenant experiences, and optimize property performance.",
          propertyMarketingAndLeasing:
            "Property marketing and leasing services to attract and retain tenants",
          talentManagementAndCustomerSupport:
            "Tenant management and customer support for seamless tenant experiences",
          maintenancePlanningAndExecution:
            "Maintenance planning and execution to ensure well-functioning properties",
          sustainabilityServices:
            "Sustainability services to enhance energy efficiency and environmental performance",
          propertyMarketingAndLeasingTitle: "Property Marketing & Leasing",
          tenantManagementAnCustomerSupportTitle:
            "Tenant Management & Customer Support",
          maintenancePlanningAndExecutionTitle:
            "Maintenance Planning & Execution",
          sustainabilityServicesTitle: "Sustainability Services",
          facilityManagementSolutions: "Facilities Management Solutions",
          spacePlanningAndOptimization: "Space Planning & Optimization",
          consumerGoodsTitle: "Consumer Goods Solutions for Success",
          consumerGoodsIndustrySolutions: "Consumer Goods Industry Solutions",
          consumerGoodsIndustryDescription:
            "In the highly competitive and ever-changing consumer goods industry, businesses face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of solutions to address them. We collaborate with manufacturers, distributors, and retailers in the consumer goods sector to provide efficient and cost-effective outsourcing services.",
          consumerGoodsExpertise:
            "Our Expertise in the Consumer Goods Industry",
          consumerGoodsExpertiseDescription:
            "With years of experience in the consumer goods industry, our team of professionals is well-equipped to handle a variety of tasks and processes. From supply chain management and inventory optimization to product data management and customer support, we offer a suite of services designed to enhance operational efficiency, improve customer satisfaction, and drive growth in the consumer goods sector.",
          supplyChainManagement:
            "Supply chain management and logistics for streamlined operations",
          inventoryOptimization:
            "Inventory optimization and demand forecasting for efficient inventory management",
          productDataManagement:
            "Product data management and content creation for accurate and engaging product information",
          customerSupport:
            "Customer support and order management for exceptional customer experiences",
          customerSupportTitle: "Customer Support & Order Management",
          supplyChainManagementTitle: "Supply Chain Management",
          inventoryOptimizationTitle:
            "Inventory Optimization & Demand Forecasting",
          productDataManagementTitle:
            "Product Data Management & Content Creation",
          ecommerceSolutions: "E-commerce Solutions for Consumer Goods",
          qualityAssurance: "Quality Assurance & Compliance",
          logisticsTitle:
            "Efficient Logistics & Transportation Solutions by Tafaseel BPO",
          logisticsAndTransportationTitle:
            "Logistics and Transportation Solutions",
          logisticsIndustrySolutionsTitle:
            "Logistics and Transportation Industry Solutions",
          logisticsIndustrySolutionsDescription:
            "In the fast-paced and dynamic logistics and transportation industry, businesses face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of solutions to address them. We collaborate with logistics companies, freight forwarders, transportation providers, and supply chain managers to provide efficient and cost-effective outsourcing services.",
          logisticsExpertiseTitle:
            "Our Expertise in the Logistics and Transportation Industry",
          logisticsExpertiseDescription:
            "With years of experience in the logistics and transportation industry, our team of professionals is well-equipped to handle a variety of tasks and processes. From route optimization and load planning to freight management and fleet tracking, we offer a suite of services designed to enhance operational efficiency, improve customer satisfaction, and drive growth in the logistics and transportation sector.",
          routeOptimization:
            "Route optimization and load planning for efficient transportation operations",
          freightManagement:
            "Freight management and logistics coordination for seamless supply chain processes",
          fleetTracking:
            "Fleet tracking and monitoring for real-time visibility and performance analysis",
          supplyChainAnalytics:
            "Supply chain analytics and optimization for improved decision-making and cost reduction",
          routeOptimizationLoadPlanning: "Route Optimization & Load Planning",
          freightManagementCoordination:
            "Freight Management & Logistics Coordination",
          fleetTrackingMonitoring: "Fleet Tracking & Monitoring",
          supplyChainAnalyticsOptimization:
            "Supply Chain Analytics & Optimization",
          warehouseManagementSolutions: "Warehouse Management Solutions",
          lastMileDeliveryServices: "Last-Mile Delivery Services",
          oilAndGasSolutionsDescription:
            "In the dynamic and demanding oil and gas industry, companies face unique challenges. At Tafaseel BPO, we understand these challenges and offer a wide range of solutions to address them. We collaborate with exploration and production companies, drilling contractors, refineries, and service providers to provide efficient and cost-effective outsourcing services.",
          oilAndGasExpertiseTitle: "Our Expertise in the Oil and Gas Industry",
          oilAndGasExpertiseDescription:
            "With years of experience in the oil and gas industry, our team of professionals is well-equipped to handle a variety of tasks and processes. From data management and analysis to safety compliance and supply chain optimization, we offer a suite of services designed to enhance operational efficiency, improve safety standards, and drive growth in the oil and gas sector.",
          dataManagementAndAnalysis:
            "Data management and analysis for informed decision-making and performance evaluation",
          safetyComplianceAndRegulatorySupport:
            "Safety compliance and regulatory support for enhanced safety standards",
          supplyChainOptimizationAndLogisticsManagement:
            "Supply chain optimization and logistics management for streamlined operations",
          documentControlAndInformation:
            "Document control and information management for efficient record-keeping",
          dataManagementAndAnalysisTitle: "Data Management & Analysis",
          safetyComplianceAndRegulatorySupportTitle:
            "Safety Compliance & Regulatory Support",
          supplyChainOptimization:
            "Supply Chain Optimization & Logistics Management",
          documentControlAndInformationTitle:
            "Document Control & Information Management",
          assetManagementSolutions: "Asset Management Solutions",
          healthSafetyEnvironment:
            "Health, Safety &amp; Environment (HSE) Services",
          admissionsEnrollmentSupport: "Admissions & Enrollment Support",
          studentSupportServices: "Student Support Services",
          facultyStaffHelplines: "Faculty & Staff Helplines",
          alumniEngagementSupport: "Alumni Engagement & Fundraising Support",
          virtualClassroomSupport: "Virtual Classroom Support Services",
          onlineCourseRegistrationAssistance:
            "Online Course Registration Assistance",
          educationTitle: "Education",
          callCenterSolutionsTitle: "Education Call Center Solutions",
          callCenterSolutionsDescription:
            "In the dynamic and fast-paced education industry, institutions face the need for efficient and effective communication with students, parents, faculty, and staff. At Tafaseel BPO, we understand these challenges and offer a wide range of call center solutions to address them. We collaborate with schools, colleges, universities, and online learning platforms to provide professional and reliable call center services.",
          educationExpertiseTitle: "Our Expertise in Education Call Centers",
          educationExpertiseDescription:
            "With years of experience in providing call center services to the education sector, our team of professionals is well-equipped to handle a variety of tasks and processes. From admissions and enrollment inquiries to student support and academic assistance, we offer a suite of services designed to enhance communication, improve efficiency, and provide exceptional experiences for all stakeholders.",
          admissionsSupport:
            "Admissions and enrollment support for prospective students",
          studentSupport:
            "Student support services for inquiries, information, and assistance",
          facultySupport:
            "Faculty and staff helplines for administrative support and coordination",
          alumniSupport:
            "Alumni engagement and fundraising support for institutional growth",
          whyTafaseelTitle:
            "At Tafaseel, we believe that good is never good enough.",
          arabianBusiness: "Arabian Business 30 Under 40 List",
          arabYouthCenter:
            'The Arab Youth Center announces the members of the second edition of the "Arab Youth Pioneers"',
          arabYouthCenterDescription:
            "ABU DHABI, 11th August, 2020 (WAM) -- The Arab Youth Center, chaired by His Highness Sheikh Theyab bin Mohammed bin Zayed Al Nahyan, announced, in conjunction with International Youth Day",
          readMore: "...Read More",
          arabYouthPioneersTitle:
            "The members of the second edition of the 'Arab Youth Pioneers' announced by the Arab Youth Center.",
          arabYouthPioneersDescription:
            "The Arab Youth Center, headed by His Highness Sheikh Diab bin Mohammed bin Zayed Al Nahyan, revealed, in conjunction with International Youth Day, the members of the second edition of the 'Arab Youth Pioneers' initiative",
          websiteExpansionTitle:
            "Tafaseel BPO unveils revamped website and Egypt expansion as part of its IPO vision for 2025",
          websiteExpansionDescription:
            "Tafaseel BPO, the largest homegrown Business Process Outsourcing (BPO) company in the UAE, takes a giant stride towards its 2025 vision with the launch of its revamped website and the opening of a new office in Egypt.",
          expansionTitle: "Tafaseel BPO expands, eyes 2025 IPO launch",
          expansionDescription:
            "AJMAN, UNITED ARAB EMIRATES — Business Process Outsourcing (BPO) provider Tafaseel BPO recently launched its modernized website and inaugurated a fresh office in Egypt as the company solidifies its growth trajectory in anticipation of its <num1> 2025 </num1> Initial Public Offering (IPO).",
          chamberPraisesTitle:
            "Ajman Chamber praises the growth of Emirati entrepreneurs' projects",
          chamberPraisesDescription:
            "Abdullah Al Muwaji, Chairman of the Board of Directors of the Ajman Chamber, speaks about the progress of UAE entrepreneurs",
          celebratingFutureLeaders:
            "Celebrating the future leaders of the Middle East",
          chamberEnhancesCommunicationTitle:
            "Ajman Chamber enhances direct communication with the private sector",
          chamberEnhancesCommunicationDescription:
            "A delegation from the Ajman Chamber of Commerce and Industry, led by Abdullah Al Muwaiji, Chairman of the Board of Directors, visited the headquarters of Tafaseel.",
          chamberPraisesGrowthTitle:
            "Ajman Chamber praises the remarkable growth of the projects of Emirati business owners",
          ajmanResourcesEventTitle:
            '"Ajman Resources" organizes the "open day" for immediate job interviews',
          ajmanResourcesEventDescription:
            "The Human Resources Department of the Government of Ajman organized an open day for immediate job interviews for the employment of nationals",
          callCentersTitle: "Call centers for free from Tafaseel",
          callCentersDescription:
            "Eng. Ahmed Khabeer, The CEO of the Tafaseel group of companies, specializing in call center services and IT, announced the provision of their services free of charge to the government. This initiative aims to support the state's efforts in combating the spread of the 'Corona' virus.",
          comingSoon: "Coming Soon..",
          costEfficiencyTitle: "Cost Efficiency",
          costEfficiencyDescription:
            "By outsourcing your contact center function to Tafaseel BPO, you can significantly reduce costs associated with setting up and managing an in-house contact center. Our cost-effective solutions provide access to skilled professionals and advanced technologies without the burden of additional expenses.",
          omnichannelSupportTitle: "Omnichannel Support",
          omnichannelSupportDescription:
            "Tafaseel BPO offers support across multiple communication channels, including phone, email, chat, and social media. Our contact center experts are trained to handle customer inquiries, resolve issues, provide technical support, and offer assistance across various platforms.",
          twentyFourSevenCustomerSupportTitle: "24/7 Customer Support",
          twentyFourSevenCustomerSupportDescription:
            "With Tafaseel BPO, you can provide round-the-clock customer support. Our contact center operates 24/7, ensuring that your customers receive assistance whenever they need it, regardless of time zones or geographic locations.",
          enhancedCustomerSatisfactionTitle: "Enhanced Customer Satisfaction",
          enhancedCustomerSatisfactionDescription:
            "Exceptional customer support leads to higher customer satisfaction and loyalty. Tafaseel BPO's dedicated contact center professionals are trained to deliver personalized, empathetic, and efficient support, ensuring that your customers have a positive experience with your brand.",
          contactCenterOutsourcingTitle: "What is Contact Center Outsourcing?",
          contactCenterOutsourcingDescription:
            "Contact center outsourcing involves partnering with a specialized firm like Tafaseel BPO to handle your customer interactions across various channels. By outsourcing your contact center operations, you can tap into a dedicated team of contact center experts who excel in providing prompt, knowledgeable, and personalized support to your customers. This allows you to focus on core business activities while ensuring exceptional customer experiences.",
          skilledContactCenterTitle: "Skilled Contact Center Professionals",
          skilledContactCenterDescription:
            "Tafaseel BPO brings a team of highly skilled contact center professionals with extensive experience in delivering outstanding customer support. Our experts are trained to handle various customer scenarios, ensuring prompt and effective resolutions.",
          cuttingEdgeTechnologiesTitle: "Cutting-edge Technologies",
          cuttingEdgeToolsAndTechnologies:
            "Cutting-edge Tools and Technologies",
          cuttingEdgeTechnologiesDescriptionContactCenter:
            "We leverage advanced contact center technologies and tools to optimize efficiency and deliver seamless customer experiences. Our omni-channel support systems enable consistent and cohesive customer interactions across multiple channels.",
          flexibleSolutionsTitle: "Flexible Solutions",
          flexibleSolutionsDescription:
            "Tafaseel BPO provides flexible contact center solutions tailored to your specific needs. Whether you require full-time support, overflow assistance during peak periods, or after-hours support, we can customize our services to meet your requirements.",
          dataDrivenInsightsTitle: "Data-driven Insights",
          dataDrivenInsightsDescription:
            "We believe in continuous improvement and optimization. Tafaseel BPO utilizes data analytics and customer feedback to gain valuable insights into customer preferences, pain points, and trends. This enables us to continuously refine our contact center strategies and deliver even better results.",
          fullName: "Full Name (*)",
          firstName: "First Name (*)",
          lastName: "Last Name (*)",
          yourEmail: "Your Email (*)",
          phoneNo: "Phone No. (*)",
          yourMessage: "Your Message (*)",
          getAQuote: "Get A Quote",
          company: "Company (*)",
          jobTitle: "Job Title (*)",
          Albania	:	"	Albania	"	,
Algeria	:	"	Algeria	"	,
Angola	:	"	Angola	"	,
Argentina	:	"	Argentina	"	,
Austria	:	"	Austria	"	,
Australia	:	"	Australia	"	,
Bahrain	:	"	Bahrain	"	,
Belarus	:	"	Belarus	"	,
Belgium	:	"	Belgium	"	,
Benin	:	"	Benin	"	,
BosniaAndHerzegovina	:	"	Bosnia And Herzegovina	"	,
Brazil	:	"	Brazil	"	,
Bulgaria	:	"	Bulgaria	"	,
Cameroon	:	"	Cameroon	"	,
Canada	:	"	Canada	"	,
Chile	:	"	Chile	"	,
China	:	"	China	"	,
Colombia	:	"	Colombia	"	,
Comoros	:	"	Comoros	"	,
CostaRica	:	"	Costa Rica	"	,
Croatia	:	"	Croatia	"	,
CzechRepublic	:	"	Czech Republic	"	,
Denmark	:	"	Denmark	"	,
DominicanRepublic	:	"	Dominican Republic	"	,
Egypt	:	"	Egypt	"	,
ElSalvador	:	"	El Salvador	"	,
Estonia	:	"	Estonia	"	,
Ethiopia	:	"	Ethiopia	"	,
Finland	:	"	Finland	"	,
France	:	"	France	"	,
Georgia	:	"	Georgia	"	,
Germany	:	"	Germany	"	,
Ghana	:	"	Ghana	"	,
Greece	:	"	Greece	"	,
Guatemala	:	"	Guatemala	"	,
Guinea	:	"	Guinea	"	,
Guyana	:	"	Guyana	"	,
Honduras	:	"	Honduras	"	,
Hungary	:	"	Hungary	"	,
India	:	"	India	"	,
Indonesia	:	"	Indonesia	"	,
Ireland	:	"	Ireland	"	,
Israel	:	"	Israel	"	,
Italy	:	"	Italy	"	,
Jamaica	:	"	Jamaica	"	,
Japan	:	"	Japan	"	,
Jordan	:	"	Jordan	"	,
Kenya	:	"	Kenya	"	,
Kuwait	:	"	Kuwait	"	,
Latam	:	"	Latam	"	,
Latvia	:	"	Latvia	"	,
Lebanon	:	"	Lebanon	"	,
Lithuania	:	"	Lithuania	"	,
Luxembourg	:	"	Luxembourg	"	,
Macedonia	:	"	Macedonia	"	,
Madagascar	:	"	Madagascar	"	,
Malaysia	:	"	Malaysia	"	,
Malta	:	"	Malta	"	,
Mauritius	:	"	Mauritius	"	,
Mexico	:	"	Mexico	"	,
Montenegro	:	"	Montenegro	"	,
Morocco	:	"	Morocco	"	,
Mozambique	:	"	Mozambique	"	,
Netherlands	:	"	Netherlands	"	,
NewZealand	:	"	New Zealand	"	,
Nicaragua	:	"	Nicaragua	"	,
Nigeria	:	"	Nigeria	"	,
Norway	:	"	Norway	"	,
Oman	:	"	Oman	"	,
Peru	:	"	Peru	"	,
Philippines	:	"	Philippines	"	,
Poland	:	"	Poland	"	,
Portugal	:	"	Portugal	"	,
Qatar	:	"	Qatar	"	,
Romania	:	"	Romania	"	,
Russia	:	"	Russia	"	,
SaudiArabia	:	"	Saudi Arabia	"	,
Senegal	:	"	Senegal	"	,
Serbia	:	"	Serbia	"	,
Singapore	:	"	Singapore	"	,
Slovakia	:	"	Slovakia	"	,
Slovenia	:	"	Slovenia	"	,
Somalia	:	"	Somalia	"	,
SouthAfrica	:	"	South Africa	"	,
SouthKorea	:	"	South Korea	"	,
SouthSudan	:	"	South Sudan	"	,
Spain	:	"	Spain	"	,
Suriname	:	"	Suriname	"	,
Sweden	:	"	Sweden	"	,
Switzerland	:	"	Switzerland	"	,
Tanzania	:	"	Tanzania	"	,
Thailand	:	"	Thailand	"	,
Tunisia	:	"	Tunisia	"	,
Turkey	:	"	Turkey	"	,
UnitedArabEmirates	:	"	United Arab Emirates	"	,
Uganda	:	"	Uganda	"	,
Ukraine	:	"	Ukraine	"	,
UnitedKingdom	:	"	United Kingdom	"	,
UnitedStates	:	"	United States	"	,
Vietnam	:	"	Vietnam	"	,
Zimbabwe	:	"	Zimbabwe	"	,
          countryToBeServed: "Country To Be Served?",
          industry: "Industry (*)",
          whereDidYouHeardAboutUs: "Where Did You Hear About Us? (*)",
          website: "Website",
          printAdvertising: "Print Advertising/Brochure",
          event: "Event/Webinar",
          referralFromTafaseelClient: "Referral from Tafaseel Client",
          tafaseelLocalSubsidiary: "Tafaseel Local Subsidiary",
          socialMedia: "Social Media",
          consentCheck:
            "I consent to receive relevant industry news, analyst reports, white papers, case studies, and product information from Tafaseel. Tafaseel will never share or sell your information to third parties.",
          seeTafaseelPrivacyPolicy: "See Tafaseel's Privacy Policy",
          submit: "Submit",
          itOutSourcingTitle:
            "IT Outsourcing: Empower Your Business with Tafaseel BPO's Expertise",
          itOutSourcingDescription:
            "Welcome to Tafaseel BPO, your trusted partner for IT outsourcing services. We understand the importance of streamlining your IT operations while reducing costs. With our tailored IT outsourcing solutions, you can leverage the expertise of our skilled IT professionals and cutting-edge technologies to drive your business forward.",
          itSolutionsAccordionHeader: "Tailored IT Solutions",
          itSolutionsAccordionBody:
            "At Tafaseel BPO, we work closely with you to understand your specific IT needs. Our experts develop customized IT outsourcing solutions that align with your business goals, ensuring optimal results.",
          itProfessionalsAccordionHeader: "Skilled IT Professionals",
          itProfessionalsAccordionBody:
            "With Tafaseel BPO, you gain access to a team of highly skilled IT professionals. Our experts have deep knowledge and experience across various domains, enabling them to handle diverse IT functions effectively.",
          dataSecurityAccordionHeader: "Data Security and Confidentiality",
          dataSecurityAccordionBody:
            "We prioritize the security and confidentiality of your data. At Tafaseel BPO, we implement stringent security measures and adhere to industry standards to ensure that your sensitive information remains protected at all times.",
          softwareDevelopmentHeading: "Software Development",
          softwareDevelopmentParagraph:
            "Our skilled software developers can create custom software solutions tailored to your business needs. From web and mobile applications to enterprise software, we deliver robust and scalable solutions.",
          infrastructureManagementHeading: "Infrastructure Management",
          infrastructureManagementParagraph:
            "Leave the management and maintenance of your IT infrastructure to us. We ensure the smooth functioning of your network, servers, and systems, minimizing downtime and maximizing efficiency.",
          cybersecurityHeading: "Cybersecurity",
          cybersecurityParagraph:
            "Protect your business from cyber threats with our top-notch cybersecurity services. We employ robust security measures, conduct regular audits, and implement advanced strategies to safeguard your data and systems.",
          helpDeskHeading: "Help Desk Support",
          helpDeskParagraph:
            "Our dedicated help desk support team is available to assist your employees and resolve IT-related issues promptly. We provide reliable and responsive support, ensuring uninterrupted operations.",
          ourTechnologies: "Our Technologies",
          salesOutsourcingHeading: "What is Sales Outsourcing?",
          salesOutsourcingParagraph:
            "Sales outsourcing is a strategic partnership with a specialized firm to handle various aspects of your sales process. By outsourcing your sales function to Tafaseel BPO, you can tap into a dedicated team of sales experts who excel in lead generation, prospecting, customer acquisition, and sales closing. This enables you to focus on core business activities while ensuring a consistent and efficient sales pipeline.",
          salesOutsourcingCosts:
            "Sales outsourcing allows you to minimize costs associated with hiring, training, and managing an in-house sales team. With Tafaseel BPO, you pay for the services provided, making it a cost-effective solution for your business.",
          salesOutsourcingExpertiseAndScalability: "Expertise and Scalability",
          salesOutsourcingExpertiseAndScalabilityBody:
            "By partnering with Tafaseel BPO, you gain access to a team of experienced sales professionals who are well-versed in sales strategies, techniques, and industry trends. Our scalable solutions enable you to adjust your sales operations based on demand fluctuations without the hassle of recruitment or downsizing.",
          acceleratedSalesCycle: "Accelerated Sales Cycle",
          acceleratedSalesCycleBody:
            "Tafaseel BPO employs effective lead generation and prospecting strategies to ensure a steady flow of qualified leads. Our sales experts are skilled in sales techniques and customer relationship management, enabling faster deal closures and revenue generation.",
          marketExpansion: "Market Expansion",
          marketExpansionBody:
            "If you are planning to enter new markets or expand your business geographically, Tafaseel BPO can be a game-changer. We have extensive networks and established relationships in various markets, helping you penetrate new territories and drive sales growth.",
          tailoredApproach: "Tailored Approach",
          tailoredApproachDescription:
            "We understand that every business has unique marketing requirements. Our marketing outsourcing solutions are customized to align with your specific needs, target audience, and industry. We work closely with you to develop a comprehensive marketing strategy tailored to your business goals.",
          provenMethodologies: "Proven Methodologies",
          provenMethodologiesDescription:
            "Our team follows a data-driven approach and employs proven sales methodologies to maximize your sales success. We combine the power of technology, analytics, and industry insights to identify and convert leads into customers effectively.",
          scalabilityFlexibility: "Scalability and Flexibility",
          scalabilityFlexibilityDescription:
            "We understand that businesses undergo growth and fluctuations. Our flexible solutions can accommodate your changing needs, whether you require additional sales support during peak seasons or want to expand into new markets.",
          flexibleScalableSolutions: "Flexible and Scalable Solutions",
          flexibleScalableSolutionsDescription:
            "Tafaseel BPO provides flexible and scalable marketing solutions that can adapt to your changing business needs. Whether you need ongoing marketing support or specific campaign assistance, we can tailor our services to meet your requirements.",
          transparentReporting: "Transparent Reporting",
          transparentReportingDescription:
            "We believe in transparency and accountability. With our detailed reporting and analytics, you gain valuable insights into your sales performance. This enables you to make informed decisions and optimize your sales strategy for better results.",
          marketingOutsourcing: "What is Marketing Outsourcing?",
          marketingOutsourcingDescription:
            "Marketing outsourcing involves partnering with a specialized firm like Tafaseel BPO to handle various aspects of your marketing function. By outsourcing your marketing tasks, you can tap into a dedicated team of marketing experts who excel in digital marketing, content creation, social media management, SEO, and more. This allows you to focus on core business activities while benefiting from a tailored and results-driven marketing strategy.",
          marketingOutsourcingCostEfficiency:
            "By outsourcing your marketing function to Tafaseel BPO, you can significantly reduce costs associated with hiring, training, and managing an in-house marketing team. Our cost-effective solutions provide access to skilled professionals without the burden of additional expenses.",
          salesOutsourcingExpertiseAndScalabilityDescriptionMarketing:
            "Tafaseel BPO brings a team of experienced marketing professionals who have in-depth knowledge of the latest marketing trends and techniques. Our scalable solutions allow you to adjust your marketing efforts based on your business needs, ensuring optimal resource allocation.",
          accessToAdvancedToolsAndTechnologies:
            "Access to Advanced Tools and Technologies",
          accessToAdvancedToolsAndTechnologiesDescription:
            "As a leading marketing outsourcing provider, Tafaseel BPO invests in cutting-edge marketing tools and technologies. By partnering with us, you gain access to advanced analytics, automation software, and marketing platforms that can enhance your marketing effectiveness.",
          strategicFocusAndInnovation: "Strategic Focus and Innovation",
          strategicFocusAndInnovationDescription:
            "By outsourcing your marketing function, you can free up your internal resources to focus on core competencies and strategic initiatives. This enables you to drive innovation, make data-driven decisions, and stay ahead of the competition in the dynamic marketplace.",
          skilledMarketingProfessionalsTitle: "Skilled Marketing Professionals",
          skilledMarketingProfessionalsDescription:
            "Tafaseel BPO brings a team of highly skilled marketing professionals with expertise in various marketing disciplines. From digital marketing and content creation to social media management and SEO, our experts ensure your marketing efforts are executed with excellence.",
          cuttingEdgeTechnologiesDescription:
            "We stay up to date with the latest marketing trends and leverage advanced tools and technologies to deliver exceptional results. Our marketing strategies are backed by data-driven insights and innovative approaches that can drive your business growth.",
          customerServiceOutsourcing: "Customer Service Outsourcing",
          customerServiceOutsourcingTitle:
            "What is Customer Service Outsourcing?",
          customerServiceOutsourcingDescription:
            "Customer service outsourcing involves partnering with a specialized firm like Tafaseel BPO to handle your customer support operations. By outsourcing your customer service tasks, you can tap into a dedicated team of customer service experts who excel in providing prompt, professional, and personalized support to your customers. This allows you to focus on core business activities while ensuring exceptional customer experiences.",
          customerSupportTitleTwentyFourSeven:
            "Twenty-Four/Seven Customer Support",
          customerSupportDescriptionTwentyFourSeven:
            "Tafaseel BPO offers round-the-clock customer support, ensuring that your customers receive assistance whenever they need it. Our team is available to handle customer inquiries, resolve issues, and provide support across multiple channels, including phone, email, chat, and social media.",
          multiLingualSupport: "Multilingual Support",
          multiLingualSupportDescription:
            "With Tafaseel BPO, you can provide customer service in multiple languages, catering to a diverse customer base. Our team consists of multilingual professionals who can effectively communicate with your customers and provide support in their preferred language.",
          improvedCustomerSatisfaction: "Improved Customer Satisfaction",
          improvedCustomerSatisfactionDescription:
            "Exceptional customer service leads to higher customer satisfaction and loyalty. Tafaseel BPO's dedicated customer service experts are trained to deliver personalized and empathetic support, ensuring that your customers have a positive experience with your brand.",
          receptionistTitle: "Receptionist",
          receptionistContent:
            "Experience exceptional call handling and warm greetings from our professional receptionists, ensuring your customers feel valued and heard.",
          socialMediaTitle: "Social Media",
          socialMediaContent:
            "Enhance your online presence with our expert social media team, engaging your audience and driving meaningful interactions.",
          contentModerationTitle: "Content Moderation",
          contentModerationContent:
            "Rest easy with our vigilant content moderators who maintain a safe and positive digital environment for your brand.",
          ethicsHotlineTitle: "Ethics Hotline",
          ethicsHotlineContent: `Foster trust and transparency with a confidential ethics hotline, encouraging employees and stakeholders to report concerns responsibly.`,
          liveChatsTitle: "Live Chats",
          liveChatsContent: `Connect instantly with customers, providing real-time support and personalized solutions through our efficient live chat services.`,
          coBrowsingTitle: "Co-browsing",
          coBrowsingContent: `Guide customers through their online journey, offering hands-on assistance with our collaborative browsing feature.`,
          surveysAndFeedbackTitle: "Surveys and Feedback",
          surveysAndFeedbackContent: `Gain valuable insights into customer satisfaction and improve services with tailored surveys and feedback analysis.`,
          overflowTitle: "Overflow",
          overflowContent: `Seamlessly handle high call volumes with our overflow support, ensuring no customer inquiry goes unanswered.`,
          videoChatTitle: "Video Chat",
          videoChatContent: `Elevate customer interactions to a much more personal level, we do this by offering face-to-face support for video chats and building stronger connections.`,
          whyChooseTafaseelBPOFinance:
            "Why Choose Tafaseel BPO for Finance Operations Outsourcing?",
          tafaseelBPOFinanceDescription:
            "Tafaseel BPO is your trusted partner for finance operations outsourcing. Our commitment to excellence and customer satisfaction sets us apart. Here's why you should choose us",
          tailoredSolutions: "Tailored Solutions",
          tailoredSolutionsDescription:
            "We understand that every business has unique finance needs. Our outsourcing solutions are customized to align with your specific requirements, objectives, and compliance standards.",
          cuttingEdgeTechnology: "Cutting-edge Technology",
          cuttingEdgeTechnologyDescription:
            "Tafaseel BPO leverages state-of-the-art financial software and tools to ensure efficient data management, real-time reporting, and seamless integration with your existing systems. Stay ahead of the competition with our technology-driven solutions.",
          dataSecurityAndConfidentiality: "Data Security and Confidentiality",
          dataSecurityAndConfidentialityDescription:
            "We prioritize the security of your sensitive financial data. Tafaseel BPO employs robust security measures, including encryption protocols and access controls, to safeguard your information from unauthorized access or breaches.",
          reliableSupport: "Reliable Support",
          reliableSupportDescription:
            "We believe in building long-term partnerships with our clients. Our dedicated support team is available round the clock to address your queries, provide guidance, and ensure smooth operations.",
          costSavingsTitle: "Cost Savings",
          costSavingsDescription:
            "By outsourcing your finance operations to Tafaseel BPO, you can significantly reduce overhead costs associated with hiring and training in-house finance teams. Our cost-effective solutions provide access to highly skilled professionals without the burden of additional expenses.",
          financeExpertsDescription:
            "Tafaseel BPO brings a team of finance experts who possess in-depth knowledge of industry best practices and regulations. We offer flexible scalability, allowing you to adjust the level of support based on your business needs, ensuring optimal resource allocation at all times.",
          enhancedEfficiencyTitle: "Enhanced Efficiency",
          enhancedEfficiencyDescription:
            "With Tafaseel BPO handling your finance operations, you can optimize processes, improve accuracy, and minimize the risk of errors. Our advanced tools and technologies enable streamlined workflows, faster turnaround times, and improved financial performance.",
          coreCompetenciesTitle: "Focus on Core Competencies",
          coreCompetenciesDescription:
            "By entrusting your finance operations to Tafaseel BPO, you free up your internal resources to focus on core business functions. This strategic shift allows you to make better-informed decisions, drive innovation, and foster overall organizational growth.",
          whyChooseHRTitle:
            "Why Choose Human Resources Outsourcing with Tafaseel BPO?",
          whyChooseHRDescription:
            "When you choose human resources outsourcing with Tafaseel BPO, you gain access to numerous benefits that can transform your HR operations.",
          costSavingsHRDescription:
            "Outsourcing your HR functions to Tafaseel BPO allows you to reduce costs associated with hiring, training, and managing an in-house HR team. Our cost-effective solutions provide access to skilled HR professionals without the burden of additional expenses.",
          expertiseComplianceAccordionHeader:
            "Expertise and Compliance: Tafaseel BPO brings a team of experienced HR",
          expertiseComplianceAccordionBody:
            "Tafaseel BPO brings a team of experienced HR professionals who possess in-depth knowledge of HR best practices and compliance regulations. We ensure that your HR processes align with legal requirements and industry standards.",
          scalabilityFlexibilityAccordionBody:
            "Our HR outsourcing solutions are scalable and flexible, allowing you to adjust your HR support based on your business needs. Whether you require full-time HR assistance or project-based support, we can customize our services to meet your requirements.",
          focusStrategicInitiativesAccordionHeader:
            "Focus on Strategic Initiatives",
          focusStrategicInitiativesAccordionBody:
            "By outsourcing your HR functions, you can redirect your internal resources and HR personnel to focus on strategic initiatives and core business activities. This enables you to drive innovation, enhance employee engagement, and achieve your business objectives.",
          recruitmentTalentAcquisitionTitle:
            "Recruitment and Talent Acquisition",
          recruitmentTalentAcquisitionDescription:
            "We assist in sourcing, screening, and selecting top talent for your organization, ensuring a robust recruitment process and attracting the right candidates.",
          onboardingOffboardingTitle: "Employee Onboarding and Offboarding",
          onboardingOffboardingDescription:
            "Our HR professionals handle the onboarding and offboarding process, ensuring a seamless transition for new hires and departing employees.",
          payrollBenefitsAdminTitle: "Payroll and Benefits Administration",
          payrollBenefitsAdminDescription:
            "Tafaseel BPO manages payroll processing, benefits administration, and compliance with employment regulations, ensuring accurate and timely payments to your employees.",
          hrPoliciesComplianceTitle: "HR Policies and Compliance",
          hrPoliciesComplianceDescription:
            "We develop and implement HR policies and procedures that align with your organization's goals and comply with relevant employment laws and regulations.",
          performanceManagementDescriptionHR:
            "Our HR experts assist in designing and implementing performance management systems, including goal setting, performance evaluations, and development plans.",
          employeeRelationsEngagementTitle: "Employee Relations and Engagement",
          employeeRelationsEngagementDescription:
            "Tafaseel BPO supports employee relations initiatives, fosters a positive work environment, and implements engagement strategies to enhance employee satisfaction and retention.",
          whyChooseProcurementTitle:
            "Why Choose Procurement Outsourcing with Tafaseel BPO?",
          whyChooseProcurementDescription:
            "When you choose procurement outsourcing with Tafaseel BPO, you can unlock numerous benefits for your organization:",
          costSavingsDescriptionProcurement:
            "By outsourcing your procurement function to Tafaseel BPO, you can significantly reduce costs associated with managing in-house procurement teams. We leverage our purchasing power, market expertise, and supplier relationships to negotiate better prices and terms, resulting in cost savings for your business.",
          expertiseIndustryKnowledgeTitle: "Expertise and Industry Knowledge",
          expertiseIndustryKnowledgeDescription:
            "Tafaseel BPO brings a team of procurement professionals with extensive expertise in various industries. We understand the intricacies of procurement processes, supplier management, and sourcing strategies, ensuring optimal results for your organization.",
          efficiencyScalabilityTitle: "Efficiency and Scalability",
          efficiencyScalabilityDescription:
            "Our procurement outsourcing solutions are designed to enhance operational efficiency and scalability. We utilize advanced technologies and streamlined processes to streamline the procurement lifecycle, allowing you to adapt to changing business needs and handle fluctuations in demand effectively.",
          riskMitigationComplianceTitle: "Risk Mitigation and Compliance",
          riskMitigationComplianceDescription:
            "Tafaseel BPO focuses on risk mitigation and compliance in procurement operations. We ensure adherence to regulatory requirements, implement robust supplier vetting processes, and establish clear contractual terms, mitigating risks and protecting your organization's interests.",
          strategicSourcingTitle: "Strategic Sourcing",
          strategicSourcingDescription:
            "We assist in identifying the right suppliers, conducting market research, and negotiating favorable terms to ensure cost-effective procurement of goods and services.",
          supplierManagementTitle: "Supplier Management",
          supplierManagementDescription:
            "Our procurement professionals handle supplier relationship management, performance monitoring, and contract administration to ensure reliable and high-quality supplies.",
          purchaseOrderManagementTitle: "Purchase Order Management",
          purchaseOrderManagementDescription:
            "Tafaseel BPO manages the end-to-end purchase order process, including order placement, tracking, and invoice reconciliation, ensuring accuracy and efficiency.",
          vendorEvalSelectionTitle: "Vendor Evaluation and Selection",
          vendorEvalSelectionDescription:
            "We conduct thorough evaluations of potential vendors, assessing their capabilities, financial stability, and track record, to ensure the selection of reliable and reputable suppliers.",
          procurementAnalyticsTitle: "Procurement Analytics",
          procurementAnalyticsDescription:
            "Our experts leverage data analytics to provide insights into your procurement operations, identify cost-saving opportunities, and optimize procurement strategies for better outcomes.",
          complianceRiskManagementTitle: "Compliance and Risk Management",
          complianceRiskManagementDescription:
            "Tafaseel BPO ensures compliance with procurement regulations, industry standards, and internal policies. We implement risk mitigation measures, perform due diligence, and establish robust contractual frameworks.",
          whyChooseWarehousingTitle:
            "Why Choose Warehousing Outsourcing with Tafaseel BPO?",
          whyChooseWarehousingDescription:
            "When you choose warehousing outsourcing with Tafaseel BPO, you can unlock numerous benefits for your organization.",
          costSavingsDescriptionWarehouse:
            "By outsourcing your warehousing function to Tafaseel BPO, you can significantly reduce costs associated with building and managing your own warehouse infrastructure. Our cost-effective solutions provide access to state-of-the-art facilities, equipment, and technology without the burden of additional expenses.",
          salesOutsourcingExpertiseAndScalabilityDescriptionWareHouse:
            "Tafaseel BPO brings a team of logistics and warehousing professionals with extensive expertise in inventory management, order fulfillment, and distribution. Our scalable solutions allow you to adapt to fluctuations in demand, ensuring optimal resource utilization.",
          advancedTechnologiesTitle: "Advanced Technologies",
          advancedTechnologiesDescription:
            "We leverage advanced warehouse management systems, inventory tracking tools, and logistics software to optimize warehousing operations. This enables real-time visibility, efficient inventory management, and accurate order processing, leading to improved customer satisfaction.",
          focusCoreBusinessTitle: "Focus on Core Business",
          focusCoreBusinessDescription:
            "Outsourcing your warehousing functions allows you to focus on your core competencies and strategic initiatives. By entrusting your logistics operations to Tafaseel BPO, you can redirect your internal resources to areas that drive growth and innovation.",
          inventoryManagementTitle: "Inventory Management",
          inventoryManagementDescription:
            "We provide end-to-end inventory management services, including receiving, storage, tracking, and fulfillment, ensuring accurate inventory levels and timely order processing.",
          orderFulfillmentTitle: "Order Fulfillment",
          orderFulfillmentDescription:
            "Our professionals handle order picking, packing, and shipping, ensuring efficient and accurate order fulfillment to meet customer expectations.",
          distributionLogisticsTitle: "Distribution and Logistics",
          distributionLogisticsDescription:
            "Tafaseel BPO manages the distribution and logistics aspects of your supply chain, optimizing transportation, route planning, and carrier management to ensure timely delivery of goods.",
          warehouseOperationsTitle: "Warehouse Operations",
          warehouseOperationsDescription:
            "We handle various warehouse operations, including space optimization, layout planning, cycle counting, and quality control, ensuring efficient and organized warehouse processes.",
          returnsManagementTitle: "Returns Management",
          returnsManagementDescription:
            "Our experts manage the returns process, including receiving, inspecting, restocking, and disposition, ensuring seamless returns handling and customer satisfaction.",
          technologyIntegrationTitle: "Technology Integration",
          technologyIntegrationDescription:
            "Tafaseel BPO integrates advanced warehouse management systems, inventory tracking tools, and reporting dashboards, providing real-time visibility and data-driven insights into your warehousing operations.",
          whyChooseTransportationTitle:
            "Why Choose Transportation Outsourcing with Tafaseel BPO?",
          whyChooseTransportationDescription:
            "When you choose transportation outsourcing with Tafaseel BPO, you can unlock numerous benefits for your organization.",
          costSavingsDescriptionTransport:
            "By outsourcing your transportation function to Tafaseel BPO, you can significantly reduce costs associated with owning and maintaining a fleet of vehicles, fuel expenses, and driver management. Our cost-effective solutions provide access to reliable transportation services without the burden of additional expenses.",
          salesOutsourcingExpertiseAndScalabilityTitle:
            "Expertise and Scalability",
          salesOutsourcingExpertiseAndScalabilityDescription:
            "Tafaseel BPO brings a team of logistics and transportation professionals with extensive expertise in route planning, carrier management, and freight optimization. Our scalable solutions allow you to adapt to fluctuations in demand and ensure timely and efficient transportation.",
          advancedTechnologiesDescriptionTransport:
            "We leverage advanced transportation management systems, GPS tracking, and real-time visibility tools to optimize transportation operations. This enables effective route planning, improved shipment tracking, and accurate delivery notifications, enhancing customer satisfaction.",
          riskMitigationAndComplianceTitle: "Risk Mitigation and Compliance",
          riskMitigationAndComplianceDescription:
            "Tafaseel BPO focuses on risk mitigation and compliance in transportation operations. We ensure adherence to regulatory requirements, implement safety protocols, and conduct thorough carrier vetting, mitigating risks and protecting your organization's interests.",
          freightManagementTitle: "Freight Management",
          freightManagementDescription:
            "We manage the end-to-end freight transportation process, including carrier selection, rate negotiation, and shipment tracking, ensuring timely and cost-effective delivery of your goods.",
          routeOptimizationTitle: "Route Optimization",
          routeOptimizationDescription:
            "Our experts utilize advanced routing software and algorithms to optimize delivery routes, minimizing transportation costs, reducing fuel consumption, and maximizing efficiency.",
          carrierManagementTitle: "Carrier Management",
          carrierManagementDescription:
            "Tafaseel BPO handles carrier relationship management, ensuring compliance, performance monitoring, and contract administration to ensure reliable and high-quality transportation services.",
          shipmentTrackingTitle: "Shipment Tracking and Visibility",
          shipmentTrackingDescription:
            "We provide real-time shipment tracking and visibility through advanced technologies, allowing you to monitor the status and location of your shipments throughout the transportation process.",
          customsComplianceTitle: "Customs and Regulatory Compliance",
          customsComplianceDescription:
            "Our professionals ensure compliance with customs regulations, import/export requirements, and transportation-related legislation, facilitating smooth cross-border transportation.",
          transportationAnalyticsTitle: "Transportation Analytics",
          transportationAnalyticsDescription:
            "Tafaseel BPO leverages data analytics to provide insights into your transportation operations, identify cost-saving opportunities, and optimize transportation strategies for better outcomes.",
          accommodationOutsourcingTitle:
            "Why Choose Accommodation Outsourcing with Tafaseel BPO?",
          accommodationOutsourcingDescription:
            "When you choose accommodation outsourcing with Tafaseel BPO, you can unlock numerous benefits for your organization.",
          accommodationCostSavingsTitle: "Cost Savings",
          accommodationCostSavingsDescription:
            "By outsourcing your accommodation function to Tafaseel BPO, you can significantly reduce costs associated with property management, maintenance, staffing, and guest services. Our cost-effective solutions provide access to quality accommodation services without the burden of additional expenses.",
          accommodationExpertiseQualityAssuranceTitle:
            "Expertise and Quality Assurance",
          accommodationExpertiseQualityAssuranceDescription:
            "Tafaseel BPO brings a team of experienced hospitality professionals who possess in-depth knowledge of industry best practices and guest satisfaction. We ensure that your accommodation services meet the highest standards of quality and exceed guest expectations.",
          accommodationEnhancedGuestExperiencesTitle:
            "Enhanced Guest Experiences",
          accommodationEnhancedGuestExperiencesDescription:
            "We focus on creating memorable guest experiences by providing personalized services, prompt assistance, and attention to detail. Our dedicated team ensures that your guests receive exceptional service, resulting in increased guest satisfaction and loyalty.",
          accommodationOperationalEfficiencyTitle: "Operational Efficiency",
          accommodationOperationalEfficiencyDescription:
            "Tafaseel BPO streamlines your accommodation operations through efficient processes, standardized procedures, and effective utilization of resources. This enables you to optimize occupancy rates, minimize vacancies, and improve overall operational efficiency.",
          propertyManagementTitle: "Property Management",
          propertyManagementDescription:
            "We handle property management tasks, including maintenance, repairs, housekeeping, and security, ensuring that your properties are well-maintained and provide a comfortable stay for guests.",
          reservationBookingManagementTitle:
            "Reservation and Booking Management",
          reservationBookingManagementDescription:
            "Our experts manage the reservation and booking process, ensuring smooth check-in and check-out procedures, accurate record-keeping, and efficient allocation of rooms.",
          guestServicesSupportTitle: "Guest Services and Support",
          guestServicesSupportDescription:
            "Tafaseel BPO provides guest services and support, including Twenty Four / Seven assistance, concierge services, and responding to guest inquiries and requests, ensuring a memorable and hassle-free stay for your guests.",
          staffAccommodationTitle: "Staff Accommodation",
          staffAccommodationDescription:
            "Discover our top-notch staff accommodation and labor camp solutions. Our facilities provide comfort, security, and convenience for your workforce. With modern amenities and a focus on well-being, we ensure a pleasant stay. Enhance your workforce's productivity with our exceptional accommodation services.",
          qualityControlInspectionsTitle: "Quality Control and Inspections",
          qualityControlInspectionsDescription:
            "We conduct regular quality control inspections to ensure that your accommodation services meet the highest standards. Our team identifies areas for improvement and implements corrective measures to enhance guest experiences.",
          revenueManagementTitle: "Revenue Management",
          revenueManagementDescription:
            "Our professionals analyze market trends, competitor rates, and demand patterns to optimize pricing strategies and maximize revenue generation for your accommodation services.",
          knowledgeProcessOutsourcingTitle:
            "Why Choose Knowledge Process Outsourcing with Tafaseel BPO?",
          knowledgeProcessOutsourcingDescription:
            "When you choose knowledge process outsourcing with Tafaseel BPO, you can unlock numerous benefits for your organization.",
          specializedSkillsTitle: "Access to Specialized Skills and Expertise",
          specializedSkillsDescription:
            "Tafaseel BPO brings a team of highly skilled professionals with specialized knowledge in various domains. We have subject matter experts, researchers, data analysts, and industry specialists who can provide valuable insights.",
          fasterTurnaroundTimesTitle: "Faster Turnaround Times",
          fasterTurnaroundTimesDescription:
            "Our dedicated team of knowledge professionals is committed to delivering high-quality results within specified timelines. We understand the importance of timely knowledge delivery for strategic decision-making and can meet your organization's time-sensitive requirements.",
          focusOnCoreCompetenciesTitle: "Focus on Core Competencies",
          focusOnCoreCompetenciesDescription:
            "By outsourcing knowledge processes to Tafaseel BPO, you can free up your internal resources to focus on core competencies and strategic initiatives. This allows you to allocate your time and energy to areas that drive innovation, growth, and value creation.",
          researchAndMarketIntelligenceTitle:
            "Research and Market Intelligence",
          researchAndMarketIntelligenceDescription:
            "We provide in-depth research and analysis services to support market intelligence, competitor analysis, industry trends, and customer insights, enabling data-driven decision-making.",
          dataAnalyticsAndReportingTitle: "Data Analytics and Reporting",
          dataAnalyticsAndReportingDescription:
            "Our experts leverage advanced analytics tools and techniques to process and analyze large volumes of data, providing valuable insights and reports for strategic planning and performance measurement.",
          contentDevelopmentAndManagementTitle:
            "Content Development and Management",
          contentDevelopmentAndManagementDescription:
            "Tafaseel BPO assists in creating and managing various types of content, including articles, blogs, reports, and whitepapers, ensuring high-quality and engaging content that aligns with your organization's goals.",
          knowledgeManagementSolutionsTitle: "Knowledge Management Solutions",
          knowledgeManagementSolutionsDescription:
            "We develop and implement knowledge management systems and processes to capture, organize, and disseminate knowledge within your organization, facilitating seamless knowledge sharing and collaboration.",
          intellectualPropertyServicesTitle: "Intellectual Property Services",
          intellectualPropertyServicesDescription:
            "Our professionals assist in intellectual property research, patent analysis, and patent drafting, helping you protect your innovative ideas and technologies.",
          trainingAndLearningSolutionsTitle: "Training and Learning Solutions",
          trainingAndLearningSolutionsDescription:
            "Tafaseel BPO offers training and learning solutions, including e-learning modules, curriculum development, and training content creation, to enhance employee knowledge and skills.",
          cxAndDigitalOutsourcingTitle:
            "Why Choose CX and Digital Outsourcing with Tafaseel BPO?",
          cxAndDigitalOutsourcingDescription:
            "When you choose workspace outsourcing with Tafaseel BPO, you can unlock numerous benefits for your organization.",
          knowledgeOutsourcingSpecializedSkills:
            "Access to Specialized Skills and Expertise",
          knowledgeOutsourcingSkillsDescription:
            "Tafaseel BPO brings a team of highly skilled professionals with specialized knowledge in various domains. We have subject matter experts, researchers, data analysts, and industry specialists who can provide valuable insights and support across a wide range of knowledge-intensive tasks.",
          knowledgeOutsourcingCostSavings:
            "Cost Savings and Operational Efficiency",
          knowledgeOutsourcingCostSavingsDescription:
            "Outsourcing knowledge processes to Tafaseel BPO allows you to reduce costs associated with hiring, training, and managing an in-house team. Our scalable solutions ensure optimal resource utilization and efficient knowledge management, resulting in cost savings and improved operational efficiency.",
          knowledgeOutsourcingFasterTurnaround: "Faster Turnaround Times",
          knowledgeOutsourcingFasterTurnaroundDescription:
            "Our dedicated team of knowledge professionals is committed to delivering high-quality results within specified timelines. We understand the importance of timely knowledge delivery for strategic decision-making and can meet your organization's time-sensitive requirements.",
          knowledgeOutsourcingFocusOnCoreCompetencies:
            "Focus on Core Competencies",
          knowledgeOutsourcingFocusDescription:
            "By outsourcing knowledge processes to Tafaseel BPO, you can free up your internal resources to focus on core competencies and strategic initiatives. This allows you to allocate your time and energy to areas that drive innovation, growth, and value creation.",
          customerServiceOutsourcingDescriptionDigitalTransformation:
            "We provide end-to-end customer service solutions, including phone, email, chat, and social media support. Our customer service representatives are trained to deliver personalized and empathetic interactions, ensuring exceptional customer satisfaction.",
          digitalMarketingOutsourcing: "Digital Marketing Outsourcing",
          digitalMarketingOutsourcingDescription:
            "Tafaseel BPO assists in digital marketing activities, including social media management, content creation, SEO optimization, paid advertising, and analytics. We help you increase brand visibility, drive traffic, and generate leads through targeted digital marketing strategies.",
          customerAnalyticsInsights: "Customer Analytics and Insights",
          customerAnalyticsInsightsDescription:
            "Our data analysts extract valuable customer insights from various data sources, enabling data-driven decision-making, personalized marketing campaigns, and proactive customer engagement.",
          uxDesign: "User Experience (UX) Design",
          uxDesignDescription:
            "Tafaseel BPO provides flexible and scalable marketing solutions that can adapt to your changing business needs. Whether you need ongoing marketing support or specific campaign assistance, we can tailor our services to meet your requirements.",
          digitalTransformationConsulting: "Digital Transformation Consulting",
          digitalTransformationConsultingDescription:
            "Tafaseel BPO offers strategic consulting services to guide your digital transformation journey. We assess your current digital capabilities, identify opportunities for improvement, and develop a roadmap for successful digital adoption.",
          aiAutomationSolutions: "AI and Automation Solutions",
          aiAutomationSolutionsDescription:
            "Our experts leverage artificial intelligence (AI) and automation technologies to optimize processes, automate repetitive tasks, and enhance efficiency across customer interactions, data analysis, and operational workflows.",
          workspaceOutsourcingTitle:
            "Why Choose Workspace Outsourcing with Tafaseel BPO?",
          workspaceOutsourcingDescription:
            "When you choose workspace outsourcing with Tafaseel BPO, you can unlock numerous benefits for your organization.",
          costEfficiencyDescriptionOutsourcing:
            "By outsourcing your workspace needs to Tafaseel BPO, you can reduce costs associated with leasing, maintenance, utilities, and other administrative tasks. Our cost-effective solutions allow you to optimize your workspace expenses and allocate resources more efficiently.",
          accessToAmenitiesTitle: "Access to Amenities and Services",
          accessToAmenitiesDescription:
            "Tafaseel BPO offers a range of amenities and services to enhance your workspace experience. From high-speed internet and state-of-the-art technology infrastructure to reception services, mail handling, and administrative support, we ensure that your workspace is equipped with everything you need to operate smoothly.",
          scalabilityAndExpansionTitle: "Scalability and Expansion",
          scalabilityAndExpansionDescription:
            "Our workspace solutions are designed to accommodate your business growth and changing needs. Whether you need to scale up your workspace or expand into new locations, we can provide flexible options that align with your expansion plans.",
          flexibleWorkEnvironmentTitle: "Flexible Work Environment",
          flexibleWorkEnvironmentDescription:
            "We provide flexible workspace solutions that cater to your specific requirements. Whether you need dedicated office spaces, coworking areas, meeting rooms, or virtual office services, we can tailor our offerings to meet your needs.",
          privateOfficeSpacesTitle: "Private Office Spaces",
          meetingRoomsTitle: "Meeting Rooms",
          meetingRoomsDescription:
            "Tafaseel BPO offers well-equipped meeting rooms for your business meetings, presentations, and client discussions. Our meeting rooms are designed to facilitate effective communication and collaboration.",
          virtualOfficeServicesTitle: "Virtual Office Services",
          virtualOfficeServicesDescription:
            "We provide virtual office solutions that include a prestigious business address, mail handling, call forwarding, and access to meeting rooms on-demand, enabling you to maintain a professional image and flexibility.",
          technologyInfrastructureTitle: "Technology Infrastructure",
          technologyInfrastructureDescription:
            "Our workspaces are equipped with high-speed internet, advanced telecommunications systems, video conferencing capabilities, and other technology infrastructure to support your business operations.",
          receptionSupportTitle: "Reception and Administrative Support",
          receptionSupportDescription:
            "Tafaseel BPO offers reception services, administrative support, and professional staff to assist with day-to-day tasks, ensuring a seamless work experience for you and your team.",
          privateOfficeSpacesDescription:
            "We provide fully furnished private office spaces that offer privacy, comfort, and a productive work environment for individuals or teams.",
          dynamicWorkspaceTitle:
            "Our Dynamic Workspace Solutions in Leading Freezones",
          yasirAlMansour: "Yasir Al-Mansour",
          yasirFeedback: `"Tafaseel BPO's authentic approach brought remarkable changes to our operations. Their specific solutions and relevance exceeded expectations, positively impacting our business. With quantifiable results and a recent timeliness, they've proven their excellence. I highly recommend Tafaseel BPO for their transformative services."`,
          priyaGupta: "Priya Gupta",
          priyaFeedback: `"Tafaseel BPO's authentic services have been a game-changer for us. Their specific solutions were precisely what we needed, and their relevance to our industry was spot-on. With a positive tone and emotional impact, our collaboration was outstanding. Their quantifiable results speak volumes about their expertise."`,
          jamalAhmed: "Jamal Ahmed",
          jamalFeedback: `"Choosing Tafaseel BPO was a decision we'll never regret. Their authentic, specific, and relevant solutions surpassed all expectations. The positive impact on our operations was remarkable. With quantifiable results, their excellence shines brightly. Tafaseel BPO's timely and transformative services are unparalleled."`,
          speakWithA: "Speak with a",
          expertToday: "expert today",
          nationalitiesDiversity: "Nationalities<br></br>Diversity",
          languagesSpoken: "Languages <br></br> Spoken",
          genderEqualityRatio: "Gender <br></br> Equality Ratio",
          privacyPoint1:
            "This privacy notice discloses the privacy practices for Tafaseel BPO. This privacy notice applies solely to information collected by this website. It will notify you of the following:",
          privacyPoint2:
            "What personally identifiable information is collected from you through the website, how it is used, and with whom it may be shared.",
          privacyPoint3:
            "What choices are available to you regarding the use of your data.",
          privacyPoint4:
            "The security procedures in place to protect the misuse of your information.",
          privacyPoint5:
            "How you can correct any inaccuracies in the information.",
          registration: "Registration",
          registrationDescription:
            "In order to use this website, a user must first complete the registration form. During registration, a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest.",
          electronicPayments: "Electronic Payments",
          electronicPaymentsDescription:
            "Tafaseel BPO uses CCAvenue online payment gateway for online payment transactions.",
          informationCollection: "Information Collection, Use, and Sharing",
          informationCollectionDescription:
            " We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone. We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request. Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products, or services, or changes to this privacy policy.",
          sharing: "Sharing",
          sharingDescription:
            "We share aggregated demographic information with our advertisers. This is not linked to any personal information that can identify any individual person.",
          yourAccessToControlOverInformation:
            "Your Access to and Control Over Information",
          yourAccessToControlOverInformationDescription:
            "You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:",
          sharingPoint1: "See what data we have about you, if any.",
          sharingPoint2: "Change/correct any data we have about you.",
          sharingPoint3: "Have us delete any data we have about you.",
          sharingPoint4:
            "Express any concern you have about our use of your data. Security",
          security: "Security",
          securityDescription: `We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the Web page.`,
          securityDescription2:
            "While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.",
          securityDescription3:
            "If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 600599996 or via email at info@tafaseel.ae.",
          fairUsagePolicy: "Fair Usage Policy",
          fairUsagePoint1:
            "Our Fair Use Policy applies to Services which are stated to be subject to the Fair Use Policy ('Fair Use Services').",
          fairUsagePoint2: "Our Fair Use Policy is intended to ensure:",
          fairUsagePoint3:
            "The availability of Our Services to all eligible Tafaseel BPO Customers; and",
          fairUsagePoint4:
            "that the Fair Use Services are not used in an unreasonable manner.",
          fairUsagePoint5:
            "We reserve the right to vary the terms of this Fair Use Policy from time to time with no prior notice and immediate effect.",
          fairUsagePoint6:
            "We may rely on the Fair Use Policy where Your usage of the Fair Use Services is unreasonable, as defined below.",
          unreasonableUse: "Unreasonable Use",
          unreasonableUseDescription:
            "It is unreasonable use of a Fair Use Service where Your use of the service is reasonably considered by Tafaseel BPO to:",
          unreasonableUsePointA: "a. be fraudulent;",
          unreasonableUsePointB: "b. involve a non-ordinary use;",
          unreasonableUsePointC:
            "c. cause significant network congestion, disruption, or otherwise adversely affect the Tafaseel BPO network, a supplier’s network; or",
          unreasonableUsePointD:
            "d. adversely affect another person’s use of or access to the Fair Use Services, the Tafaseel BPO network, or a supplier’s network.",
          unreasonableUseWithoutLimitation: "Without limitation:",
          unreasonableUsePointAFraudulent:
            "a. Fraudulent use includes resupplying or reselling a Service without Tafaseel BPO's written consent so that someone else may access, use, or commercially exploit a Fair Use Service;",
          unreasonableUsePointBNonOrdinary:
            "b. Non-ordinary use includes circumstances where You make or receive calls in any non-ordinary manner without obtaining Our written consent first, which consent We may give or withhold, or make subject to conditions, in Our discretion. Use in a non-ordinary manner includes:",
          unreasonableUseForwardingExcessiveCalls:
            "forwarding excessive calls (i.e., where the number of calls of the relevant type in a given period is at least three times the average number of calls of that type in that period for all customers on the same plan)",
          unreasonableUseExtensiveCallTimeSpan:
            "extensive call time span (i.e., where the call duration of the relevant type in a given period is at least three times the average call duration of that type in that period for all customers on the same plan)",
          unreasonableUseTransferringCalls:
            "transferring calls from more than one (1) branch without consent",
          unreasonableUseUsageToMenace:
            "usage to menace, harass, or injure any person or damage anything;",
          unreasonableUseUsageInConnectionWithBreach:
            "usage in connection with a breach of or committing an offense against any law, regulation, standard, or code;",
          unreasonableUseOtherActivity:
            "any other activity that would not be reasonably regarded as ordinary use in relation to the Fair Use Service.",
          ourRights: "Our Rights",
          ourRightsDescription:
            "If We reasonably consider your use of the Fair Use Services is unreasonable, we may, at our sole discretion, without telling you before we do so:",
          ourRightsPointA: "a. block inbound ports so you cannot host services",
          ourRightsPointB:
            "b. remove any unlimited calls feature and charge you for your calls at Tafaseel BPO's standard rates;",
          ourRightsPointC:
            "c. suspend or limit the Service (or any feature of it) in accordance with Our Agreement with You; and/or",
          ourRightsPointD:
            "d. terminate the Service in accordance with Our Agreement with You.",
          ourRightsPolicySupplementary:
            "This policy is supplementary to and does not limit any of Tafaseel BPO's rights.",
          termsOfUse: "Terms of Use",
          termsOfUseDescription:
            "These Website Standard Terms of Use (these 'Terms') contained herein on this webpage, shall govern your use of the website of Tafaseel BPO, including all pages within this website (collectively referred to herein below as this 'Website'). These Terms apply in full force and effect to your use of this Website, and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website if you have any objection to any of these Terms.",
          termsOfUseMinors:
            "This Website is not for use by any minors (defined as those who are not at least 18 years of age) without adult consent or supervision.",
          intellectualPropertyRights: "Intellectual Property Rights",
          intellectualPropertyRightsDescription:
            "Under these Terms, Tafaseel BPO and/or its licensors own all rights to the intellectual property and material contained on this Website, and all such rights are reserved.",
          intellectualPropertyRightsLicense:
            "You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.",
          restrictions: "Restrictions",
          restrictionsIntro:
            "You are expressly and emphatically restricted from all of the following:",
          restrictionPublishing:
            "Publishing any website material in any media;",
          restrictionCommercializing:
            "Selling, sublicensing, and/or otherwise commercializing any Website material;",
          restrictionPubliclyPerforming:
            "Publicly performing and/or showing any Website material;",
          restrictionDamagingUse:
            "Using this Website in any way that is, or may be, damaging to this Website;",
          restrictionImpactingAccess:
            "Using this Website in any way that impacts user access to this Website;",
          restrictionContraryToLaws:
            "Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;",
          restrictionDataMining:
            "Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website or while using this Website;",
          restrictionUnauthorizedAdvertising:
            "Using this Website to engage in any unauthorized advertising or marketing;",
          restrictionConfidentialInfo:
            "Certain areas of this Website are restricted from access by you, and Tafaseel BPO may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential, and you must maintain confidentiality of such information.",
          yourContent: "Your Content",
          yourContentDescription:
            'In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video, text, images, or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Tafaseel BPO a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media. Your Content must be your own, and it must not be infringing on any third party\'s rights. Tafaseel BPO reserves the right to remove any of your content from this website at any time, and for any reason, without notice.',
          noWarranties: "No Warranties",
          noWarrantiesDescription:
            'This Website is provided "as is," with all faults, and Tafaseel BPO makes no express or implied representations or warranties of any kind related to this Website or the materials contained on this website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.',
          limitationOfLiability: "Limitation of Liability",
          limitationOfLiabilityDescription:
            "In no event shall Tafaseel BPO, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise. Tafaseel BPO, including its officers, directors, and employees, shall not be liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.",
          indemnification: "Indemnification",
          indemnificationDescription:
            "You hereby indemnify to the fullest extent Tafaseel BPO from and against any and all liabilities, costs, demands, causes of action, damages, and expenses (including reasonable attorney's fees) arising out of or in any way related to your breach of any of the provisions of these Terms.",
          severability: "Severability",
          severabilityDescription:
            "If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.",
          variationOfTerms: "Variation of Terms",
          variationOfTermsDescription:
            "Tafaseel BPO is permitted to revise these Terms at any time as it sees fit, and by using this Website, you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing the use of this Website.",
          assignment: "Assignment",
          assignmentDescription:
            "Tafaseel BPO shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.",
          refunds: "Refunds",
          refundsDescription:
            "Tafaseel BPO services are provided with no contractual payment obligations; every payment made to Tafaseel BPO is considered to be paid in full in advance and will be non-refundable.",
          cancellationPolicy: "Cancellation Policy",
          cancellationPolicyDescription:
            "If you desire to cancel a Tafaseel BPO service bought and paid in advance, you should write an email to info@tafaseel.ae requesting so, without the need to justify your motives. No refund requests will be entertained in case of early cancellation of services bought.",
          entireAgreement: "Entire Agreement",
          entireAgreementDescription:
            "These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Tafaseel BPO and you in relation to your use of this Website and supersede all prior agreements and understandings with respect to the same.",
          governingLawJurisdiction: "Governing Law & Jurisdiction",
          governingLawJurisdictionDescription:
            "These Terms will be governed by and construed in accordance with the laws of the United Arab Emirates, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Ajman, United Arab Emirates, for the resolution of any disputes.",
        },
      },
      ar: {
        translation: {
          aboutUsDescription:
            "شركة تفاصيل بي بي او هي إحدى شركات مجموعة تفاصيل القابضة وتعد من أكبر شركات التعهيد (outsourcing) في دولة الإمارات العربية المتحدة ولديها فروع في جمهورية مصرالعربية والمملكة العربية السعودية ومملكة البحرين، حيث تعمل على توفير أفضل خدمات التعهيد باستخدام كوادر بشرية مؤهلة بخبرات عالمية، كما تقدم لعملائها أرقى المنشآت وأحدث ما توصلت إليه تكنولوجيا الاتصالات والمعلومات.",
          liveStats: "تفاصيل في أرقام",
          employees: "موظف",
          nationalities: "جنسية",
          languages: "لغة",
          millionPlus: "مليون+",
          billionPlus: "مليار+",
          liveTouched: "أثر إيجابي على حياة",
          successfulRings: "عملية ناجحة",
          paymentProcessed: "الدفعات التي أدارتها الشركة نيابةً عن عملائها",
          liveChats: "محادثة مباشرة",
          emailsHandled: "البريد الالكتروني المستلم",
          sites: "مواقع",
          whatWeDo: "خدماتنا",
          serviceDescription:
            " نفخر بتوفير حلول تعهيد استثنائية ومخصصة لتلبية متطلبات مشاريعكم وشركاتكم. ونحن ملتزمون بالسعي لتنمية مشاريعكم وتطويرها بشكل اسثنائي يفوق التوقعات، وذلك من خلال النهج الذي نتبعه والذي يركز على التميز في خدمة العملاء. ويحرص فريقنا المحترف على تقديم خدمات عالية الجودة يمكنكم الاعتماد عليها لتساعدكم في الارتقاء بأداء مشاريعكم وشركاتكم. شراكتكم معنا تمنحكم التفوق على منافسيكم وتحقيق نجاحات مبهرة في ساحة الأعمال الديناميكية المزدهرة.",
          industryFocus: "مجالات عملنا",
          contactCenter: "تعهيد مراكز الاتصال",
          itOutSourcing: "تعهيد خدمات تقنية المعلومات",
          financeOperationOutSourcing: "تعهيد عمليات الشؤون المالية",
          salesOperationOutsourcing: "تعهيد عمليات المبيعات",
          marketingOperationsOutSourcing: "تعهيد عمليات التسويق",
          salesOutSourcing: "تعهيد خدمات المبيعات",
          marketingOutSourcing: "تعهيد خدمات التسويق",
          customerOutSourcing: "تعهيد عمليات خدمة العملاء",
          operationalFunctions: "الخدمات التشغيلية",
          humanResourceOutSourcing: "تعهيد الموارد البشرية",
          procurementOutSourcing: "تعهيد عمليات الشراء",
          warehousingOutsourcing: "تعهيد عمليات التخزين",
          transportationOutsourcing: "تعهيد خدمات النقل والمواصلات",
          cxAndDigitalTransformationOutsourcing:
            "تعهيد تجارب العملاء والتحول الرقمي",
          workSpace: "أماكن العمل",
          accommodationOutsourcing: "تعهيد خدمات الإسكان",
          businessSolution: "حلول الأعمال",
          knowledgeProcessOutsourcing: "تعهيد عملية توفير الخبرات",
          cxAndDigitalOutsourcing: "تعهيد عمليات تحسين تجربة العملاء والرقمنة",
          workspaceOutsourcing: "تعهيد الخدمات المتعلقة بأماكن العمل",
          affliateCompanies: "الشركات<br></br>التابعة",
          ourClients: "عملاؤنا",
          testimonials: "تزكيات العملاء",
          arjunSingha: "آرجون سينغ",
          arjunSinghFeedback: `"كان النهج المتميز والمتخصص الذي تتبعه شركة تفاصيل عاملاً جوهرياً في تطوير أعمالنا، حيث كان التناغم الذي أبدته الشركة في فهم احتياجاتنا الفريدة وتلبيتها مبهراً، مما أسفر عن نتائج إيجابية بارزة. يفهم فريق شركة تفاصيل احتياجات عملائهم بدقّة بما يمتلكونه من حسٍّ إيجابي وأثر بالغ، وتبدي نتائج أعمالهم القابلة للقياس مدى خبرتهم الكبيرة والتزامهم الراسخ بتحقيق النجاح."`,
          malikAbubakar: "مالك أبو بكر",
          malikFeedback: `"نحن ممتنون لشركة تفاصيل لخدمات التعهيد لما قدّمته من دعم كبير لشركتنا وفق الإطار الزمني الأمثل، حيث عززت استراتيجياتهم الواضحة وحلولهم الملائمة إنتاجيتنا إلى حد كبير. وقد اتسمت رحلة التعاون بيننا بحسّهم الإيجابي وأثرهم البالغ، ولا يمكن لأحد إنكار تميزهم الذي أثبتوه بالنتائج الكبيرة والقابلة للقياس. إن شركة تفاصيل لخدمات التعهيد قد أثبتت تميّزها."`,
          benjamin: "بينجامين ديفيس",
          benjaminFeedback: `"كان قرارنا بالتعاون مع شركة تفاصيل لخدمات التعهيد نقلةً نوعيةً لنا، فحلولهم الفريدة والدقيقة والملائمة أسفرت عن نتائج مبهرة. ويظهر الأثر البالغ لعملهم جليّاً في صورة عميلنا التي وافق على نشرها، كما إن نتائج عملهم القابلة للقياس تبرهن تميزهم، فهم يتحرّون الدقة والإيجاز في عملهم إلى حد مبهر، لذا فإن شركة تفاصيل لخدمات التعهيد لا نظير لها."`,
          fromVisionToVictory: "من<br></br> الرؤية وحتى النجاح",
          ourInspirationalJourney: " رحلتنا الملهمة",
          maryamAlNauimi: "سعادة مريم النعيمي",
          presidentAndFounder: "رئيسة الشركة ومؤسستها",
          maryamAlNauimiDescription: `"كان الشغف والإصرار الحافزان الأبرز لي خلال مسيرتي المهنية التي بدأت في العمل كمشرفة في مركز اتصالات وحتى تأسيسي لشركة تفاصيل لخدمات التعهيد. بدأت الشركة مسيرتها مع وكيلين فقط، وكان سعينا هادفاً لوضع مفهوم جديد لتقديم الخدمات، واليوم تزدهر مسيرة شركتنا بفضل التزام فريقنا بالاحترافية وسعينا لتحقيق النجاح."`,
          ahmedKhbeer: "أحمد خبير",
          partnerAndGroupCEO: "الشريك والرئيس التنفيذي للمجموعة",
          ahmedKhbeerDescription: `"بدأت مسيرتي المهنية كوكيل في مركز الاتصال، ثم تشاركت مع سعادة الشيخة مريم للسعي لقيادة جهود تنمية شركة تفاصيل لتعهيد العمليات التجارية، وساعدنا في ذلك رؤيتنا المشتركة واعتمادنا على التكنولوجيا. وانطلاقاً من نهجنا المرتكز على الفريق ومكان العمل والعمليات، فإننا مستمرون بتقديم الخدمات الريادية وتبني النهج الإبداعي."`,
          mission: "رسالتنا",
          missionDescription:
            "نسعى لخوض رحلة ريادية نهدف من خلالها للارتقاء بقطاع تعهيد الأعمال ومد جسور التعاون بين القارات، ولأن نصبح منارة التميز في خدمات الأعمال العالمية والأخذ بيد الشركات نحو نجاح منقطع النظير وإلهامها لامتلاك إمكانيات لا محدودة.",
          aboutUsDescription1:
            "نرتكز في شركة تفاصيل لخدمات التعهيد على قوة فريقنا ومكان العمل والعمليات التي نجريها، وتعززها القوة التحويلية للتكنولوجيا التي نستخدمها. وتتيح لنا هذه التركيبة المثالية القدرة على تقديم خدمات لا تضاهى، حيث يعمل فريقنا المتفاني في بيئة مهيأة بعناية ووفق عمليات سلسة تجرى باستخدام أحدث التقنيات على تقديم أقصى درجات التميز لعملائنا الكرام.",
          technology: "التكنولوجيا",
          compliance: "معايير الامتثال",
          nationalitiesDiversity: "التنوع<br></br>والجنسيات",
          languagesSpoken: "اللغات <br></br>المحكية",
          genderEqualityRatio: "نسبة المساواة<br></br>بين الجنسين",
          technologyDescription:
            "نسخّر أحدث التقنيات للارتقاء بجودة خدماتنا، بدايةً من أتمتة العمليات ووصولاً إلى استخدامنا الذكاء الاصطناعي، فإننا نسخّر الإبداع التقني لتكوين مفهوم جديد لمجال تقديم الخدمات وضمان أعلى درجات الكفاءة على كافة الأصعدة. تمكّننا التكنولوجيا من المداومة على تقديم تجارب متميزة لعملائنا الكرام.",
          groupOfCompanies: "مجموعة شركات",
          fromUAEToTheWorld: "انطلقت من الإمارات إلى كافة أنحاء العالم",
          rannah: "منصة رنّة",
          rannahDescription:
            "منصة رنّة (Rannah.ae) هي أول منصة مؤتمتة بالكامل في العالم تتيح الاشتراك في مركز الاتصال عند الطلب، والمصممة لتلائم كافة أنواع الأعمال في كافّة أرجاء العالم.",
          tafaseelBPO: "شركة تفاصيل لخدمات التعهيد",
          tafaseelBPODescription:
            "شركة إماراتية رائدة في مجال خدمات تعهيد الأعمال، تأسست في عام 2011، ومتخصصة بتعهيد مراكز الاتصال وإنشائها وإدارتها وتشغيلها وتحويلها، إلى جانب تقديم حلول تكنولوجيا المعلومات المتكاملة والبنى التحتية المرتبطة بها وحلول تواصل الأعمال التجارية، بالإضافة إلى التدريب والاستشارات في مجال خدمة العملاء.",
          salamaHealthCare: "سلامة للرعاية الصحية",
          salamaHealthCareService: "خدمات سلامة الطبية",
          salamaHealthCareDescription:
            "تتخصص سلامة للرعاية الصحية بتوفير الرعاية الصحية المنزلية لتعزيز العيش الآمن والصحي لمجتمعنا، وذلك من خلال ضمان توفير رعاية عالية الجودة ملائمة لك في منزلك.",
          bloomingBeautyLounge: "صالون بلومنج للتجميل",
          bloomingBeautyLoungeDescription:
            "صالون متألق يقدم خدمات شاملة في مجال التجميل والحياة الاجتماعية للسيدات، حيث يتيح لهن الفرصة للعناية بجمالهن والتعارف والتألق بجمالهن.",
          tafaseelIT: "شركة تفاصيل لتقنية المعلومات",
          tafaseelITDescription:
            "تقدم تفاصيل أحدث حلول تكنولوجيا المعلومات التي تأخذ بيد شركتك للنجاح وتحضرك للمستقبل الناجح. حقق تحولاً جذرياً لشركتك على يد تفاصيل، مزودة حلول تكنولوجيا المعلومات ذات الخبرة الكبيرة والتي تركز على التكنولوجيا والتواصل.",
          whyTafaseel: "لماذا تفاصيل؟",
          services: "الخدمات",
          industries: "مجالات عملنا",
          insights: "الرؤى والأفكار",
          careers: "الوظائف",
          esg: "ESG",
          login: "تسجيل دخول",
          industriesTitle: "حلول متقدمة خاصة بالصناعة",
          mediaAndCommunication: "الإعلام والتواصل",
          automotive: "السيارات",
          retail: "التجزئة",
          healthCare: "الرعاية الصحية",
          foodAndBeverages: "المأكولات والمشروبات",
          travelAndTours: "السياحة والسفر",
          ecommerce: "التجارة الالكترونية",
          governmentAndPublicService: "الخدمات الحكومية والعامة",
          realEstateAndFacilityManagement: "العقارات وإدارة المرافق",
          consumerGoods: "السلع الاستهلاكية",
          logisticsAndTransportationIndustry:
            "قطاع الخدمات اللوجستية والمواصلات",
          oilAndGasCompanies: "شركات النفط والغاز",
          educationSolutions: "حلول التعليم",
          aboutUs: "نبذةٌ عنّا",
          tafaseelLearning: "تعليم تفاصيل",
          leadership: "الفريق الإداري",
          contactUs: "تواصل معنا",
          readArticle: "قراءة المقال",
          callUs: "اتصل بنا",
          mondayToFriday: "الاثنين - الجمعة",
          call: "اتصل",
          email: "البريد الالكتروني",
          weWillReplyASAP: "سنقوم بالرد في أقرب وقت",
          sendUsAnEmail: "أرسل بريد الكتروني",
          dubaiOffice: "مكتب دبي",
          dubaiAddress: "دبي كوميرسيتي، 11 شارع 17 - أم رمول",
          ajmanOfficeHQ: "مكتب عجمان (الفرع الرئيسي)",
          ajmanHQAddress:
            "برج الفاتح، شارع الشيخ مكتوم بن راشد - صناعية عجمان 1",
          ajmanOfficeBO: "مكتب عجمان (BO)",
          ajmanBOAddress: "مبنى بوليفارد B، الجرف 2",
          cairoOffice: "مكتب القاهرة",
          cairoAddress:
            "مبنى (O118)، الدور الثالث، مكتب (302)، الطريق الدائري، وان قطامية، القاهرة، مصر",
          viewMap: "إعرض الخريطة",
          letsTalk: "تواصل معنا",
          reasonToContact: "حدد سبب اتصالك",
          contactSale: "اتصل بممثل المبيعات",
          wantToWorkForTafaseel: "أريد أن أعمل في شركة تفاصيل",
          howCanWeHelp: "كيف يمكننا المساعدة",
          termsAndConditions: "الشروط والأحكام",
          fairUsage: "سياسة الاستخدام العادل",
          privacyPolicy: "سياسة الخصوصيّة",
          username: "اسم المستخدم",
          password: "كلمة المرور",
          environmentSocialGovernance: "البيئة والحوكمة الاجتماعية",
          environmentSocialGovernanceDescription1: `أهلاً بكم في مكتب الاستدامة، المركز الرئيسي المعني بتنسيق وتوجيه خارطة طريق الاستدامة الخاصة بـشركة "تفاصيل". وبصفتنا مشرفين على رؤية الاستدامة في تفاصيل،`,
          environmentSocialGovernanceDescription2:
            "فإننا ملتزمون بتعزيز مستقبل أكثر اخضرارًا واستدامة.",
          sustainablePractices: "الممارسات المستدامة",
          sustainablePracticesDescription: `نقوم بمواءمة المشاريع والبرامج لتعزيز الممارسات المستدامة ورصد أداء الاستدامة لدينا والإبلاغ عنه، وتنسيق تنفيذ خطة العمل المناخية الخاصة بـ "تفاصل". يمتد التزامنا بالشفافية ليشمل إيصال  ما نقوم به من أعمال ومبادرات تتعلَّق بالاستدامة إلى الجمهور.`,
          engagingWithTheCommunity: "التعاون مع المجتمع المحلي",
          engagingWithTheCommunityDescription:
            "نحن نعمل بنشاط مع المجتمع، ونعزز ثقافة الاستدامة ونرفع مستوى الوعي حول مسؤوليتنا الجماعية لحماية البيئة. معًا، يمكننا إحداث تأثير إيجابي.",
          environmentSocialGovernanceFooterText1:
            "انضم إلينا في رحلتنا نحو مستقبل أكثر استدامة. ندعوك لاستكشتاف موقعنا الإلكتروني لمعرفة المزيد عن مبادراتنا وكيف يمكنك المشاركة فيها.",
          environmentSocialGovernanceFooterText2:
            "معًا، يمكننا إحداث تغييرٍ دائمٍ.",
          getInTouch: "تواصل معنا",
          haveSuggestionsMakingTafaseelBetter:
            "هل لديك اقتراحات لجعل تفاصيل أكثر استدامة؟",
          wantToBeInvolved: "أتود المشاركة؟",
          emailUsAt: "راسلنا عبر البريد الألكتروني",
          whyWorkForTafaseel: "لماذا تفاصيل؟",
          careersDescription1:
            "توفِّر شركة تفاصيل لخدمات التعهيد بيئة حيوية تتعتمد على أحدث التقنيات، ما يعزِّز  التطور لدى الموظفين ويشجعهم على التعاون. يحظى موظفو تفاصيل بالاهتمام والتقدير لما يقدمونه من عمل مؤثر، مع الاستمتاع ببيئة عمل داعمة والحفاظ على التوازن بين العمل والحياة.",
          careersDescription2: `فالقيادة الحكيمة والتعويضات التنافسية والمخصصات المميَّزة كلها هذه المزايا تجعل من "تفاصل" خيارًا جذابًا لاستقطاب الأشخاص المحترفين.`,
          homegrownHeroes: "أبطال شركتنا",
          lanaHashim: "لانا هاشم",
          lanaHashimDescription:
            "بدأت رحلتي المتواضعة في شركة تفاصيل لخدمات التعهيد كموظَّفة في مركز الاتصالات في يونيو عام 2013. واليوم، وأنا أتحدَّث بصفتي مديرة عمليات مركز الاتصالات، أود التعبير عن عميق فخري بالتقدُّم الملحوظ الذي أحرزته. لا شك أن قيادة فريقنا المتفاني كان لها الدور الحقيقي في خلق بيئة عمل ملهمة يتمكَّن فيه الجميع من تحقيق الازدهار والنجاح. كذلك فإن القوة التي دفعتني إلى تحقيق هذا النجاح هي التزام شركة تفاصيل بتعزيز التطوّر الشخصي والتأييد المتواصل لزملائي في العمل. وها أنا أسعى مع الحفاظ على قيمنا،إلى تخطي كل ما هو متوقع في حدود الإنجاز، لأترك بصمة لا تنسى في كل تفاعل مع العملاء. إلى جميع الزملاء الطموحين، الذين يتمتعون بالشغف والتفاني، يمكن أن تكون رحلتكم في شركة تفاصيل رحلة استثنائيَّة.",
          noorulAmeen: "نور الأمين",
          aboutNoorulAmeen:
            "محترف ماهر في مجال تقنية المعلومات والتواصل بخبرة تزيد عن 7 سنوات في دولة الإمارات. وهو خبير في التخطيط الاستراتيجي لتقنية المعلومات وإدارة البنية التحتية لتقنية المعلومات، والأمن السيبراني وقيادة الفرق، وتعزز خبراته هذه من الكفاءة العملية والابتكار.",
          noorulAmeenDescription: `بدأت كمهندس في مجال تكنولوجيا المعلومات والاتصالات، ثم قادتني رحلتي الممتعة في شركة تفاصيل لخدمات التعهيد للوصول إلى منصب مرموق كمدير تكنولوجيا المعلومات والاتصالات. لقد عملت، على مدى 7 سنوات، على صقل المهارات في مجال التخطيط الاستراتيجي والأمن السيبراني، مما أدى إلى تعزيز الكفاءة التشغيلية وتشجيع الابتكار في هذه البيئة الديناميكية. وقد مكنني التزام شركة "تفاصيل" بتطوير الموظفين من النجاح على المستوى الشخصي والمهني. ومن خلال تعاوني مع فريق عمل استثنائي، شعرت بثقافة بيئة العمل التي تقدَّر الأفكار والمساهمات. وأنا ممتنٌ للثقة والفرص التي منحت لي، وأتطلع إلى المستقبل مشرق مع استمرارشركة "تفاصيل" في تحقيق آفاق جديدة في مجال العمل.`,
          jobOpportunities: "فرص العمل",
          workForceManagementOfficer: "مسؤول إدرة القوى العاملة (WFM)",
          workForceManagementOfficerDescription:
            "يعمل على تلبية الأهداف الخدمية والإنتاجية والحفاظ على منصة إدارة القوى العاملة وتوقع أعباء العمل وتحليل الأداء من أجل تحسين العمل. كما يقوم بتنسيق التقارير وتطويرعمليات جدولة الوكلاء والمساعدة في التخطيط الاستراتيجي وتقديم التوصيات إلى الإدارة العليا.",
          ceoTitle: "مدير تنفيذي- المملكة العربية السعودية",
          ceoDescription:
            "تبحث شركة تفاصيل لخدمات التعهيد الدولية، وهي شركة تابعة لمجموعة تفاصيل لخدمات التعهيد، عن مدير تنفيذي يتمتع برؤية متميّزة لقيادة العمليات في المملكة العربية السعودية ودفع عجلة النمو والابتكار وتعزيز التميُّز وبناء الشراكات ورصد الاتجاهات السائدة، وضمان الامتثال بالمعايير وإدراة الشؤون المالية على أفضل نحو.",
          applyNow: "تقدَّم الآن",
          contactCentreManagerTitle: "مدير مركز اتصال",
          contactCentreManagerDescription:
            "يقوم بقيادة المديرين والتأكد من تحقيق الأهداف وتدريب الفرق والحفاظ على الامتثال بالمعايير واقتراح التحسينات العملية والتعاون مع الإدارات الأخرى وتحليل التقارير والتواصل مع العملاء وتحقيق أهداف المشروع. المتطلبات: شهادة بكالوريوس، خبرة من 7-10 سنوات (خمس في مجال تعهيد عمليات إدارة الأعمال) يتمتع بشخصية قيادية ويتحدَّث لغتين، ولديه معرفة في مجال الصناعة ويتمتع بمهارات في مجال التكنولوجيا ويحمل على الشهادات المفترضة.",
          seniorAccountantTitle: "كبيرالمحاسبين",
          seniorAccountantDescription:
            "يقوم بضمان إغلاق حسابات نهاية الشهر في الوقت المناسب، وإعداد البيانات المالية، ومراجعة النشاط المالي وتدقيق الحسابات وتسجيل المعاملات ومساعدة فريق الحسابات. يُشترط الحصول على شهادة CMA/CPA، وثلاث سنوات من الخبرة المحاسبية وإمكانية التواصل الممتاز باللغتين العربية والإنجليزية ومهارات استخدام الحاسوب.",
          ictSupportEngineerTitle: "مهندس دعم تكنولوجيا المعلومات الاتصالات",
          ictSupportEngineerDescription:
            "يقوم بتركيب وحدات الكمبيوتر وتخصيصها، وحل المشكلات الفنية للعملاء داخل الشركة والحفاظ على البنية التحتية لمركز البيانات وإدارة تراخيص البرامج واقتراح تحسينات الإجراءات. يُشترط الحصول على درجة علمية في مجال دراسة الحاسوب وإتقان اللغة الإنجليزية ومهارات تعدد المهام والإلمام ببرامج مايكروسوفت أوفيس وويندوز 10 واستخدم الاتصال الهاتفي عبر بروتوكول الإنترنت (IP) والربط الشبكي والأجهزة/البرمجيات.",
          networkSecurityEngineerTitle: "مهندس أمن الشبكات",
          networkSecurityEngineerDescription:
            "يقوم بالحفاظ على بنية الشبكات الواسعة والمحليّة (LAN وWAN)، وتنفيذ بروتوكولات الأمان، وحل المشكلات الأمنية وتتبع البرامج النصية الضعيفة والتأكُّد من الامتثال لسياسة نظم إدارة أمن المعلومات (ISMS). يُشترط أن يكون حاصلاً على درجة البكالوريوس في علوم الحاسبوب أو أي مجال ذي صلة، ولديه خبرة سابقة كمهندس أمن شبكات، وملماً بالبرمجة ويتمتع مهارات تحليلية قوية لاستكشاف الأخطاء وإصلاحها.",
          customerServiceRepresentativeTitle:
            "ممثل خدمة العملاء (من أصحاب الهمم)",
          customerServiceRepresentativeDescription:
            "يقوم بالتعامل مع المكالمات الواردة / الصادرة، والرد على رسائل البريد الإلكتروني / الدردشات، وحل شكاوى / استفسارات العملاء ورفع المشكلات لمرؤوسيه وتقديم معلومات عن الخدمة وتوثيق المكالمات وفقًا لإجراءات التشغيل الموحدة والمتابعة والاحتفاظ بسجلات المكالمات، وأن يتمتع الخبرة في مجال الصناعة المالية ويتحدَّث لغتين (العربية / والإنجليزي مستوى B2)",
          customerServiceRepresentativeRussianTitle:
            "ممثل خدمة العملاء (يتحدَّث اللغة الروسية)",
          customerServiceRepresentativeRussianDescription:
            "يقوم بالرد على المكالمات ورسائل البريد الإلكتروني والمحادثات، وحل الشكاوى/الاستفسارات ورفع المشكلات إلى مرؤوسيه وتقديم معلومات عن الخدمات، وتوثيق المكالمات وفقًا لإجراءات التشغيل الموحدة. إضافة إلى متابعة واستكمال سجلات المكالمات والتقارير. يشترط وجود خبرة كموظف في مركز الاتصال، والتحدّث باللغتين: العربية والروسية.",
          talentedWorkForce: "فريق<br></br> محترف",
          convenientLocation: "موقع<br>ملائم",
          highestQualityService: "جودة<br>خدمات<br>لا تضاهى",
          stringentProcess: "عمليات متناهية<br>الدقة",
          theBestPrice: "أنسب<br>الأسعار",
          advanceTech: "تقنيات<br>متطورة",
          excellence: "التفوق",
          integrity: "النزاهة",
          clientCentricApproach: "التركيز على خدمة العميل",
          peopleOriented: "موجهة نحو الناس",
          socialResponsibility: "المسؤولية<br/>الاجتماعية",
          accountability: "المسئولية",
          continuousLearning: "التعلم المستمر",
          transparency: "الشفافية",
          dataPrivacy: "خصوصية البيانات",
          processDescription:
            "يلعب قسم العمليات دورًا حاسمًا في ضمان تحقيق جميع الأهداف أو تجاوزها: تساعد أقسام العمليات عملائنا على تقديم خدمة عملاء عالية الجودة تتجاوز توقعات العملاء.",
          processTagLine: `"اعمل ما تبرع به وقم بتعهيد الباقي من الأعمال"`,
          peterDrucker: "-بيتر دروكر",
          performanceManagementTitle: "إدارة الأداء",
          performanceManagementDescription:
            "مراقبة أداء الموظف من خلال مقاييس مثل وقت التعامل مع المكالمة، ومتوسط سرعة الرد، ومعدلات الحل في المكالمة الأولى. تحديد مجالات التحسين وتدريب الموظفين لتحسين أدائهم.",
          workforceManagementTitle: "إدارة القوى العاملة",
          workforceManagementDescription:
            "ضمان توفر عدد كافٍ من الموظفين للتعامل مع حجم المكالمات، وضمان تدريب الموظفين بشكل مناسب وجدولتهم لتلبية طلبات العملاء.",
          technologyManagementTitle: "إدارة التكنولوجيا",
          technologyManagementDescription:
            "إدارة البنية التحتية التكنولوجية لمركز الاتصال، بما في ذلك أنظمة الهاتف، وبرامج إدارة علاقات العملاء (CRM)، وغيرها من الأدوات لتحسين الكفاءة ورضا العملاء.",
          processImprovementTitle: "تحسين العمليات",
          processImprovementDescription:
            "ضمان التعامل بشكل احترافي ومهذب، والتأكد من أن الموظفين يتبعون العمليات والبروتوكولات المعتمدة. قد يتطلب ذلك مراقبة المكالمات، وتقديم ملاحظات للموظفين، وإجراء جلسات تدريب منتظمة لتحسين الأداء.",
          qualityAssuranceTitle: "ضمان الجودة",
          qualityAssuranceDescription:
            "ضمان التعامل بشكل احترافي ومهذب، والتأكد من أن الموظفين يتبعون العمليات والبروتوكولات المعتمدة. قد يتطلب ذلك مراقبة المكالمات، وتقديم ملاحظات للموظفين، وإجراء جلسات تدريب منتظمة لتحسين الأداء.",
          process: "انجازات",
          highlights: "فريق العمليات",
          chatsHandled: "الدردشات المستلمة",
          paymentsProcessed: "الدفعات التي أدارتها الشركة نيابةً عن عملائها",
          fcrScore: "نسبة حل المشكلة من المرة الأولى%",
          cSatScore: "درجة رضا العملاء%",
          mktScore: "عدد اختبارات المعرفة الشهرية",
          qualityScore: "درجة الجودة%",
          serviceLevel: "مستوى الخدمة%",
          answeredRate: "معدل الإجابة%",
          abandonedRate: "معدل قطع الاتصال%",
          utilizationRate: "معدل انشغال موظف مركز الاتصال",
          privateSector: "القطاع الخاص",
          governmentEntities: "الجهات الحكومية",
          managedServiceProjects: "عدد المشاريع التي نديرها",
          aboutMaryamAlNuaimi:
            "السيدة مريم رائدة إماراتية، رائدة أعمال في مجالات عدّة، وهي من أسست مجموعة تفاصيل لخدمات التعهيد. وهي ريادية في قطاع مراكز الاتصال في الشرق الأوسط، ونالت العديد من الجوائز والتقديرات. السيدة مريم تكرّس نفسها لخلق أثر اجتماعي بارز وتمكين المرأة وتعزيز التضامن الوطني. وهي قائدة ملهمة تقود شركة تفاصيل ببصيرة نافذة في مسيرة النجاح والازدهار في مختلف القطاعات.",
          aboutAhmedKhbeer:
            "في مجال القيادة الديناميكية، يمثل أحمد خبير خير مثال لامتلاك إمكانيات لا محدودة. فقد أوصلت مسيرته الملهمة أكبر شركة لتعهيد الأعمال في الإمارات إلى نجاحات غير مسبوقة، فقد ساهم في إنماء فريق تفاصيل من 14 موظفًا إلى 1,000 موظف، كما شارك في تأسيس مشاريع مزدهرة مثل منصّة رنّة (Rannah.ae) وتقنية المعلومات من تفاصيل ومشروع نماء للاستثمار الزراعي، وقدم استشارات لحكومتي دولة الإمارات والمملكة العربية السعودية. يراعي أحمد ثقافة القيم، مما يجعل كل عضو في الفريق شريكاً أساسياً في نجاحنا. الهدف الجريء الذي يسعى إليه أحمد هو إدراج شركة تفاصيل لخدمات التعهيد في بورصة دبي بحلول عام 2025. إصراره الراسخ وقيادته الملهمة يجعلانه القوة الدافعة لنا في رحلتنا نحو التميز.",
          ictDirector: "مدير تقنية المعلومات والتواصل",
          mohammedJamil: "محمد جميل",
          financeDirector: "مدير الشؤون المالية",
          aboutMohammedJamil:
            "بخبرة تزيد عن 9 سنوات في مجالات تدقيق الحسابات والمحاسبة في قطاعات التصنيع والاستيراد والتصدير والصناعات الخدمية، يتفوق محمد في تطوير عناصر التحكم المالي وتبسيط الإجراءات المالية والوصول بدقة المحاسبة إلى أوجها. وهو ضليع في استخدام تطبيقات أوراكل للإدارة المالية و (إي أر بي) أودو وكويك بوكس وتالي (إي آر بي)9 وزوهو بوكس ومايكروسوفت أوفيس.",
          aymanFadl: "أيمن فضل",
          operationsAndSupportFunctionDirector: "مدير العمليات ووظائف الدعم",
          aboutAyman:
            "مدير عمليات مركز الاتصال بخبرة تزيد عن عشرة سنوات في دولة الإمارات، وهو خبير في مجال تعهيد عمليات الأعمال وخدمات مراكز الاتصال وضمان الجودة والتدريب، وهو يركز على التطور وضمان رضا العملاء ومؤشرات الأداء الرئيسية.",
          muhanadElRashied: "مهند الرشيد",
          humanCapitalDirector: "مدير الموارد البشرية",
          aboutMuhanadElRashied:
            "بصفته مديرًا استراتيجيًا للموارد البشرية، يتمتع مهند بخبرة كبيرة تزيد عن 8 سنوات في مجال إدارة الموارد البشرية في مختلف مجالات تعهيد الأعمال (مراكز الاتصال) الحكومية والخاصة، وبالارتكاز لمسيرته الناجحة في إدارة الموارد البشرية، فإنه يتبع أفضل معايير العمل وأساليبه الرائجة.",
          contactCenterOperationsManager: "مديرة عمليات مركز الاتصال",
          aboutLanaHashim:
            "مديرة عمليات ذات خبرة كبيرة ومهارة عالية في قيادة الفرق وإدارة علاقات العملاء وتدريب الموظفين، وتمتد مسيرتها مع شركة تفاصيل لأكثر من عقد من الزمن، بدأتها كوكيلة في مركز الاتصال.",
          purnimaKrishnan: "بورنيما كريشنان",
          riskAndComplianceSpecialist:
            "أخصائية في مجال إدارة المخاطر والامتثال",
          aboutPurnimaKrishnan:
            "محترفة في مجال الحوكمة وإدارة المخاطر والامتثال بخبرة تزيد عن 8 سنوات في مجالي الموارد البشرية والحوكمة. وقد عملت في دبي والعين وعجمان (الإمارات)، ومومباي/ ديلهي (الهند). وهي خبيرة في إدارة التقدم الاستراتيجي والموارد البشرية والتوظيف والمهارات الإدارية، وهي تساهم في نمو الشركة وخلق بيئات العمل الإيجابية.",
          mahaMahram: "مها محرم",
          projectManagementOfficer: "مسؤولة إدارة المشاريع",
          aboutMahaMahram:
            "تتحلى مها بروح الفريق والتعاون، وتتمتع بخبرة تزيد عن 6 سنوات في الإمارات، وهي تجيد تحمل ضغط العمل وملتزمة بروح الفريق والتعاون والعزم. تساهم مها بفعالية في أي مشروع، وتخلق جواً إيجابياً وتدعم زملاءها. وقد بدأت مسيرتها المزدهرة كخريجة جديدة عملت في تفاصيل كوكيلة في مركز الاتصال، وأصبحت الآن مسؤولة إدارة المشاريع مع حصولها على العديد من الشهادات.",
          emadMohammed: "عماد محمد",
          businessDevelopmentManager: "مدير تطوير الأعمال التجارية",
          aboutEmadMohammed:
            "محترف يسعى لتحقيق النتائج المبهرة، ويتمتع بخبرة تزيد عن 15 عامًا في تطوير الأعمال وإدارة علاقات العملاء وتحسين تجربة العملاء والعمليات وقطاع التجزئة وإدارة الحسابات ونجاح العملاء، وحاز خبرته بالعمل في الإمارات ومصر.",
          sage: "ساج",
          marketingManager: "مدير التسويق",
          aboutSage:
            "بخبرة تزيد عن 12 عامًا كمدير تسويق، منها 8 سنوات في دبي. ساج ماهر في تحسين محركات البحث وإدارة حسابات التواصل الاجتماعي والعلاقات العامة والدفع مقابل النقر وتطوير الويب والعمل ضمن الفرق وقيادتها. وقد عمل في الإمارات والهند والمملكة المتحدة. ويسعى لتحقيق النتائج، وهو ملمٌّ بتوجهات العمل ولديه القدرة على التكيف مع مختلف أنواع الحملات.",
          abdelRahmanAlSage: "عبد الرحمن السقّا",
          operationsSupervisor: "مشرف عمليات",
          aboutAbdelRahmanAlSage:
            "محترف مخضرم بخبرة تزيد عن 8 سنوات في مصر. وهو خبير في التدريب وإدارة المشاريع والقيادة والتواصل والتفكير الناقد وحل المشكلات وتخطيط الأعمال. ويضيف معرفةً كبيرة ومهارات قيمة للفريق بالأعمال.",
          adelAlaydi: "عادل العايدي",
          ictAssistantManager: "مساعد مدير تقنية المعلومات والتواصل",
          aboutAdelAlaydi:
            "خبير شغوف بالتكنولوجيا والدعم منذ عام 2005. من مواليد الإمارات وذو خبرة عمل في دبي والشارقة وعجمان. ويؤمن بتحفيز الآخرين والقيادة بمبدأ القدوة.",
          alyMahmoud: "علي محمود",
          hrAssistantManager: "مساعد مدير الموارد البشرية",
          aboutAlyMahmoud:
            "مساعد مدير الموارد البشرية بخبرة تزيد عن 5 سنوات في الإمارات. ماهر في إجراءات التوظيف والتعاقد والتدريب، وعلاقات الموظفين. محترف في التواصل الوظيفي ويبني علاقات قوية مع أعضاء الفريق.",
          ahmedBassam: "أحمد بسام",
          assistantManagerOps: "مساعد مدير العمليات",
          aboutAhmedBassam:
            "خبير متميز في خدمة العملاء وإدارة مراكز الاتصال والقيادة وإدارة الفريق بخبرة تزيد عن 7 سنوات في الإمارات. وهو ماهر في إدارة الجودة والتدريب وتحسين العمليات وتحليل البيانات.",
          mahmoudBadar: "محمود بدر",
          projectManager: "مدير مشروع",
          aboutMahmoudBadar:
            'مدير مركز اتصال يتحلى بالعزيمة والمسؤولية، أحرز النجاح لأكثر من 10 سنوات في مجموعات دولية ومحلية مختلفة، وهو ماهر في تعزيز الكفاءة وتبسيط الإجراءات وتحقيق النجاح على المدى الطويل. يقود حاليًا عمليات مركز الاتصال في شركة "الفطيم للهندسة والتقنيات".',
          muradAlBloushi: "مراد البلوشي",
          humanCapitalGeneralist: "اختصاصي موارد بشرية",
          aboutMuradAlBloushi:
            "مساعد مدير الموارد البشرية بخبرة 3 سنوات في إدارة الموارد البشرية و5 سنوات في قطاع تعهيد الأعمال في الإمارات. وهو خبير في العلاقات العامة والعلاقات الإدارية، كما يسهم في تطوير السياسات وتحليل البيانات وأعمال الموارد البشرية. يحمل روح الفريق ويمتلك مهارات تواصل كبيرة.",
          yaghoobAlJanahi: "يعقوب الجناحي",
          pro: "مسؤول العلاقات العامة",
          aboutYaghoobAlJanahi:
            "أخصائي في العلاقات العامة والعلاقات الإدارية بخبرة تزيد عن 5 سنوات في الإمارات. ويدير شؤون التواصل والمواعيد وعلاقات العملاء بكفاءة، ويتفانى بتقديم الخدمات الممتازة وضمان الامتثال.",
          mohsenEshag: "محسن إسحاق",
          operationsCoordinator: "منسق العمليات",
          aboutMohsenEshag:
            "منسق عمليات بخبرة تزيد عن 12 عاماً في السودان والإمارات، وخبير في تنسيق الفرق والمهام الإدارية، ويتفانى بضمان سير العمل بكفاءة وبالمساهمة في تحقيق النجاح.",
          ahmedAlmansy: "أحمد المنسي",
          assistantManager: "مساعد مدير - الجودة والتدريب",
          aboutAhmedAlmansy:
            "خبير متميز في خدمة العملاء وإدارة مراكز الاتصال والقيادة وإدارة الفرق بخبرة تزيد عن 7 سنوات في الإمارات. وهو ماهر في إدارة الجودة والتدريب وتحسين العمليات وتحليل البيانات.",
          pressRelease: "أخبار تفاصيل",
          blogs: "المدونات",
          videos: "الفيديوهات",
          tafaseelTalks: "محاضرات تفاصيل",
          mediaAndCommunicationSolutions: "حلول الإعلام والتواصل",
          industrySolutions: "حلول قطاع الإعلام والتواصل",
          industrySolutionsDescription:
            "نحن ندرك التحديات والفرص الماثلة في قطاع الإعلام والتواصل. تم تصميم حلولنا الشاملة لدعم نجاحك في هذا السياق الرقمي سريع التطور، حيث نقدم مجموعة من الخدمات التي تسمح لشركتك البقاء في صدارة المنافسة، بما في ذلك:",
          ourExpertise: "خبراتنا",
          ourExpertiseDescription:
            "يتمتع فريق المحترفين لدينا بخبرات فائقة في قطاع الإعلام والتواصل. كما نتميز بتقديم الخدمات التالية",
          contentCreationAndManagement:
            "كتابة المحتوى وإدارته عبر منصات التواصل الاجتماعي المتنوعة.",
          socialMediaManagement:
            "إدارة حسابات التواصل الاجتماعي وتحقيق المشاركة المجتمعية",
          dataAnalyticsAndInsights:
            "تحليل البيانات والرؤى التي تؤدي للاستهداف الفعال للجمهور.",
          customerSupportAndRelationshipManagement:
            "دعم العملاء وإدارة العلاقات معهم",
          exploreOurRangeOfServices:
            "استكشف باقة الخدمات المتنوعة المصممة خصيصاً لقطاع الإعلام والتواصل، حيث نقدم.",
          weOffer: "الخدمات التالية:",
          moderationAndQualityControl: "الإدارة وضبط الجودة",
          digitalMarketingServices: "خدمات التسويق الإلكتروني",
          customerSupportAndRelationManagement:
            "دعم العملاء وإدارة العلاقات معهم",
          contentCreationAndManagement1: "كتابة المحتوى وإدارته  ",
          dataAnalyticsInsights: "التحليلات والرؤى المتعلقة بالبيانات",
          automotiveOutsourcingSolutions: "حلول تعهيد السيارات ",
          industrySpecificSolutions: "حلول مخصصة لقطاع السيارات والمركبات",
          industrySpecificSolutionsDescription:
            "نحن ندرك تماماً الطبيعة ذات الوتيرة السريعة والتنافسية لقطاع السيارات، لذا فقد صممنا مجموعة حلولنا الشاملة لدعم نجاحك. تشارك معنا للحصول على خدمات تعهيد فعّالة واقتصادية من حيث التكلفة، والمصممة خصيصًا لتناسب شركات السيارات.",
          ourAutomotiveExpertise: "خبراتنا في مجال السيارات",
          ourAutomotiveExpertiseDescription:
            "فريقنا الذي يتمتع بسنوات من الخبرة في قطاع السيارات مجهّز ومستعدّ بالكامل لتولي مجموعة واسعة من المهام والعمليات. نقدم باقةً من الخدمات المخصصة لتلبية الاحتياجات الفريدة لشركات السيارات، بما في ذلك:",
          customerSupportAndSalesAssistance:
            "دعم العملاء والمساعدة في مبيعات منتجات السيارات والخدمات المتعلقة بها",
          dataManagementAndAnalytics:
            "إدارة البيانات وإجراء تحليلات المخزون والمبيعات والرؤى المتعلقة بالعملاء",
          backOfficeOperations:
            "العمليات المكتبية، بما في ذلك الشؤون المالية والمحاسبة والمهام الإدارية",
          leadGenerationAndAppointmentSetting:
            "الوصول للعملاء المحتملين وتحديد المواعيد لوكلاء البيع ومراكز الخدمة",
          comprehensiveAutomotiveSolutions: "حلول سيارات شاملة",
          retailSolutionsForSuccess: "حلول تجارة التجزئة لتحقيق النجاح",
          retailIndustrySolutions: "الحلول المقدَّمة في قطاع تجارة التجزئة",
          retailIndustrySolutionsDescription:
            "في صناعة التجزئة المتطورة باستمرار وذات الطبيعة التنافسية، تواجه الشركات تحديات فريدة من نوعها. لذلك نحن في شركة تفاصيل لخدمات التعهيد، ندرك هذه التحديات ونقدم مجموعة واسعة من الحلول للتغلب عليها. نحن نتعاون مع متاجر التجزئة وشركات التجارة الإلكترونية وتجار التجزئة عبر القنوات المتعددة لتقديم خدمات تعهيد فعّالة واقتصادية من حيث التكلفة.",
          ourExpertiseInRetailIndustry: "خبرتنا في قطاع تجارة التجزئة",
          ourExpertiseInRetailIndustryDescription:
            "نظراً لأن فريقنا المحترف يتمتع بسنوات من الخبرة في قطاع البيع بالتجزئة، فقد أصبح مؤهلاً تأهيلاً جيداً للتعامل مع مجموعة متنوعة من المهام والعمليات، بدءًا من دعم العملاء وإدارة الطلبات إلى إدارة المخزون وتحليلات البيانات، لذلك نحن نسعى إلى تقديم مجموعة من الخدمات التي تهدف إلى تعزيز الكفاءة التشغيلية وتحسين رضا العملاء ودفع عجلة النمو في مجال",
          customerSupportAndInquiryManagement:
            "دعم العملاء وإدارة الاستعلامات لشركات البيع بالتجزئة",
          orderProcessingAndManagement:
            "تجهيز الطلبات وإدارتها لقنوات البيع المتصلة وغير المتصلة عبر الإنترنت",
          inventoryManagementAndOptimization:
            "إدارة المخزون واستخدامه على النحو الأمثل لإنجاز عمليات سلسلة التوريد بكفاءة",
          dataAnalyticsAndInsightsForSales:
            "إجراء تحليلات البيانات والرؤى لتوقع بالمبيعات وتحليل الأداء",
          customerSupportAndInquiryManagementTitle:
            "دعم العملاء وإدارة الاستعلامات",
          orderProcessingAndManagementTitle: "تجهيز الطلبات وإدارتها",
          streamlinedHealthcareSolutions:
            "الحلول المبسطة لتقديم الرعاية الصحية",
          healthcareIndustrySolutions: "الحلول المقدمة لقطاع الرعاية الصحية",
          healthcareIndustrySolutionsDescription:
            "تشهد المنظمات تحديات متنوعة في قطاع الرعاية الصحية المتسم بسرعة تطوره. لذلك نسعى نحن شركة تفاصيل لخدمات التعهيد إلى تقديم مجموعة واسعة من الحلول لمواجهة هذه التحديات، من خلال تعاوننا مع المستشفيات والعيادات والشركات الطبية ومقدمي الرعاية الصحية لتقديم خدمات تعهيد تتسم بالكفاءة والتكلفة المجدية.",
          ourExpertiseInHealthcare: "خبرتنا في قطاع الرعاية الصحية",
          ourExpertiseInHealthcareDescription:
            "نتفرَّد بفريق من المحترفين يتمتع بخبرة واسعة وطويلة في مجال الرعاية الصحية، ويمتلك الكفاءة اللازمة لإنجاز جميع المهام والعمليات بالغة الأهمية. لذلك يسعدنا أن نقدم مجموعة شاملة من الخدمات المصممة لتعزيز الكفاءة التشغيلية ورعاية المرضى:",
          patientSupportAndAppointment: "دعم المرضى وجدولة المواعيد",
          medicalBillingAndClaimsManagement:
            "تحصيل الفواتير الطبية وإدارة المطالبات",
          patientSupportAndAppointmentTitle: "دعم المرضى وجدولة المواعيد",
          medicalBillingAndClaimsManagementTitle:
            "تحصيل الفواتير الطبية وإدارة المطالبات",
          medicalTranscriptionAndDocumentation: "خدمات النسخ والتوثيق الطبي",
          dataManagementAndAnalyticsTitle: "إدارة البيانات والتحليلات",
          revenueCycleManagement: "إدارة دورة العوائد",
          medicalCodingAndCompliance: "الترميز الطبي والامتثال",
          foodAndBeveragesOutsourcingSolutions: "حلول تعهيد الأغذية والمشروبات",
          industriesWeCaterTo: "القطاعات التي نلبي احتياجاتها",
          foodAndBeveragesOutsourcingSolutionsDescription:
            "في قطاع الأغذية والمشروبات المتسم ببيئة تنافسية تتطور باستمرار، تواجه الشركات تحديات استثنائيَّة. ونحن في شركة تفاصيل لتعهيد إدارة الأعمال التجارية ندرك هذه التحديات ونقدِّم طيفاً واسعاً من حلول التعهيد لمساعدة الشركات في مواجهتها، من خلال تعاوننا مع مجموعة من المطاعم والمقاهي وخدمات توصيل الطعام وشركات تقديم الطعام وغيرها من الجهات لتقديم خدمات تتسم بالكفاءة والتكلفة المنخفضة.",
          ourExpertiseInFoodAndBeverages:
            "خبرتنا في مجال تعهيد الأغذية والمشروبات",
          ourExpertiseInFoodAndBeveragesDescription:
            "  يتميّز فريقنا  بخبرته الواسعة التي امتدت لسنوات من العمل في قطاع الأغذية والمشروبات، مما  جعله يمتلك الكفاءة اللازمة للتعامل مع مجموعة متنوعة من المهام والعمليات. لذلك نفخر بتقديم  مجموعة شاملة من الخدمات المصممة لتعزيز الكفاءة التشغيلية وتحسين رضا العملاء ودفع عجلة النمو في قطاع الأغذية والمشروبات.",
          menuPlanningAndDevelopment:
            "تنظيم قوائم الطعام وتطويرها لتشمل عروض الطهي المتنوعة",
          orderManagementAndFulfillment:
            "إدارة الطلبات وتلبيتها لضمان تبسيط العمليات",
          qualityControlAndCompliance:
            "ضبط الجودة والامتثال بالحفاظ على معايير سلامة الأغذية العالية",
          customerSupportAndEngagement:
            "دعم العملاء ومشاركتهم في تجارب تناول الطعام استثنائية",
          industriesWeServe: "القطاعات التي نقوم بخدمتها",
          exploreOurRangeOfServicesFoodAndBeverages:
            "استكشف مجموعة الخدمات التي نقدمها خصيصًا لقطاع الأغذية والمشروبات،",
          weProvideSolutionsTo: "كما نفخر بتقديم  حلول لما يلي:",
          menuPlanningAndDevelopmentTitle: "تنظيم قوائم الطعام وتطويرها",
          orderManagementAndFulfillmentTitle: "إدارة الطلبات وتلبيتها",
          customerSupportAndEngagementTitle: "دعم العملاء وتفاعلهم",
          qualityControlAndComplianceTitle: "مراقبة الجودة والامتثال",
          travelAndToursOutsourcingSolutions: "حلول تعهيد السفر والرحلات",
          industrySpecificSolutionsTitle: "حلول مخصصة لقطاع السفر والرحلات",
          travelAndToursOutsourcingSolutionsDescription:
            "في عالم قطاع السفر والرحلات المفعم بالنشاط والتنافس، تواجه الشركات تحديات فريدة من نوعها. ونحن في شركة تفاصيل لخدمات التعهيد نعي التحديات التي تواجهها الشركات ونقدم مجموعة واسعة من حلول التعهيد لمواجهتها، إذ نتعاون مع وكالات السفر ومنظمي الرحلات السياحية وشركات إدارة الرحلات ومقدمي خدمات النقل لتقديم خدمات تتسم بالكفاءة والتكلفة المنخفضة.",
          ourExpertiseInTravelTours: "خبرتنا في قطاع السفر والرحلات",
          ourExpertiseInTravelToursDescription:
            "نظراً لخبرتنا الطويلة في قطاع السفر والرحلات، يتميَّز فريقنا المحترف في التعامل مع المهام والعمليات بالغة الأهميَّة. إذ نقدم مجموعة شاملة من الخدمات المصممة لتعزيز الكفاءة التشغيلية وتحسين رضا العملاء ودفع عجلة النمو:",
          itineraryPlanningAndCustomization:
            "تخطيط الرحلات وتخصيصها لتقديم تجارب سفر لا تُنسى",
          reservationManagementAndCoordinationForFlights:
            "إدارة الحجوزات والتنسيق الرحلات الجوية والإقامة والأنشطة",
          customerSupportAndAssistance: "دعم العملاء ومساعدتهم طيلة رحلة السفر",
          destinationResearchAndRecommendations:
            "البحث عن وجهات جديدة وتقديم التوصيات لتجارب السفر ذات طابع شخصي",
          itineraryPlanningAndCustomizationTitle: "تخطيط و تخصيص الجدول الزمني",
          reservationManagementAndCoordinationTitle: "إدارة وتنسيق الحجوزات",
          customerSupportAndAssistanceTitle: "دعم ومساعدة العملاء",
          destinationResearchAndRecommendationsTitle: "بحث واقتراح وجهات السفر",
          tourGuideServices: "خدمات الدليل السياحي",
          transportationLogistics: "خدمات النقل",
          productListingAndCatalog:
            " إدراج المنتجات وإدارة دليل السلع (الكاتالوج) للحصول على عروض تتسم بالدقة والجاذبية عبر الإنترنت",
          inventoryManagement:
            "إدارة المخزون واستخدامه على النحو الأمثل لتسهيل عمليات سلسلة التوريد",
          orderProcessingAndFulfillment:
            "معالجة الطلبات وتنفيذها لضمان تسليمها بكفاءة وفي الوقت المناسب",
          customerSupportAndInquiry:
            "دعم العملاء وإدارة الاستفسارات لتقديم تجارب تسوُّق استثنائية عبر الإنترنت",
          ourExpertiseInECommerce: "خبرتنا في مجال التجارة الإلكترونية",
          ourExpertiseInECommerceDescription:
            "نظراً لخبرتنا التي دامت لسنوات في مجال التجارة الإلكترونية تتفرّد شركتنا بفريق من المحترفين الذين يمتلكون الخبرة اللازمة للتعامل مع طيف  متنوِّع من المهام والعمليات. بدءًا من إعداد قوائم المنتجات وإدارة المخزون إلى معالجة الطلبات ودعم العملاء، ونحن نفخر بنقديم مجموعة من الخدمات التي تهدف إلى تعزيز الكفاءة التشغيلية وتحسين رضا العملاء ودفع عجلة النمو في قطاع التجارة الإلكترونية.",
          eCommerceIndustrySolution: "حلول قطاع التجارة الإلكترونية ",
          eCommerceIndustrySolutionDescription:
            "في ظلِّ التطورات المتسارعة والبيئة التنافسية التي تسود عالم التجارة الإلكترونية ، تواجه الشركات اليوم تحديات فريدة من نوعها.ونظراً لأننا في شركة تفاصيل لخدمات التعهيد ندرك هذه التحديَّات فإننا نفخر بتقديم مجموعة واسعة من الحلول لمواجهتها، من خلال الجهود التي نبذلها في التعاون مع تجار التجزئة عبر الإنترنت والأسواق وشركات التجارة الإلكترونية الناشئة لتوفير خدمات التعهيد التي تتسم بتحقيق بالكفاءة والجدوى الاقتصاديّة.",
          eCommerceSolutionsForOnlineSuccess:
            "حلول التجارة الإلكترونية لتحقيق النجاح عبر الإنترنت",
          productListingAndCatalogManagementTitle:
            "إدراج المنتجات وإدارة دليل السلع",
          orderProcessingAndFulfillmentTitle: "معالجة الطلبات وتنفيذها ",
          eCommerceWebsiteDevelopment: "تطوير مواقع التجارة الالكترونية",
          inventoryManagementAndOptimizationTitle:
            "إدارة المخزون واستخدامه على النحو الأمثل",
          digitalMarketingAndAdvertising: "التسويق الرقمي والدعاية",
          governmentAndPublicServiceSolutions:
            "الحلول المقدمة في قطاع الخدمات الحكومية والعامة",
          governmentAndPublicServiceSolutionsDescription:
            "في ظلِّ البيئة المعقدة والمتطورة باستمرار لقطاع الخدمات الحكومية والعامة، تواجه المنظمات تحديات استثنائيّة. ونظراً لوعينا في شركة تفاصيل لخدمات التعهيد وإداركنا لتلك التحديات فإننا نقدِّم طيفاً واسعاً من الحلول للتصدي لها، من خلال تعاوننا مع الهيئات الحكومية وإدارات الخدمات العامة والمنظمات المدنية لتقديم خدمات التعهيد بكل كفاءة وموثوقية.",
          ourExpertiseInGovernmentAndPublicService:
            "خبرتنا في مجال الخدمات الحكومية والعامة",
          ourExpertiseInGovernmentAndPublicServiceDescription:
            " بفضل خبرتنا التي دامت لسنوات في العمل مع الجهات الحكومية وهيئات الخدمة العامة، فإن فريقنا من المحترفين بات مؤهلاً تأهيلاً جيداً للقيام بمجموعة متنوِّعة من المهام والعمليات. ونحن نقدم طائفة من الخدمات بدءًا من إدارة البيانات ودعم المواطنين إلى الخدمات الإدارية ومعالجة المستندات، التي تهدف إلى لتعزيز الكفاءة التشغيلية وتحسين تجارب المواطنين وتعزيز الحوكمة الفعَّالة.",
          dataManagementAndAnalyticsForInformedDecisionMaking:
            "إدارة البيانات وتحليلاتها لاتخاذ قرارات مدروسة وصياغة السياسات الحكيمة",
          citizenSupportAndEngagement:
            "دعم المواطنين ومشاركتهم من خلال تقديم خطوط المساعدة والبوابات الإلكترونية وخدمات البريد الإلكتروني",
          administrativeServices:
            "تقديم الخدمات الإدارية، بما في ذلك معالجة الوثائق وحفظ السجلات ونشر المعلومات",
          researchAndReportingToSupport:
            "القيام بالبحث وإعداد التقارير لدعم اتخاذ القرارات القائمة على الأدلة وتقييم الأداء",
          citizenSupportAndEngagementTitle: "دعم ومشاركة المواطنين ",
          administrativeServicesTitle: "خدمات إدارية",
          researchAndReporting: "البحوث والتقارير",
          digitalTransformationSolutions: "حلول التحول الرقمي",
          projectManagementConsultancy: "استشارات وإدارة المشاريع",
          realEstateTitle: "حلول وإدارة العقارات والمرافق",
          realEstateDescription:
            "في ظلّ البيئة التنافسيَّة والنشيطة لقطاع إدراة العقارات والمرافق تواجه المؤسسات تحديَّا استثنائيَّة. وبما أننا في شركة تفاصيل لخدمات التعهيد ندرك حجم هذه التحديات فقد عملنا على تقديم طيف واسع من الحلول لمواجهتها، وذلك بالتعاون مع المطوِّرين العقاريين وشركات إدارة العقارات ومقدمي خدمات إدارة المرافق وأصحاب المباني لتقديم خدمات تعهيد تتسم بالكفاءة والموثوقية.  ",
          realEstateExpertiseTitle: "خبرتنا في مجال إدارة العقارات والمرافق",
          realEstateExpertiseDescription:
            "بعد سنوات من الخبرة في العمل مع وحدات إدارة العقارات والمرافق، أصبح فريقنا المحترف مؤهلاً تأهيلاً جيداً للتعامل مع مجموعة متنوعة من المهام والعمليات. بدءًا من تسويق العقارات وإدارة شؤون المستأجرين إلى تخطيط علمليات الصيانة وخدمات الاستدامة، إذ نقدِّم مجموعة من الخدمات التي تهدف إلى تعزيز الكفاءة التشغيلية وتحسين تجارب المستأجرين وتحقيق الأداء الأمثل في مجال العقارات.",
          propertyMarketingAndLeasing:
            "خدمات تسويق وتأجير العقارات لاستقطاب المستأجرين الجدد والحفاظ على المسـتأجرين الحاليين",
          talentManagementAndCustomerSupport:
            "إدارة شؤون المستأجرين ودعم العملاء لضمان حصولهم على تجارب تتسم بالسهولة والسلاسة",
          maintenancePlanningAndExecution:
            "تخطيط عمليات الصيانة وتنفيذها لضمان اشتغال العقارات على أكمل وجه",
          sustainabilityServices:
            "خدمات الاستدامة لتعزيز كفاءة الطاقة والأداء البيئي",
          propertyMarketingAndLeasingTitle: "خدمات تسويق وتأجير العقارات",
          tenantManagementAnCustomerSupportTitle:
            "إدارة شؤون المستأجرين ودعم العملاء",
          maintenancePlanningAndExecutionTitle: "تخطيط عمليات الصيانة وتنفيذها",
          sustainabilityServicesTitle: "خدمات الاستدامة",
          facilityManagementSolutions: "حلول إدارة المرافق",
          spacePlanningAndOptimization: "تخطيط المساحة وتحسينها",
          consumerGoodsTitle:
            "تقديم الحلول في مجال السلع الاستهلاكية لتحقيق النجاح",
          consumerGoodsIndustrySolutions:
            "الحلول المقدَّمة في مجال السلع الاستهلاكية ",
          consumerGoodsIndustryDescription:
            "تواجه الشركات اليوم تحديات اسثنائيَّة في مجال صناعة السلع الاستهلاكيَّة الشديد التنافس والمتغير باستمرار، ونحن في شركة تفاصيل لخدمات التعهيد نتفهم هذه التحديات ونقدِّم مجموعة واسعة من الحلول لمواجهتها. إذ نتعاون مع المصنعين والموزِّعين وتجار التجزئة في قطاع السلع الاستهلاكيَّة لتقديم خدمات تعهيد تتسم بالكفاءة والتكلفة المنخفضة.",
          consumerGoodsExpertise: "خبرتنا في مجال صناعة السلع الاستهلاكية",
          consumerGoodsExpertiseDescription:
            "نتيجة تراكم سنوات من الخبرة في مجال صناعة السلع الاستهلاكية أصبح فريقنا مؤهل تأهيلاً جيداً للتعامل مع مجموعة متنوعة من المهام والعلميات. إذ نقدِّم مجموعة من الخدمات التي تهدف إلى تعزيز الكفاءة التشغيلية وتحسين رضا العملاء، بدءاً من إدارة سلسلة التوريد وتحسين المخزون إلى إدارة بيانات المنتجات ودعم العملاء. ",
          supplyChainManagement:
            "إدارة سلسلة التوريد والخدمات اللوجستية لتبسيط العمليات",
          inventoryOptimization:
            "تحسين المخزون والتنبؤ بالطلب لإدارة المخزون بكفاءة",
          productDataManagement:
            "إدارة بيانات المنتج وإنشاء المحتوى للحصول على معلومات دقيقة وجذابة عن المنتج",
          customerSupport:
            "دعم العملاء وإدارة الطلبات لحصول العملاء على تجارب استثنائية",
          customerSupportTitle: "دعم العملاء وإدارة الطلبات",
          supplyChainManagementTitle: "إدارة الإمدادات",
          inventoryOptimizationTitle: "تحسين المخزون والتنبؤ بالطلب",
          productDataManagementTitle: "إدارة بيانات المنتج وإنشاء المحتوى",
          ecommerceSolutions: "حلول التجارة الالكترونية للسلع الاستهلاكية",
          qualityAssurance: "ضمان الجودة والامتثال",
          logisticsTitle: "الحلول اللوجستية الفعالة والنقل من تفاصيل",
          logisticsAndTransportationTitle: "حلول الخدمات اللوجستية والنقل",
          logisticsIndustrySolutionsTitle:
            "الحلول المقدَّمة في مجال الخدمات اللوجستية وقطاع النقل",
          logisticsIndustrySolutionsDescription:
            " في مجال الخدمات اللوجستية وقطاع النقل الذي يتقدَّم بوتيرة سريعة وديناميكية تواجه الشركات تحديات استثنائيَّة. ونحن في شركة تفاصيل لخدمات التعهيد نتفهم هذه التحديّات ونقدِّم مجموعة واسعة من الحلول لمواجهتها. إذ نتعاون مع شركات الخدمات اللوجستية ووكلاء الشحن ومقدمي خدمات النقل ومديري سلاسل التوريد لتقديم خدمات التعهيد الخارجي التي تتسم بالكفاءة والتكلفة المنخفضة.",
          logisticsExpertiseTitle:
            "خبرتنا في مجال الخدمات اللوجستية وقطاع النقل",
          logisticsExpertiseDescription:
            "نتيجة تراكم سنوات من الخبرة في مجال الخدمات اللوجستية والنقل، أصبح فريقنا من المحترفين مؤهلاً تأهيلاً جيدًا للتعامل مع مجموعة متنوعة من المهام والعمليات. بدءًا من اختيار الطرق المثلى وتخطيط الأحمال وحتى إدارة الشحن وتتبع حركة المركبات، نقدم مجموعة من الخدمات التي تهدف إلى تعزيز الكفاءة التشغيلية وتحسين رضا العملاء ودفع عجلة النمو في قطاع الخدمات اللوجستية والنقل.",
          routeOptimization:
            "اختيارالطرق المثلى وتخطيط الأحمال لتحقيق الكفاءة في عمليات النقل",
          freightManagement:
            "إدارة الشحن والتنسيق الخدمات اللوجستية لإنجاز عمليات سلسلة التوريد بسهولة وسلاسة",
          fleetTracking:
            "تتبُّع حركة المركبات ومراقبتها لرؤيتها في الوقت الفعلي وتحليل أدائها",
          supplyChainAnalytics:
            "تحليلات سلسلة التوريد والاستفادة المثلى منها لتحسين عملية صنع القرار وخفض التكاليف",
          routeOptimizationLoadPlanning: "تحسين الطريق وتخطيط الحمولة",
          freightManagementCoordination: "إدارة البضائع وتنسيق اللوجستيات",
          fleetTrackingMonitoring: "تتبع ومراقبة الأسطول",
          supplyChainAnalyticsOptimization: "تحليل وتحسين سلسلة التوريد",
          warehouseManagementSolutions: "حلول إدارة المستودعات",
          lastMileDeliveryServices: "خدمات التوصيل الأخير",
          oilAndGasSolutionsDescription:
            "تواجه الشركات تحديات استثنائيَّة في مجال صناعة النفط والغاز الذي يتسم بالديناميكية والصعوبة، ونحن في شركة لخدمات التعهيد نتفهَّم هذه التحديات ونقدَّم مجموعة واسعة من الحلول لمواجهتها. إذ نتعاون مع شركات التنقيب والإنتاج ومقاولي الحفر والمصافي ومزوِّدي الخدمات لتقديم خدمات تعهيد خارجية تتسم بالكفاءة والتكلفة المنخفضة.",
          oilAndGasExpertiseTitle: "خبرتنا في مجال صناعة النفط والغاز",
          oilAndGasExpertiseDescription:
            " نعتمد على فريق من المحترفين يتمتعون بسنوات من الخبرة في مجال صناعة النفط والغاز، ما يجعلهم مؤهلين تأهيلاً جيداً للتعامل مع مجموعة متنوِّعة من المهام والعمليات بدءًا من إدارة البيانات وتحليلها إلى الامتثال لمعاييلا السلامة وضمان التشغيل الأمثل لسلسلة التوريد، إذ نقدِّم مجموعة من الخدمات التي تهدف إلى تعزيز الكفاءة التشغيلية وتحسين معايير السلامة ودفع عجلة النمو في قطاع النفط والغاز.",
          dataManagementAndAnalysis:
            "إدارة البيانات وتحليلها لاتخاذ قرارات مدروسة وتقييم الأداء",
          safetyComplianceAndRegulatorySupport:
            "الامتثال بمعايير السلامة وتقديم المساندة الرقابيَّة لضمان الالتزام بمقاييس الموحدة في مجال صناعة النفط والغاز",
          supplyChainOptimizationAndLogisticsManagement:
            "تحسين عمل سلسلة التوريد وإدارة الخدمات اللوجستية لتبسيط العمليات",
          documentControlAndInformation:
            "مراقبة الوثائق وإدارة المعلومات لحفظ السجلات بطريقة فعَّالة",
          dataManagementAndAnalysisTitle: "إدارة البيانات وتحليلها",
          safetyComplianceAndRegulatorySupportTitle:
            "الامتثال بمعايير السلامة وتقديم المساندة الرقابيَّة",
          supplyChainOptimization:
            "تحسين سلسلة التوريد وإدارة الخدمات اللوجستية",
          documentControlAndInformationTitle: "ضبط الوثائق وإدارة المعلومات",
          assetManagementSolutions: "حلول إدارة الأصول",
          healthSafetyEnvironment: "خدمات الصحة والسلامة والبيئة (HSE)",
          admissionsEnrollmentSupport: "دعم القبول والتسجيل",
          studentSupportServices: "خدمات دعم الطلاب",
          facultyStaffHelplines: "خطوط مساعدة أعضاء هيئة التدريس والموظفين",
          alumniEngagementSupport: "دعم التواصل مع الخريجين وجمع التبرعات",
          virtualClassroomSupport: "خدمات دعم الصف الافتراضي",
          onlineCourseRegistrationAssistance:
            "مساعدة في تسجيل الدورات الأونلاين",
          educationTitle: "التعليم",
          callCenterSolutionsTitle:
            "الحلول المقدَّمة لمراكز الاتصال التعليميَّة",
          callCenterSolutionsDescription:
            "في قطاع التعليم المتميِّز بحيويَّته ووتيرة تقدُّمه المتسارعة، تواجه المؤسسات صعوبة في إيجاد وسائل التواصل الفعالة مع الطلاب وأولياء الأمور وأعضاء هيئة التدريس والموظفين. ونحن في شركة تفاصيل لخدمات التعهيد ندرك هذه الصعوبات ونقدِّم طيفاً واسعاً من الحلول الخاصة بمراكز الاتصال لتذليلها. إذ نتعاون مع المدارس والكليات والجامعات ومنصات التعلُّم عبر الإنترنت لتقديم خدمات احترافية وموثوقة لمراكز الاتصال.",
          educationExpertiseTitle: "خبرتنا في مجال مراكز الاتصال التعليميَّة",
          educationExpertiseDescription:
            "يتمتع فريقنا المحترف بتأهيلٍ متميِّزيمكِّنه من إدارة مجموعة واسعة من المهام والعمليات نتيجة خبرته التي امتدت لسنوات في مجال تقديم خدمات مراكز الاتصال لقطاع التعليم. وتتنوَّع تلك المهام من استفسارات القبول والتسجيل إلى الدعم الطلاب والمساعدة الأكاديمية، فنحن نقدِّم مجموعة من الخدمات التي تهدف إلى تعزيز التواصل وتحسين الكفاءة وتوفير تجارب استثنائية لجميع المعنيين بالعمليَّة التعليمية.",
          admissionsSupport:
            "دعم علميات القبول والتسجيل لاستقطاب الطلاب المحتملين",
          studentSupport:
            "خدمات دعم الطلاب لتقديم الاستفسارات والمعلومات والمساعدة",
          facultySupport:
            "خطوط مساعدة لأعضاء هيئة التدريس والموظفين لتقديم الدعم الإداري والتنسيق",
          alumniSupport: "مشاركة الخريجين وجمع التبرعات لدعم النمو المؤسسي",
          whyTafaseelTitle: "نؤمن في تفاصيل أن الجودة العالية ",
          arabianBusiness: "قائمة أربيان بزنس لأفضل 30 شخصاً دون سن الأربعين",
          arabYouthCenter:
            'مركز الشباب العربي يعلن أسماء أعضاء الطبعة الثانية من "رواد الشباب العرب"',
          arabYouthCenterDescription:
            "أبوظبي، 11 أغسطس 2020 (وام) - أعلن المركز العربي للشباب، برئاسة سمو الشيخ ذياب بن محمد بن زايد آل نهيان، بالتزامن مع اليوم الدولي للشباب",
          readMore: "...قراءة المزيد",
          arabYouthPioneersTitle:
            "أعلن مركز الشباب العربي أعضاء الطبعة الثانية من 'رواد الشباب العرب'.",
          arabYouthPioneersDescription:
            "المركز العربي للشباب، برئاسة سمو الشيخ ذياب بن محمد بن زايد آل نهيان، كشف، بالتزامن مع اليوم الدولي للشباب، أعضاء الطبعة الثانية من مبادرة 'رواد الشباب العرب'.",
          websiteExpansionTitle:
            "Tafaseel BPO تكشف عن تحديث موقع الويب وتوسعتها في مصر كجزء من رؤيتها للاكتتاب العام الأولي لعام 2025.",
          websiteExpansionDescription:
            "Tafaseel BPO، أكبر شركة لتفويض عمليات الأعمال محلية التأسيس في الإمارات العربية المتحدة، تحقق خطوة ضخمة نحو رؤيتها لعام 2025 من خلال إطلاق موقع الويب المحسّن وافتتاح مكتب جديد في مصر.",
          expansionTitle:
            "Tafaseel BPO توسع نطاق عملها وتستعد للاكتتاب العام الأولي لعام 2025.",
          expansionDescription:
            'عجمان، الإمارات العربية المتحدة- مزود خدمات تفويض عمليات الأعمال "تفاصيل" أطلق مؤخرًا موقعه الإلكتروني المحدث وافتتح مكتبًا جديدًا في مصر، مع ترسيخ شركة "تفاصيل" مسار نموها استعدادًا لعرضها العام الأولي (IPO) في عام <num1>2025</num1>.',
          chamberPraisesTitle:
            "غرفة عجمان تشيد بنمو مشاريع رواد الأعمال الإماراتيين",
          chamberPraisesDescription:
            "عبدالله المويجعي رئيس مجلس إدارة غرفة عجمان يتحدث عن تقدم رواد الأعمال الإماراتيين",
          celebratingFutureLeaders: "الاحتفاء بقادة المستقبل في الشرق الأوسط",
          chamberEnhancesCommunicationTitle:
            "غرفة عجمان تعزز التواصل المباشر مع القطاع الخاص",
          chamberEnhancesCommunicationDescription:
            "زار وفد من غرفة تجارة وصناعة عجمان برئاسة عبدالله المويجعي رئيس مجلس الإدارة المقر الرئيسي لشركة تفاصيل.",
          chamberPraisesGrowthTitle:
            "غرفة عجمان تشيد بالنمو الملحوظ لمشاريع أصحاب الأعمال الإماراتيين",
          ajmanResourcesEventTitle:
            '"موارد عجمان" تنظم "اليوم المفتوح" للمقابلات الوظيفية الفورية',
          ajmanResourcesEventDescription:
            "نظمت دائرة الموارد البشرية لحكومة عجمان يوماً مفتوحاً للمقابلات الوظيفية الفورية لتوظيف المواطنين",
          callCentersTitle: "مراكز الاتصال مجاناً من تفاصيل",
          callCentersDescription:
            'م. أعلن أحمد خبير الرئيس التنفيذي لمجموعة شركات تفصيل المتخصصة في خدمات مراكز الاتصال وتقنية المعلومات عن تقديم خدماتها مجاناً للحكومة. وتهدف هذه المبادرة إلى دعم جهود الدولة في مكافحة انتشار فيروس "كورونا".',
          comingSoon: "ترقبوا ...",
          costEfficiencyTitle: "الجدوى من حيث التكلفة",
          costEfficiencyDescription:
            "يمكنك توفير تكاليف إعداد وإدارة مركز اتصال داخلي بشكل كبيرمن خلال تعهيد أعمال مركز الاتصال الخاص بك إلى شركة تفاصيل لخدمات التعهيد، إذ توفر حلولنا الاقتصادية من حيث التكلفة إمكانية الاستعانة بمحترفين ماهرين وتقنيات متطورة دون تحمل تكاليف إضافية.",
          omnichannelSupportTitle:
            "ادارة جميع قنوات التواصل الصوتية والغير صوتية",
          omnichannelSupportDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد الدعم عبر عدة قنوات اتصال، بما في ذلك الهاتف والبريد الإلكتروني والدردشة ووسائل التواصل الاجتماعي. يتم تدريب خبراء مركز الاتصال لدينا على الاستجابة لاستفسارات العملاء وطلباتهم وحل المشكلات وتقديم الدعم التقني وتقديم المساعدة عبر مختلف المنصات.",
          twentyFourSevenCustomerSupportTitle:
            "مراكز اتصال تعمل علي مدار العام",
          twentyFourSevenCustomerSupportDescription:
            "مع شركة تفاصيل لخدمات التعهيد، يمكنك توفير دعم العملاء على مدار الساعة. يعمل مركز الاتصال لدينا على مدار الساعة، مما يضمن أن يتلقى عملاؤك المساعدة في أي وقت يحتاجون إليها، بغض النظر عن فوارق الوقت أو مكان وجودهم.",
          enhancedCustomerSatisfactionTitle: "زيادة رضا العملاء",
          enhancedCustomerSatisfactionDescription:
            "يؤدي تقديم الدعم المتميز للعملاء إلى زيادة رضاهم واعتمادهم للعلامة التجارية. خبراء مركز الاتصال المحترفون في شركة تفاصيل مدربون على تقديم دعم مخصص بطريقة ودودة وفعّالة، مما يضمن لعملائك تجربة إيجابية مع علامتك التجارية.",
          contactCenterOutsourcingTitle: "ما هو تعهيد مراكز الاتصال؟",
          contactCenterOutsourcingDescription:
            "تعهيد عمليات مراكز الاتصال يتضمن الشراكة مع شركة متخصصة مثل شركة تفاصيل لخدمات التعهيد لتتعامل مع تفاعلات عملائك عبر مختلف قنوات الاتصال. من خلال تعهيد عمليات مركز الاتصال لديك، يمكنك الاستفادة من فريق متفرغ من خبراء مراكز الاتصال المتميزين في تقديم دعم فوري ومخصص ومفيد لعملائك، مما يمكنك من التركيز على أنشطة الأعمال الأساسية مع ضمان تقديم تجارب استثنائية للعملاء.",
          skilledContactCenterTitle: "خبراء مراكز اتصال ماهرون",
          skilledContactCenterDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين في مجال مراكز الاتصال ذوي المهارات العالية والخبرة الواسعة في تقديم دعم العملاء المتميز. خبرائنا مدرّبون على التعامل مع مختلف متطلبات العملاء، مما يضمن تقديم حلول فورية وفعّالة.",
          cuttingEdgeTechnologiesTitle: "أحدث ما توصلت إليه التكنولوجيا",
          cuttingEdgeTechnologiesDescriptionContactCenter:
            "نستخدم تقنيات وأدوات متطورة في مراكز الاتصال لتحسين الكفاءة وتقديم تجارب سلسة للعملاء. تتيح لنا أنظمة الدعم متعدد القنوات التي نستخدمها تحقيق تفاعلات متسقة ومتكاملة مع العملاء وعبر عدة قنوات.",
          flexibleSolutionsTitle: "حلول مرنة",
          flexibleSolutionsDescription:
            "توفر شركة تفاصيل لخدمات التعهيد حلولاً مرنة لمراكز الاتصال تتناسب مع احتياجات شركتك الفريدة. سواء كنت بحاجة إلى دعم دائم أو مساعدة إضافية خلال فترات الذروة أو دعم خارج ساعات العمل، يمكننا تخصيص خدماتنا لتلبية متطلباتك.",
          dataDrivenInsightsTitle: "تحليلات مرتكزة على البيانات",
          dataDrivenInsightsDescription:
            "نحن نؤمن بالتحسين والتطوير المستمر. تستخدم شركة تفاصيل لخدمات التعهيد تحليلات البيانات وتعليقات العملاء لاكتساب رؤى قيّمة حول تفضيلات العملاء ونقاط المعاناة والتوجهات السائدة، مما يمكننا من تحسين استراتيجيات مركز الاتصال لدينا بشكل مستمر وتحقيق نتائج أفضل.",
          fullName: "الاسم الكامل (*)",
          firstName: "الاسم (*)",
          lastName: "الكنية (*)",
          yourEmail: "بريدك الاكتروني (*)",
          phoneNo: "رقم الهاتف (*)",
          yourMessage: "رسالتك (*)",
          getAQuote: "احصل على عرض سعر",
          company: "شركتك (*)",
          jobTitle: "المسمى الوظيفي (*)",
          countryToBeServed: "البلد الذي ترغب في ان نوفر لك فيه خدماتنا؟",
          Albania: "	البانيا	",
          Algeria: "	الجزائر	",
          Angola: "	أنغولا	",
          Argentina: "	الأرجنتين	",
          Austria: "	النمسا	",
          Australia: "	أستراليا	",
          Bahrain: "	البحرين	",
          Belarus: "	بيلاروس	",
          Belgium: "	بلجيكا	",
          Benin: "	بنين	",
          BosniaAndHerzegovina: "	البوسنة والهرسك	",
          Brazil: "	البرازيل	",
          Bulgaria: "	بلغاريا	",
          Cameroon: "	الكاميرون	",
          Canada: "	كندا	",
          Chile: "	تشيلي	",
          China: "	الصين	",
          Colombia: "	كولومبيا	",
          Comoros: "	جزر القمر	",
          CostaRica: "	كوستاريكا	",
          Croatia: "	كرواتيا	",
          CzechRepublic: "	جمهورية التشيك	",
          Denmark: "	الدنمارك	",
          DominicanRepublic: "	جمهورية الدومينيكان	",
          Egypt: "	مصر	",
          ElSalvador: "	السلفادور	",
          Estonia: "	استونيا	",
          Ethiopia: "	إثيوبيا	",
          Finland: "	فنلندا	",
          France: "	فرنسا	",
          Georgia: "	جورجيا	",
          Germany: "	ألمانيا	",
          Ghana: "	غانا	",
          Greece: "	اليونان	",
          Guatemala: "	غواتيمالا	",
          Guinea: "	غينيا	",
          Guyana: "	غيانا	",
          Honduras: "	هندوراس	",
          Hungary: "	هنغاريا	",
          India: "	الهند	",
          Indonesia: "	إندونيسيا	",
          Ireland: "	أيرلندا	",
          Israel: "	إسرائيل	",
          Italy: "	إيطاليا	",
          Jamaica: "	جامايكا	",
          Japan: "	اليابان	",
          Jordan: "	الأردن	",
          Kenya: "	كينيا	",
          Kuwait: "	الكويت	",
          Latam: "	أمريكا اللاتينية	",
          Latvia: "	لاتفيا	",
          Lebanon: "	لبنان	",
          Lithuania: "	ليتوانيا	",
          Luxembourg: "	لوكسمبورغ	",
          Macedonia: "	مقدونيا	",
          Madagascar: "	مدغشقر	",
          Malaysia: "	ماليزيا	",
          Malta: "	مالطا	",
          Mauritius: "	موريشيوس	",
          Mexico: "	المكسيك	",
          Montenegro: "	الجبل الأسود	",
          Morocco: "	المغرب	",
          Mozambique: "	موزمبيق	",
          Netherlands: "	هولندا	",
          NewZealand: "	نيوزيلندا	",
          Nicaragua: "	نيكاراغوا	",
          Nigeria: "	نيجيريا	",
          Norway: "	النرويج	",
          Oman: "	عمان	",
          Peru: "	بيرو	",
          Philippines: "	الفلبين	",
          Poland: "	بولندا	",
          Portugal: "	البرتغال	",
          Qatar: "	قطر	",
          Romania: "	رومانيا	",
          Russia: "	روسيا	",
          SaudiArabia: "	المملكة العربية السعودية	",
          Senegal: "	السنغال	",
          Serbia: "	صربيا	",
          Singapore: "	سنغافورة	",
          Slovakia: "	سلوفاكيا	",
          Slovenia: "	سلوفينيا	",
          Somalia: "	الصومال	",
          SouthAfrica: "	جنوب أفريقيا	",
          SouthKorea: "	كوريا الجنوبية	",
          SouthSudan: "	جنوب السودان	",
          Spain: "	إسبانيا	",
          Suriname: "	سورينام	",
          Sweden: "	السويد	",
          Switzerland: "	سويسرا	",
          Tanzania: "	تنزانيا	",
          Thailand: "	تايلاند	",
          Tunisia: "	تونس	",
          Turkey: "	تركيا	",
          UnitedArabEmirates: "	الإمارات العربية المتحدة	",
          Uganda: "	أوغندا	",
          Ukraine: "	أوكرانيا	",
          UnitedKingdom: "	المملكة المتحدة	",
          UnitedStates: "	الولايات المتحدة	",
          Vietnam: "	فيتنام	",
          Zimbabwe: "	زيمبابوي	",
          industry: "الصناعة (*)",
          whereDidYouHeardAboutUs: "كيف تعرفت علينا؟ (*)",
          website: "موقع الكتروني",
          printAdvertising: "منشورات تسويقية ورقية",
          event: "فعالية / ندوة الكترونية",
          referralFromTafaseelClient: "تزكية من عميل يتعامل مع شركة تفاصيل",
          tafaseelLocalSubsidiary: "شركة محلية تابعة لتفاصيل",
          socialMedia: "منصات التواصل الاجتماعي",
          consentCheck:
            "أوافق على تلقي الأخبار وتقارير المحللين والملفات التقنية ودراسات الحالة ومعلومات المنتج ذات الصلة بقطاع عملي من شركة تفاصيل. لن تشارك شركة تفاصيل معلوماتك أو تبيعها لأطراف ثالثة. راجع سياسة الخصوصية لدى تفاصيل.",
          seeTafaseelPrivacyPolicy: "راجع سياسة الخصوصية في تفاصيل",
          submit: "إرسال",
          itOutSourcingTitle: "تطوير البرمجيات",
          itOutSourcingDescription:
            "مرحبًا بك في شركة تفاصيل لخدمات التعهيد، شريكك الموثوق لخدمات تعهيد تكنولوجيا المعلومات. نحن ندرك أهمية تيسير عمليات تكنولوجيا المعلومات الخاصة بك مع خفض التكاليف. من خلال حلولنا المخصصة لتعهيد تكنولوجيا المعلومات، يمكنك الاستفادة من خبرة محترفي تكنولوجيا المعلومات لدينا وتقنياتنا المتطورة لتعزيز نجاح شركتك.",
          itSolutionsAccordionHeader: "حلول تكنولوجيا المعلومات المخصصة",
          itSolutionsAccordionBody:
            "في شركة تفاصيل لخدمات التعهيد، نعمل عن كثب معك لفهم احتياجات تكنولوجيا المعلومات التي تحتاجها بدقة. يطوّر خبراؤنا حلولاً مخصصة لتعهيد تكنولوجيا المعلومات بما يتناسب مع أهداف شركتك، مما يضمن تحقيق أفضل النتائج.",
          itProfessionalsAccordionHeader:
            "خبراء محترفون بمجال تكنولوجيا المعلومات",
          itProfessionalsAccordionBody:
            "مع شركة تفاصيل لخدمات التعهيد، يمكنك الاستفادة من فريق من خبراء تكنولوجيا المعلومات ذوي المهارات الفائقة. يتمتع خبراؤنا بمعرفة عميقة وخبرة واسعة في مجالات متنوعة، مما يتيح لهم التعامل بمنتهى الكفاءة مع الجونب المتنوعة لتكنولوجيا المعلومات.",
          dataSecurityAccordionHeader:
            "أمن البيانات واستراتيجيات السرية الرقمية",
          dataSecurityAccordionBody:
            "أمن بياناتك وسريتها أولوية بالنسبة لنا. في شركة تفاصيل لخدمات التعهيد، نقوم بتنفيذ إجراءات أمان صارمة ونلتزم بمعايير العمل لضمان حماية دائمة لمعلوماتك الحساسة.",
          softwareDevelopmentHeading: "تصميم البرمجيات",
          softwareDevelopmentParagraph:
            "يمكن لمطوري البرمجيات المحترفين في فريقنا إنشاء حلول برمجية مخصصة تتناسب مع احتياجات عملك. من تطبيقات الويب والهواتف المحمولة إلى برمجيات الشركات، نقدم حلولًا قوية وقابلة للتطوير.",
          infrastructureManagementHeading: "إدارة البنى التحتية",
          infrastructureManagementParagraph:
            "دعنا نتولى إدارة البنية التحتية لتكنولوجيا المعلومات الخاصة بك وصيانتها. نضمن سلاسة عمل شبكتك وخوادمك وأنظمتك، مما يقلل من فترات التوقف ويزيد من الكفاءة.",
          cybersecurityHeading: "الأمن السيبراني",
          cybersecurityParagraph:
            "بادر بحماية عملك من التهديدات الإلكترونية باستخدام خدماتنا المتطورة في مجال الأمن السيبراني. نستخدم إجراءات أمان قوية ونقوم بإجراء فحوصات دورية وننفذ استراتيجيات متطورة لحماية بياناتك وأنظمتك.",
          helpDeskHeading: "دعم مكتب المساعدة",
          helpDeskParagraph:
            "فريق دعم مكتب المساعدة المتفاني لدينا متاح لمساعدة موظفيك وحل مشاكل تكنولوجيا المعلومات بسرعة فائقة. نحن نقدم دعمًا موثوقًا وسريع الاستجابة، مما يضمن استمرارية العمليات بدون انقطاع.",
          ourTechnologies: "تقنياتنا",
          salesOutsourcingHeading: "ما هو تعهيد المبيعات؟",
          salesOutsourcingParagraph:
            "تعهيد المبيعات يعني عقد شراكة استراتيجية مع شركة متخصصة لتولي عدة جوانب من عملية البيع الخاصة بك. من خلال تعهيد عملية البيع الخاصة بك إلى شركة تفاصيل لخدمات التعهيد، يمكنك الاستفادة من خدمات فريق متخصص من خبراء المبيعات المتميزين في مجال التسويق عبر الانترنت وخلق فرص البيع واستقطاب العملاء وإتمام عمليات البيع، مما يتيح لك التركيز على أنشطة الأعمال الأساسية بينما تضمن سير عمليات البيع بشكل متسق وفعّال.",
          salesOutsourcingCosts:
            "يتيح لك تعهيد المبيعات خفض التكاليف المترتبة على توظيف وتدريب وإدارة فريق مبيعات خاص بشركتك. مع شركة تفاصيل لخدمات التعهيد، ستدفع مقابل الخدمات المقدمة فقط، مما يجعل التعهيد حلاً اقتصادياً من حيث التكلفة لشركتك.",
          salesOutsourcingExpertiseAndScalability: "الخبرات وقابلية التوسع",
          salesOutsourcingExpertiseAndScalabilityBody:
            "يتبع فريقنا نهجاً مرتكزاً على البيانات ويستخدم منهجيات المبيعات التي أثبتت فعاليتها لزيادة نجاح مبيعاتك. نجمع بين مميزات التكنولوجيا والتحليلات والرؤى المتعلقة بالصناعة لتحديد وتحويل العملاء المحتملين إلى عملاء فعليين بمنتهى الفعالية.",
          acceleratedSalesCycle: "تعزيز سرعة دورة المبيعات",
          acceleratedSalesCycleBody:
            "تستخدم شركة تفاصيل لخدمات التعهيد استراتيجيات فعّالة في التسويق عبر الانترنت والبحث عن العملاء المحتملين لضمان تدفق مستمر من العملاء المؤهلين. يتقن خبراؤنا في المبيعات تقنيات البيع وإدارة العلاقات مع العملاء، مما يسهم في إتمام الصفقات وتحقيق الإيرادات بوتيرة أسرع.",
          marketExpansion: "توسعة القاعدة السوقية",
          marketExpansionBody:
            "إذا كنت تخطط لدخول أسواق جديدة أو توسيع الانتشار الجغرافي لشركتك، يمكن لشركة تفاصيل لخدمات التعهيد أن تكون عاملاً محورياً في نجاح ذلك، إذ إننا نمتلك شبكات وعلاقات واسعة في العديد من الأسواق، مما يساعدك في دخول أسواق جديدة وتحقيق نمو في المبيعات.",
          tailoredApproach: "نهج مخصص ليناسب شركتك",
          tailoredApproachDescription:
            "نحن ندرك أن كل عمل لديه متطلبات تسويق فريدة. لذا فإن حلول تعهيد التسويق التي نقدمها مُخصصة لتتناسب مع احتياجاتك الفريدة وجمهورك المستهدف وقطاع عملك. نعمل معك عن كثب لتطوير استراتيجية تسويق شاملة مصممة خصيصًا لتحقيق أهداف عملك.",
          provenMethodologies: "منهجيات أثبتت فعاليتها",
          provenMethodologiesDescription:
            "يتبع فريقنا نهجاً مرتكزاً على البيانات ويستخدم منهجيات المبيعات التي أثبتت فعاليتها لزيادة نجاح مبيعاتك. نجمع بين ميزات التكنولوجيا والتحليلات والرؤى المتعلقة بالصناعة لتحديد وتحويل العملاء المحتملين إلى عملاء فعليين بمنتهى الفعالية.",
          scalabilityFlexibility: "قابلية التوسع والمرونة",
          scalabilityFlexibilityDescription:
            "نحن ندرك أن الشركات تخضع للنمو والتقلبات. يمكن لحلولنا المرنة أن تلبي احتياجاتك المتغيرة، سواء كنت تحتاج إلى دعم مبيعات إضافي خلال مواسم الذروة أو ترغب في التوسع في أسواق جديدة.",
          flexibleScalableSolutions: "حلول مرنة وقابلة للتوسع",
          flexibleScalableSolutionsDescription:
            "توفر شركة تفاصيل لخدمات التعهيد حلولاً تسويقية مرنة وقابلة للتطوير يمكنها التكيف مع احتياجات عملك المتغيرة. سواء كنت بحاجة إلى دعم تسويقي مستمر أو مساعدة محددة في حملة تسويقية، يمكننا تصميم خدماتنا لتلبية متطلباتك.",
          transparentReporting: "تقارير تتسم بالشفافية",
          transparentReportingDescription:
            "نحن نؤمن بالشفافية والمساءلة. من خلال تقاريرنا وتحليلاتنا المفصلة، يمكنك الحصول على رؤى قيّمة حول أداء مبيعاتك مما يمكّنك من اتخاذ قرارات مستنيرة وتحسين استراتيجية المبيعات الخاصة بك للحصول على نتائج أفضل.",
          marketingOutsourcing: "ما هو تعهيد التسويق؟",
          marketingOutsourcingDescription:
            "يتضمن تعهيد التسويق عقد شراكة مع شركة متخصصة مثل شركة تفاصيل لخدمات التعهيد للتعامل مع الجوانب المختلفة لعملية التسويق الخاصة بك. حتى يمكنك الاستفادة من فريق متخصص من خبراء التسويق المتميزين في التسويق عبر الانترنت وكتابة المحتوى وإدارة حسابات التواصل الاجتماعي وتحسين نتائج محركات البحث وغيرها الكثير، مما يتيح لك التركيز على الأنشطة التجارية الأساسية مع الاستفادة من استراتيجية تسويق مخصصة تركز على تحقيق النتائج.",
          marketingOutsourcingCostEfficiency:
            "من خلال تعهيد عمليات التسويق الخاصة بك إلى شركة تفاصيل لخدمات التعهيد، يمكنك تقليل التكاليف المتعلقة بالتوظيف والتدريب وإدارة فريق تسويق خاص بك بشكل كبير. توفر حلولنا الاقتصادية من حيث التكلفة إمكانية الاستفادة من خبرات خبراء محترفين دون تحمل عبء النفقات الإضافية.",
          salesOutsourcingExpertiseAndScalabilityDescriptionMarketing:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من خبراء التسويق المحترفين، والذين لديهم معرفة عميقة بأحدث توجهات التسويق وتقنياته. تسمح لك حلولنا القابلة للتوسع بتصميم جهود التسويق وتعديلها بناءً على احتياجات شركتك، مما يضمن التخصيص الأمثل للموارد.",
          accessToAdvancedToolsAndTechnologies:
            "الاستفادة من الأدوات والتقنيات المتطورة",
          accessToAdvancedToolsAndTechnologiesDescription:
            "باعتبارها شركة رائدة في مجال تعهيد التسويق، تستثمر شركة تفاصيل لخدمات التعهيد  في أدوات وتقنيات التسويق المتطورة. تتيح لك الشراكة معنا الاستفادة من التحليلات المتطورة وبرامج االتشغيل الألي ومنصات التسويق التي يمكنها أن تعزز فعالية التسويق لديك.",
          strategicFocusAndInnovation: "التركيز والابداع الاستراتيجي ",
          strategicFocusAndInnovationDescription:
            "من خلال تعهيد عمليات التسويق الخاصة بك، يمكنك تكريس مواردك الداخلية للتركيز على الكفاءات الأساسية والمبادرات الإستراتيجية، مما يمكّنك من تعزيز الابتكار واتخاذ القرارات المستندة إلى البيانات والبقاء في صدارة المنافسة في السوق الديناميكي.",
          skilledMarketingProfessionalsTitle: "خبراء التسويق المحترفون",
          skilledMarketingProfessionalsDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقاً من خبراء التسويق ذوي الكفاءة العالية والخبرة في مجالات التسويق المتنوعة، من التسويق عبر الانترنت وكتابة المحتوى إلى إدارة حسابات التواصل الاجتماعي وتحسين نتائج محركات البحث، يضمن خبراؤنا تنفيذ جهود التسويق الخاصة بك بتميز.",
          cuttingEdgeToolsAndTechnologies: "الأدوات والتقنيات فائقة التطور",
          cuttingEdgeTechnologiesDescription:
            "نبقى على اطلاع دائم على أحدث استراتيجيات التسويق ونستفيد من الأدوات والتقنيات المتطورة لتحقيق نتائج استثنائية. استراتيجيات التسويق لدينا مدعومة برؤىً تستند على البيانات وأساليب مبتكرة يمكن أن تعزز نمو شركتك.",
          customerServiceOutsourcing: "تعهيد خدمة العملاء",
          customerServiceOutsourcingTitle: "ما هو تعهيد خدمة العملاء؟",
          customerServiceOutsourcingDescription:
            "تعهيد خدمة العملاء يتضمن عقد شراكة مع شركة متخصصة مثل شركة تفاصيل لخدمات التعهيد للتعامل مع عمليات دعم العملاء الخاصة بك. يتيح لك تعهيد مهام خدمة العملاء الاستفادة من خبرات فريق متخصص في خدمة العملاء، والذي يتميز بتقديم دعم فوري واحترافي ومخصص لعملائك. مما يتيح لك التركيز على الأنشطة الأساسية لعملك مع ضمان تقديم تجارب استثنائية للعملاء.",
          customerSupportTitleTwentyFourSeven: "دعم العملاء على مدار الساعة",
          customerSupportDescriptionTwentyFourSeven:
            "تقدم شركة تفاصيل لخدمات التعهيد دعمًا للعملاء على مدار الساعة لضمان أن عملائك سيتلقون المساعدة في أي وقت يحتاجونه. فريقنا متاح للإجابة عن استفسارات العملاء وحل المشكلات وتقديم الدعم عبر عدة قنوات، بما في ذلك الهاتف والبريد الإلكتروني والدردشة ووسائل التواصل الاجتماعي.",
          multiLingualSupport: "نقدم الدعم بلغات متعددة",
          multiLingualSupportDescription:
            "مع شركة تفاصيل لخدمات التعهيد، يمكنك تقديم خدمة العملاء بعدة لغات لتلبية احتياجات قاعدة عملاء متنوعة. يتكون فريقنا من محترفين يجيدون عدة لغات مما يمكنهم من التواصل بفعالية مع عملائك وتقديم الدعم لهم باللغة التي يختارونها.",
          improvedCustomerSatisfaction: "زيادة رضا العملاء",
          improvedCustomerSatisfactionDescription:
            "يؤدي تقديم الخدمة المتميزة للعملاء إلى زيادة رضاهم واعتمادهم للعلامة التجارية. فريق شركة تفاصيل المختص بخدمة العملاء مدربٌ على تقديم دعم مخصص بطريقة ودودة وفعّالة، مما يضمن لعملائك تجربة إيجابية مع علامتك التجارية.",
          receptionistTitle: "موظفو تلقي اتصالات العملاء",
          receptionistContent:
            "بالاستعانة بموظفي تلقي المكالمات المحترفين في فريقنا، ستحظى بتجربة متميزة في تلقي الاتصالات وإعادة توجيهها والترحيب بالعملاء بحفاوة، مما يضمن لعملائك أن يشعروا بأنهم موضع تقدير واهتمام",
          socialMediaTitle: "منصات التواصل الاجتماعي",
          socialMediaContent:
            "عزز حضورك الرقمي من خلال الاستعانة بفريق خبراء منصات التواصل الاجتماعي لدينا، والذي سيعمل على زيادة تفاعل جمهورك مع حساباتك وتحقيق تفاعلات بنّاءة.",
          contentModerationTitle: "إدارة المحتوى",
          contentModerationContent:
            "انعم بالراحة من خلال الاستعانة بمدراء المحتوى النبهاء في فريقنا، والذين سيحافظون على أمن البيئة الرقمية لعلامتك التجارية ومنحها الطابع الإيجابي دوماً.",
          ethicsHotlineTitle: "الخط الساخن المخصص للمسائل الأخلاقية",
          ethicsHotlineContent: `عزز الثقة والشفافية بتخصيص خط ساخن للمسائل الأخلاقية يحافظ على سريّة البلاغات، والذي سيشجع الموظفين وأصحاب المصلحة على الإبلاغ عن مخاوفهم بشكل مسؤول.`,
          liveChatsTitle: "الدردشات المباشرة",
          liveChatsContent: `تواصل مع العملاء بشكل فوري وقدم لهم دعماً لحظياً وحلولاً مخصصة من خلال الاستعانة بخدمات الدردشات المباشرة عالية الكفاءة التي نقدمها.`,
          coBrowsingTitle: "التصفح المشترك",
          coBrowsingContent: `أرشد العملاء خلال تصفحهم الانترنت لتقديم المساعدة العملية لهم من خلال خدمة التصفح المشترك التي نقدمها.`,
          surveysAndFeedbackTitle: "استطلاعات الرأي والتعليقات",
          surveysAndFeedbackContent: `احصل على رؤى قيّمة حول رضا العملاء وقم بتحسين الخدمة من خلال استخدام استطلاعات رأي مخصصة وتحليل التعليقات.`,
          overflowTitle: "تدفق المكالمات الزائد",
          overflowContent: `تعامل بسلاسة مع الأعداد الكبيرة من المكالمات باستخدام الذي نقدمه في حالات تدفق المكالمات الزائد، مما يضمن عدم ترك أي استفسار للعميل دون إجابة.`,
          videoChatTitle: "دردشات الفيديو",
          videoChatContent: `ارفع تفاعلات العملاء إلى مستوى أكثر شخصية، نحقق لك ذلك من خلال تقديم دعم التواصل وجهاً لوجه من خلال دردشات الفيديو وبناء صلات أقوى مع العملاء.`,
          whyChooseTafaseelBPOFinance:
            "لماذا عليك اختيار شركة تفاصيل لخدمات التعهيد؟",
          tafaseelBPOFinanceDescription:
            "شركة تفاصيل لخدمات التعهيد هي شريكك الموثوق لتعهيد العمليات المالية. التزامنا بالتميز ورضا العملاء هو ما يميزنا. إليك الأسباب التي تدفعك لاختيارنا",
          tailoredSolutions: "حلولنا المخصصة",
          tailoredSolutionsDescription:
            "نحن ندرك أن لكل عمل احتياجاته المالية الفريدة. حلول التعهيد التي نقدمها مخصصة لتتناسب مع متطلباتك الفريدة وأهدافك ومعايير الامتثال الخاصة بك.",
          cuttingEdgeTechnology: "أحدث ما وصلت إليه التكنولوجيا",
          cuttingEdgeTechnologyDescription:
            "تسخّر شركة تفاصيل لخدمات التعهيد أحدث البرمجيات والأدوات  المالية لضمان إدارة البيانات بكفاءة وتقديم التقارير بشكل لحظي وتحقيق التكامل السلس مع أنظمتك الحالية. إبقَ في الصدارة مع حلولنا المعتمدة على أحدث التقنيات.",
          dataSecurityAndConfidentiality:
            "أمن البيانات واستراتيجيات السرية الرقمي",
          dataSecurityAndConfidentialityDescription:
            "نضع أمن بياناتك المالية على رأس أولوياتنا. تعتمد شركة تفاصيل لخدمات التعهيد إجراءات أمان قوية، بما في ذلك بروتوكولات التشفير وضوابط الوصول، وذلك لحماية معلوماتك من الوصول غير المصرح به أو الاختراق.",
          reliableSupport: "دعم تثق به",
          reliableSupportDescription:
            "نحن نؤمن بأهمية بناء شراكات طويلة الأمد مع عملائنا. فريق الدعم المتفاني لدينا متاح على مدار الساعة للرد على استفساراتكم وتقديم الاستشارات وضمان سير عملياتكم بسلاسة.",
          costSavingsTitle: "خفض التكاليف",
          costSavingsDescription:
            "من خلال تعهيد عملياتك المالية إلى شركة تفاصيل لخدمات التعهيد، يمكنك خفض التكاليف المرتبطة بتوظيف وتدريب فرق عمليات مالية في شركتك بشكل كبير. تقدم لكم حلولنا الاقتصادية من حيث التكلفة إمكانية الاستفادة من خدمات محترفي المجال دون تحمّل عبء التكاليف الزائدة.",
          financeExpertsDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من خبراء الشؤون المالية الذين يمتلكون معرفة عميقة بأفضل الممارسات والقوانين المتعلقة بالمجال. نحن نقدم قابلية مرنة للتوسع، مما يتيح لك تعديل مستوى الدعم بناءً على احتياجات عملك، مما يضمن تخصيص الموارد بالشكل الأمثل في كافة الأوقات.",
          enhancedEfficiencyTitle: "تعزيز الكفاءة",
          enhancedEfficiencyDescription:
            "يمكنك تحسين العمليات المالية في شركتك وزيادة دقتها وتقليل مخاطر وقوع الأخطاء، وذلك من خلال تعهيد عملياتك المالية إلى شركة تفاصيل لخدمات التعهيد. تتيح لنا أدواتنا وتقنياتنا المتطورة تسريع سير العمل وتسريع الاستجابة وتحسين الأداء المالي.",
          coreCompetenciesTitle: "التركيز على الكفاءات الأساسية",
          coreCompetenciesDescription:
            "من خلال تعهيد عملياتك المالية لشركة تفاصيل لخدمات التعهيد يصبح بوسعك تفريغ مواردك الداخلية للتركيز على جوانب الأعمال الأساسية. ويتيح لك هذا التحول الاستراتيجي اتخاذ قرارات مدروسة بشكل أفضل ودفع عجلة الابتكار وتعزيز النمو العام لشركتك.",
          whyChooseHRTitle:
            "لماذا عليك تعهيد عمليات الموارد البشرية إلى شركة تفاصيل لخدمات التعهيد؟",
          whyChooseHRDescription:
            "عند تعهيد عمليات الموارد البشرية إلى شركة تفاصيل لخدمات التعهيد، يتيح لك ذلك الوصول إلى العديد من الفوائد التي يمكن أن ترتق بعمليات الموارد البشرية الخاصة بك.",
          costSavingsHRDescription:
            "من خلال تعهيد عمليات الموارد البشرية إلى شركة تفاصيل لخدمات التعهيد، يمكنك خفض التكاليف المرتبطة بتوظيف وتدريب فريق موارد بشرية في شركتك. تقدم لكم حلولنا الاقتصادية من حيث التكلفة إمكانية الاستفادة من خدمات محترفي مجال الموارد البشرية دون تحمّل عبء التكاليف الزائدة.",
          expertiseComplianceAccordionHeader:
            "الخبرات والامتثال: تقدم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين ذوي الخبرة في إدارة الموارد البشرية",
          expertiseComplianceAccordionBody:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين ذوي الخبرة في إدارة الموارد البشرية، والذين يمتلكون معرفة عميقة بأفضل الممارسات في مجال إدارة الموارد البشرية وتشريعات الامتثال. نحن نضمن امتثال عمليات الموارد البشرية في شركتك للمتطلبات القانونية ومعايير مجال العمل.",
          scalabilityFlexibilityAccordionBody:
            "حلول تعهيد إدارة الموارد البشرية لدينا مرنة وقابلة للتوسع، مما يتيح لك ضبط دعم إدارة الموارد البشرية بناءً على احتياجات شركتك. يمكننا تخصيص خدماتنا لتلبية احتياجاتك سواء كنت بحاجة إلى دعم إدارة الموارد البشرية بدوام كامل أو دعمًا مؤقتاً في أحد مشاريعك.",
          focusStrategicInitiativesAccordionHeader:
            "التركيز على المبادرات الاستراتيجية",
          focusStrategicInitiativesAccordionBody:
            "من خلال تعهيد مهام إدارة الموارد البشرية، يمكنك إعادة توظيف الموارد الداخلية والموظفين في قسم الموارد البشرية للتركيز على المبادرات الاستراتيجية والأنشطة الأساسية للشركة. مما يتيح لك دفع عجلة الابتكار وتعزيز التفاعل مع الموظفين وتحقيق أهداف شركتك.",
          recruitmentTalentAcquisitionTitle: "التوظيف واستقطاب المواهب",
          recruitmentTalentAcquisitionDescription:
            "نساعد في البحث عن أصحاب المواهب واختبارهم واختيار أعلى الكفاءات للعمل في مؤسستك لضمان عملية توظيف مثالية واستقطاب الموظفين المناسبين.",
          onboardingOffboardingTitle: "التوظيف وتسريح الموظفين",
          onboardingOffboardingDescription:
            "يدير فريقنا من المحترفين في إدارة الموارد البشرية عمليات التوظيف وتسريح الموظفين، مما يضمن تحقيق انتقال سلس سواء للموظفين الجدد أو الذين أنهيت خدماتهم.",
          payrollBenefitsAdminTitle: "إدارة جدول الرواتب والمستحقات",
          payrollBenefitsAdminDescription:
            "تدير شركة تفاصيل لخدمات التعهيد معالجة جداول الرواتب وإدارة مستحقّات الموظفين والامتثال للتشريعات الخاصة بالتوظيف، مما يضمن دقّة المدفوعات وتسليمها في الوقت المحدد لموظفيك.",
          hrPoliciesComplianceTitle: "سياسات الموارد البشرية والامتثال",
          hrPoliciesComplianceDescription:
            "نقوم بتطوير وتنفيذ سياسات وإجراءات الموارد البشرية الملائمة لأهدف شركتك والتي تمتثل لقوانين وتشريعات التوظيف ذات الصلة.",
          performanceManagementDescriptionHR:
            "يساعد خبراء إدارة الموارد البشرية لدينا في تنفيذ أنظمة إدارة الأداء، بما فيها تحديد الأهداف وتقييمات الأداء وخطط التطوير",
          employeeRelationsEngagementTitle:
            "علاقات الموظفين وتعاملهم فيما بينهم",
          employeeRelationsEngagementDescription:
            "تدعم شركة تفاصيل لخدمات التعهيد المبادرات المتعلقة بعلاقات الموظفين وتعزز بيئة العمل الإيجابية وتنفذ استراتيجيات التفاعل لتعزيز رضا الموظفين والمحافظة عليهم ضمن الفريق.",
          whyChooseProcurementTitle:
            "لماذا عليك اختيار تعهيد عمليات الشراء لدى شركة تفاصيل لخدمات التعهيد",
          whyChooseProcurementDescription:
            "عند اختيار خدمات تعهيد عمليات الشراء لدى شركة تفاصيل لخدمات التعهيد، يمكنك الاستفادة من العديد من المزايا لمؤسستك، والتي تشمل:",
          costSavingsDescriptionProcurement:
            "يمكنك خفض تكاليف إدارة فرق المشتريات الداخلية بشكل كبير من خلال تعهيد عمليات الشراء إلى شركة تفاصيل لخدمات التعهيد. نسخّر قدرتنا الشرائية وخبرتنا في السوق وعلاقاتنا مع الموردين للتفاوض على أسعار وشروط أفضل، مما يؤدي إلى خفض التكاليف المترتبة على شركتك.",
          expertiseIndustryKnowledgeTitle: "الخبرات والمعرفة بقطاع العمل",
          expertiseIndustryKnowledgeDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين في مجال عمليات الشراء، والذين يتمتعون بخبرة واسعة في مختلف قطاعات العمل. فهمنا لكافة تفاصيل عمليات الشراء والتعامل مع الموردين واستراتيجيات التوريد يضمن تحقيق النتائج الأمثل لمؤسستك.",
          efficiencyScalabilityTitle: "الكفاءة وقابلية التوسع",
          efficiencyScalabilityDescription:
            "حلول تعهيد عمليات الشراء التي نقدمها مصممة لتعزيز الكفاءة التشغيلية وقابلية توسع الأعمال. نسخّرالتكنولوجيا المتطورة والعمليات السلسة لتسريع دورة عملية الشراء، مما يسمح لك بالتكيف مع التغيرات في احتياجات الأعمال ومعالجة التقلبات في الطلب بفعالية.",
          riskMitigationComplianceTitle: "الامتثال والحد من المخاطر",
          riskMitigationComplianceDescription:
            "تركز شركة تفاصيل لخدمات التعهيد على الامتثال والحد من المخاطر في عمليات الشراء. نحن نضمن الامتثال للمتطلبات التنظيمية، وننفذ عمليات فعالة للتحقق من الموردين ونضع شروطًا تعاقدية واضحة، مما يقلل من المخاطر ويحمي مصالح مؤسستك.",
          strategicSourcingTitle: "التوريد الاستراتيجي",
          strategicSourcingDescription:
            "نساعدك في تحديد الموردين المناسبين وإجراء أبحاث السوق والتفاوض على شروط مريحة لضمان تأمين السلع والخدمات بتكلفة ملائمة.",
          supplierManagementTitle: "التعامل مع الموردين",
          supplierManagementDescription:
            "خبراء عمليات الشراء المحترفون لدينا يتولون إدارة العلاقات مع الموردين ورصد الأداء والإشراف على العقود لضمان توفير إمدادات موثوقة وعالية الجودة.",
          purchaseOrderManagementTitle: "إدارة أوامر الشراء",
          purchaseOrderManagementDescription:
            "تدير شركة تفاصيل لخدمات التعهيد كافة جوانب عملية أوامر الشراء، بما في ذلك تقديم أوامر الشراء ومتابعتها وتسوية الفواتير، مما يضمن الدقة والكفاءة.",
          vendorEvalSelectionTitle: "تقييم الباعة واختيارهم",
          vendorEvalSelectionDescription:
            "نجري تقييماً شاملاً للموردين المحتملين، حيث نقيم قدراتهم واستقرارهم المالي وسجل أدائهم  لضمان اختيار موردين موثوقين وذوي سمعة طيبة.",
          procurementAnalyticsTitle: "تحليل عمليات الشراء",
          procurementAnalyticsDescription:
            "يعتمد خبراؤنا على تحليل البيانات لتقديم الرؤى حول عمليات الشراء الخاصة بك وتحديد الفرص  الممكنة لخفض التكاليف وتحسين استراتيجيات الشراء لتحقيق نتائج أفضل.",
          complianceRiskManagementTitle: "الامتثال وإدارة المخاطر",
          complianceRiskManagementDescription:
            "تضمن شركة تفاصيل لخدمات التعهيد الامتثال للتشريعات المتعلقة بالشراء والمعايير الصناعية والسياسات الداخلية. نقوم بتنفيذ تدابير الحد من المخاطر، وبذل الحيطة اللازمة وإنشاء إطار تعاقدي متين.",
          whyChooseWarehousingTitle:
            "لماذا عليك اختيار تعهيد عمليات التخزين إلى شركة تفاصيل لخدمات التعهيد؟",
          whyChooseWarehousingDescription:
            "يتيح لك تعهيد عمليات التخزين إلى شركة تفاصيل لخدمات التعهيد تحقيق العديد من الفوائد لمؤسستك.",
          costSavingsDescriptionWarehouse:
            "يمكنك خفض التكاليف المرتبطة ببناء وإدارة بنية تحتية خاصة بعمليات التخزين إلى حد كبير من خلال تعهيد عمليات التخزين إلى شركة تفاصيل لخدمات التعهيد. حلولنا الاقتصادية من حيث التكلفة تتيح لك الاستفادة من أحدث المرافق والمعدات والتقنيات دون تحمل عبء التكاليف الزائدة.",
          salesOutsourcingExpertiseAndScalabilityDescriptionWareHouse:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين في مجال الدعم اللوجستي والتخزين، والذين يتمتعون بخبرة متعمقة في إدارة المخزون وتنفيذ الطلبات والتوزيع. توفر حلولنا القابلة للتوسع إمكانية التكيف مع تقلبات الطلب، مما يضمن استخدامًا أمثل للموارد.",
          advancedTechnologiesTitle: "التقنيات المتطورة",
          advancedTechnologiesDescription:
            "نستخدم أنظمة إدارة المستودعات وأدوات تتبع المخزون وبرمجيات الدعم اللوجستي المتطورة لتحسين عمليات التخزين. يتيح ذلك إمكانية الرؤية اللحظية والإدارة الفعّالة للمخزون ومعالجة الطلبات بدقةّ، مما يؤدي إلى تحسين رضا العملاء.",
          focusCoreBusinessTitle: "التركيز على الأعمال الرئيسية",
          focusCoreBusinessDescription:
            "تعهيد عمليات التخزين يتيح لك التركيز على قدراتك الأساسية والمبادرات الاستراتيجية. من خلال تعهيد عمليات الدعم اللزجستي إلى شركة تفاصيل لخدمات التعهيد، يمكنك إعادة توجيه الموارد الداخلية إلى المجالات التي تعزز النمو والابتكار.",
          inventoryManagementTitle: "إدارة المخزون",
          inventoryManagementDescription:
            "نقدم خدمات إدارة المخزون الشاملة، بما في ذلك الاستلام والتخزين والمراقبة والتنفيذ، مما يضمن دقة بيانات المخزون وإتمام الطلبيات في الوقت المناسب.",
          orderFulfillmentTitle: "تنفيذ الطلبيات",
          orderFulfillmentDescription:
            "يدير محترفونا عمليات استلام الطلبات والتعبئة والشحن، مما يضمن فعالية ودقّة تنفيذ الطلبيات لتلبية توقعات العملاء.",
          distributionLogisticsTitle: "التوزيع والخدمات اللوجستية",
          distributionLogisticsDescription:
            "تدير شركة تفاصيل لخدمات التعهيد جوانب التوزيع والخدمات اللوجستية في سلسلة التوريد الخاصة بك، كما تعمل على التنفيذ المثالي لعمليات النقل ورسم المسارات  وإدارة وسائل النقل لضمان تسليم السلع في الوقت المحدد.",
          warehouseOperationsTitle: "عمليات المستودعات",
          warehouseOperationsDescription:
            "ندير مختلف عمليات المستودعات، بما في ذلك تحسين استغلال المساحة وتصميم المخططات والجرد الدوري ومراقبة الجودة، مما يضمن تنفيذ عمليات مستودعات فعالة ومنظمة.",
          returnsManagementTitle: "إدارة السلع المرتجعة",
          returnsManagementDescription:
            "يدير خبراؤنا عملية إدارة السلع المرتجعة، بما في ذلك استلامها وفحصها وإعادة تخزينها والتصرف بها، مما يضمن معالجة سلسة للسلع المرتجعة وتحقيق رضا العملاء.",
          technologyIntegrationTitle: "إدماج التكنولوجيا",
          technologyIntegrationDescription:
            "تعتمد شركة تفاصيل لخدمات التعهيد لأنظمة المتقدمة لإدارة المستودعات وأدوات تتبع المخزون وقوائم بيانات التقارير، مما يتيح إمكانية الرؤية اللحظية للمخزةن وتقديم رؤى تستند على البيانات حول عمليات التخزين الخاصة بك.",
          whyChooseTransportationTitle:
            "لماذا عليك اختيار تعهيد عمليات النقل إلى شركة تفاصيل لخدمات التعهيد؟",
          whyChooseTransportationDescription:
            "حين تختار تعهيد خدمات النقل إلى شركة تفاصيل لخدمات التعهيد فإنك ستحصل على العديد من الميزات لشركتك:",
          costSavingsDescriptionTransport:
            "من خلال تعهيد عمليات النقل إلى شركة تفاصيل لخدمات التعهيد، يمكنك تحقيق تقليل كبير في التكاليف المرتبطة بامتلاك أسطول من المركبات وصياننه، إلى جانب تقادي ارتفاع أسعار الوقود وإدارة السائقين. حلولنا الاقتصادية من حيث التكلفة توفر الاستفادة من خدمات النقل الموثوقة دون عبء التكاليف الإضافية.",
          salesOutsourcingExpertiseAndScalabilityTitle: "الخبرة وقابلية التوسع",
          salesOutsourcingExpertiseAndScalabilityDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين في مجال الخدمات اللوجستية والنقل ممن يتمتعون بخبرة واسعة في تخطيط المسارات والتعامل مع الناقلين وتحسين  سير الشحنات. حلولنا القابلة للتوسع تسمح لك بالتكيف مع تقلبات الطلب وضمان نقل فعّال وتسليم في الوقت المحدد.",
          advancedTechnologiesDescriptionTransport:
            "نستخدم أنظمة إدارة النقل المتقدمة، وتتبع المواقع بواسطة نظام تحديد المواقع العالمي (GPS)، وأدوات المراقبة اللحظية لتحسين عمليات النقل. مما يسمح بإجراء التخطيط الفعال للمسارات وتحسين تتبع الشحنات وإرسال إشعارات التسليم بدقة، مما يعزز رضا العملاء.",
          riskMitigationAndComplianceTitle: "الامتثال والحد من المخاطر",
          riskMitigationAndComplianceDescription:
            "تركز شركة تفاصيل لخدمات التعهيد على الامتثال والحد من المخاطر في عمليات النقل. نحن نضمن الامتثال للمتطلبات التنظيمية وننفذ بروتوكولات السلامة ونجري تحققاً دقيقًا لشركات النقل، مما يحد من المخاطر ويحمي مصالح مؤسستك.",
          freightManagementTitle: "إدارة الشحن",
          freightManagementDescription:
            "نتولى إدارة عمليات الشحن والنقل الشاملة، بما فيها اختيار شركات النقل والتفاوض على الأسعار وتتبع الشحنات، مما يضمن توصيل بضائعك في الوقت المحدد وبتكلفة ملائمة.",
          routeOptimizationTitle: "اختيار المسارات الأمثل",
          routeOptimizationDescription:
            "يستخدم خبراؤنا برمجيات وخوارزميات توجيه متقدمة اختيار أفضل مسارات التسليم، مما يقلل من تكاليف النقل واستهلاك الوقود ويعزز الكفاءة.",
          carrierManagementTitle: "إدارة شركات النقل",
          carrierManagementDescription:
            "تدير شركة تفاصيل لخدمات التعهيد علاقات الناقلين لضمان الامتثال ومراقبة الأداء والإشراف على تنفيذ العقود لضمان توفير خدمات النقل الموثوقة وعالية الجودة.",
          shipmentTrackingTitle: "تتبع الشحنات وإمكانية مراقبتها",
          shipmentTrackingDescription:
            "نقدم خدمة تتبع الشحنات والمراقبة اللحظية باستخدام تقنيات متطورة، مما يتيح لك مراقبة حالة شحناتك وموقعها طوال عملية النقل.",
          customsComplianceTitle: "الامتثال للجمارك والقوانين الناظمة",
          customsComplianceDescription:
            "يضمن محترفونا الامتثال لتشريعات الجمارك ومتطلبات الاستيراد/التصدير والتشريعات المتعلقة بالنقل، مما يسهل عمليات النقل عبر الحدود بسلاسة.",
          transportationAnalyticsTitle: "تحليل عمليات النقل",
          transportationAnalyticsDescription:
            "تسخّر شركة تفاصيل لخدمات التعهيد تحليل البيانات لتقديم رؤى حول عمليات النقل الخاصة بك وتحديد إمكانية توفير التكاليف وتحسين استراتيجيات النقل لتحقيق نتائج أفضل.",
          accommodationOutsourcingTitle:
            "لماذا عليك اختيار تعهيد خدمات الإقامة إلى شركة تفاصيل لخدمات التعهيد؟",
          accommodationOutsourcingDescription:
            "باختيارك لتعهيد خدمات الإقامة إلى شركة تفاصيل لخدمات التعهيد فإنك ستتمكن من الاستفادة من العديد من المنافع لمؤسستك.",
          accommodationCostSavingsTitle: "خفض التكاليف",
          accommodationCostSavingsDescription:
            "من خلال تعهيد خدمات الإقامة إلى شركة تفاصيل لخدمات التعهيد، يمكنك خفض التكاليف المرتبطة بإدارة العقارات وصيانتها وتكاليف العمالة وخدمات الضيوف إلى حد كبير. حلولنا الاقتصادية  من حيث التكلفة تتيح لك الاستفادة من خدمات الإقامة عالية الجودة دون تكبّد تكاليف إضافية.",
          accommodationExpertiseQualityAssuranceTitle: "الخبرات وضمان الجودة",
          accommodationExpertiseQualityAssuranceDescription:
            "تقدّم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين في مجال الضيافة من ذوي الخبرة الكبيرة بأفضل الممارسات في قطاع الضيافة ونيل رضا الضيوف. نضمن أن خدمات الإقامة الخاصة بك تلبي أعلى معايير الجودة وتتجاوز توقعات الضيوف.",
          accommodationEnhancedGuestExperiencesTitle: "تحسين تجارب الضيوف",
          accommodationEnhancedGuestExperiencesDescription:
            "نركز على خلق تجارب لا تُنسى للضيوف من خلال تقديم خدمات مخصصة ومساعدة فورية والاهتمام بالتفاصيل. يحرص فريقنا المتفاني على أن يتلقى ضيوفك خدمة استثنائية، مما يؤدي إلى زيادة رضا الضيوف واعتمادهم على علامتك التجارية.",
          accommodationOperationalEfficiencyTitle: "الكفاءة التشغيلية",
          accommodationOperationalEfficiencyDescription:
            "شركة تفاصيل لخدمات التعهيد تبسط عمليات الإقامة الخاصة بك من خلال عمليات فعالة وإجراءات معيارية واستخدام فعّال للموارد، مما يتيح لك تحسين معدلات الإشغال وتقليل عدد الغرف الشاغرة وتحسين كفاءة التشغيل العامة.",
          propertyManagementTitle: "إدارة العقارات",
          propertyManagementDescription:
            "نتولى مهام إدارة العقارات، بما في ذلك الصيانة والإصلاحات والتدبير المنزلي والحماية، مما يضمن أن تكون عقاراتك بحالة جيدة وتوفر إقامة مريحة للضيوف.",
          reservationBookingManagementTitle: "إدارة الحجوزات",
          reservationBookingManagementDescription:
            "يدير خبراؤنا عمليات الحجوزات بما يضمن سلاسة إجراءات تسجيل الدخول والخروج وحفظ السجلات وتخصيص الغرف بكفاءة",
          guestServicesSupportTitle: "خدمة الضيوف وتقديم الدعم لهم",
          guestServicesSupportDescription:
            "تقدّم شركة تفاصيل لخدمات التعهيد خدمات الضيوف وتقدم الدعم لهم، بما في ذلك تقديم المساعدة على مدار الساعة وخدمات المساعدة والإرشاد والاستجابة لاستفسارات الضيوف وطلباتهم، وذلك لضمان تمتع ضيوفك بإقامة مريحة لا تنسى.",
          staffAccommodationTitle: "إسكان الموظفين",
          staffAccommodationDescription:
            "استكشف حلول إسكان الموظفين ومساكن العمال الفاخرة التي نقدمها. توفر مرافقنا الراحة والأمان، لفريق العمل لديك. نضمن لموظفيك إقامة ممتعة بفضل وسائل الراحة الحديثة وتركيزنا على الرفاهية. عزز إنتاجية فريق العمل لديك من خلال خدمات الإقامة الاستثنائية التي نقدمها.",
          qualityControlInspectionsTitle: "مراقبة الجودة والتفقّد",
          qualityControlInspectionsDescription:
            "نجري تفقّداً منتظماً لمراقبة الجودة للتأكد من أن خدمات الإقامة الخاصة بك تحقق أعلى المعايير. يقوم فريقنا بتحديد الأمور التي يمكن تحسينها وتنفيذ التدابير اللازمة لتحسين تجارب الضيوف.",
          revenueManagementTitle: "إدارة العوائد",
          revenueManagementDescription:
            "يحلل محترفونا توجهات السوق ومعدلات المنافسين وأنماط الطلب لوضع استراتيجيات التسعير المثلى وتحقيق أعلى العوائد لخدمات الإقامة التي تقدمها.",
          knowledgeProcessOutsourcingTitle:
            "لماذا عليك اختيار تعهيد خدمات اكتساب المعرفة إلى شركة تفاصيل لخدمات التعهيد؟",
          knowledgeProcessOutsourcingDescription:
            "حين تختار تعهيد عمليات اكتساب المعرفة إلى شركة تفاصيل لخدمات التعهيد فإنك ستتمكن من الاستفادة من العديد من المنافع لمؤسستك.",
          specializedSkillsTitle: "الاستفادة من المهارات والخبرات التخصصية",
          specializedSkillsDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقاً من المحترفين ذوي الخبرة الفائقة والتخصصية في العديد من المجالات. فريقنا يضم خبراء وباحثين ومحللي بيانات ومختصين في مختلف المجالات، والذين يستطيعون تقديم الآراء والتحليلات القيّمة.",
          fasterTurnaroundTimesTitle: "تسريع فترات الاستجابة",
          fasterTurnaroundTimesDescription:
            "فريقنا المتفاني من المحترفين في مجال تطوير المعرفة ملتزم بتقديم نتائج عالية الجودة ضمن إطار زمني محدد. نحن ندرك أهمية توفير المعرفة في الوقت المناسب لتسهيل اتخاذ القرارات الاستراتيجية، ويمكننا تلبية المتطلبات العاجلة لمؤسستك في الوقت المحدد.",
          focusOnCoreCompetenciesTitle: "التركيز على الكفاءات الأساسية",
          focusOnCoreCompetenciesDescription:
            "من خلال تعهيد عمليات اكتساب المعرفة إلى شركة تفاصيل لخدمات التعهيد، يمكنك تفريغ مواردك الداخلية للتركيز على الكفاءات الأساسية والمبادرات الاستراتيجية، مما يسمح لك بتوجيه وقتك وطاقتك للعمل على المجالات التي تعزز الابتكار والنمو وخلق القيمة.",
          researchAndMarketIntelligenceTitle:
            "الأبحاث والمعلومات التحليلية للسوق",
          researchAndMarketIntelligenceDescription:
            "نقدم خدمات بحث وتحليل متعمقة لتقديم الدعم المتعلق بالمعلومات التحليلية للسوق وتحليل المنافسين وتوجهات قطاع العمل وآراء العملاء، مما يتيح اتخاذ القرارات المستندة إلى البيانات.",
          dataAnalyticsAndReportingTitle: "تحليل البيانات وتقديم التقارير",
          dataAnalyticsAndReportingDescription:
            "يستفيد خبراؤنا من أدوات وتقنيات التحليلات المتقدمة لمعالجة وتحليل كميات كبيرة من البيانات، مما يوفر رؤى قيمة وتقارير للتخطيط الاستراتيجي وقياس الأداء.",
          contentDevelopmentAndManagementTitle: "تطوير المحتوى وإدارته",
          contentDevelopmentAndManagementDescription:
            "تساعد شركة تفاصيل لخدمات التعهيد في إنشاء وإدارة مختلف أنواع المحتوى، بما في ذلك المقالات والمدونات والتقارير والوثائق التقنية لضمان تقديم جودة عالية ومحتوى جذاب يتماشى مع أهداف مؤسستك.",
          knowledgeManagementSolutionsTitle: "حلول إدارة المعرفة",
          knowledgeManagementSolutionsDescription:
            "نقوم بتطوير وتنفيذ أنظمة وعمليات إدارة المعرفة لاكتساب المعرفة وتنظيمها ونشرها داخل مؤسستك، مما يساهم في إجراء عملية تبادل المعرفة والتعاون بسلاسة.",
          intellectualPropertyServicesTitle: "خدمات الملكية الفكرية",
          intellectualPropertyServicesDescription:
            "يساعد محترفونا في إجراء الأبحاث المتعلقة بالملكية الفكرية وتحليل براءات الاختراع وصياغتها، مما يساعدك في حماية أفكارك وتقنياتك المبتكرة.",
          trainingAndLearningSolutionsTitle: "حلول التدريب والتعلم",
          trainingAndLearningSolutionsDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد حلول التدريب والتعلم، بما فيها نماذج التعلم عبر الانترنت وتطوير المناهج وصياغة المواد التدريبية، وذلك لرفع مهارات الموظفين ومعارفهم.",
          cxAndDigitalOutsourcingTitle:
            "لماذا عليك تعهيد خدمات تجربة العميل والتعهيد الرقمي إلى شركة تفاصيل لخدمات التعهيد؟",
          cxAndDigitalOutsourcingDescription:
            "باختيارك لتعهيد أماكن العمل لدى شركة تفاصيل لخدمات التعهيد فإنك ستتمكن من الاستفادة من العديد من المنافع لمؤسستك",
          knowledgeOutsourcingSpecializedSkills:
            "الاستفادة من المهارات والخبرات التخصصية",
          knowledgeOutsourcingSkillsDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد فريقًا من المحترفين ذوي الخبرة العالية والمعرفة المتخصصة في مجالات متنوعة. يضم فريقنا خبراء وباحثين ومحللي بيانات ومحترفين في مجالات العمل، والذين يمكنهم تقديم الدعم والتحليلات القيمة في مجموعة واسعة من المهام التي تتطلب المعرفة التخصصية.",
          knowledgeOutsourcingCostSavings:
            "خفض التكاليف وتعزيز الكفاءة التشغيلية",
          knowledgeOutsourcingCostSavingsDescription:
            "اختيارك لتعهيد عمليات اكتساب المعرفة إلى شركة تفاصيل لخدمات التعهيد يمكنك من خفض التكاليف المترتبة على توظيف وتدريب وإدارة فريق متخصص في شركتك. حلولنا القابلة للتوسع تضمن استخدامًا أمثل للموارد وإدارة فعّالة لعمليات اكتساب المعرفة، مما يؤدي إلى خفض التكاليف وتحسين كفاءة العمليات.",
          knowledgeOutsourcingFasterTurnaround: "تسريع فترات الاستجابة",
          knowledgeOutsourcingFasterTurnaroundDescription:
            "فريقنا المتفاني من المحترفين في مجال تطوير المعرفة ملتزم بتقديم نتائج عالية الجودة ضمن إطار زمني محدد. نحن ندرك أهمية توفير المعرفة في الوقت المناسب لتسهيل اتخاذ القرارات الاستراتيجية، ويمكننا تلبية المتطلبات العاجلة لمؤسستك في الوقت المحدد.",
          knowledgeOutsourcingFocusOnCoreCompetencies:
            "التركيز على الكفاءات الأساسية",
          knowledgeOutsourcingFocusDescription:
            "من خلال تعهيد عمليات اكتساب المعرفة إلى شركة تفاصيل لخدمات التعهيد، يمكنك تفريغ مواردك الداخلية للتركيز على الكفاءات الأساسية والمبادرات الاستراتيجية، مما يسمح لك بتوجيه وقتك وطاقتك للعمل على المجالات التي تعزز الابتكار والنمو وخلق القيمة.",
          customerServiceOutsourcingDescriptionDigitalTransformation:
            "نقدم حلول خدمة العملاء الشاملة، بما في ذلك حلول تقديم الخدمة عبر الهاتف والبريد الإلكتروني والدردشة ووسائل التواصل الاجتماعي. ممثلو خدمة العملاء لدينا مدربون على تقديم تفاعلات مخصصة وبشكل ودّي، مما يضمن تحقيق مستوى متميز من رضا العملاء.",
          digitalMarketingOutsourcing: "تعهيد عمليات التسويق الالكتروني",
          digitalMarketingOutsourcingDescription:
            "تساعد شركة تفاصيل لخدمات التعهيد في عمليات التسويق الرقمي، بما في ذلك إدارة حسابات منصات التواصل الاجتماعي وكتابة المحتوى وتحسين نتائج محركات البحث والإعلانات المدفوعة والتحليلات. نساعدك على زيادة معدل ظهور العلامة التجارية وجذب حركة التصفح وتوليد قائمة العملاء المحتملين من خلال استراتيجيات التسويق الرقمي الموجّهة.",
          customerAnalyticsInsights: "التحليلات والرؤى المتعلقة بالعملاء",
          customerAnalyticsInsightsDescription:
            "يستخلص محللو البيانات في فريقنا رؤىً قيّمة متعلقة بالعملاء من مصادر بيانات متعددة، مما يسمح باتخاذ قرارات مدروسة مستندة إلى البيانات وإطلاق حملات تسويقية مخصصة وتفاعل عملاء استباقي",
          uxDesign: "تصميم تجربة المستخدم",
          uxDesignDescription:
            "يوفر شركة تفاصيل لخدمات التعهيد حلولاً تسويقية مرنة وقابلة للتطوير يمكنها التكيف مع احتياجات عملك المتغيرة. سواء كنت بحاجة إلى دعم تسويقي مستمر أو مساعدة محددة في الحملة، يمكننا تصميم خدماتنا لتلبية متطلباتك.",
          digitalTransformationConsulting: "استشارات التحول الرقمي",
          digitalTransformationConsultingDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد خدمات استشارية استراتيجية لتوجيه مسيرتك نحو التحول الرقمي. نقوم بتقييم قدراتك الرقمية الحالية وتحديد ما يمكن تحسينه وتطوير خارطة طريق نحو اعتماد نهج رقمي ناجح.",
          aiAutomationSolutions: "حلول الذكاء الاصطناعي والأتمتة",
          aiAutomationSolutionsDescription:
            "يستفيد خبراؤنا من تقنيات الذكاء الاصطناعي والأتمتة لتحسين العمليات وأتمتة المهام المتكررة وتعزيز الكفاءة المتعلقة بتفاعلات العملاء وتحليل البيانات وسير العمليات التشغيلية.",
          workspaceOutsourcingTitle:
            "لماذا عليك تعهيد أماكن العمل إلى شركة تفاصيل لخدمات التعهيد؟",
          workspaceOutsourcingDescription:
            "باختيارك لتعهيد أماكن العمل لدى شركة تفاصيل لخدمات التعهيد فإنك ستتمكن من الاستفادة من العديد من المنافع لمؤسستك",
          costEfficiencyDescriptionOutsourcing:
            "اختيارك لتعهيد أماكن العمل إلى شركة تفاصيل لخدمات التعهيد يمكنك من خفض التكاليف المترتبة على استئجار أماكن العمل وأعمال الصيانة والمرافق العامة وغيرها من المهام الإدارية. حلولنا الاقتصادية من حيث التكلفة تتيح لك خفض نفقات مكان عملك وتخصيص الموارد بصورة أكثر كفاءة.",
          accessToAmenitiesTitle: "الاستفادة من الخدمات ووسائل الراحة",
          accessToAmenitiesDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد مجموعة من وسائل الراحة والخدمات لتحسين تجربتك في أماكن العمل. نضمن لك أن يكون مكان عملك مجهزاً بكل ما تحتاجه للعمل بسلاسة، بدايةً من الإنترنت عالي السرعة والبنية التحتية التكنولوجية الحديثة ووصولاً إلى خدمات الاستقبال وإدارة البريد والدعم الإداري.",
          scalabilityAndExpansionTitle: "توسيع الأعمال وقابلية التوسع",
          scalabilityAndExpansionDescription:
            "حلول أماكن العمل التي نقدمها مصممة لاستيعاب نمو عملك واحتياجاتك المتغيّرة. سواء كنت بحاجة إلى توسيع مساحة العمل الخاصة بك أو التوسع في مواقع جديدة، يمكننا توفير خيارات مرنة تتناسب مع خططك التوسعية.",
          flexibleWorkEnvironmentTitle: "بيئة عمل مرنة",
          flexibleWorkEnvironmentDescription:
            "نقدم حلول مساحة عمل مرنة تلبي احتياجاتك الخاصة. سواء كنت بحاجة إلى مكاتب مخصصة أو مناطق عمل مشتركة أو غرف اجتماعات أو خدمات مكتب افتراضي، يمكننا تخصيص عروضنا لتلبي احتياجاتك.",
          privateOfficeSpacesTitle: "مكاتب خاصّة",
          meetingRoomsTitle: "قاعات الاجتماعات",
          meetingRoomsDescription:
            "تقدم شركة تفاصيل لخدمات التعهيد قاعات اجتماعات مجهّزة بالكامل لإجراء اجتماعات العمل واستعراض العروض التقديمية وإجراء النقاشات مع العملاء. قاعات الاجتماعات التي نقدمها مصممة لتيسير سبل التواصل الفعّال والتعاون.",
          virtualOfficeServicesTitle: "خدمات المكاتب الافتراضية",
          virtualOfficeServicesDescription:
            "نقدم حلول المكاتب الافتراضية التي تتضمن عنواناً مرموقاً وإدارة البريد الالكتروني وإعادة توجيه الاتصالات وإمكانية استخدام قاعات الاجتماعات عند الطلب، مما يمكنك من الحفاظ على السمة الاحترافية والمرونة في عملك.",
          technologyInfrastructureTitle: "بنية تحتية تقنية",
          technologyInfrastructureDescription:
            "تم تجهيز مساحات العمل لدينا بالإنترنت عالي السرعة وأنظمة الاتصالات المتقدمة وإمكانيات عقد مؤتمرات الفيديو والبنية التحتية التكنولوجية الأخرى لدعم عمليات عملك.",
          receptionSupportTitle: "دعم الاستقبال والإدارة",
          receptionSupportDescription:
            "تقدم تفاصيل لخدمات التعهيد الخارجي خدمات الاستقبال، والدعم الإداري، وفريق محترف لمساعدتك في المهام اليومية، مما يضمن تجربة عمل سلسة لك ولفريقك.",
          privateOfficeSpacesDescription:
            "نقدم مكاتب خاصّة مؤثثة بالكامل لتقدم الخصوصية والراحة وبيئة عمل تساعد على الإنتاجية سواءً كانت للأفراد أو الفرق",
          dynamicWorkspaceTitle:
            "حلولنا الديناميكية لأماكن العمل في المناطق الحرة الرائدة.",
          yasirAlMansour: "ياسر المنصور",
          yasirFeedback: `"فاقت شركة تفاصيل لخدمات التعهيد التوقعات بما قدمته من حلول فريدة ومحددة تتناسب مع احتياجاتنا، فقد أسهم نهجهم الإيجابي في خلق أثر بارز، وتميّزهم بادٍ بوضوح في نتائج عملهم القابلة للقياس. إن التزام شركة تفاصيل لخدمات التعهيد بالمواعيد وتلبيتهم لاحتياجات العملاء المخصصة ونهجهم الجذّاب قد وضعهم في الطليعة."`,
          priyaGupta: "بريا غوبتا",
          priyaFeedback: `"تقدم شركة تفاصيل لخدمات التعهيد حلولاً فريدة وواضحة وملائمة لاحتياجات عملائها، والنتائج الملموسة التي يحققونها تبرز تميّزهم الكبير، كما إن التزامهم بالمواعيد وسرعتهم في الإنجاز وقدرتهم على التناغم مع احتياجات عملائهم جعل من شركة تفاصيل لخدمات التعهيد الشريك الأمثل لتحقيق النجاح."`,
          jamalAhmed: "جمال أحمد",
          jamalFeedback: `"قرارنا بالتعاون مع شركة تفاصيل لخدمات التعهيد قرار لن نندم على اتخاذه، فقد فاقت حلولهم المتميزة وواضحة المعالم والتي تلبي احتياجات عملائها بصورة مثالية كافة التوقعات، وقد كان أثرهم الإيجابي في إنجاح عملياتنا بارزاً، ولا داعي للكلام عن تميّزهم، فالنتائج الملموسة خير دليل على ذلك.
                    إن الخدمات الفورية والنوعية التي تقدمها شركة تفاصيل لخدمات التعهيد لا مثيل لها."`,
          speakWithA: "تكلم مع خبير",
          expertToday: "اليوم",
          privacyPoint1: `يكشف إشعار الخصوصية هذا عن ممارسات الخصوصية لشركة "تفاصيل" لخدمات التعهيد ولا ينطبق إشعار الخصوصية هذا سوى على المعلومات التي يجمعها هذا الموقع الذي سيُبلغك بما يلي:`,
          privacyPoint2:
            "ما هي معلومات التعريف الشخصية التي يجمعها منك الموقع، وكيف تُستخدم ومع من يمكن مشاركتها.",
          privacyPoint3:
            "ما هي الاختيارات المتاحة لك فيما يتعلق باستخدام بياناتك.",
          privacyPoint4:
            "الإجراءات الأمنية المتبعة لحماية معلوماتك من إساءة الاستخدام.",
          privacyPoint5: "كيف يمكنك تصحيح أي معلومات غير دقيقة.",
          registration: "تسجيل",
          registrationDescription:
            "من أجل استخدام هذا الموقع، يجب على المستخدم أولاً ملء استمارة التسجيل. أثناء التسجيل، يُطلب من المستخدم تقديم معلومات معينة (مثل الاسم وعنوان البريد الإلكتروني). وتستخدم هذه المعلومات للاتصال بك في أعربت عن اهتمامك بالمنتجات/الخدمات الموجودة على موقعنا.",
          electronicPayments: "المدفوعات الإلكترونية",
          electronicPaymentsDescription: `تستخدم شركة "تفاصيل" لخدمات التعهيد بوابة الدفع الالكترونية CCAvenue لمعاملات الدفع عبر الإنترنت.`,
          informationCollection: "جمع المعلومات واستخدامها ومشاركتها",
          informationCollectionDescription:
            "نحن المالكون الحصريون للمعلومات التي تُجمع على هذا الموقع. لا يتسنى لنا سوى الوصول إلى/جمع المعلومات التي تقدمها لنا طواعيَّةً عبر البريد الإلكتروني أو أي اتصال مباشر آخر منك، ولن نبيع أو نأجِّر هذه المعلومات لأي شخص آخر. سوف نستخدم معلوماتك للرد عليك، وفيما يتعلَّق بسبب اتصالك بنا. لن نشارك معلوماتك مع أي طرف ثالث خارج مؤسستنا، باستثناء ما هو ضروري لتلبية طلبك. إلا إذا طلبت منا عدم القيام بذلك، فقد نتصل بك عبر البريد الإلكتروني في المستقبل لإبلاغك بالعروض الخاصة أو المنتجات والخدمات الجديدة أو التغييرات التي تطرأ على سياسة الخصوصية هذه.",
          sharing: "المشاركة",
          sharingDescription:
            "نحن نشارك المعلومات الديموغرافية المجمعة مع المعلنين لدينا، ولا يرتبط هذا بأي معلومات شخصية يمكن أن تحدِّد هويَّة أي شخص على حدة.",
          yourAccessToControlOverInformation:
            "وصولك إلى المعلومات والتحكم فيها",
          yourAccessToControlOverInformationDescription:
            "يمكنك إلغاء الاشتراك بأي اتصالات مستقبلية من جانبنا في أي وقت تشاء. كما يمكنك القيام بما يلي في أي وقت تشاء من خلال طريق الاتصال بنا عبر عنوان البريد الإلكتروني أو رقم الهاتف الموجود على موقعنا:",
          sharingPoint1: "التعرُّف على البيانات التي لدينا عنك، إن وجدت.",
          sharingPoint2: "تغيير/تصحيح أي بيانات لدينا عنك.",
          sharingPoint3: "طلب حذف أي بيانات لدينا عنك.",
          sharingPoint4: "التعبير عن أي مخاوف لديك بشأن استخدامنا لبياناتك.",
          security: "الحماية",
          securityDescription: `نحن نتخذ الاحتياطات اللازمة لحماية المعلومات الخاصة بك. عندما ترسل معلومات حساسة عبر الموقع الإلكتروني، تكون معلوماتك محميَّة سواء كنت متصل أو غير متصل عبر الإنترنت. أينما نجمع معلومات حساسة (مثل بيانات بطاقة الائتمان)، تُشفَّر هذه المعلومات وتنقل إلينا بطريقة آمنة. يمكنك التحقق من ذلك من خلال البحث عن أيقونة القفل في شريط العناوين والبحث عن ""https"" في بداية عنوان صفحة الويب.`,
          securityDescription2:
            "على الرغم من أننا نستخدم التشفير لحماية المعلومات الحساسة المنقولة عبر الإنترنت، فإننا نحمي أيضًا معلوماتك في حال عدم اتصالك عبر الانترنيت. لا يُسمح بالوصول إلى معلومات التعريف الشخصيّة إلا للموظفين الذين يحتاجون إليها لأداء مهمة معينة (كإعداد الفواتير أو خدمة العملاء على سبيل المثال). تُحفظ أجهزة الكمبيوتر/الخوادم التي تخزَّن فيها معلومات التعريف الشخصية في بيئة آمنة.",
          securityDescription3:
            "إذا شعرت أننا لا نلتزم بسياسة الخصوصية هذه، عليك الاتصال بنا فورًا عبر الهاتف على الرقم 600599996 أو عبر البريد الإلكتروني على",

          fairUsagePolicy: "سياسة الاستخدام العادل",
          fairUsagePoint1: `تنطبق سياسة الاستخدام العادل الخاصة بنا على الخدمات التي يُنص عليها بأنها تخضع لسياسة الاستخدام العادل (""خدمات الاستخدام العادل"").`,
          fairUsagePoint2: "تهدف سياسة الاستخدام العادل لدينا إلى ضمان ما يلي:",
          fairUsagePoint3:
            "توفُّر خدماتنا لجميع العملاء المؤهلين في شركة تفاصيل لخدمات التعهيد؛ و",
          fairUsagePoint4:
            "عدم استخدام خدمات الاستخدام العادل بطريقة غير مقبولة.",
          fairUsagePoint5:
            "وفي هذا الصدد فإننا نحتفظ بحقنا في تغيير شروط سياسة الاستخدام العادل هذه من وقت لآخر دون إشعار مسبق وبتنفيذ فوري.",
          fairUsagePoint6:
            "كما يمكننا الاعتماد على سياسة الاستخدام العادل عندما يكون استخدامك لخدمات الاستخدام العادل غير مقبول، كما هو محدد أدناه.",
          unreasonableUse: "الاستخدام غير المقبول",
          unreasonableUseDescription:
            "يعد استخدامك لخدمة الاستخدام العادل غير مقبولاً إذا اعتبرت شركة تفاصيل لخدمات التعهيد على نحو منطقي بأن استخدامك كان:",
          unreasonableUsePointA: "أ. احتيالياً؛",
          unreasonableUsePointB: "ب. تضمَّن استخداماً غير عادي؛",
          unreasonableUsePointC:
            'ج. تسبب في إحداث ازدحام كبير في الشبكة أو تعطيلها أو التأثير سلبًا على شبكة شركة "تفاصيل" للخدمات التعهيد، وهي شبكة الموردين؛ أو',
          unreasonableUsePointD:
            'د.  أثَّر سلبًا في استخدام شخص آخر لخدمات الاستخدام العادل أو وصوله إليها أو شبكة شركة "تفاصيل" لخدمات التعهيد أو شبكة الموردين.',
          unreasonableUseWithoutLimitation: "على سبيل المثال لا الحصر:",
          unreasonableUsePointAFraudulent:
            'أ. يتضمن الاستخدام الاحتيالي إعادة توفير خدمة ما أو إعادة بيعها دون الحصول على موافقة خطيّة من شركة "تفاصيل" لخدمات التعهيد ليتمكن شخص آخر من الوصول إلى خدمة الاستخدام العادل أو استخدامها أو الاستفادة منها تجاريًا؛',
          unreasonableUsePointBNonOrdinary:
            "ب. يشمل الاستخدام غير العادي الظروف التي تُجري فيها مكالمات بأي طريقة غير عادية أو تتلقاها دون الحصول على موافقتنا الخطية أولاً، التي قد نمنحها أو نحجبها أو نخضعها للشروط وذلك حسب تقديرنا. يشمل الاستخدام بطريقة غير عادية:",
          unreasonableUseForwardingExcessiveCalls:
            "الإفراط في إعادة توجيه المكالمات (أي عندما يكون عدد المكالمات من النوع ذي الصلة في فترة معينة على الأقل ثلاثة أضعاف متوسط عدد المكالمات من هذا النوع في تلك الفترة لجميع العملاء الموجودين في نفس الخطة)",
          unreasonableUseExtensiveCallTimeSpan:
            "إطالة مدة المكالمة (أي حيث تكون مدة المكالمة من النوع ذي الصلة في فترة معينة على الأقل ثلاثة أضعاف متوسط مدة المكالمة من هذا النوع في تلك الفترة لجميع العملاء الموجودين في نفس الخطة)",
          unreasonableUseTransferringCalls:
            "تحويل المكالمات من أكثر من فرع (1) دون الحصول على موافقة",
          unreasonableUseUsageToMenace:
            "استخدام المكالمات لتهديد أو إزعاج أو أذية أي شخص ما أو التسبب بإعطاب أو تلف أي شيء؛",
          unreasonableUseUsageInConnectionWithBreach:
            "استخدام المكالمات فيما يتعلق بارتكاب أي انتهاك أو خرق لأي قوانين أو أنظمة أو ضوابط أو سياسات؛",
          unreasonableUseOtherActivity:
            "إضافة إلى أي نشاط آخر لا يمكن اعتباره إلى حد معقول استخدامًا عاديًا في إطار خدمة الاستخدام العادل.",
          ourRights: "حقوقنا",
          ourRightsDescription:
            "إذا اعتبرنا إلى حدٍ منطقي أن استخدامك لخدمات الاستخدام العادل غير مقبولة، يحق لنا، وفقًا لتقديرنا الخاص، ودون إبلاغك مسبقاً أن نقوم ما يلي:",
          ourRightsPointA:
            "أ. حظر المنافذ الواردة كي لا تتمكن من استضافة الخدمات",
          ourRightsPointB: `ب. إزالة أي ميزة مكالمات غير محدودة وتحصيل رسوم مكالماتك بالأسعار القياسية الخاصة بشركة "تفاصيل" لتعهيد إدراة الأعمال التجارية؛`,
          ourRightsPointC:
            "ج. تعليق الخدمة أو الحد منها (أو أي من ميزة لها) وفقًا لاتفاقيتنا معك؛ و/أو",
          ourRightsPointD: "د. إنهاء الخدمة وفقًا لاتفاقيتنا معك.",
          ourRightsPolicySupplementary:
            "تعد هذه السياسة مكملة لسياسة العامة لشركة تفاصيل لتعهيد إدارة الأعمال التجارية ولا تقيِّد أي حقٍ من حقوقها.",
          peopleOurPower: "الناس... قوتنا",
          placesAndPrecence: "الأماكن والحضور",
          values: "قيمنا",
          process2: "العمليات",
          termsOfUse: "شروط الاستخدام",
          termsOfUseDescription: `ُنظِّم شروط الاستخدام القياسيَّة لهذا الموقع الالكترونيِّ (هذه "الشروط") الواردة هنا في صفحة الويب هذه، استخدامك لموقع شركة تفاصيل لخدمات التعهيد، بما في ذلك جميع الصفحات الموجودة في هذا الموقع (المشار إليها مجتمعة أدناه باسم "الموقع الإلكترونيِّ"). وتنطبق هذه الشروط بكامل القوَّة والتأثير على استخدامك لهذا الموقع، وباستخدام هذا الموقع، فإنك تقبل صراحة جميع الشروط والأحكام الواردة فيه بالكامل. يجب عليك عدم استخدام هذا الموقع إذا كان لديك أيُّ اعتراض على أيٍّ من هذه الشروط.`,
          termsOfUseMinors:
            "يجب ألا يستخدم القاصرون (ممن تقل أعمارهم عن 18 عامًا) هذا الموقع دون موافقة أو إشراف البالغين.",
          intellectualPropertyRights: "حقوق الملكيَّة الفكريَّة",
          intellectualPropertyRightsDescription:
            "بموجب هذه الشروط، تمتلك شركة تفاصيل لخدمات التعهيد و/أو الجهات المرخَّصة التابعة لها جميع حقوق الملكيَّة الفكريَّة والمواد الواردة في هذا الموقع، وجميع هذه الحقوق محفوظة.",
          intellectualPropertyRightsLicense:
            "لا يُمنح المستخدم سوى ترخيصًا محدودًا، مع مراعاة القيود المنصوص عليها في هذه الشروط، وذلك بهدف عرض المواد الواردة في هذا الموقع.",
          restrictions: "القيود",
          restrictionsIntro: "أنت محظور بشكل صريح وقاطع من كل ما يلي:",
          restrictionPublishing:
            "نشر أي مواد خاصة بالموقع الإلكتروني بأي وسيلة إعلامية؛",
          restrictionCommercializing:
            "بيع وترخيص من الباطن و/أو تسويق أي مادة من مواد الموقع الإلكتروني؛",
          restrictionPubliclyPerforming:
            "أداء و/أو عرض أي مادة من مواد الموقع الإلكتروني بشكل علني؛",
          restrictionDamagingUse:
            "استخدام هذا الموقع بأي طريقة تلحق الضرر بهذا الموقع أو قد تضر به؛",
          restrictionImpactingAccess:
            "استخدام هذا الموقع بأي طريقة تؤثر على وصول المستخدم إلى هذا الموقع؛",
          restrictionContraryToLaws:
            "استخدام هذا الموقع بشكل مخالف للقوانين واللوائح المعمول بها، أو بطريقة تسبب، أو قد تسبب، ضررًا للموقع، أو لأي شخص أو كيان تجاري؛",
          restrictionDataMining:
            "الانخراط في استخراج للبيانات، أو جمع البيانات، أو استخراج البيانات، أو أي نشاط مماثل آخر فيما يتعلق بهذا الموقع أو أثناء استخدام هذا الموقع؛",
          restrictionUnauthorizedAdvertising:
            "استخدام هذا الموقع للمشاركة في أي إعلان أو تسويق غير مصرح به؛",
          restrictionConfidentialInfo:
            "يتم حظر وصولك إلى مناطق معينة في هذا الموقع، ويجوز لـ شركة تفاصيل لخدمات التعهيد تقييد وصولك إلى أي مناطق في هذا الموقع، في أي وقت، وفقًا لتقديرها الوحيد والمطلق. إن أي معرف مستخدم وكلمة مرور قد تكون لديك لهذا الموقع تعتبر سرية، ويجب عليك الحفاظ على سرية هذه المعلومات.",
          yourContent: "المحتوى الخاص بك",
          yourContentDescription:
            'في هذه الشروط والأحكام القياسية لموقع الويب، "المحتوى الخاص بك" يعني أي صوت أو فيديو أو نص أو صور أو مواد أخرى تختار عرضها على هذا الموقع. فيما يتعلق بالمحتوى الخاص بك، من خلال عرضه، فإنك تمنح شركة تفاصيل لخدمات التعهيد ترخيصًا غير حصري وعالمي وغير قابل للإلغاء وخالي من حقوق الملكية وقابل للترخيص من الباطن لاستخدامه وإعادة إنتاجه وتكييفه ونشره وترجمته وتوزيعه في أي وسيط وفي جميع الوسائط. يجب أن يكون محتواك خاصًا بك، ويجب ألا تُنتهك حقوق أي طرف ثالث. تحتفظ شركة تفاصيل لخدمات التعهيد بالحق في إزالة أي محتوى خاص بك من هذا الموقع في أي وقت ولأي سبب دون إشعار.',
          noWarranties: "لا ضمانات",
          noWarrantiesDescription:
            'يتم توفير هذا الموقع "كما هو" مع جميع الأخطاء، ولا تقدم شركة تفاصيل لخدمات التعهيد أي تعهدات أو ضمانات صريحة أو ضمنية من أي نوع تتعلق بهذا الموقع أو المواد الواردة في هذا الموقع. بالإضافة إلى ذلك، لا يجوز تفسير أي شيء وارد في هذا الموقع على أنه تقديم استشارة أو مشورة لك.',
          limitationOfLiability: "تحديد المسؤولية",
          limitationOfLiabilityDescription:
            "لن تتحمل شركة تفاصيل لخدمات التعهيد أو أي من مسؤوليها ومديريها وموظفيها بأي حال من الأحوال المسؤولية تجاهك عن أي شيء ينشأ عن أو يرتبط بأي شكل من الأشكال باستخدامك لهذا الموقع، سواء كانت هذه المسؤولية بموجب عقد أو ضرر أو غير ذلك. لن يكون شركة تفاصيل لخدمات التعهيد، بما في ذلك مسؤوليه ومديريه وموظفيه، مسؤولين عن أي مسؤولية غير مباشرة أو تبعية أو خاصة تنشأ عن أي شكل من الأشكال باستخدامك لهذا الموقع أو تتعلق به.",
          indemnification: "التعويض",
          indemnificationDescription:
            "أنت بموجب هذا تعوض إلى أقصى حد شركة تفاصيل لخدمات التعهيد من وضد أي وجميع الالتزامات والتكاليف والمطالب وأسباب الدعوى والأضرار والمصروفات (بما في ذلك أتعاب المحاماة المعقولة) الناشئة عن أو بأي شكل من الأشكال المتعلقة بخرقك لأي من الشروط والأحكام. أحكام هذه الشروط.",
          severability: "الاستقلالية",
          severabilityDescription:
            "إذا تبين أن أي حكم من هذه الشروط غير قابل للتنفيذ أو غير صالح بموجب أي قانون معمول به، فإن عدم قابلية التنفيذ أو البطلان هذا لا يجعل هذه الشروط غير قابلة للتنفيذ أو غير صالحة ككل، ويجب حذف هذه الأحكام دون التأثير على الأحكام المتبقية هنا.",
          variationOfTerms: "اختلاف المصطلحات",
          variationOfTermsDescription:
            "يُسمح لـ شركة تفاصيل لخدمات التعهيد بمراجعة هذه الشروط في أي وقت حسب ما تراه مناسبًا، وباستخدام هذا الموقع، يُتوقع منك مراجعة هذه الشروط بشكل منتظم للتأكد من فهمك لجميع الشروط والأحكام التي تحكم استخدام هذا الموقع.",
          assignment: "تكليف",
          assignmentDescription:
            "يُسمح لشركة تفاصيل لخدمات التعهيد بتعيين حقوقها و/أو التزاماتها ونقلها والتعاقد من الباطن بموجب هذه الشروط دون الحاجة إلى أي إشعار أو موافقة. ومع ذلك، لا يُسمح لك بتعيين أو نقل أو التعاقد من الباطن على أي من حقوقك و/أو التزاماتك بموجب هذه الشروط.",
          refunds: "المبالغ المستردة",
          refundsDescription:
            "تُقدَّم خدمات شركة تفاصيل لخدمات التعهيد دون أي التزامات دفع تعاقدية، حيث تعتبر كل دفعة تتم إلى شركة تفاصيل لخدمات التعهيد مدفوعة بالكامل مقدمًا، ولن تكون قابلة للاسترداد.",
          cancellationPolicy: "سياسة الإلغاء",
          cancellationPolicyDescription:
            "إذا كنت ترغب في إلغاء خدمة قمت بشرائها ودفع قيمتها مقدمًا من شركة تفاصيل لخدمات التعهيد، يجب أن ترسل بريدًا إلكترونيًا إلى info@tafaseel.ae تطلب فيه الإلغاء، دون الحاجة إلى تبرير دوافعك. لن يتم قبول طلبات استرداد الأموال في حالة الإلغاء المبكر للخدمات المشتراة.",
          entireAgreement: "الاتفاق الكامل",
          entireAgreementDescription:
            "تشكل هذه الشروط، بما في ذلك أي إشعارات قانونية وإخلاء المسؤولية المتضمنة على هذا الموقع الإلكتروني، الاتفاق الكامل بين شركة تفاصيل لخدمات التعهيد وبينك فيما يتعلق باستخدامك لهذا الموقع الإلكتروني، وتحل هذه الشروط محل جميع الاتفاقات السابقة بشأن هذا الموضوع.",
          governingLawJurisdiction: "القانون الساري والاختصاص القضائي",
          governingLawJurisdictionDescription:
            "هذه الشروط خاضعة لقوانين دولة الإمارات العربية المتحدة وتفسّر بموجبها، وتقدم إلى القضاء غير الحصري للمحاكم الاتحادية والولائية الموجودة في عجمان، الإمارات العربية المتحدة، لحل أي نزاعات.",
        },
      },
    },
  });
