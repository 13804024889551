import { Link } from "react-router-dom";

const ErrorComp = () => {
  return (
    <div className="container d-flex cont-error justify-content-center">
      <div className="row align-items-center">
        <div>
          <h1>
            <num1>404</num1>
          </h1>
          <h3>
            <num1>Oops! This Page Could Not Be Found</num1>
          </h3>
          <h5>
            <num1>Sorry but the page you are looking for does not exists</num1>
          </h5>
          <Link to="/">
            <button className="btn btn-error-cont mt-2">Go To Homepage</button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ErrorComp;
