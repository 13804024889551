import Accordion from "react-bootstrap/Accordion";

import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import EnquiryFormSmall from "../EnquirFormSmall";
import { Trans } from "react-i18next";

const HumanResourcesLanding = (props) => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const [counterOn, setCounterOn] = useState(false);
  return (
    <div className="cont-services-digital-cx">
      <Helmet>
        <title>
          Optimize HR Functions: Human Resources Outsourcing with Tafaseel BPO
        </title>
        <meta
          name="description"
          content="Elevate your HR management with Tafaseel BPO's human resources outsourcing solutions. Collaborate with our expert HR professionals to streamline processes, enhance talent acquisition, and foster a productive workforce. Benefit from our strategic insights, compliance expertise, and dedication to optimizing your HR functions."
        />
      </Helmet>
      <div className="hr-img0  mt-md-5 align-items-center">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6 mt-5">
            <h1 className="mb-5"><Trans i18nKey="humanResourceOutSourcing">Human Resources Outsourcing</Trans></h1>
          </div>
          <div className="col-12 col-lg-6">
            <EnquiryFormSmall subProp={props.subProp}></EnquiryFormSmall>
          </div>
        </div>
      </div>

      {/* ////////////////Intelligent solutions/////////////////// */}
      <div className="container-fluid intelligent-text">
        <div className="row align-items-center">
          <div class="col-12 col-md-6 p-5 text-start">
            <h3 data-aos="fade-up">
              Why Choose Human Resources Outsourcing with Tafaseel BPO?
            </h3>
            <p className="pe-4" data-aos="fade-up">
              When you choose human resources outsourcing with Tafaseel BPO, you
              gain access to numerous benefits that can transform your HR
              operations
            </p>
          </div>
          <div class="col-12 col-md-6 p-5 text-start" data-aos="fade-up">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Cost Savings</Accordion.Header>
                <Accordion.Body>
                  Outsourcing your HR functions to Tafaseel BPO allows you to
                  reduce costs associated with hiring, training, and managing an
                  in-house HR team. Our cost-effective solutions provide access
                  to skilled HR professionals without the burden of additional
                  expenses.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Expertise and Compliance: Tafaseel BPO brings a team of
                  experienced HR{" "}
                </Accordion.Header>
                <Accordion.Body>
                  Tafaseel BPO brings a team of experienced HR professionals who
                  possess in-depth knowledge of HR best practices and compliance
                  regulations. We ensure that your HR processes align with legal
                  requirements and industry standards.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Scalability and Flexibility</Accordion.Header>
                <Accordion.Body>
                  Our HR outsourcing solutions are scalable and flexible,
                  allowing you to adjust your HR support based on your business
                  needs. Whether you require full-time HR assistance or
                  project-based support, we can customize our services to meet
                  your requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Focus on Strategic Initiatives
                </Accordion.Header>
                <Accordion.Body>
                  By outsourcing your HR functions, you can redirect your
                  internal resources and HR personnel to focus on strategic
                  initiatives and core business activities. This enables you to
                  drive innovation, enhance employee engagement, and achieve
                  your business objectives.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////////// */}

      <div
        id="carouselExampleFade"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card col-lg-3">
                <div class="card-body">
                  <h5 class="card-title">Recruitment and Talent Acquisition</h5>
                  <p class="card-text p-3">
                    We assist in sourcing, screening, and selecting top talent
                    for your organization, ensuring a robust recruitment process
                    and attracting the right candidates.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title">
                    Employee Onboarding and Offboarding
                  </h5>
                  <p class="card-text p-3">
                    Our HR professionals handle the onboarding and offboarding
                    process, ensuring a seamless transition for new hires and
                    departing employees.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title">
                    Payroll and Benefits Administration
                  </h5>
                  <p class="card-text p-3">
                    Tafaseel BPO manages payroll processing, benefits
                    administration, and compliance with employment regulations,
                    ensuring accurate and timely payments to your employees.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card col-lg-3">
                <div class="card-body">
                  <h5 class="card-title">HR Policies and Compliance</h5>
                  <p class="card-text p-3">
                    We develop and implement HR policies and procedures that
                    align with your organization&#39;s goals and comply with
                    relevant employment laws and regulations.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title">Performance Management</h5>
                  <p class="card-text p-3">
                    Our HR experts assist in designing and implementing
                    performance management systems, including goal setting,
                    performance evaluations, and development plans.
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block">
                <div class="card-body">
                  <h5 class="card-title">Employee Relations and Engagement</h5>
                  <p class="card-text p-3">
                    Tafaseel BPO supports employee relations initiatives,
                    fosters a positive work environment, and implements
                    engagement strategies to enhance employee satisfaction and
                    retention.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default HumanResourcesLanding;
