import axios from "axios";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import jsonp from "jsonp";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { db } from "../firebaseConfig";
import { Trans, useTranslation } from "react-i18next";
import ar from 'react-phone-input-2/lang/ar.json'

const EnquiryForm = (prop) => {
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryLocalization, setCountryLocalization] = useState('')
  const { t, i18n } = useTranslation();
  let subProp = prop.subProp !== undefined ? prop.subProp : t('contactUs');
  // console.log(subProp);
  // console.log(subProp);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formspreeUrl = process.env.REACT_APP_FORMSPREE_URL;
    // Get the form data
    const formData = new FormData(event.target);
    // submitNotifyForm(formData);
    // const dialCode = countryCode.dialCode;
    const formDataObj = {};
    for (let [name, value] of formData.entries()) {
      formDataObj[name] = value;
    }

    const formDataSenderObj = {
      SUBJECT: formDataObj.SUBJECT,
      EMAIL: formDataObj.EMAIL,
      PHONE: formDataObj.PHONE,
      FIRSTNAME: formDataObj["FIRST NAME"],
      LASTNAME: formDataObj["LAST NAME"],
    };
    // console.log(formDataObj);

    // const dialCode = countryCode.dialCode;
    // console.log(dialCode);
    // console.log(phone);

    try {
      await addDoc(collection(db, "sales-leads"), {
        ...formDataObj,
        Timestamp: serverTimestamp(),
        LeadStatus: "Not Contacted",
        Source: "Organic",
        Subject: subProp,
      });
      // alert("Form submitted successfully!");
    } catch (err) {
      console.log(err);
    }

    // submitNotifyForm(formData);
    try {
      // Send a POST request to Formspree endpoint
      await axios.post(formspreeUrl, formData);

      // If both submissions are successful, push the event to dataLayer for GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmission'
      });

      // Perform any desired actions after successful form submission
      // For example, display a success message
      alert("Form submitted successfully!");
      event.target.reset();
      setPhone("+971");
      submitNotifyForm(formDataSenderObj);
    } catch (error) {
      alert("Form submission failed!");
    }
  };

  const submitNotifyForm = (formData) => {
    // console.log(formData);
    const url = process.env.REACT_APP_API_URL;

    let queryString = "";
    for (const [name, value] of Object.entries(formData)) {
      queryString += `&${encodeURIComponent(name)}=${encodeURIComponent(
        value
      )}`;
    }
    console.log(`${queryString}`);

    jsonp(`${url}${queryString}`, { param: "c" }, (_, data) => {
      const { msg, result } = data;
      // do something with the response
      // alert(msg);
    });
  };

  useEffect(() => {
    if (i18n.language === 'ar')
      setCountryLocalization(ar);
    else
      setCountryLocalization('');
  }, [i18n.language])

  return (
    <div className="container-fluid cont-form cont-enquiry-form pt-5 pb-5">
      <div className="row justify-content-center align-items-center">
        <h1 className="col-lg-6 text-white">
          <span><Trans i18nKey="speakWithA">Speak with a</Trans></span> {subProp} <span><Trans i18nKey="expertToday">expert today</Trans></span>
        </h1>
        <form
          className="ps-5 pe-5 col-12 col-lg-5 pt-4 pb-5"
          onSubmit={handleSubmit}
        >
          <h1 className="pb-3 form-text-h1"><Trans i18nKey="letsTalk">Let's Talk</Trans></h1>

          <div className="row">
            <div class="mb-4 col-12 col-lg-6">
              <input name="SUBJECT" type="hidden" value={subProp} />
              <input
                name="FIRST NAME"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder={t('firstName')}
                required
              ></input>
            </div>
            <div class="mb-4 col-12 col-lg-6">
              <input
                name="LAST NAME"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder={t('lastName')}
                required
              ></input>
            </div>
            <div class="mb-4 col-12 col-md-12">
              <input
                name="EMAIL"
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder={t('yourEmail')}
                style={{ fontFamily: "sans-serif" }}
                required
              ></input>
            </div>
            <div class="mb-4 col-12 col-lg-6">
              <num1>

                {i18n.language !== 'ar' && <PhoneInput
                  country={"ae"}
                  value={phone}
                  onChange={(value, country, e, formattedValue) => {
                    setPhone(formattedValue);
                    setCountryCode(country);
                  }}
                  inputStyle={{ width: "100%" }}
                  placeholder={t('phoneNo')}
                  required
                />}
                {i18n.language === 'ar' &&
                  <PhoneInput
                    country={"ae"}
                    value={phone}
                    onChange={(value, country, e, formattedValue) => {
                      setPhone(formattedValue);
                      setCountryCode(country);
                    }}
                    localization={ar}
                    inputStyle={{ width: "100%" }}
                    placeholder={t('phoneNo')}
                    required
                  />}
              </num1>
              <input name="PHONE" className="form-control" type="hidden" value={phone} />
              {/* <input
                name="PHONE"
                type="number"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Phone No. (*)"
                required
                style={{ fontFamily: "sans-serif" }}
              ></input> */}
            </div>
            <div class="mb-3 col-12 col-lg-6">
              <input
                name="COMPANY"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder={t('company')}
                required
                style={{ fontFamily: "sans-serif" }}
              ></input>
            </div>
            <div class="mb-4 col-12 col-lg-6">
              <input
                name="JOB"
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder={t('jobTitle')}
                required
                style={{ fontFamily: "sans-serif" }}
              ></input>
            </div>

            <div class="mb-4 col-12 col-lg-6 w-40">
              <select
                class="form-select"
                id="referalDropdown"
                name="Country To Be Served?"
                required
              >
                <option value="" selected>
                  <Trans i18nKey="countryToBeServed">Country to be served (*)</Trans>
                </option>
<option value="Albania"><Trans i18nkey="Albania">Albania</Trans></option>
<option value="Algeria"><Trans i18nkey="Algeria">Algeria</Trans></option>
<option value="Angola"><Trans i18nkey="Angola">Angola</Trans></option>
<option value="Argentina"><Trans i18nkey="Argentina">Argentina</Trans></option>
<option value="Austria"><Trans i18nkey="Austria">Austria</Trans></option>
<option value="Australia"><Trans i18nkey="Australia">Australia</Trans></option>
<option value="Bahrain"><Trans i18nkey="Bahrain">Bahrain</Trans></option>
<option value="Belarus"><Trans i18nkey="Belarus">Belarus</Trans></option>
<option value="Belgium"><Trans i18nkey="Belgium">Belgium</Trans></option>
<option value="Benin"><Trans i18nkey="Benin">Benin</Trans></option>
<option value="Bosnia And Herzegovina"><Trans i18nkey="BosniaAndHerzegovina">Bosnia And Herzegovina</Trans></option>
<option value="Brazil"><Trans i18nkey="Brazil">Brazil</Trans></option>
<option value="Bulgaria"><Trans i18nkey="Bulgaria">Bulgaria</Trans></option>
<option value="Cameroon"><Trans i18nkey="Cameroon">Cameroon</Trans></option>
<option value="Canada"><Trans i18nkey="Canada">Canada</Trans></option>
<option value="Chile"><Trans i18nkey="Chile">Chile</Trans></option>
<option value="China"><Trans i18nkey="China">China</Trans></option>
<option value="Colombia"><Trans i18nkey="Colombia">Colombia</Trans></option>
<option value="Comoros"><Trans i18nkey="Comoros">Comoros</Trans></option>
<option value="Costa Rica"><Trans i18nkey="CostaRica">Costa Rica</Trans></option>
<option value="Croatia"><Trans i18nkey="Croatia">Croatia</Trans></option>
<option value="Czech Republic"><Trans i18nkey="CzechRepublic">Czech Republic</Trans></option>
<option value="Denmark"><Trans i18nkey="Denmark">Denmark</Trans></option>
<option value="Dominican Republic"><Trans i18nkey="DominicanRepublic">Dominican Republic</Trans></option>
<option value="Egypt"><Trans i18nkey="Egypt">Egypt</Trans></option>
<option value="El Salvador"><Trans i18nkey="ElSalvador">El Salvador</Trans></option>
<option value="Estonia"><Trans i18nkey="Estonia">Estonia</Trans></option>
<option value="Ethiopia"><Trans i18nkey="Ethiopia">Ethiopia</Trans></option>
<option value="Finland"><Trans i18nkey="Finland">Finland</Trans></option>
<option value="France"><Trans i18nkey="France">France</Trans></option>
<option value="Georgia"><Trans i18nkey="Georgia">Georgia</Trans></option>
<option value="Germany"><Trans i18nkey="Germany">Germany</Trans></option>
<option value="Ghana"><Trans i18nkey="Ghana">Ghana</Trans></option>
<option value="Greece"><Trans i18nkey="Greece">Greece</Trans></option>
<option value="Guatemala"><Trans i18nkey="Guatemala">Guatemala</Trans></option>
<option value="Guinea"><Trans i18nkey="Guinea">Guinea</Trans></option>
<option value="Guyana"><Trans i18nkey="Guyana">Guyana</Trans></option>
<option value="Honduras"><Trans i18nkey="Honduras">Honduras</Trans></option>
<option value="Hungary"><Trans i18nkey="Hungary">Hungary</Trans></option>
<option value="India"><Trans i18nkey="India">India</Trans></option>
<option value="Indonesia"><Trans i18nkey="Indonesia">Indonesia</Trans></option>
<option value="Ireland"><Trans i18nkey="Ireland">Ireland</Trans></option>
<option value="Israel"><Trans i18nkey="Israel">Israel</Trans></option>
<option value="Italy"><Trans i18nkey="Italy">Italy</Trans></option>
<option value="Jamaica"><Trans i18nkey="Jamaica">Jamaica</Trans></option>
<option value="Japan"><Trans i18nkey="Japan">Japan</Trans></option>
<option value="Jordan"><Trans i18nkey="Jordan">Jordan</Trans></option>
<option value="Kenya"><Trans i18nkey="Kenya">Kenya</Trans></option>
<option value="Kuwait"><Trans i18nkey="Kuwait">Kuwait</Trans></option>
<option value="Latam"><Trans i18nkey="Latam">Latam</Trans></option>
<option value="Latvia"><Trans i18nkey="Latvia">Latvia</Trans></option>
<option value="Lebanon"><Trans i18nkey="Lebanon">Lebanon</Trans></option>
<option value="Lithuania"><Trans i18nkey="Lithuania">Lithuania</Trans></option>
<option value="Luxembourg"><Trans i18nkey="Luxembourg">Luxembourg</Trans></option>
<option value="Macedonia"><Trans i18nkey="Macedonia">Macedonia</Trans></option>
<option value="Madagascar"><Trans i18nkey="Madagascar">Madagascar</Trans></option>
<option value="Malaysia"><Trans i18nkey="Malaysia">Malaysia</Trans></option>
<option value="Malta"><Trans i18nkey="Malta">Malta</Trans></option>
<option value="Mauritius"><Trans i18nkey="Mauritius">Mauritius</Trans></option>
<option value="Mexico"><Trans i18nkey="Mexico">Mexico</Trans></option>
<option value="Montenegro"><Trans i18nkey="Montenegro">Montenegro</Trans></option>
<option value="Morocco"><Trans i18nkey="Morocco">Morocco</Trans></option>
<option value="Mozambique"><Trans i18nkey="Mozambique">Mozambique</Trans></option>
<option value="Netherlands"><Trans i18nkey="Netherlands">Netherlands</Trans></option>
<option value="New Zealand"><Trans i18nkey="NewZealand">New Zealand</Trans></option>
<option value="Nicaragua"><Trans i18nkey="Nicaragua">Nicaragua</Trans></option>
<option value="Nigeria"><Trans i18nkey="Nigeria">Nigeria</Trans></option>
<option value="Norway"><Trans i18nkey="Norway">Norway</Trans></option>
<option value="Oman"><Trans i18nkey="Oman">Oman</Trans></option>
<option value="Peru"><Trans i18nkey="Peru">Peru</Trans></option>
<option value="Philippines"><Trans i18nkey="Philippines">Philippines</Trans></option>
<option value="Poland"><Trans i18nkey="Poland">Poland</Trans></option>
<option value="Portugal"><Trans i18nkey="Portugal">Portugal</Trans></option>
<option value="Qatar"><Trans i18nkey="Qatar">Qatar</Trans></option>
<option value="Romania"><Trans i18nkey="Romania">Romania</Trans></option>
<option value="Russia"><Trans i18nkey="Russia">Russia</Trans></option>
<option value="Saudi Arabia"><Trans i18nkey="SaudiArabia">Saudi Arabia</Trans></option>
<option value="Senegal"><Trans i18nkey="Senegal">Senegal</Trans></option>
<option value="Serbia"><Trans i18nkey="Serbia">Serbia</Trans></option>
<option value="Singapore"><Trans i18nkey="Singapore">Singapore</Trans></option>
<option value="Slovakia"><Trans i18nkey="Slovakia">Slovakia</Trans></option>
<option value="Slovenia"><Trans i18nkey="Slovenia">Slovenia</Trans></option>
<option value="Somalia"><Trans i18nkey="Somalia">Somalia</Trans></option>
<option value="South Africa"><Trans i18nkey="SouthAfrica">South Africa</Trans></option>
<option value="South Korea"><Trans i18nkey="SouthKorea">South Korea</Trans></option>
<option value="South Sudan"><Trans i18nkey="SouthSudan">South Sudan</Trans></option>
<option value="Spain"><Trans i18nkey="Spain">Spain</Trans></option>
<option value="Suriname"><Trans i18nkey="Suriname">Suriname</Trans></option>
<option value="Sweden"><Trans i18nkey="Sweden">Sweden</Trans></option>
<option value="Switzerland"><Trans i18nkey="Switzerland">Switzerland</Trans></option>
<option value="Tanzania"><Trans i18nkey="Tanzania">Tanzania</Trans></option>
<option value="Thailand"><Trans i18nkey="Thailand">Thailand</Trans></option>
<option value="Tunisia"><Trans i18nkey="Tunisia">Tunisia</Trans></option>
<option value="Turkey"><Trans i18nkey="Turkey">Turkey</Trans></option>
<option value="United Arab Emirates"><Trans i18nkey="UnitedArabEmirates">United Arab Emirates</Trans></option>
<option value="Uganda"><Trans i18nkey="Uganda">Uganda</Trans></option>
<option value="Ukraine"><Trans i18nkey="Ukraine">Ukraine</Trans></option>
<option value="United Kingdom"><Trans i18nkey="UnitedKingdom">United Kingdom</Trans></option>
<option value="United States"><Trans i18nkey="UnitedStates">United States</Trans></option>
<option value="Vietnam"><Trans i18nkey="Vietnam">Vietnam</Trans></option>
<option value="Zimbabwe"><Trans i18nkey="Zimbabwe">Zimbabwe</Trans></option>

              </select>
            </div>

            <div class="mb-4 col-12 col-lg-6">
              <select
                class="form-select"
                id="industryDropdown"
                name="Industry"
                required
              >
                <option value="" selected>
                  <Trans i18nKey="industry">Industry (*)</Trans>
                </option>
                <option value="Media & Communication">
                  <Trans i18nKey="mediaAndCommunication">Media & Communication</Trans>
                </option>
                <option value="Automotive"><Trans i18nKey="automotive">Automotive</Trans></option>
                <option value="Retail"><Trans i18nKey="retail">Retail</Trans></option>
                <option value="Health Care"><Trans i18nKey="healthCare">Health Care</Trans></option>
                <option value="Food & Beverages"><Trans i18nKey="foodAndBeverages">Food & Beverages</Trans></option>
                <option value="Travel & Tours"><Trans i18nKey="travelAndTours">Travel & Tours</Trans></option>
                <option value="E-Commerce"><Trans i18nKey="ecommerce">E-Commerce</Trans></option>
                <option value="Government & Public Service"><Trans i18nKey="governmentAndPublicService">Government & Public Service</Trans></option>
                <option value="Real Estate & Facility Management">
                  <Trans i18nKey="realEstateAndFacilityManagement">Real Estate & Facility Management</Trans>
                </option>
                <option value="Consumer Goods">
                  <Trans i18nKey="consumerGoods">Consumer Goods</Trans>
                </option>
                <option value="Logistics And Transportation Industry">
                  <Trans i18nKey="logisticsAndTransportationIndustry">Logistics And Transportation Industry</Trans>
                </option>
                <option value="Oil And Gas Companies">
                  <Trans i18nKey="oilAndGasCompanies">Oil And Gas Companies</Trans>
                </option>
                <option value="Education Solutions"><Trans i18nKey="educationSolutions">Education Solutions</Trans></option>
              </select>
            </div>

            <div class="mb-4 col-12 col-lg-6">
              <select
                class="form-select"
                id="referalDropdown"
                name="Where Did You Hear About Us? "
                required
              >
                <option value="" selected>
                  <Trans i18nKey="whereDidYouHeardAboutUs">Where Did You Hear About Us? (*)</Trans>
                </option>
                <option value="Website"><Trans i18nKey="website">Website</Trans></option>
                <option value="Print Advertising/Brochure">
                  <Trans i18nKey="printAdvertising">Print Advertising/Brochure</Trans>
                </option>
                <option value="Event/Webinar"><Trans i18nKey="event">Event/Webinar</Trans></option>
                <option value="Referal From Tafaseel Client">
                  <Trans i18nKey="referralFromTafaseelClient">Referal From Tafaseel Client</Trans>
                </option>
                <option value="Tafaseel Local Subsidiary">
                  <Trans i18nKey="tafaseelLocalSubsidary">Tafaseel Local Subsidiary</Trans>
                </option>
                <option value="Social Media"><Trans i18nKey="socialMedia">Social Media</Trans></option>
              </select>
            </div>
          </div>

          <div class="mb-4">
            <textarea
              name="MESSAGE"
              class="form-control"
              placeholder={t('yourMessage')}
              id="floatingTextarea2"
              style={{ height: "100px", fontFamily: "sans-serif" }}
              required
            ></textarea>
          </div>
          <div class="mb-4">
            <label
              class="form-check-label text-start text-black"
              for="exampleCheck1"
            >
              <input
                type="checkbox"
                name="Consent Checkbox"
                className=""
                required
              ></input>
              <Trans i18nKey="consentCheck">
                {" "} I consent to receive relevant industry news, analyst reports,
                white papers, case studies, and product information from Tafaseel.
                Tafaseel will never share or sell your information to third
                parties.{" "}
              </Trans>
              <a
                href="https://www.tafaseel.ae/#/privacy-policy/"
                target="_blank"
                className="privacy-anchor"
              >
                <Trans i18nKey="seeTafaseelPrivacyPolicy">See Tafaseel's Privacy Policy</Trans>
              </a>
            </label>
          </div>
          <button type="submit" class="btn col-12 col-md-5">
            <Trans i18nKey="submit">Submit</Trans>
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnquiryForm;
