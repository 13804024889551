import Accordion from "react-bootstrap/Accordion";

import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import EnquiryFormSmall from "../EnquirFormSmall";
import { Trans, useTranslation } from "react-i18next";

const MarketingLanding = (props) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const [counterOn, setCounterOn] = useState(false);
  return (
    <div className="cont-services-digital-cx">
      <Helmet>
        <title>
          Amplify Your Brand: Marketing Outsourcing with Tafaseel BPO
        </title>
        <meta
          name="description"
          content="Elevate your brand's presence with Tafaseel BPO's marketing outsourcing solutions. Collaborate with our skilled marketing professionals to create impactful campaigns, enhance customer engagement, and achieve remarkable results. Benefit from our strategic insights, creative prowess, and data-driven approach to maximize your marketing success."
        />
      </Helmet>
      <div className="marketing-img0  align-items-center mt-md-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-6 mt-5">
            <h1 className="mb-5"><Trans i18nKey="marketingOutSourcing">Marketing Outsourcing</Trans></h1>
          </div>
          <div className="col-12 col-lg-6">
            <EnquiryFormSmall subProp={props.subProp}></EnquiryFormSmall>
          </div>
        </div>
      </div>

      {/* ////////////////Intelligent solutions/////////////////// */}
      <div className="container-fluid intelligent-text">
        <div className="row align-items-center">
          <div class="col-12 col-md-6 p-5" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'start' }}>
            <h3 data-aos="fade-up">
              <Trans i18nKey="marketingOutsourcing">What is Marketing Outsourcing?</Trans>
            </h3>
            <p className="pe-4" data-aos="fade-up">
              <Trans i18nKey="marketingOutsourcingDescription">
                Marketing outsourcing involves partnering with a specialized firm like Tafaseel BPO to handle various aspects of your marketing function. By outsourcing your marketing tasks, you can tap into a dedicated team of marketing experts who excel in digital marketing, content creation, social media management, SEO, and more. This allows you to focus on core business activities while benefiting from a tailored and results-driven marketing strategy.
              </Trans>
            </p>

          </div>
          <div class="col-12 col-md-6 p-5" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'start' }} data-aos="fade-up">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header><Trans i18nKey="costEfficiencyTitle">Cost Efficiency</Trans></Accordion.Header>
                <Accordion.Body>
                  <Trans i18nKey="marketingOutsourcingCostEfficiency">
                    By outsourcing your marketing function to Tafaseel BPO, you
                    can significantly reduce costs associated with hiring,
                    training, and managing an in-house marketing team. Our
                    cost-effective solutions provide access to skilled
                    professionals without the burden of additional expenses.
                  </Trans>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <Trans i18nKey="salesOutsourcingExpertiseAndScalability">Expertise and Scalability</Trans>
                </Accordion.Header>
                <Accordion.Body>
                  <Trans i18nKey="salesOutsourcingExpertiseAndScalabilityDescriptionMarketing">
                    Tafaseel BPO brings a team of experienced marketing
                    professionals who have in-depth knowledge of the latest
                    marketing trends and techniques. Our scalable solutions allow
                    you to adjust your marketing efforts based on your business
                    needs, ensuring optimal resource allocation.
                  </Trans>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <Trans i18nKey="accessToAdvancedToolsAndTechnologies">Access to Advanced Tools and Technologies</Trans>
                </Accordion.Header>
                <Accordion.Body>
                  <Trans i18nKey="accessToAdvancedToolsAndTechnologiesDescription">
                    As a leading marketing outsourcing provider, Tafaseel BPO
                    invests in cutting-edge marketing tools and technologies. By
                    partnering with us, you gain access to advanced analytics,
                    automation software, and marketing platforms that can enhance
                    your marketing effectiveness.
                  </Trans>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <Trans i18nKey="strategicFocusAndInnovation">Strategic Focus and Innovation</Trans>
                </Accordion.Header>
                <Accordion.Body>
                  <Trans i18nKey="strategicFocusAndInnovationDescription">
                    By outsourcing your marketing function, you can free up your
                    internal resources to focus on core competencies and strategic
                    initiatives. This enables you to drive innovation, make
                    data-driven decisions, and stay ahead of the competition in
                    the dynamic marketplace.
                  </Trans>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </div>
        </div>
      </div>

      <div
        id="carouselExampleFade"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card col-lg-3">
                <div class="card-body">
                  <h5 class="card-title"><Trans i18nKey="tailoredApproach">Tailored Approach</Trans></h5>
                  <p class="card-text p-3">
                    <Trans i18nKey="tailoredApproachDescription">
                      We understand that every business has unique marketing requirements. Our marketing outsourcing solutions are customized to align with your specific needs, target audience, and industry. We work closely with you to develop a comprehensive marketing strategy tailored to your business goals.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 className="card-title">
                    <Trans i18nKey="skilledMarketingProfessionalsTitle">
                      Skilled Marketing Professionals
                    </Trans>
                  </h5>
                  <p className="card-text p-3">
                    <Trans i18nKey="skilledMarketingProfessionalsDescription">
                      Tafaseel BPO brings a team of highly skilled marketing professionals with expertise in various marketing disciplines. From digital marketing and content creation to social media management and SEO, our experts ensure your marketing efforts are executed with excellence.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title">
                    <Trans i18nKey="cuttingEdgeTechnologiesTitle">
                    Cutting-edge Tools and Technologies
                    </Trans>
                  </h5>
                  <p class="card-text p-3">
                    <Trans i18nKey="cuttingEdgeTechnologiesDescription">
                    We stay up to date with the latest marketing trends and
                    leverage advanced tools and technologies to deliver
                    exceptional results. Our marketing strategies are backed by
                    data-driven insights and innovative approaches that can
                    drive your business growth.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="cards-wrapper" data-aos="fade-up">
              <div class="card col-lg-3">
                <div class="card-body">
                  <h5 class="card-title"><Trans i18nKey="flexibleScalableSolutions">Flexible and Scalable Solutions</Trans></h5>
                  <p class="card-text p-3">
                    <Trans i18nKey="flexibleScalableSolutionsDescription">
                      Tafaseel BPO provides flexible and scalable marketing solutions that can adapt to your changing business needs. Whether you need ongoing marketing support or specific campaign assistance, we can tailor our services to meet your requirements.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block col-md-3">
                <div class="card-body">
                  <h5 class="card-title"><Trans i18nKey="tailoredApproach">Tailored Approach</Trans></h5>
                  <p class="card-text p-3">
                    <Trans i18nKey="tailoredApproachDescription">
                      We understand that every business has unique marketing requirements. Our marketing outsourcing solutions are customized to align with your specific needs, target audience, and industry. We work closely with you to develop a comprehensive marketing strategy tailored to your business goals.
                    </Trans>
                  </p>
                </div>
              </div>
              <div class="card d-none d-lg-block">
                <div class="card-body">
                  <h5 className="card-title">
                    <Trans i18nKey="skilledMarketingProfessionalsTitle">
                      Skilled Marketing Professionals
                    </Trans>
                  </h5>
                  <p className="card-text p-3">
                    <Trans i18nKey="skilledMarketingProfessionalsDescription">
                      Tafaseel BPO brings a team of highly skilled marketing professionals with expertise in various marketing disciplines. From digital marketing and content creation to social media management and SEO, our experts ensure your marketing efforts are executed with excellence.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default MarketingLanding;
