import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";

const LogoutButton = () => {
  const Navigate = useNavigate();
  const handleLogoutFun = async () => {
    try {
      await signOut(auth);
      alert("User is signed out");
      Navigate("/");
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <button className="btn btn-danger ms-3 rounded-pill" onClick={handleLogoutFun}>
        Logout
      </button>
    </>
  );
};
export default LogoutButton;
