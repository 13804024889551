import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useUserContext } from "../Context/UserContext";
import LogoutButton from "./LogoutButton";
import { db } from "../../firebaseConfig";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Button } from "react-bootstrap";
import CountriesList from "./CountriesList";
import { Link } from "react-router-dom";
import LoginNavComp from "./LoginNav";
import { Trans, useTranslation } from "react-i18next";

const SalesPage = () => {
  const {t} = useTranslation()
  const { userInfo } = useUserContext();
  // console.log(userInfo);

  //filter Status
  const [statusFilter, setStatusFilter] = useState("All");
  const [fieldFilter, setFieldFilter] = useState("None");
  //read
  const [salesData, setSalesData] = useState("");
  const getSalesData = async () => {
    const lowerStatusFilter = statusFilter.toLowerCase();
    try {
      let salesDataQuery;
      // console.log(fieldFilter, statusFilter);

      if (fieldFilter === "None" || statusFilter === "All") {
        salesDataQuery = await getDocs(
          query(collection(db, "sales-leads"), orderBy("Timestamp", "desc"))
        );
        // console.log(salesDataQuery);
      } else {
        salesDataQuery = await getDocs(
          query(
            collection(db, "sales-leads"),
            where(fieldFilter, "==", statusFilter),
            // where(fieldFilter, ">=", statusFilter),
            // where(fieldFilter, "<=", statusFilter + "\uf8ff"),
            // orderBy(fieldFilter),
            orderBy("Timestamp", "desc")
          )
        );
      }

      // salesDataQuery = await getDocs(
      //   query(collection(db, "sales-leads"), orderBy("Timestamp", "desc"))
      // );

      // console.log(movieData);
      const filterData = salesDataQuery.docs.map((items) => {
        return {
          ...items.data(),
          id: items.id,
        };
      });
      setSalesData(filterData);
    } catch (err) {
      console.log(err);
    }
  };

  ///color test
  // const [leadStatusCol, setLeadStatusCol] = useState("");

  useEffect(() => {
    getSalesData();
  }, []);
  // fieldFilter statusField
  //Status Val

  // console.log(formStatusVal);
  // console.log(salesData[0].id);
  const handleFormStatusChangeFun = async (Status, DocId) => {
    // console.log(Status, DocId);
    try {
      const docRef = doc(db, "sales-leads", DocId); // Update 'sales-leads' with your collection name
      await updateDoc(docRef, {
        LeadStatus: Status,
      });
      console.log("Document updated successfully");
      getSalesData();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  //for changing colour
  // console.log(salesData);

  // console.log(statusFilter);
  // const percentage = 7;

  const filterFun = () => {
    getSalesData();
  };

  const percentage = salesData.length !== 0 ? salesData.length : 0;
  return (
    <div className="container-fluid mt-5 pt-md-5 sales-dash-cont  pb-md-5">
      <div className="row justify-content-evenly ">
        <LoginNavComp></LoginNavComp>

        <h2>Sales Dashboard</h2>

        <div className="container-fluid mt-2 col-12 col-lg-6">
          <div className="row justify-content-between p-3 align-items-center mb-5">
            <div className="logout-cont d-flex p-3 justify-content-center  align-items-center">
              <div className="col-8 text-start">
                <h4 className="mb-0">
                  <num1>{userInfo.user.displayName}</num1>
                </h4>
              </div>

              <div className="col-4 text-end">
                <LogoutButton></LogoutButton>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 ">
          <div className="row justify-content-center align-items-center">
            <h1>Total Leads</h1>
            <div style={{ width: 250, height: 250 }} className="mb-5">
              <CircularProgressbar value={percentage} text={`${percentage}`} />
            </div>

            <h1>Sales Leads</h1>

            <div class="container mx-3">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <label for="inputField" class="form-label">
                    <h4>Filter Data</h4>
                  </label>
                  <div class="input-group row">
                    <div className="col pe-0">
                      {fieldFilter === "LeadStatus" ? (
                        <select
                          className="form-select col-8"
                          onChange={(e) => setStatusFilter(e.target.value)}
                        >
                          <option value={statusFilter}>{statusFilter}</option>
                          <option value="All">All</option>
                          <option value="Hot">Hot</option>
                          <option value="Cold">Cold</option>
                          <option value="Warm">Warm</option>
                          <option value="Not Contacted">Not Contacted</option>
                        </select>
                      ) : fieldFilter === "Source" ? (
                        <select
                          className="form-select col-8"
                          onChange={(e) => setStatusFilter(e.target.value)}
                        >
                          {/* <option value={statusFilter}>{statusFilter}</option> */}
                          <option value="All">All</option>
                          <option value="Organic">Organic</option>
                          <option value="Paid">Paid</option>
                        </select>
                      ) : fieldFilter === "Country To Be Served?" ? (
                        <select
                          className="form-select col-8"
                          onChange={(e) => setStatusFilter(e.target.value)}
                        >
                          <option value="All">All</option>
                          <CountriesList></CountriesList>
                        </select>
                      ) : fieldFilter === "Industry" ? (
                        <select
                          className="form-select col-8"
                          onChange={(e) => setStatusFilter(e.target.value)}
                        >
                          <option value="All">All</option>

                          <option value="Media & Communication">
                            <Trans i18nKey="mediaAndCommunication">Media & Communication</Trans>
                          </option>
                          <option value="Automotive"><Trans i18nKey="automotive">Automotive</Trans></option>
                          <option value="Retail"><Trans i18nKey="retail">Retail</Trans></option>
                          <option value="Health Care"><Trans i18nKey="healthCare">Health Care</Trans></option>
                          <option value="Food & Beverages"><Trans i18nKey="foodAndBeverages">Food & Beverages</Trans></option>
                          <option value="Travel & Tours"><Trans i18nKey="travelAndTours">Travel & Tours</Trans></option>
                          <option value="E-Commerce"><Trans i18nKey="ecommerce">E-Commerce</Trans></option>
                          <option value="Government & Public Service">
                            <Trans i18nKey="governmentAndPublicService">Government & Public Service</Trans>
                          </option>
                          <option value="Real Estate & Facility Management">
                            <Trans i18nKey="realEstateAndFacilityManagement">Real Estate & Facility Management</Trans>
                          </option>
                          <option value="Consumer Goods">
                            <Trans i18nKey="consumerGoods">Consumer Goods</Trans>
                            </option>
                          <option value="Logistics And Transportation Industry">
                            <Trans i18nKey="logisticsAndTransportationIndustry">Logistics And Transportation Industry</Trans>
                          </option>
                          <option value="Oil And Gas Companies">
                            <Trans i18nKey="oilAndGasCompanies">Oil And Gas Companies</Trans>
                          </option>
                          <option value="Education Solutions">
                            <Trans i18nKey="educationSolutions">Education Solutions</Trans>
                          </option>
                        </select>
                      ) : fieldFilter === "SUBJECT" ? (
                        <select
                          className="form-select col-8"
                          onChange={(e) => setStatusFilter(e.target.value)}
                        >
                          <option value="All">All</option>
                          <option value="IT Outsourcing"><Trans i18nKey="itOutSourcing">IT Outsourcing</Trans></option>
                          <option value="Finance Operations Outsourcing">
                            <Trans i18nKey="financeOperationOutSourcing">Finance Operations Outsourcing</Trans>
                          </option>
                          <option value="Sales Operations Outsourcing">
                            <Trans i18nKey="salesOperationOutsourcing">Sales Operations Outsourcing</Trans>
                          </option>
                          <option value="Marketing Operations Outsourcing">
                            <Trans i18nKey="marketingOperationsOutSourcing">Marketing Operations Outsourcing</Trans>
                          </option>
                          <option value="Customer Service Outsourcing">
                            <Trans i18nKey="customerServiceOutsourcing">Customer Service Outsourcing</Trans>
                          </option>
                          <option value="Contact Center Outsourcing">
                            <Trans i18nKey="contactCenter">Contact Center Outsourcing</Trans>
                          </option>
                          <option value="Human Resource Outsourcing">
                            <Trans i18nKey="humanResourceOutSourcing">Human Resource Outsourcing</Trans>

                          </option>
                          <option value="Procurement Outsourcing">
                            <Trans i18nKey="procurementOutSourcing">Procurement Outsourcing</Trans>
                          </option>
                          <option value="Warehousing Outsourcing">
                            <Trans i18nKey="warehousingOutsourcing">Warehousing Outsourcing</Trans>
                          </option>
                          <option value="Transportation Outsourcing">
                            <Trans i18nKey="transportationOutsourcing">Transportation Outsourcing</Trans>
                          </option>
                          <option value="Accommodation Outsourcing">
                            <Trans i18nKey="accommodationOutsourcing">Accommodation Outsourcing</Trans>
                          </option>
                          <option value="Knowledge Process Outsourcing">
                            <Trans i18nKey="knowledgeProcessOutsourcing">Knowledge Process Outsourcing</Trans>
                          </option>
                          <option value="CX & Digital Transformation Outsourcing">
                            <Trans i18nKey="cxAndDigitalTransformationOutsourcing">CX & Digital Transformation Outsourcing</Trans>
                          </option>
                          <option value="Workspace">Workspace</option>
                        </select>
                      ) : fieldFilter === "Timestamp" ? (
                        <input
                          type="date"
                          id="calendarInput"
                          name="calendarInput"
                          className="form-control w-100"
                          onChange={(e) => setStatusFilter(e.target.value)}
                        ></input>
                      ) : (
                        <select className="form-select col-8">
                          <option value="All">All</option>
                        </select>
                        // <input
                        //   type="text"
                        //   class="form-control col-8"
                        //   id="inputField"
                        //   placeholder="Enter text"
                        //   onChange={(e) => setStatusFilter(e.target.value)}
                        // ></input>
                      )}
                    </div>

                    <div className="col-3 ps-0">
                      <select
                        className="form-select col-8"
                        onChange={(e) => setFieldFilter(e.target.value)}
                      >
                        <option value="None">None</option>
                        {/* <option value="FIRST NAME">Name</option> */}
                        {/* <option value="EMAIL">Email</option> */}
                        {/* <option value="PHONE">Phone</option> */}
                        {/* <option value="COMPANY">Company</option> */}
                        {/* <option value="Timestamp">Date</option> */}

                        <option value="Country To Be Served?">
                          Country to serve
                        </option>
                        {/* <option value="JOB">Job Title</option> */}
                        <option value="Industry">Industry</option>
                        <option value="SUBJECT">Subject</option>
                        <option value="Source">Source</option>
                        <option value="LeadStatus">Status</option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="col-3 btn btn-danger mt-3 mb-4"
                    onClick={filterFun}
                  >
                    {" "}
                    Filter{" "}
                  </button>
                </div>
              </div>
            </div>
            {/* 
            <div className="col-4 d-flex justify-content-center align-items-center mb-2">
              <p className="col-4 mb-0">Filer Data: </p>
              <select
                className="form-select col-8"
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value={statusFilter}>{statusFilter}</option>
                <option value="All">All</option>
                <option value="Hot">Hot</option>
                <option value="Cold">Cold</option>
                <option value="Warm">Warm</option>
                <option value="Not Contacted">Not Contacted</option>
              </select>
            </div> */}

            {salesData.length !== 0 ? (
              <div className="col-12 table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Company</th>
                      <th scope="col">Job Title</th>
                      <th scope="col">Country To Serve</th>
                      <th scope="col">Industry</th>
                      <th scope="col">Message</th>
                      <th scope="col">Source</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesData.map((val, index) => {
                      const jsDate0 = val.Timestamp;
                      const jsDate = new Date(
                        jsDate0.seconds * 1000 + jsDate0.nanoseconds / 1000000
                      );
                      // console.log(jsDate);
                      // console.log(val.MESSAGE);

                      const monthAbbreviation = jsDate.toLocaleString(
                        "default",
                        { month: "short" }
                      );
                      const day = jsDate.getDate();
                      const time = jsDate.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      });

                      const year = jsDate.getFullYear().toString().slice(-2);
                      const formattedDate = `${monthAbbreviation} ${day}, ${year}, ${time}`;
                      // console.log(formattedDate);
                      return (
                        <tr key={index} style={{ fontSize: "0.9em" }}>
                          {/* <th scope="row">
                          <num1>{formattedDate}</num1>
                        </th> */}
                          <td className="col-1">
                            <num1>{formattedDate}</num1>
                          </td>
                          <td className="col-1">
                            <num1>
                              {val.NAME !== undefined
                                ? val.NAME
                                : val["FIRST NAME"]}
                            </num1>
                          </td>
                          <td className="col-1">
                            <num1>{val["LAST NAME"]}</num1>
                          </td>
                          <td className="col-1">
                            <num1>{val.EMAIL}</num1>
                          </td>
                          <td className="col-1">
                            <num1>{val.PHONE}</num1>
                          </td>
                          <td className="col">
                            <num1>{val.COMPANY}</num1>
                          </td>
                          <td className="col-1">
                            <num1>{val.JOB}</num1>
                          </td>
                          <td className="col-1">
                            <num1>{val["Country To Be Served?"]}</num1>
                          </td>
                          <td className="col-1">
                            <num1>{val.Industry}</num1>
                          </td>
                          <td className="col-2 sales-message-container">
                            <textarea
                              className="form-control"
                              id="exampleTextarea"
                              rows="5"
                              readOnly
                              value={val.MESSAGE}
                            ></textarea>
                            {/* <num1>{val.MESSAGE}</num1> */}
                          </td>
                          <td className="col ">
                            <num1>{val.Source}</num1>
                          </td>
                          <td className="col">
                            <num1>{val.Subject}</num1>
                          </td>
                          <td className="col-2">
                            <select
                              className={`form-select ${val.LeadStatus === "Hot"
                                  ? "sales-status-dropdown-hot"
                                  : val.LeadStatus === "Cold"
                                    ? "sales-status-dropdown-cold"
                                    : val.LeadStatus === "Warm"
                                      ? "sales-status-dropdown-warm"
                                      : ""
                                }`}
                              value={val.LeadStatus}
                              onChange={(e) => {
                                // setLeadStatusCol(e.target.value);
                                // setLeadStatus(e.target.value)
                                const Status = e.target.value;
                                const DocId = val.id;

                                handleFormStatusChangeFun(Status, DocId);
                                // console.log(val.id);
                              }}
                            // style={{ fontSize: "0.9em" }}
                            >
                              {/* <option value={val.LeadStatus} selected>
                                {val.LeadStatus}
                              </option> */}
                              <option value="Hot">Hot</option>
                              <option value="Cold">Cold</option>
                              <option value="Warm">Warm</option>
                              <option value="Not Contacted">
                                Not Contacted
                              </option>
                            </select>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="mt-3">
                <h4 className="text-center">No data available</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesPage;
