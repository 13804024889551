import { useState } from "react";
import { auth } from "../../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const LoginPage = (props) => {
  const {i18n} = useTranslation();
  const [emailInput, setEmailInput] = useState("");
  const [passInput, setPassInput] = useState("");

  // const handleFormFun = async (e) => {
  //   e.preventDefault();
  //   console.log(emailInput);
  //   console.log(passInput);
  //   // const emailRegex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
  //   // let emailInput1;
  //   // if (emailRegex.test(emailInput)) {
  //   //   //   console.log("Invalid email format");
  //   //   emailInput1 = emailInput;
  //   // } else {
  //   //   emailInput1 = emailInput + "@test.com";
  //   // }

  //   // try {
  //   //   await signInWithEmailAndPassword(auth, emailInput1, passInput);
  //   //   alert("User logged-in successfully!");

  //   // } catch (err) {
  //   //   console.log(err);
  //   // }

  //   setEmailInput("");
  //   setPassInput("");
  // };

  // console.log(auth?.currentUser?.email);
  const Navigate = useNavigate();
  const handleFormFun = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,4}$/;
    let emailInput1;
    if (emailRegex.test(emailInput)) {
      //   console.log("Invalid email format");

      emailInput1 = emailInput;
    } else {
      emailInput1 = emailInput + "@tafaseel.ae";
    }
    // console.log(emailInput1);
    // console.log(passInput);

    try {
      await signInWithEmailAndPassword(auth, emailInput1, passInput);
      alert("User logged-in successfully!");
      //   checkUserLogin();
      Navigate("/employee-dashboard");
    } catch (err) {
      alert(err);
    }

    props.handleClose();
    setEmailInput("");
    setPassInput("");
  };

  return (
    <div className="container" style={{direction: `${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}} >
      <div className="row justify-content-center">
        <form className="col-11 col-md-10">
          <div class="mb-3">
            <label for="exampleInputEmail1" style={{direction: 'rtl !important'}} class="form-label">
              <Trans i18nKey="username">Username</Trans>
            </label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
            ></input>
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
            <Trans i18nKey="password">Password</Trans>
            </label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              value={passInput}
              onChange={(e) => setPassInput(e.target.value)}
            ></input>
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              class="btn justify-content-center  col-5  btn-mobile"
              onClick={handleFormFun}
            >
              <Trans i18nKey="login">Login</Trans>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LoginPage;
