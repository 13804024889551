import { Trans } from "react-i18next";

const TermsOfService = () => {
  return (
    <div className="cont-privacy-policy pt-lg-5">
      <div className="privacy-img">
        <h1 className="mb-5"><Trans i18nKey="termsAndConditions">Terms & Conditions</Trans></h1>
      </div>

      {/* <h1>Hello</h1> */}
      <div className="footer-notice-text text-start">
        <div className="container ps-5 pe-5 ">
          <div className="row">
            <h5>
              <Trans i18nKey="termsOfUse">Terms of Use</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="termsOfUseDescription">
                These Website Standard Terms of Use (these "Terms") contained herein on
                this webpage, shall govern your use of the website of Tafaseel BPO,
                including all pages within this website (collectively referred to herein
                below as this "Website"). These Terms apply in full force and effect to
                your use of this Website, and by using this Website, you expressly accept
                all terms and conditions contained herein in full. You must not use this
                Website if you have any objection to any of these Terms.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="termsOfUseMinors">
                This Website is not for use by any minors (defined as those who are not at
                least 18 years of age) without adult consent or supervision.
              </Trans>
            </p>

          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5>
              <Trans i18nKey="intellectualPropertyRights">Intellectual Property Rights</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="intellectualPropertyRightsDescription">
                Under these Terms, Tafaseel BPO and/or its licensors own all rights to the
                intellectual property and material contained on this Website, and all such
                rights are reserved.
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="intellectualPropertyRightsLicense">
                You are granted a limited license only, subject to the restrictions
                provided in these Terms, for purposes of viewing the material contained on
                this Website.
              </Trans>
            </p>

          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5>
              <Trans i18nKey="restrictions">Restrictions</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="restrictionsIntro">
                You are expressly and emphatically restricted from all of the following:
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionPublishing">
                Publishing any website material in any media;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionCommercializing">
                Selling, sublicensing, and/or otherwise commercializing any Website material;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionPubliclyPerforming">
                Publicly performing and/or showing any Website material;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionDamagingUse">
                Using this Website in any way that is, or may be, damaging to this Website;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionImpactingAccess">
                Using this Website in any way that impacts user access to this Website;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionContraryToLaws">
                Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionDataMining">
                Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website or while using this Website;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionUnauthorizedAdvertising">
                Using this Website to engage in any unauthorized advertising or marketing;
              </Trans>
            </p>
            <p className="p-blue">
              <Trans i18nKey="restrictionConfidentialInfo">
                Certain areas of this Website are restricted from access by you, and Tafaseel BPO may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential, and you must maintain confidentiality of such information.
              </Trans>
            </p>

          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5>
              <Trans i18nKey="yourContent">Your Content</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="yourContentDescription">
                In these Website Standard Terms and Conditions, "Your Content" shall mean
                any audio, video, text, images, or other material you choose to display on
                this Website. With respect to Your Content, by displaying it, you grant
                Tafaseel BPO a non-exclusive, worldwide, irrevocable, royalty-free,
                sublicensable license to use, reproduce, adapt, publish, translate, and
                distribute it in any and all media. Your Content must be your own, and it
                must not be infringing on any third party's rights. Tafaseel BPO reserves
                the right to remove any of your content from this website at any time, and
                for any reason, without notice.
              </Trans>
            </p>

          </div>
        </div>
        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5>
              <Trans i18nKey="noWarranties">No Warranties</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="noWarrantiesDescription">
                This Website is provided "as is," with all faults, and Tafaseel BPO makes
                no express or implied representations or warranties of any kind related to
                this Website or the materials contained on this website. Additionally,
                nothing contained on this Website shall be construed as providing consult
                or advice to you.
              </Trans>
            </p>

          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4 ">
          <div className="row">
            <h5>
              <Trans i18nKey="limitationOfLiability">Limitation of Liability</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="limitationOfLiabilityDescription">
                In no event shall Tafaseel BPO, nor any of its officers, directors, and
                employees, be liable to you for anything arising out of or in any way
                connected with your use of this website, whether such liability is under
                contract, tort, or otherwise. Tafaseel BPO, including its officers,
                directors, and employees, shall not be liable for any indirect,
                consequential, or special liability arising out of or in any way related
                to your use of this Website.
              </Trans>
            </p>

          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="indemnification">Indemnification</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="indemnificationDescription">
                You hereby indemnify to the fullest extent Tafaseel BPO from and against
                any and all liabilities, costs, demands, causes of action, damages, and
                expenses (including reasonable attorney's fees) arising out of or in any
                way related to your breach of any of the provisions of these Terms.
              </Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="severability">Severability</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="severabilityDescription">
                If any provision of these Terms is found to be unenforceable or invalid
                under any applicable law, such unenforceability or invalidity shall not
                render these Terms unenforceable or invalid as a whole, and such
                provisions shall be deleted without affecting the remaining provisions
                herein.
              </Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="variationOfTerms">Variation of Terms</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="variationOfTermsDescription">
                Tafaseel BPO is permitted to revise these Terms at any time as it sees
                fit, and by using this Website, you are expected to review such Terms on
                a regular basis to ensure you understand all terms and conditions
                governing the use of this Website.
              </Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="assignment">Assignment</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="assignmentDescription">
                Tafaseel BPO shall be permitted to assign, transfer, and subcontract its
                rights and/or obligations under these Terms without any notification or
                consent required. However, you shall not be permitted to assign,
                transfer, or subcontract any of your rights and/or obligations under
                these Terms.
              </Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="refunds">Refunds</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="refundsDescription">
                Tafaseel BPO services are provided with no contractual payment
                obligations; every payment made to Tafaseel BPO is considered to be paid
                in full in advance and will be non-refundable.
              </Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="cancellationPolicy">Cancellation Policy</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="cancellationPolicyDescription">
                If you desire to cancel a Tafaseel BPO service bought and paid in
                advance, you should write an email to info@tafaseel.ae requesting so,
                without the need to justify your motives. No refund requests will be
                entertained in case of early cancellation of services bought.
              </Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="entireAgreement">Entire Agreement</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="entireAgreementDescription">
                These Terms, including any legal notices and disclaimers contained on
                this Website, constitute the entire agreement between Tafaseel BPO and
                you in relation to your use of this Website and supersede all prior
                agreements and understandings with respect to the same.
              </Trans>
            </p>
          </div>
        </div>

        <div className="container ps-5 pe-5 pt-3 pt-md-4">
          <div className="row">
            <h5>
              <Trans i18nKey="governingLawJurisdiction">Governing Law & Jurisdiction</Trans>
            </h5>
            <p className="p-blue">
              <Trans i18nKey="governingLawJurisdictionDescription">
                These Terms will be governed by and construed in accordance with the laws
                of the United Arab Emirates, and you submit to the non-exclusive
                jurisdiction of the state and federal courts located in Ajman, United
                Arab Emirates, for the resolution of any disputes.
              </Trans>
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};
export default TermsOfService;
