

const WistiaVideoComp = (props) => {
    // console.log(props.linkProp);
     
    
    return(
        <div className="wistia-video d-block d-lg-none">
        <div className="wistia_responsive_padding">
          <div className="wistia_responsive_wrapper">
            <iframe
              src={props.linkProp}
              title="Comp 1 Video"
              allow="autoplay; fullscreen"
              allowtransparency="true"
              frameborder="0"
              class="wistia_embed"
              name="wistia_embed"
              msallowfullscreen
              width="100%"
              height="100%"
              controls="0"
              className="wistia-video"
            ></iframe>
          </div>
        </div>
      </div>
    )
}
export default WistiaVideoComp;